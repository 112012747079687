import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { performUserLoginProcess } from '@actions/auth';
import { setMessage } from '@actions/toast';
import {LOADING_TOAST } from '@utils/constants';
import {getServerConfig} from '@utils/configurations';
import NavigationBar from '@sharedComponents/Navbar';

const NAME_INPUT_ICON = require("@resources/icons/user-input.svg");
const PASSWORD_INPUT_ICON = require("@resources/icons/padlock.svg");

const Login = ({performUserLoginProcess, isLoggedIn}) => {
    const {t} = useTranslation('auth');
    const [state, setState] = useState({
        p_email: '',
        p_psw: '',
    });

    useEffect(() => {
        getServerConfig();
    }, []);

    const {is_visitor, p_email, p_psw} = state;
    
    if (isLoggedIn) return <Redirect to="/main"/>;

    const onChange = (e) => {
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        setState({...state, [nodeName]: nodeValue });
    };

    const onSubmit = async e => { 
        setMessage('', LOADING_TOAST);
        const email_number = p_email.replace(/\s/g, '');
        performUserLoginProcess(email_number, p_psw, is_visitor?1:0);  
        e.preventDefault();
    }

    return (
        <div className="container-front-page">
            <NavigationBar />
            <main className="main-content auth-container login-container">
                <div className="form-container">
                    <form className="auth-form dark-form">
                        {/* :: TITLE */}
                        <h2 className="form-header">{t('login')}</h2>
                        {/* :: EMAIL INPUT */}
                        <div className="form-group iconed-input">
                            <img src={NAME_INPUT_ICON} alt="user_icon" />
                            <input type="text" name="p_email" className="form-control dark-input" value={p_email} placeholder={t('email_or_cell')} onChange={e => onChange(e)}/>
                        </div>
                        {/* :: PASSWORD INPUT */}
                        <div className="form-group iconed-input">
                            <img src={PASSWORD_INPUT_ICON} alt="lock_icon" />
                            <input type="password" name="p_psw" className="form-control dark-input"  value={p_psw} placeholder={t('password')} onChange={e => onChange(e)} />
                        </div>
                        {/* :: FORGOT PASSWORD LINK */}
                        <Link to="/forgotPassword" type="button" className="button link-button text-small">{t("forgot_password")}</Link>
                        {/* :: BUTTONS */}
                        <div className="form-buttons clearfix ">
                            <Link to="/fasReq" className="button link-button text-no-underline left-sided-button">{t('fas_req')}</Link>
                            <button type="submit" className="button dark-button submit-button right-sided-button" onClick={e=> onSubmit(e)}>
                                {t('login')}</button>
                            <Link to="/registration" className="button dark-button mr-3 right-sided-button" >{t('sign_up')}</Link>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    )
}

Login.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
}

const mapDispatchToProps = {
    performUserLoginProcess,
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
})


export default connect(mapStateToProps, mapDispatchToProps)(Login);
