import React, { Fragment, useState } from 'react'
import { FormGroup, Col, CustomInput, Input, Label} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {DEFAULT_ARRIVAL_HOUR } from '@utils/constants';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { getInputDateFormatFromString } from '@utils/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { fixNewHostVisitFields } from '@utils/validations';
import { toastErrorMessage } from '@actions/toast';

const HostsFastVisitDataPage1 = ({flag, visit, toastErrorMessage}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
       flag: false,
    });

    const {p_date_time, p_time, p_period, p_id_rpp, p_detail, p_multi_entry, p_access } = visit || {};

    // ::: EVENT HANDLERS
    const onChange = (e) => {
        const nodeName = e.target.name;
        let nodeValue = e.target.value;
        const prevValue = visit[nodeName];
        try {
            visit[nodeName] = nodeValue;
            fixNewHostVisitFields(visit); //::>> Throws Error if a field is invalid
            setState({...state, flag: !state.flag});
        } catch (error) {
            visit[nodeName] = prevValue;
            toastErrorMessage(error.message);
        } 
    };

    const onChangeMultyEntry = (e, value) => { 
        visit['p_multi_entry'] =  value;
        setState({...state, flag: !state.flag});
    }

    const onChangePAccess = (e, value) => { 
        visit['p_access'] = value;
        setState({...state, flag: !state.flag});
      }

    
    return (
        <Fragment>
            <FormGroup>
                <FormGroup row>
                    {/* ::: VISIT DATE DATEPICKER INPUT */}
                    <Label sm={1}>{t("date")}</Label>
                    <Col md={5}>
                        <Input type="date" name="p_date_time" className="required" value={getInputDateFormatFromString(p_date_time)} onChange={(e) => onChange(e)}  />
                    </Col>
                    {/* ::: VISIT HOUR HOURPICKER INPUT */}
                    <Label sm={1}>{t("hour")}</Label>
                    <Col md={5}>
                        <Input type="time" className="required" value={p_time? p_time: DEFAULT_ARRIVAL_HOUR} name="p_time" onChange={(e) => onChange(e)} />
                    </Col>
                </FormGroup>
                {/* ::: VISIT PERIOD INPUT */}
                <FormGroup row>
                    <Label sm={2}>{t("period")}</Label>
                        <Col md={3}> 
                            <Input type="number" name="p_period" value={p_period || 1} className="required" onChange={(e) => onChange(e)} />
                        </Col>
                </FormGroup>
                <div className="separator transparent mb-4"/>
                <FormGroup>
                    {/* ::: REASON OF VISIT SELECT INPUT */}
                    <FormGroup>
                        <Input type="select" name="p_id_rpp" className="required" value={p_id_rpp} onChange={e=> onChange(e)} >
                            <CustomSelectOption options={AppFunctionalityUsable.reasonsOfVisit} hasId={true}/>
                        </Input>
                    </FormGroup>
                    {/* ::: REASON OF VISIT TEXTAREA INPUT */}
                    <FormGroup> 
                        <Input type="textarea" name="p_detail" className="required" placeholder={t("reason")} value={p_detail} onChange={(e) => onChange(e)} />
                    </FormGroup>
                </FormGroup>
                <div className="separator transparent mb-4"/>
                {/* ::: MULTIPLE ENTRY CHECKBOX INPUT */}
                <FormGroup>
                    <CustomInput type="checkbox" id="p_multi_entry" checked={p_multi_entry === 1? true: false} label={t("mult_ent")} onChange={(e) => onChangeMultyEntry(e, p_multi_entry === 1? 0: 1)} />
                    <small className="form-text text-muted">{t('mult_ent_desc')}</small>
                </FormGroup>
                <div className="separator transparent mb-4"></div>
                {/* ::: ENTRY/EXIT CHECKBOX INPUT */}
                <FormGroup check inline>
                    <Label check>
                        <CustomInput id="chec1" label={t("entry_exit")} checked={p_access === 1? true: false} type="checkbox" onChange={(e) => onChangePAccess(e,1)} /> 
                    </Label>
                </FormGroup>
                {/* ::: EXIT CHECKBOX INPUT */}
                <FormGroup check inline>
                    <Label check>
                        <CustomInput id="chec13" label={t("exit")} checked={p_access === 1? false: true} type="checkbox" onChange={(e) => onChangePAccess(e, 0)} /> 
                    </Label>
                </FormGroup>
            </FormGroup>
        </Fragment>
    )
}

HostsFastVisitDataPage1.propTypes = {
    flag: PropTypes.bool,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    toastErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(HostsFastVisitDataPage1)
