import React, { Fragment, useState, useEffect } from 'react'
import { FormGroup, Row, Col, CustomInput, Input, Label, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {DEFAULT_ARRIVAL_HOUR, MIN_CHAR_FOR_SELECT_HOST, ROOT_URL } from '@utils/constants';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { doesArrayExist, buildEmployeeNameToIdMapViceVersa, getInputDateFormatFromString, isVisitToUpdate, isNotEmptyValue, capitalize, isNotEmptyObject, validateObjectParameter, getMomentFromString } from '@utils/utils';
import Extra from '@model/Extras/Extra';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadTemplateFile } from '@actions/admin';
import { v4 } from 'uuid';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { toastErrorMessage, toastWarningMessage } from '@actions/toast';
import { fixNewVisitorVisitFields, fixNewVisitorVisitFieldsWithSkippableParams } from '@utils/validations';
import DataListFormForUsers from '@components/Shared/FormElements/DataListFormForUsers';
import { getAppConfig } from '@utils/configurations';


function VisitDataPage1({flag, visit, uploadTemplateFile, hideCol, toastErrorMessage}) {
    const { t } = useTranslation('request');
    const { MAX_TIME_PERIOD } = getAppConfig();
    const validatedVisit = validateObjectParameter(visit);
    const [state, setState] = useState({
        loading: false,
        toShowDropdown: false,
        subTerminals: Object.keys(AppFunctionalityUsable.subTerminalNameTerminalIDMap),
        host_name: validatedVisit.host_fname?  `${validatedVisit.host_fname} ${validatedVisit.host_lname}` : '',
    });

    const {p_id_department, p_date_time, p_period, file_name, is_collective, p_tocompany} = validatedVisit;
    const p_time = getMomentFromString(p_date_time).format('HH:mm');
    const {p_id_company, loading, host_name, toShowDropdown, subTerminals} = state;
    
    const to_edit = isVisitToUpdate(validatedVisit);

    useEffect(() => {
        if(isNotEmptyValue(validatedVisit.p_id_company)) Extra.getFuncOfTerm(validatedVisit.p_id_company);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [p_id_company]);

    useEffect(() => { 
        if(isNotEmptyValue(validatedVisit.p_id_host)) { 
            const employeeMapCopy = buildEmployeeNameToIdMapViceVersa(Extra.usersOfTerminal);
            if(isNotEmptyObject(employeeMapCopy)){
                const hostName = employeeMapCopy[validatedVisit.p_id_host];
                setState({...state, host_name: capitalize(hostName)})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    // :::::: EVENT HANDLERS
    const onSelectHost = (e, hostName) => {
        try {
            console.log(`HOST NAME: ${hostName}`)
            validatedVisit.p_id_host = hostName;
            
            if(to_edit)
                fixNewVisitorVisitFieldsWithSkippableParams(validatedVisit, [p_period > MAX_TIME_PERIOD? "p_period":""]);
            else
                fixNewVisitorVisitFields(validatedVisit);

            setState({...state, toShowDropdown: false, host_name: capitalize(hostName)});
        } catch (error) {
            toastErrorMessage(error.message);
        }
    }

    const onChange = (e) => {
        const nodeName = e.target.name;
        let nodeValue = e.target.value;
        const prevValue = validatedVisit[nodeName];
        try {
            validatedVisit[nodeName] = nodeValue;

            if(to_edit)
                fixNewVisitorVisitFieldsWithSkippableParams(validatedVisit, [p_period > MAX_TIME_PERIOD? "p_period":""]);
            else
                fixNewVisitorVisitFields(validatedVisit); //::>> Throws Error if a field is invalid

            if (nodeName === "p_id_host") {
                setState({...state, host_name: nodeValue, toShowDropdown: isToShowDataList(nodeValue)});
            } else if (nodeName === "p_id_company"){
                const selectedOption = AppFunctionalityUsable.terminals.find(terminal => +terminal.code === +nodeValue);
                if(subTerminals.includes(selectedOption.name)) { 
                    const terminalCode = AppFunctionalityUsable.subTerminalNameTerminalIDMap[selectedOption.name];
                    setState({ ...state, [nodeName]: nodeValue});
                    validatedVisit[nodeName] = terminalCode;
                    validatedVisit["p_id_subcompany"] = +nodeValue;
                } else  {
                    setState({ ...state, [nodeName]: [nodeValue]});
                }
                
            } else {
                setState({...state, validatedVisit: validatedVisit});
            }
        } catch (error) {
            validatedVisit[nodeName] = prevValue;
            toastErrorMessage(error.message);
        }       
    };

    const onChangeSwitch = e => { 
        validatedVisit['is_collective'] = !is_collective;
        setState({...state, validatedVisit: validatedVisit});
    }
    
    const changeToHost = (e, value) => {
        validatedVisit['p_tocompany'] = value? 1:0;
        setState({...state, validatedVisit: validatedVisit});
    };

    const isToShowDataList = (host_name) => {
        let toShow = true;
        try {
            toShow = toShow && isNotEmptyValue(host_name);
            toShow = toShow && (host_name.length >= MIN_CHAR_FOR_SELECT_HOST);
        } catch (error) {
            toShow = false;
        }
        return toShow;
    }
    
    const onFileSelect = async (e) => { 
        const file = e.target.files[0];
        if (!file) return;
        setState({...state, selectedFile: file.name, loading: true});

        const data = new FormData();
        data.append("file", file);
        data.append("name", file.name);
        const usersOfFile = await uploadTemplateFile(data, 1);
        validatedVisit['p_users'] =  usersOfFile;
        setState({...state, loading: false});
    }

    // :::::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const _checkboxesForDestinationType = <FormGroup>
                                                <Row>
                                                    <Col md={3}>
                                                        <CustomInput type="checkbox" id="to_host_smt" checked={!validatedVisit.p_tocompany || false} label={t('to_hos')} onChange={e => changeToHost(e, false)} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <CustomInput type="checkbox" id="to_terminal" checked={validatedVisit.p_tocompany || false} label={t('to_thr')} onChange={e => changeToHost(e, true)} />
                                                    </Col>
                                                </Row>
                                            </FormGroup>

    const _textInputForHostSelection = <FormGroup className="light-form-group hidden-data-list-container">
                                            <DataListFormForUsers  toShow={toShowDropdown} inputFieldName="p_id_host" elements={Extra.usersOfTerminal} inputValue={host_name} onAction={onSelectHost}  />
                                            <Input autoComplete="off"  name="p_id_host" disabled={to_edit} list="employees" id="employee" placeholder={t("com_nam")} className="required" value={host_name} onChange={e => onChange(e)} />
                                        </FormGroup>

    const _switchInputForCollectiveVisits =  <FormGroup className="mt-4">
                                                <CustomInput type="switch" name="is_collective" checked={is_collective ? "checked" : false} id="custonSwithd" onChange={(e) => onChangeSwitch(e)} inline label={file_name? file_name:t("is_col")} />
                                                {loading && <img alt="loading" className="mx-auto my-2 loading-gif" src={require(`@resources/icons/loading.gif`)}/>}
                                            </FormGroup>
    
    const _viewOnlyListGroupForVisitorsList = <FormGroup>
                                        <ListGroup>
                                            {doesArrayExist(validatedVisit.grouped) && validatedVisit.grouped.map(vis => { 
                                                return <ListGroupItem key={v4()} className="justify-content-between">{vis.v_name}</ListGroupItem>
                                                })}
                                        </ListGroup>
                                    </FormGroup>

    const _editListGroupForProcessedVisitors = <Fragment>
                                                    <FormGroup>
                                                        <CustomInput type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="collectiveFile" label={t("cho_fil")} name="file" onChange={e => onFileSelect(e)}/>
                                                        <small><a className="text-green pl-1 link-text" href={`${ROOT_URL}/files/visitors/templates`}>{t('dow_tem_fil')}</a></small>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ListGroup>
                                                            {validatedVisit.p_users && validatedVisit.p_users.map(vis => { 
                                                                return <ListGroupItem key={vis.p_nid} className="justify-content-between">{vis.p_fname + ' ' + vis.p_lname} <Badge color={vis.error? "danger": vis.warning? "warning": "success"}>{vis.error? t(vis.messages.error) : vis.warning? t(vis.messages.warning):t('success')}</Badge></ListGroupItem>
                                                                })}
                                                        </ListGroup>
                                                    </FormGroup>
                                                </Fragment>


    // ::: CONDITIONALS FOR HTML ELEMENTS;
    const isToShowTypeDestinationCheckbox = !to_edit;
    const isToShowCollectiveVisitSwitchInput = !to_edit && !hideCol;
    const isToShowViewOnlyListOfVisitorsListGroup = to_edit;
    const isToShowEditListOfVisitorsListGroup = is_collective;

    return (
        <Fragment>
            {/* ::: CHECKBOXES FOR DESTINATION TYPE OF VISIT */}
            {isToShowTypeDestinationCheckbox && _checkboxesForDestinationType}
            <FormGroup>
                {/* ::: TERMINAL SELECTION */}
                <FormGroup>
                    <Input type="select" name="p_id_company" value={p_id_company} className="required" disabled={to_edit} onChange={e => onChange(e)}>
                        <CustomSelectOption options={AppFunctionalityUsable.terminals} hasId={true}/>
                    </Input>
                </FormGroup>
                {/* ::: DEPARTMENT SELECTION */}
                <FormGroup>
                    <Input type="select" name="p_id_department" value={p_id_department} className="required" disabled={to_edit} onChange={e => onChange(e)}>
                        <CustomSelectOption options={AppFunctionalityUsable.departments} hasId={true}/>
                    </Input>
                </FormGroup>
                {/* ::: HOST SELECTION TEXT INPUT WITH DATALIST */}
                {!p_tocompany && _textInputForHostSelection}
                <div className="separator transparent my-4"/>
                <FormGroup row>
                    {/* ::: DATE SELECTION INPUT */}
                    <Label sm={1}>{t("date")}</Label>
                    <Col md={5}>
                        <Input type="date" name="p_date_time" className="required" value={getInputDateFormatFromString(p_date_time)} onChange={(e) => onChange(e)}/>
                    </Col>
                    {/* ::: START HOUR OF VISIT SELECTION INPUT */}
                    <Label sm={1}>{t("hour")}</Label>
                    <Col md={5}>
                        <Input type="time" className="required" value={p_time? p_time: DEFAULT_ARRIVAL_HOUR} name="p_time" onChange={(e) => onChange(e)} />
                    </Col>
                </FormGroup>
                {/* ::: PERIOD OF VISIT SELECTION INPUT */}
                <FormGroup row>
                    <Label sm={2}>{t("period_hour")}</Label>
                        <Col md={3}> 
                            <Input type="number" name="p_period" disabled={p_period > MAX_TIME_PERIOD} value={p_period || 1} className="required" onChange={(e) => onChange(e)} />
                        </Col>
                </FormGroup>
                <div className="separator transparent my-4"/>
                {/* ::: COLLECTIVE VISITS SWITCH INPUT */}
                {isToShowCollectiveVisitSwitchInput && _switchInputForCollectiveVisits}
                {/* ::: LIST OF VISITORS LIST GROUP (VIEW ONLY) */}
                {isToShowViewOnlyListOfVisitorsListGroup && _viewOnlyListGroupForVisitorsList}
                {/* ::: LIST OF VISITORS LIST GROUP (TO DEDIT)  */}
                {isToShowEditListOfVisitorsListGroup && _editListGroupForProcessedVisitors}
            </FormGroup>
        </Fragment>
    )
}


VisitDataPage1.propTypes = {
    flag: PropTypes.bool,
    uploadTemplateFile: PropTypes.func.isRequired,
    toastWarningMessage: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    uploadTemplateFile,
    toastWarningMessage,
    toastErrorMessage,
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitDataPage1);
