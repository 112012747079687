import { ROOT_URL } from "./constants";
import defaultConfig from './defaultConfig';
import { sendRequestDataWithDefaultConfig } from "./utils";

let FUNCTIONALITY_CONFIG = {};

export const getServerConfig = async (forceGet=false) => { 

    try {
        if(!forceGet) {
            //::>> Check to see if an update has been gotten
            if(Object.keys(FUNCTIONALITY_CONFIG).length > 0)
                return;
            //::>> Otherwise it will get an update
        }
            
        const link = `${ROOT_URL}/admin/getConfig`;
        const response = await sendRequestDataWithDefaultConfig(link, {});
        FUNCTIONALITY_CONFIG = response;
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getAppConfig = () => {
    if (Object.keys(FUNCTIONALITY_CONFIG).length === 0) {
        let conf =  defaultConfig;
        return conf;
    } 
    return FUNCTIONALITY_CONFIG;
}

export const updateAppConfigurations = (updatedConfig) => { 
    FUNCTIONALITY_CONFIG = updatedConfig;
}
