import Material from "@model/Extras/Material";
import Vehicle from "@model/Extras/Vehicle";
import { getAppConfig } from "@utils/configurations";
import { ACCEPTED, ALL, CANCELED, DEFAULT_COMBOBOX_CODE, HOST, HOST_VISIT_STATE_CHANGE_CODE, REJECTED, REQUEST_PANEL, ROOT_URL, VISITS_LOCALSTORAGE_KEY } from "@utils/constants";
import { checkApiResponseForErrors, getDefaultVisitsTimeFrame, getInputDateFormatFromString, getLoggedUserStateNumber, getLoggedUserType, getMomentFromString, getObjectCopy, getObjectFromLocalStorage, getToday, getVisitTimeFrameFromMoment, isCAMemberUser, isCoreSecurityRequest, isCoreSecurityUser, isEmptyArray, isEmptyValue, isHostRequest, isHostUser, isServiceProviderUser, isThreadRequest, isThreadUser, isThreadUserFromMPDC, isVisitorUser, isVisitToUpdate, saveObjectToLocalStorage, sendRequestDataWithDefaultConfig, validateArrayParameter, validateObjectParameter } from "@utils/utils";
import { fixNewUserFields, valdiateHostVisitFields, validateNewUserFields, validateVisitorVisitFields, validateVisitorVisitFieldsWithSkippableParams } from "@utils/validations";
import { fetchWorkersOfServiceProvider } from "./admin";
import { addUserCountryCodeToPhoneNumber, getNewUserObject, uploadDocumentPhotoIfExists } from "./auth";
import { buildAndStartLoader, toastErrorMessage, toastInformationMessage, toastSuccessMessage, toastWarningMessage } from "./toast";
import { SET_GENERAL_VISIT_STATE, SET_VISITS, SET_VISIT_ON_MODAL } from "./types";

const { MAX_TIME_PERIOD } = getAppConfig();

export const fetchLoggedUserVisits = (userObj) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const userToGetVists = validateObjectParameter(userObj);
        const timeFrame = getDefaultVisitsTimeFrame();
        await dispatch(fetchVisitsByTypeOfUser(userToGetVists, timeFrame));
        dispatch(toastSuccessMessage());
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
}

export const fetchVisitsByTypeOfUser = (user, timeFrame) => async dispatch => { 
    let visits = [];
    if(isVisitorUser(user)){
        visits = await dispatch(fetchVisits({p_id_x: user.p_id, ...timeFrame, prod: 1}));
    } else if(isHostUser(user)){
        visits = await(dispatch(fetchVisits({p_id_host: user.p_id, ...timeFrame, prod: 1})));
    } else if (isThreadUserFromMPDC(user)) {
        visits = await(dispatch(fetchVisits({p_is_core: 1, ...timeFrame, prod: 1})));
    } else if(isThreadUser(user)){
        visits = await(dispatch(fetchVisits({p_id_comp: user.p_id_company, ...timeFrame, prod: 1})));
    } else if(isCoreSecurityUser(user) || isCAMemberUser(user)){
        visits = await(dispatch(fetchVisits({p_is_core: 1, ...timeFrame, prod: 1})));
    } else if(isServiceProviderUser(user)){
        visits = await(dispatch(fetchVisits({p_id_entity: user.p_id_entity , ...timeFrame, prod: 1})));
        visits.forEach(visit => {
            visit.id_users = [];
            visit.p_users = [];
        });
    } else
        throw new Error('Could not identify user. No visits can be fetched!');
    dispatch(warnUserIfNoVisits(visits));
    saveObjectToLocalStorage(VISITS_LOCALSTORAGE_KEY, visits);
}

const fetchVisits = (requestBody) => async dispatch => { 
    const result = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/getScheVisit`, requestBody);
    checkApiResponseForErrors(result);
    const [genState, visits] = separateGeneralStateFromVisitsList(result);
    dispatch(setStoreData(SET_VISITS, visits));
    dispatch(setStoreData(SET_GENERAL_VISIT_STATE, genState));
    return result;
}

const separateGeneralStateFromVisitsList = (apiResponse) => { 
    const securedApiResponse = validateArrayParameter(apiResponse);
    const genState = securedApiResponse[0];
    const visits = securedApiResponse.filter((_, index) => index !== 0);
    return [genState, visits];
}

const warnUserIfNoVisits = (visits) => dispatch => { 
    if(isEmptyArray(visits) || (visits.length < 2))
        dispatch(toastInformationMessage("There are no new visits!"))
}


export const setStoreData = (type, payload) => dispatch => { 
    dispatch({
      type,
      payload
    });
  }

export const setNewVisitorFastRequestDefaultFields = (newVisitObject) => dispatch => { 
    setComonUserNewVisitsDefaultFields(newVisitObject, {p_id: -1});
    newVisitObject.p_fast = 1;
    newVisitObject.p_rule = 1;
    newVisitObject.p_id_host = '';
    newVisitObject.p_internal = 0;
    newVisitObject.p_id_department = DEFAULT_COMBOBOX_CODE;
    newVisitObject.p_id_company = DEFAULT_COMBOBOX_CODE;

    return newVisitObject;
}

export const setNewVisitorVisitDefaultFields = (newVisitObject, loggedUser) => dispatch => { 
    setComonUserNewVisitsDefaultFields(newVisitObject, loggedUser);
    newVisitObject.p_id_host = '';
    newVisitObject.p_internal = 0;
    dispatch(setStoreData(SET_VISIT_ON_MODAL, newVisitObject));
}

export const setNewServiceProviderVisitDefaultFields = (newVisitObject, loggedUser) => dispatch => { 
    dispatch(setNewVisitorVisitDefaultFields(newVisitObject, loggedUser));
    newVisitObject.id_users = []; 
    newVisitObject.p_users = []; 
    return newVisitObject;
}

export const setNewHostSimpleVisitDefaultFields = (newVisitObject, loggedUser) => dispatch => { 
    setComonUserNewVisitsDefaultFields(newVisitObject, loggedUser);
    const userOfVisit = dispatch(getNewUserObject({}));
    fixNewUserFields(userOfVisit);
    userOfVisit.p_is_toEmail = 1;
    newVisitObject.p_internal = 1;
    newVisitObject.p_id_department = loggedUser.p_id_department;
    newVisitObject.p_id_host = loggedUser.p_id;
    newVisitObject.p_id_company = loggedUser.p_id_company;
    newVisitObject.p_users = [userOfVisit];

    dispatch(setStoreData(SET_VISIT_ON_MODAL, newVisitObject));
}

export const setNewHostServiceRequestDefaultFields = (newVisitObject, loggedUser) => dispatch => { 
    setComonUserNewVisitsDefaultFields(newVisitObject, loggedUser);

    newVisitObject.p_internal = 1;
    newVisitObject.p_id_department = loggedUser.p_id_department;
    newVisitObject.p_id_host = loggedUser.p_id;
    newVisitObject.p_id_x = loggedUser.p_id;  
    newVisitObject.p_id_company = -1;
    newVisitObject.p_fast = 0;
    newVisitObject.p_toCompany = 1;

    dispatch(setStoreData(SET_VISIT_ON_MODAL, newVisitObject));
}

export const setNewHostFastRequestDefaultFields = (newVisitObject, loggedUser) => dispatch => { 
    setComonUserNewVisitsDefaultFields(newVisitObject, loggedUser);

    newVisitObject.p_internal = 1;
    newVisitObject.p_id_department = loggedUser.p_id_department;
    newVisitObject.p_id_host = loggedUser.p_id;
    newVisitObject.p_id_company = loggedUser.p_id_company;
    newVisitObject.p_id_x = loggedUser.p_id;  
    newVisitObject.p_fast = 1;
    newVisitObject.p_toCompany = 0;

    dispatch(setStoreData(SET_VISIT_ON_MODAL, newVisitObject));
}



const setComonUserNewVisitsDefaultFields = (newVisitObject, loggedUser) => { 
    const userCreatingVisit = validateObjectParameter(loggedUser);
    const userId = userCreatingVisit.p_id;
    newVisitObject.p_multi_entry = 0;
    newVisitObject.p_period = 1
    newVisitObject.p_date_time = getToday().set("hour", 8).add(1, 'days').toISOString();
    newVisitObject.p_submited =  getInputDateFormatFromString(getToday().toISOString());
    newVisitObject.p_tocompany = 0;
    newVisitObject.p_fast = 0;
    newVisitObject.p_rule = 1;
    newVisitObject.extraArray = [new Material(userId), new Vehicle(userId)];
    newVisitObject.p_id_x = userId;
    newVisitObject.userCode = userId;
    newVisitObject.to_host = true;
    newVisitObject.is_collective = false;
    newVisitObject.host_name = '';
    newVisitObject.type_visit = 0;
    newVisitObject.p_access = 1;
    newVisitObject.p_id_gate = 70;
}

export const fetchAllVisitMaterials = (visit, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const visitToFetchData = validateObjectParameter(visit);
        const requestBody = { p_int_id: visitToFetchData.p_id };
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/getSvisitExtra`, requestBody);
        visit.extraArray = renderMaterialsAndVehiclesToObjectsToVisit(response, loggedUser.p_id);
        checkApiResponseForErrors(response);
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
    return false;
}

export const fetchServiceProviderDataIfUserIs = (visit,loggedUser) => async dispatch => { 
    const visitToCheck = validateObjectParameter(visit);
    if(isServiceProviderUser(loggedUser))
        await dispatch(fetchServiceProviderWorkersToGoToVisit(visitToCheck, loggedUser))
}

const fetchServiceProviderWorkersToGoToVisit = (visit, serviceProvider) => async dispatch =>  {
    const loader = dispatch(buildAndStartLoader());
    try {
        const requestBody = {p_svisit: visit.p_id, prod: true};
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/getSelectedVisitors`, requestBody);
        checkApiResponseForErrors(response);
        response.forEach(workerId => { 
            if(!visit.id_users.includes(workerId))
                visit.id_users.push(workerId);
        });
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
}

const renderMaterialsAndVehiclesToObjectsToVisit = (visitMaterials, userId) => {
    const vistMaterialsToCheck = validateArrayParameter(visitMaterials);
 
    const materials = vistMaterialsToCheck.map( ex => { 
        const object = { p_oname: ex.str_oname, p_qnt: ex.int_qnt, p_ismtrl: ex.bool_ismtrl? 1:0 , p_id: ex.int_id, p_is_active: ex.bool_is_active? 1: 0,  p_cod_user: userId};
        let material;

        if(object.p_ismtrl)
            material = new Material();
        else 
            material = new Vehicle();
        
        Object.keys(object).forEach(key => material[key] = object[key]);
        return material;
    });

    return materials;
}

export const getAllVisitMaterials = (visit) => { 
    const visitToCheck = validateObjectParameter(visit);
    const visitObjects = validateArrayParameter(visitToCheck.extraArray);
    return visitObjects.filter(obj => (obj instanceof Material) && obj.p_is_active);
}

export const getAllVisitVehicles = (visit) => { 
    const visitToCheck = validateObjectParameter(visit);
    const visitObjects = validateArrayParameter(visitToCheck.extraArray);
    return visitObjects.filter(obj => (obj instanceof Vehicle) && obj.p_is_active);
}

export const addMaterialToVisit = (visit) => { 
    const visitObj = validateObjectParameter(visit);
    const visitArr = validateArrayParameter(visitObj.extraArray)
    visit.extraArray = [new Material(visit.p_id_x), ...visitArr];
}

export const addVehicleToVisit = (visit) => { 
    const visitObj = validateObjectParameter(visit);
    const visitArr = validateArrayParameter(visitObj.extraArray)
    visit.extraArray = [new Vehicle(visit.p_id_x), ...visitArr];
}

export const removeVisitMaterialsFromVisit = (visit, materialName) => { 
    const visitObj = validateObjectParameter(visit);
    const visitArr = validateArrayParameter(visitObj.extraArray);
    if(isVisitToUpdate(visit))
        removeAlreadySubmitedVisitMaterial(visit, materialName);
    else 
        visit.extraArray = visitArr.filter(obj => materialName !== obj.p_oname);
}

const removeAlreadySubmitedVisitMaterial = (visit, materialName) => { 
    const material =  visit.extraArray.find(materialObj => materialName === materialObj.p_oname );
    if(material.p_id){
        material['p_is_active'] = 0;
    } else { 
       visit.extraArray = visit.extraArray.filter(obj => material.p_oname !== obj.p_oname );
    }
}

const clearEmptyNamedVisitMaterials = (visit) => {
    const visitObj = validateObjectParameter(visit);
    const visitArr = validateArrayParameter(visitObj.extraArray)
    visit.extraArray = visitArr.filter((arr) => !isEmptyValue(arr.p_oname));
}

const setDefaultQuantityForMaterialsIfNecessary = (visit) => { 
    visit.extraArray.forEach(extra => { 
        if(isEmptyValue(extra.p_qnt))
            extra.p_qnt = 1;
    })
}

const formalizeVisitExtras = (visit) => { 
    clearEmptyNamedVisitMaterials(visit);
    setDefaultQuantityForMaterialsIfNecessary(visit);
}


export const performVisitorVisitSubmissionProcess = (visit) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        validateVisitorVisitFields(visit);
        formalizeVisitExtras(visit);
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addScheVisit`, visit);
        checkApiResponseForErrors(response);
        dispatch(clearVisitOnModal());
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
    return false;
}

export const performVisitorFastVisitSubmissionProcess = (visitData, user) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    //::> In case it fails, the phone number without the country code will be replaced with the phone number with country code.
    const {p_pnumber_1, p_pnumber_2} = user;
    try {
        const visit = getObjectCopy(visitData);
        validateVisitorVisitFields(visit);
        validateNewUserFields(user);
        addUserCountryCodeToPhoneNumber(user);
        formalizeVisitExtras(visit);
        const requestBody = {...visit, p_user: user};
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addVisitorFastRequest`, requestBody);
        checkApiResponseForErrors(response);
        dispatch(uploadDocumentPhotoIfExists(user));
        dispatch(toastSuccessMessage("Visit submited. You will recieve an email or text message with confirmation", 15000));
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
        user.p_pnumber_1 = p_pnumber_1;
        user.p_pnumber_2 = p_pnumber_2;
    }
    loader.stop();
    return false;
}

export const performHostVisitSubmissionProcess = (visit) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    const {p_pnumber_1,p_pnumber_2} = visit.p_users[0];
    try {
        valdiateHostVisitFields(visit);
        addUserCountryCodeToPhoneNumber(visit.p_users[0]);
        formalizeVisitExtras(visit);
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addScheVisit`, visit);
        checkApiResponseForErrors(response);
        dispatch(uploadDocumentPhotoIfExists(visit.singleUser));
        dispatch(clearVisitOnModal());
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
        visit.p_users[0].p_pnumber_1 = p_pnumber_1;
        visit.p_users[0].p_pnumber_2 = p_pnumber_2;
    }
    loader.stop();
    return false;
}

export const performHostServiceRequestSubmissionProcess = (visit, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    visit.p_id_company = loggedUser.p_id_company;
    try {
        valdiateHostVisitFields(visit);
        formalizeVisitExtras(visit);
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addScheVisit`, visit);
        checkApiResponseForErrors(response);
        dispatch(clearVisitOnModal());
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
    return false;
}

export const performHostFastRequestSubmissionProcess = (visitData, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const visit = getObjectCopy(visitData);
        valdiateHostVisitFields(visit);
        formalizeVisitExtras(visit);
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addScheVisit`, visit);
        checkApiResponseForErrors(response);
        dispatch(clearVisitOnModal());
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
    return false;
}


export const clearVisitOnModal = () => dispatch => { 
    const newVisit = setNewVisitorVisitDefaultFields({})
    dispatch(setStoreData(SET_VISIT_ON_MODAL, newVisit));
}


export const performVisitUpdateProcess = (visitObject, loggedUser) => async dispatch => {
    const loader = dispatch(buildAndStartLoader());
    try {
        const visitToUpdate = validateObjectParameter(visitObject);
        validateEditedVisitAccordingToUserType(visitToUpdate, loggedUser);
        addNecessaryFieldsForUpdate(visitToUpdate, loggedUser);
        await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/editScheVisit`, visitToUpdate);
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    } 
    loader.stop();
    return false;
}

const validateEditedVisitAccordingToUserType = (visitObject, loggedUser) => { 
    if(isVisitorUser(loggedUser)){
        //::>> This means host has changed visit period, so it's allowed
        if(visitObject.p_period > MAX_TIME_PERIOD)
            validateVisitorVisitFieldsWithSkippableParams(visitObject, ["p_period"])
        else 
            validateVisitorVisitFields(visitObject);
    }
    else {
        valdiateHostVisitFields(visitObject);
    }
}

const addNecessaryFieldsForUpdate = (visitData, loggedUser) => { 
    visitData.p_cod_user = loggedUser.p_id;
    visitData.p_svisit = visitData.p_id;
}

export const relateAWorkerOfServiceProvToServiceRequest = (visitObject) => async dispatch => { 
    try {
        const visit = validateObjectParameter(visitObject);
        const firstActiveWorker = await dispatch(getFirstActiveWorkerOfServiceProvider(visit));

        if(isEmptyValue(firstActiveWorker))
            dispatch(toastWarningMessage('Service Provider has no active users!'));
        else 
            visit.p_users = [firstActiveWorker];
        
    } catch (error) {
        dispatch(toastErrorMessage(error.message))
    }
}

export const getFirstActiveWorkerOfServiceProvider = (visit) => async dispatch => {
    const workersOfServiceProvider = await dispatch(fetchWorkersOfServiceProvider(visit.p_id_company));
    const workersToFilterActive = validateArrayParameter(workersOfServiceProvider);
    const activeWorkers = workersToFilterActive.filter(usr => usr.p_active);
    return activeWorkers[0];
}


export const filterMainPageVisits = (filterKey, filterValue, loggedUser) => async dispatch => {
    const loader = dispatch(buildAndStartLoader());
    try {
        if (filterKey === 'p_date_time') 
            await dispatch(filterUserVisitsByTime(loggedUser, filterValue));
        else 
            dispatch(filterUserVisitsByParameters(filterKey, filterValue))
        
    } catch (error) {
        dispatch(toastErrorMessage(error.message))
    } 
    loader.stop();
}

export const filterMainPagevisitsByState = (filterKey) => dispatch => { 
    if ((filterKey === REQUEST_PANEL) || (filterKey === ALL)) { 
        dispatch(filterUserVisitsByParameters(filterKey, ''));
    } else { 
        dispatch(filterUserVisitsByParameters('state', filterKey));
    }
}

const filterUserVisitsByTime = (loggedUser, filterTime) => async dispatch => { 
    const {VISIT_TO_SHOW_DAYS_RANGE} = getAppConfig();
    const defaultDateTime = getDefaultVisitsTimeFrame();
    let startDate = getMomentFromString(filterTime);
    let endDate = getMomentFromString(defaultDateTime.p_dtm_end);
    if(startDate.isAfter(endDate))
        endDate = getMomentFromString(filterTime).add(VISIT_TO_SHOW_DAYS_RANGE, 'days');
    
    const timeFrame = getVisitTimeFrameFromMoment(startDate, endDate);
    await dispatch(fetchVisitsByTypeOfUser(loggedUser, timeFrame))
}

const filterUserVisitsByParameters = (filterKey, filterValue) => dispatch =>  {
    const storedVisits = getObjectFromLocalStorage(VISITS_LOCALSTORAGE_KEY);
    const auxArray =separateGeneralStateFromVisitsList(storedVisits);
    const visitsToFilter = auxArray[1];
    const filteredVisits = visitsToFilter.filter( visit => { 
        if(isEmptyValue(filterValue) || isEmptyValue(visit[filterKey])) 
            return true;
        
        let isAMatch = visit[filterKey].toLowerCase().startsWith(filterValue.toLowerCase());
        if(!isAMatch && !isEmptyArray(visit.grouped)) {
            for(let companyVisit of visit.grouped) { 
                isAMatch = companyVisit[filterKey].toLowerCase().startsWith(filterValue.toLowerCase());
            }
        }
        return isAMatch;
    });
    dispatch(setStoreData(SET_VISITS, filteredVisits));
}

export const hasHostTakenAction = (visit) => { 
    return (visit.states[HOST] === ACCEPTED) && !isEmptyValue(visit.host_fname)
}

export const hasTerminalTakenAction = (visit) => { 
    return (visit.states['TRHEAD'] === ACCEPTED);
}

export const canTakeActionInTerminalVisit = (visit, loggedUser) =>  {
    let canTakeAction = false;
    if(isThreadUser(loggedUser)) 
        canTakeAction = true;
    else if (hasTerminalTakenAction(visit))
        canTakeAction = true;
    return canTakeAction;
}

export const canUserTakeAction = (visit, loggedUser) => { 
    let canTakeAction = false;
    if(isHostUser(loggedUser))
        canTakeAction = true;
    else if (hasHostTakenAction(visit))
        canTakeAction = true;
    else if (isVisitToTerminal(visit))
        canTakeAction = canTakeActionInTerminalVisit(visit, loggedUser);
    else if (isVisitToLoggedUser(visit, loggedUser))
        canTakeAction = true;
    
    return canTakeAction;
}

export const performRejectOrCancelVisitProcess = (visitStateObj, visit, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        let requestBodyOfVisitState ={};
        if(wasVisitAcceptedByLoggedUser(loggedUser, visit))
            requestBodyOfVisitState = buildCancelVisitStateRequestBody(visitStateObj, visit, loggedUser);
        else 
            requestBodyOfVisitState = buildVisitStateRequestBodyByUserType(visitStateObj, visit, loggedUser);

        await dispatch(performAcceptoRejectVisitIfVisitIsToTerminal(visitStateObj, visit, loggedUser));
        await dispatch(changeVisitState(requestBodyOfVisitState));
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    
    loader.stop();
    return false;
}

const buildCancelVisitStateRequestBody = (visitStateObj, visit, loggedUser) => { 
    const isToRevert = visit.state === CANCELED;
    return {
        p_int: getLoggedUserStateNumber(loggedUser),
        p_id_svisit: visit.p_id,
        p_htc_cancel: isToRevert? null: 1,
        p_id_x: visit.p_id_x,
        p_cod_why: visitStateObj.rejectionCode,
        p_cancel_why: isToRevert? '': visitStateObj.message,
        p_when_cancel: getToday().toISOString(),
    }
}

const wasVisitAcceptedByLoggedUser = (loggedUser, visit) => { 
    const userType = getLoggedUserType(loggedUser);
    return visit.states[userType] === ACCEPTED;
}

export const performAcceptVisitProcess = (visitStateObj, visit, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const requestBodyForVisitState = buildVisitStateRequestBodyByUserType(visitStateObj, visit, loggedUser);
        dispatch(giveWarningInformationByVisitState(visitStateObj.verdict, visit));
        await dispatch(performAcceptoRejectVisitIfVisitIsToTerminal(visitStateObj, visit, loggedUser));
        await dispatch(changeVisitState(requestBodyForVisitState));
        dispatch(forceNotificationToUser(visit, loggedUser));
        loader.stop();
        return true;
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
    return false;
}

const performAcceptoRejectVisitIfVisitIsToTerminal = (visitStateObj, visit, loggedUser) => dispatch => { 
    if(isVisitToTerminal(visit))
        dispatch(performTerminalAcceptVisitIfThread(visitStateObj, visit, loggedUser));
}

const performTerminalAcceptVisitIfThread = (visitStateObj, visit, loggedUser) => async dispatch => { 
    if(isThreadUser(loggedUser)) {
        visitStateObj.p_id_htc = loggedUser.p_id;
        visitStateObj.userVisitStateChangeCode = HOST_VISIT_STATE_CHANGE_CODE;
        await makeThreadResponsableForVisit(visit, loggedUser);
        const requestBody = buildVisitStateRequestBody(visitStateObj, visit, loggedUser);
        await dispatch(changeVisitState(requestBody));
    }
        
}

const makeThreadResponsableForVisit = async (visit, loggedUser) => { 
    const requestBodyForResponsabilityChange = {p_id: visit.p_id, p_id_host: loggedUser.p_id};
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/editVisitCodHost`, requestBodyForResponsabilityChange);
    checkApiResponseForErrors(response);
}



const changeVisitState = (requestBodyForVisitState) => async dispatch => { 
    const link = isEmptyValue(requestBodyForVisitState.p_htc_cancel)? `${ROOT_URL}/visits/editVstate`:`${ROOT_URL}/visits/editVstateCanc`;
    const response = await sendRequestDataWithDefaultConfig(link, requestBodyForVisitState);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());
}

const giveWarningInformationByVisitState = (verdict, visit) => dispatch => { 
    if(visit.state === REJECTED)
        if(verdict)
            dispatch(toastWarningMessage('This visit had been rejected!'));
            
    // if(visit.state === CANCELED)
    //     if(verdict)
    //         throw new Error('This visit has been canceled!');

}

export const buildVisitStateObj = (verdict, message='', rejectionCode=null) => { 
    return {verdict, message, rejectionCode};
}


const buildVisitStateRequestBodyByUserType = (visitStateObj, visit, loggedUser) => {
    visitStateObj.userVisitStateChangeCode = getLoggedUserStateNumber(loggedUser);
    const reqBodyStateObj = buildVisitStateRequestBody(visitStateObj, visit, loggedUser);
    return reqBodyStateObj;
}

const buildVisitStateRequestBody = (visitStateObj, visit, loggedUser) => { 
    const toUseSubmissionDate = isToUseSubmissionDate(visit, loggedUser, visitStateObj.userVisitStateChangeCode)
    const visitStateData = {
        p_int: visitStateObj.userVisitStateChangeCode,
        p_id_svisit: visit.p_id,
        p_id_htc: loggedUser.p_id,
        p_bool_htc: visitStateObj.verdict,
        p_cod_why: visitStateObj.rejectionCode,
        p_why: visitStateObj.message,
        p_dtm_htc: toUseSubmissionDate? visit.p_submited : getToday().toISOString(),
      }
      return visitStateData;
}

const isToUseSubmissionDate = (visit, loggedUser, userVisitStateChangeCode) => { 
    return isVisitMadeByLoggedUser(visit, loggedUser) && (userVisitStateChangeCode === getLoggedUserStateNumber(loggedUser))
}

const isVisitMadeByLoggedUser = (request, loggedUser) => {
    let visitMadeByLoggedUser = false;
    if(request.p_internal && (request.p_id_host === loggedUser.p_id)) { 
      if(isHostUser(loggedUser)){
        visitMadeByLoggedUser = isHostRequest(request);
      } else if (isThreadUser(loggedUser)){
        visitMadeByLoggedUser = isThreadRequest(request)
      } else { 
        visitMadeByLoggedUser = isCoreSecurityRequest(request)
      }
    }
    return visitMadeByLoggedUser;
  }

export const isVisitToTerminal = visit => { 
    return isEmptyValue(visit.p_id_host);
}

export const isVisitToLoggedUser = (visit, loggedUser) => {
    return visit.p_id_host === loggedUser.p_id;
}

export const performWorkerToVisitAssociation = (visit) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const requestBody = {p_id_svisit: visit.p_id, p_users: visit.id_users};
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/addReqVis`, requestBody);
        checkApiResponseForErrors(response);
        dispatch(toastSuccessMessage());
        loader.stop();
        return true;        
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }

    loader.stop();
    return false;
}


export const forceNotificationToUser = (visit, loggedUser) => async dispatch => { 
    const loader = dispatch(buildAndStartLoader());
    try {
        const requestBody = {p_id_svisit: visit.p_id, p_id_from: loggedUser.p_id};
        await sendRequestDataWithDefaultConfig(`${ROOT_URL}/visits/notifyUser`, requestBody);
        dispatch(toastSuccessMessage());
    } catch (error) {
        dispatch(toastErrorMessage(error.message));
    }
    loader.stop();
}