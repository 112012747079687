import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { fetchAllHosts, filterHosts, open_modal } from '@actions/admin'
import UserSettingsTabEditModal from './UserSettingsEditModal'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import { Fragment } from 'react'
import SettingsItemTable from '../Shared/SettingsItemTable'
import { Filter } from 'react-feather'
import { performNoValidationUserDataUpdateProcess } from '@actions/auth'

const UserSettingsTab = ({flag,userData, hosts, fetchAllHosts,filterHosts, performNoValidationUserDataUpdateProcess, user, open_modal, modal_data}) => {
    const {t} = useTranslation('settings');
    const { USER_FILTER } = getAppConfig();
    const [state, setState] = useState({
        filter: 'Primeiro Nome',
        filter_val: '',
    });

    const {filter, filter_val} = state;
    useEffect(() => { 
        fetchAllHosts();
        AppFunctionalityUsable.getTerminals(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ::: EVENT HANDLERS
    const onChange = e => { 
        const targetName = e.target.name;
        const targetValue = e.target.value;

        if(targetName === 'filter_type') { 
            setState({...state, filter_val: targetValue});
            filterHosts(USER_FILTER[filter], targetValue);
        } else if(targetName === 'filter') { 
            setState({...state, [targetName]: targetValue, filter_val: ''});
            filterHosts(USER_FILTER[filter], '');
        } else if (targetName === 'page_num'){
            setState({...state, page: +targetValue? +targetValue : 1})
        }
    }

    const onEditUser = (e, user) => { 
        open_modal("_", user);
    }

    // ::: CHILDREN PROPERTIES
    const tableOptions =  { 
        title: t('users'),
        canEdit: true,
        showAddBtn: false,
    }

    return (
        <Fragment>
            <div className="my-2 d-flex justify-content-end">
                <Form inline>
                    {/* ::: FILTERS */}
                    <Filter size="24px" className="mx-3"/>
                    <Input type="select" name="filter" className="mx-1" value={filter} onChange={e => onChange(e)}>
                        {Object.keys(USER_FILTER).map(key => { 
                            return <option key={v4()} value={key}>{key}</option>
                        })}
                    </Input>
                    <Input type="text" name="filter_type" placeholder={t('fil_val')} value={filter_val} className="mx-1" onChange={e => onChange(e)} />
                </Form>
            </div>
            {/* ::: USERS TABLE */}
            <SettingsItemTable items={hosts}  openEditModal={onEditUser} options={tableOptions} updateFunction={performNoValidationUserDataUpdateProcess} />
            {modal_data && <UserSettingsTabEditModal/>}
        </Fragment>
    )
}

UserSettingsTab.propTypes = {
    flag: PropTypes.bool,
    user: PropTypes.object,
    open_modal: PropTypes.func.isRequired,
    fetchAllHosts: PropTypes.func.isRequired,
    hosts: PropTypes.array,
    performNoValidationUserDataUpdateProcess: PropTypes.func.isRequired,
    filterHosts: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
    modal_data: state.admin.modal_data,
    hosts: state.admin.hosts,
})

const mapDispatchToProps = {
    open_modal,
    fetchAllHosts,
    performNoValidationUserDataUpdateProcess,
    filterHosts,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsTab)
