import { generateNewCompany } from '@actions/admin';
import Company from '@model/Settings/Company';
import { ERROR_TOAST } from '@utils/constants';
import { containsAlphaCharacters, isNotEmptyValue } from '@utils/utils';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, CustomInput, Form, FormGroup, Input } from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';

const AddTerminalModal = ({setMessage, userId, closeModal, toShow}) => {
    const { t } = useTranslation('settings');
    const [state, setState] = useState({
        newComp: generateNewCompany(userId),
    })

    useEffect(() => {
        const newComp = new Company();
        newComp.createNewCompany(userId);
        setState({...state, newComp});
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
   
    const {newComp} = state;
    const { p_cname,p_cemail,p_cpnumber_1,p_cpsw2,p_cpnumber_2,p_cpsw} = newComp;
    
    //::: EVENT HANDLERS
    const onSubmit = async e => { 
        const isSuccessful = await newComp.save();
        if(isSuccessful){
            const newComp = new Company();
            newComp.createNewCompany(userId);
            setState({...state, newComp, });
        }
    }

    const validatePhoneNumber = (phoneNumber) => { 
        let validatedPhoneNumber = phoneNumber;
        try {
            if(containsAlphaCharacters(validatedPhoneNumber)) { 
                throw new Error("Numbers cannot contain letters");
            }
        } catch (error) {
            setMessage(error.message, ERROR_TOAST);
            validatedPhoneNumber = validatedPhoneNumber.substring(0, validatedPhoneNumber.length - 1);
        }
        return validatedPhoneNumber;
    }

    const close = e => { 

        setState({...state, newComp: generateNewCompany(userId)});
        closeModal(e);
    }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const _listOfSubCompaniesTextInput = newComp.sub_companies.map( (subComp, index) => 
                                    <FormGroup key={index}>
                                        <Fragment>
                                            <Col md={6}>
                                                <Input type="text" name="p_scname" value={subComp? subComp.p_scname : ""} placeholder={t('sub_com_nam')} onChange={e => subComp.set(e.target.name, e.target.value)}/>
                                            </Col>
                                        </Fragment>
                                    </FormGroup>);

    const _singleCompanyButton = <FormGroup row>
                                            <Col md={6}>
                                                <Button color="primary" name='addSubCompany' className="w-100" onClick={e => newComp.addSubCompany()}>{t('add')}</Button>
                                            </Col>
                                        </FormGroup>;

    // :::: HTML CONDITIONALS
    const isToShowSubCompanies = isNotEmptyValue(newComp.p_has_sub_comp) && newComp.p_has_sub_comp;

    return (
        <Modal isOpen={toShow} size="lg" className="no-border modal-xl" centered>
            {/* ::: MODAL TITLE */}
            <h3 className="title-of-modal">{t('add_ter')}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form className="my-4">
                    <FormGroup row>
                        {/* ::: COMPANY NAME */}
                        <Col md={6}>
                            <Input type="text" name="p_cname" value={p_cname || ""} onChange={e => newComp.set(e.target.name, e.target.value)} className="required" placeholder={t('com_nam')} />
                        </Col>
                        {/* ::: COMPANY EMAIL */}
                        <Col md={6}>
                            <Input type="email" name="p_cemail" value={p_cemail || ""} onChange={e => newComp.set(e.target.name, e.target.value)} className="required"  placeholder={t('com_ema')} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        {/* ::: COMPANY NUMBER 01 */}
                        <Col md={6}>
                            <Input type="text" name="p_cpnumber_1" value={p_cpnumber_1 || ""} onChange={e => newComp.set(e.target.name, validatePhoneNumber(e.target.value))} className="required" placeholder={t('com_num_1')} />
                        </Col>
                        {/* ::: COMPANY NUMBER 02 */}
                        <Col md={6}>
                            <Input type="text" name="p_cpnumber_2" value={p_cpnumber_2 || ""} onChange={e => newComp.set(e.target.name, validatePhoneNumber(e.target.value))} className="required"  placeholder={t('com_num_2')} />
                        </Col>
                    </FormGroup>
                    {/* ::: COMPANY PASSWORD */}
                    <FormGroup>
                        <Input type="password" name="p_cpsw" value={p_cpsw || ""} onChange={e => newComp.set(e.target.name, e.target.value)} placeholder={t('password')} className="required" />
                    </FormGroup>
                    {/* ::: COMPANY PASSOWRD CONFIRMATION */}
                    <FormGroup>
                        <Input type="password" name="p_cpsw2" value={p_cpsw2 || ""}  onChange={e => newComp.set(e.target.name, e.target.value)} placeholder={t('password')} className="required" />
                    </FormGroup>
                    {/* ::: COMPANY PUBLIC SERVICE CHECK */}
                    <FormGroup>
                        <CustomInput type="checkbox" id="isPublicService" name="p_is_ps" label={t('is_pub_ser')} checked={newComp.p_is_ps}   onChange={e => newComp.set(e.target.name, !newComp.p_is_ps)}></CustomInput>
                    </FormGroup>
                    {/* ::: COMPANY HAS SUBCOMPANY CHECK */}
                    <FormGroup>
                        <CustomInput type="checkbox" id="hasSubCompany" name="p_has_sub_comp" label={t('has_sub')} checked={newComp.p_has_sub_comp} onChange={e => newComp.set(e.target.name, !newComp.p_has_sub_comp)}></CustomInput>
                    </FormGroup>
                    {/* ::: SUBCOMPANY INPUTS */}
                    {isToShowSubCompanies && _listOfSubCompaniesTextInput}
                    {isToShowSubCompanies && _singleCompanyButton}
                    {/* ::: FORM BUTTONS */}
                    <FormGroup>
                        <div className="d-flex justify-content-between my-2">
                            <Button  color="success" onClick={e => onSubmit(e)}>{t('save')}</Button>
                            <Button color="secondary" onClick={e => close(e)}>{t('close')}</Button>
                        </div>
                    </FormGroup>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default AddTerminalModal;
