import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';


const VisitorFastRequestPage3 = ({flag, visit}) => {
    const {t} = useTranslation('request');
    const [state, setState] = useState({
        loading: false,
        to_company: false,
        flag: false,
        host_name: visit.host_fname?  `${visit.host_fname} ${visit.host_lname}` : '',
    });

    

    const {p_id_rpp, p_detail, p_multi_entry, p_access} = visit || {};


    const onChange = (e) => {
        e.preventDefault();
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        visit[nodeName] = nodeValue;
        setState({...state, flag: !state.flag});
    };

    
    const onChangeMultyEntry = (e, value) => { 
        visit['p_multi_entry'] = value;
        setState({...state, flag: !state.flag});
    }

    const onChangePAccess = (e, value) => { 
        visit['p_access'] = value;
        setState({...state, flag: !state.flag});
    }
    


    return (
        <div className="form-page">
            {/* :: FORM TITLE */}
            <h2 className="form-header">{t('req_dat')}</h2>
            {/* :: REASON OF VISITS SELECT INPUT */}
            <div className="form-group">
                <select type="select"  name="p_id_rpp" className="form-control dark-select required" value={p_id_rpp} onChange={e=> onChange(e)}>
                    <CustomSelectOption options={AppFunctionalityUsable.reasonsOfVisit} hasId={true}/>
                </select>
            </div>
            {/* :: REASON OF VISIT DETAILS SELECT INPUT */}
            <div className="form-group">
                <textarea rows="4" cols="50" name="p_detail" className="form-control dark-textarea required" placeholder={t("reason")} value={p_detail} onChange={(e) => onChange(e)} />
            </div>
            {/* :: MULTIPLE ENTRY CHECKBOX */}
            <div className="form-group">
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="p_multi_entry" checked={p_multi_entry === 1? true: false} onChange={(e) => onChangeMultyEntry(e, p_multi_entry === 1? 0: 1)}/>
                    <label className="custom-control-label dark-label" htmlFor="p_multi_entry">{t("mult_ent")}</label>
                    <small className="form-text text-white">{t('mult_ent_desc')}</small>
                </div>
            </div>
            {/* :: ENTRY/EXIT EXIT CHECKBOX */}
            <div className="form-group inline-select-container">
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="check01" checked={p_access === 1? true: false} onChange={(e) => onChangePAccess(e,1)}/>
                    <label className="custom-control-label dark-label" htmlFor="check01">{t("entry_exit")}</label>
                </div>
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="check02" checked={p_access === 1? false: true} onChange={(e) => onChangePAccess(e, 0)}/>
                    <label className="custom-control-label dark-label" htmlFor="check02">{t("exit")}</label>
                </div>
            </div>
        </div>
    )
}


VisitorFastRequestPage3.propTypes = {
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitorFastRequestPage3);
