import VisitExtra from "./VisitExtra";

class Vehicle extends VisitExtra {

    constructor(cod_user) { 
        super();
        this.p_ismtrl = 0;
        this.p_cod_user = cod_user;
    }

    formalize() {
        this.p_oname = this.p_oname.toUpperCase();
    }

    /**
     * Gets all the object properties, modifying the p_ismtrl bool
     */
    getProperties () {
        return {...super.getProperties(), p_ismtrl: this.p_ismtrl}
    }
}

export default Vehicle;