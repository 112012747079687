import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { close_modal } from '@actions/admin'
import { toastErrorMessage } from '@actions/toast'
import { useTranslation } from 'react-i18next'
import { doesVariableExist } from '@utils/utils'

const RejectModal = ({flag, modal, modal_data, close_modal, toastErrorMessage}) => {
    const { t } = useTranslation('main');
    const {funcToExecute, onSuccessFunc, actionName} = modal_data || {};

    const onClose = e => {
        close_modal();
    }

    const onSend = async e => {
        if(!doesVariableExist(funcToExecute))
            return toastErrorMessage("No action to perform!");

        let isSuccessful  = false;
        isSuccessful = await funcToExecute();
        if(isSuccessful) 
            onSuccessFunc();
        
        onClose();
    }

    return (
        <Modal isOpen={true} size="m" className="no-border" centered>
            <h3 className="title-of-modal">{t("act_conf")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
              <p className="text-center">{t("wish_to")} {t(actionName)}?</p>
            </ModalBody>
            <ModalFooter>
              <div className=" w-100 d-flex align-items-center justify-content-between">
                <div>
                  <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                </div>
                <div>
                  <Button name='submit' color="success" onClick={e => onSend(e)} className="mx-3"> {t('yes')} </Button>
                </div>
              </div>
            </ModalFooter>
        </Modal>
    )
}

RejectModal.propTypes = {
    modal_data: PropTypes.object,
    toastErrorMessage: PropTypes.func.isRequired,
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data,
})

const mapDispatchToProps = {
    close_modal,
    toastErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal)
