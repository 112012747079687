import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from '@components/Auth/Login';
import Toast from '@components/Shared/Toast'
import VisitorRegistrationPage from '@auth/VisitorRegistration/VisitorRegistrationPage';
import CompanyRegistrationPage from '@auth/CompanyRegistration/CompanyRegistrationPage';
import MainPage from '@components/MainPage/MainPage';
import InductionPage from '@components/InductionPage/InductionPage';
import VisitorFastVisitForm from '@components/VisitorFastRequest/VisitorFastVisitForm';
import VerifyAccount from '@components/SupportPages/VerifyAccount';
import ForgotPassword from '@components/SupportPages/ForgotPassword';
import RecoverPassword from '@components/SupportPages/RecoverPassword';
import ContactUs from '@components/SupportPages/ContactUs';
import PageNotFound from '@components/PageNotFound';
import ErrorBoundary from '@components/Shared/ErrorBoundary';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Toast/>
          <Route exact path="/" component={Login}/>
          <Switch>
            <ErrorBoundary>
              <Route exact path="/login" component={Login}/>
              <Route exact path="/registration" component={VisitorRegistrationPage}/>
              <Route exact path="/main" component={MainPage}/>
              <Route exact path="/fasReq" component={VisitorFastVisitForm}/>
              <Route exact path="/induction/" component={InductionPage}/>
              <Route exact path="/induction/:token" component={InductionPage}/>
              <Route exact path="/registerEntity/:token" component={CompanyRegistrationPage}/>
              <Route exact path="/accountVer" component={VerifyAccount}/>
              <Route exact path="/accountVer/:token" component={VerifyAccount}/>
              <Route exact path="/forgotPassword" component={ForgotPassword}/>
              <Route exact path="/recoverPassword/:token" component={RecoverPassword}/>
              <Route exact path="/contactUs" component={ContactUs}/>
              <Route path="/" component={PageNotFound}/>
            </ErrorBoundary>
          </Switch> 
      </Router>
    </Provider>
     
    
  );
}

export default App;
