import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { calculateNumberOfPages, validateArrayParameter } from '@utils/utils'
import { CONFIRMATION_MODAL, EDIT_BTN_MESSAGE, NUMBER_REQUEST_PER_PAGE_REQUEST_PAGE} from '@utils/constants'
import { v4 } from 'uuid'
import { open_modal } from '@actions/admin'
import { toastErrorMessage } from '@actions/toast'
import { useTranslation } from 'react-i18next';
import { performAcceptVisitProcess, fetchAllVisitMaterials, fetchLoggedUserVisits, forceNotificationToUser } from '@actions/visits'
import { buildVisitsTableUtilsObject } from '@utils/visitsDisplay'
import { Bell } from 'react-feather'
import TableNavigation from '@components/MainPage/Shared/TableNavigation'

const CALENDAR_ICON = require(`@resources/icons/calendar.svg`);
const ALERT_TRIANGLE_ICON = require(`@resources/icons/alert-triangle.svg`);
const LINK_ICON = require(`@resources/icons/link.svg`);
const USER_CHECK_ICON = require(`@resources/icons/user-check.svg`);
const USER_ICON = require(`@resources/icons/user.svg`);
const PUBLIC_SERVICE_ICON = require('@resources/icons/public_service.svg');

const HostsVisitsTable = ({flag, user, actionsForChildren, open_modal, forceNotificationToUser ,visits}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState ({ 
        page: 1,
    })
    const { page } = state;
    const { onRowClicked, onClickToUpdateVisit, onClickAcceptReject } = actionsForChildren;

    const onClickNotify = (visit, user) => { 
       open_modal(CONFIRMATION_MODAL, { 
        funcToExecute: () => forceNotificationToUser(visit, user),
        onSuccessFunc: () => fetchLoggedUserVisits(user),
        actionName: "not_user"}
       );
    }

    const validatedVisitArr = validateArrayParameter(visits);
    // ::: NAVIGATION
    const numberOfVisits = validatedVisitArr.length;
    const  totalPages =  calculateNumberOfPages(numberOfVisits, NUMBER_REQUEST_PER_PAGE_REQUEST_PAGE);
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const setPage = (e, page) => {setState({...state, page })}

    const lowerBoundPageNum = (page-1) * NUMBER_REQUEST_PER_PAGE_REQUEST_PAGE;
    const upperBoundPageNum = page * NUMBER_REQUEST_PER_PAGE_REQUEST_PAGE;
    const listOfVisits = validatedVisitArr.filter((_, index) => (index >= lowerBoundPageNum) && (index < upperBoundPageNum));
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);

    const componentData = {
        pageIndicators: indicators,
        totalPages,
        totalItems: numberOfVisits,
        page,
        next,
        prev,
        setPage,
    }

    // ::: EVENT HANDLERS

 

    // :::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    // :: TABLE ROWS
    const visitRows = listOfVisits.map(visit => { 
        const tableUtilsFlagsAndMessages = buildVisitsTableUtilsObject(visit, user);
        const { visitDate, numberOfVisitors,noActionText, isToShowHostState, isToShowThreadState, canTakeAction, acceptBtnColor, rejectBtnColor, editBtnColor,
                rejectBtnString, acceptButtonString, hasExpired, hasVisitorsChanged, isInternalFastVisit, isMyVisit, hostState, threadState, coreSecState,
                hostIcon, threadIcon, coreSecIcon, canEdit } = tableUtilsFlagsAndMessages;
        const isToShowEditButton = canEdit || isMyVisit;
        // ::: HTML ELEMENTS
        const _actionButtons = <Fragment>
                                <div className="table-btn-container">
                                    <span name="accept" className={`faded-button ${acceptBtnColor}  w-25 table-action-btn`} onClick={e => onClickAcceptReject(e, visit)}>{t(acceptButtonString)}</span>
                                </div>
                                <div className="table-btn-container">
                                    <span name="reject" className={`faded-button  ${rejectBtnColor} text-thin table-action-btn`} onClick={e => onClickAcceptReject(e, visit)}>{t(rejectBtnString)}</span>
                                </div>
                                {isToShowEditButton && <div className="ml-3 table-btn-container"> <span className={`faded-button ${editBtnColor} table-action-btn`} onClick={e => onClickToUpdateVisit(visit, canEdit)}>{t(EDIT_BTN_MESSAGE)}</span></div>}
                                <div className="table-icon-container">
                                    <Bell size="24px" className="bell-btn" onClick={e => onClickNotify(visit, user)}/>
                                </div>
                                </Fragment>;
        const _noActionView = <span className={`bold td ${hasExpired? 'text-red':'text-green'}`}>{t(noActionText)}</span>;

        return <tr key={v4()}>
                    {/* ::: NAME OF HOST CELL */}
                    <td  data-toggle="tooltip" data-placement="top" title={`${visit.v_name}`}  className="hoverable td text-truncate" onClick={e => onRowClicked(visit)} >
                            {visit.v_name + ` `} 
                            {/* ::: EXTRA ICONS FOR THE CELL */}
                            {visit.sv_is_ps && <img alt="public_service_svg" className="float-right table-icon mr-4 mt-1" src={PUBLIC_SERVICE_ICON}/>}
                            {isMyVisit && <img alt="link svg" className="float-right mr-4 mt-1 table-icon" src={LINK_ICON}/>}
                            {isInternalFastVisit &&  <img className="float-right mr-2 mt-1 table-icon" alt="link svg" src={USER_CHECK_ICON}/>}
                    </td>
                    {/* ::: VISIT DATE CELL */}
                    <td  onClick={e => onRowClicked(visit)} ><img alt="calendar svg" src={CALENDAR_ICON}/>{visitDate}</td>
                    {/* ::: NUMBER VISITORS CELL */}
                    <td  onClick={e => onRowClicked(visit)} ><img alt="user svg" src={USER_ICON}/> {numberOfVisitors} {hasVisitorsChanged && <img alt="link svg" className="ml-2  mt-1" src={ALERT_TRIANGLE_ICON}/>}</td>
                    {/* ::: VISIT GATE CELL */}
                    <td  onClick={e => onRowClicked(visit)} >{visit.gate}</td>
                    {/* ::: VISIT STATE CELL */}
                    <td  onClick={e => onRowClicked(visit)} >
                        <span className={`material-icons md-16 mr-1 ${isToShowHostState? hostState:'text-white'}`}>{hostIcon}</span>
                        <span className={`material-icons md-16 mr-1 ${isToShowThreadState? threadState: 'text-white'}`}>{threadIcon}</span>
                        <span className={`material-icons md-16 ${coreSecState}`}>{coreSecIcon}</span>
                    </td>
                    {/* ::: VISIT ACTION CELL */}
                    <td>{canTakeAction? _actionButtons: _noActionView}</td>
                </tr>
    })
    



    return (
        <div className="elevated-container table-container"> 
            {/* :: TABLE TITLE */}
            <span className="elevated-contaniner-title">{t('my_req')}</span>
            <table className="striped-table">
                <thead>
                    {/* :: TABLE HEADER */}
                    <tr>
                        <th>{t('name')}</th>
                        <th>{t('date')}</th>
                        <th>{t('num-vis')}</th>
                        <th>{t('gate')}</th>
                        <th>{t('state')}</th>
                        <th>{t('action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* :: TABLE ROWS */}
                    {visitRows}
                </tbody>
            </table>
            {/* ::: TABLE NAVIGATION */}
            <TableNavigation componentData={componentData}/>
        </div>
    )
}

HostsVisitsTable.propTypes = {
    flag: PropTypes.bool,
    open_modal: PropTypes.func.isRequired,
    user: PropTypes.object,
    toastErrorMessage: PropTypes.func.isRequired,
    fetchAllVisitMaterials: PropTypes.func.isRequired,
    forceNotificationToUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
})

const mapDispatchToProps = {
    open_modal,
    performAcceptVisitProcess,
    toastErrorMessage,
    fetchAllVisitMaterials,
    fetchLoggedUserVisits,
    forceNotificationToUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(HostsVisitsTable)
