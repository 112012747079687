import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import { ROOT_URL } from '@utils/constants';
import { Fragment } from 'react';


const InductionRadioGroupAnswer = ({flag, questNum, indQuestion}) => {

    let isRadioButtonChecked, inductionOptionImageLink;
    const checkBoxes = indQuestion.options.map((opt) => {
        isRadioButtonChecked = indQuestion.get('selectedOption').qid === opt.qid? 'checked': '';
        inductionOptionImageLink = `${ROOT_URL}/files/induction/${opt.file_name}`;
        return (
            <div key={v4()} className="custom-control custom-radio custom-control-inline mx-3 position-relative">
                <input type="radio"  checked={isRadioButtonChecked} className="custom-control-input" onChange={e => indQuestion.selectOption(opt)} />
                <label className="custom-control-label" name={opt.qid}>
                    <img alt="img_src" style={{maxWidth:"200px", maxHeight:"200px"}} name={opt.qid} src={inductionOptionImageLink} onClick={e =>  indQuestion.selectOption(opt)} />
                </label>
            </div>
        )
    })

    return (
        <Fragment>
             <h4 className="py-3">{`${questNum}. ${indQuestion.p_question}`}</h4>
             <div className="d-flex justify-content-center">
                {checkBoxes}
             </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    flag: state.admin.flag
})


InductionRadioGroupAnswer.prototype = {
    flag: PropTypes.bool,
    name: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    set_induction_answer: PropTypes.func.isRequired,
}


export default connect(mapStateToProps,{})(InductionRadioGroupAnswer);