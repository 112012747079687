import React from 'react'
import { Link } from 'react-router-dom'
import NavigationBar from './Shared/Navbar'

const PageNotFound = () => {
    return (
        <div className="container-front-page">
            <NavigationBar />
            <main className="main-content auth-container login-container">
            <div className="form-container">
                    <form className="auth-form dark-form">
                        <h2 className="form-header">404 - PAGE NOT FOUND</h2>
                        <Link to="/login" className="button dark-button submit-button">Go Home</Link>
                    </form>
            </div>
            </main>
        </div>
    )
}

export default PageNotFound
