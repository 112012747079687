import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, Button, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { close_modal } from '@actions/admin'
import VisitViewPage1 from './VisitViewPage1'
import VisitViewPage2 from './VisitViewPage2'
import { doesObjectExist } from '@utils/utils'
import { removeMessage, toastErrorMessage } from '@actions/toast'
import { getUserById } from '@actions/auth'
import VisitorInfoViewModal from './VisitorInfoViewModal'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'

const VisitViewModal = ({modal_data, close_modal,getUserById , toastErrorMessage}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        page: 0,
        totalPages: 1,
        show_modal: false,
        user: {}
    })

    useEffect(() => { 
        AppFunctionalityUsable.getInductionTypes();
    }, []);

    const visit = modal_data;
    const {page, totalPages, show_modal, user} = state;

    // ::: NAVIGATION
    const next = e => { setState({...state, page: page >= totalPages? totalPages : page + 1});}
    const prev = e => { setState({...state, page: page <= 0? 0:page - 1});}

    // ::: EVENT HANDLERS
    const onClose = e => { 
        close_modal();
    }

    const onOpenModal = async (p_id) => { 
        return false;
        /* :::: Disabled feature
        const user = await getUserById(p_id);
        if (isEmptyObject(user)) 
            toastErrorMessage('Could not load User!');
        else 
            setState({...state, show_modal: true, user: user})
        */
    }

    const onCloseModal = () => { 
        setState({...state, show_modal: false})
    }

    return (
        <Fragment>
            <Modal isOpen={true} className="no-border" centered size="lg">
                {/* ::: MODAL TITLE */}
                <h3 className="title-of-modal">{t("request")}</h3>
                <ModalBody className="modal-body paged-form-container">
                    <Form className="modal-paged-form">
                        {/* ::: FORM PAGES */}
                        {page === 0 && <VisitViewPage1 visit={visit || {}} onOpenModal ={onOpenModal} />}
                        {page === 1 && <VisitViewPage2 visit={visit || {grouped: []}}/>}
                    </Form>
                      {/* ::: PAGE INDICATORS */}
                    <div className="page-indicators-container blue-version">
                        <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                        <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/* ::: PAGE BUTTONS */}
                    <div className="modal-footer-button-container">
                        <Button className="left-sided-button" onClick={e => onClose(e)}>{t('close')}</Button>
                        <Button className="right-sided-button" color={`${'primary'}`} disabled={page === 1} onClick={e =>next(e)} >{t('next')}</Button>
                        <Button className="right-sided-button"  color="primary" disabled={page <= 0} onClick={e => prev(e)}>{t('previous')}</Button>
                    </div>
                </ModalFooter>
            </Modal>
            { show_modal && doesObjectExist(user) && <VisitorInfoViewModal fromModal={true} modal_info={[false, user, onCloseModal]}/>}
         </Fragment>
    )
}

VisitViewModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
    modal_data: PropTypes.object,
    toastErrorMessage: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    removeMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag, 
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
    toastErrorMessage, 
    removeMessage,
    getUserById,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitViewModal)
