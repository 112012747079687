import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { open_modal } from '@actions/admin'
import { SEE_ALERT_MSG_MODAL } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import { isEmptyArray, validateArrayParameter } from '@utils/utils'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { v4 } from 'uuid'

const AlertMessagesView = ({open_modal}) => {
    const {t} = useTranslation('main');
    const alertMessages = validateArrayParameter(AppFunctionalityUsable.alertMessages);
    const onOpenAlertModal = (alertMsg) => { 
        open_modal(SEE_ALERT_MSG_MODAL, alertMsg)
    }

    // ::::::::::::::::::::: GENERATED HTML ELEMENTS
    const alertMessageViewList = alertMessages.map(alertMsg => { 
        return  <div key={v4()} className="alert-message-box alert-card">
                    <h6 className="alert-title">{alertMsg.p_title}</h6>
                    <span className="alert-action text-white" onClick={e => onOpenAlertModal(alertMsg)}>{t('open')}</span>
                </div>
    });
    const noAlertMessageView = <div className="alert-message-box alert-card no-messages ">
                                    <h6 className="alert-title">{t('no_mes')}</h6>
                                </div>
    // :::: CONDITIONAL DISPLAY
    const alertMessageCards = isEmptyArray(alertMessageViewList)? noAlertMessageView : alertMessageViewList;

    return (
        <div className="alert-message-container">
            <h5 className="text-bold">{t('ale_mess')}</h5>
            {/* :: ALERT MESSAGES */}
            {alertMessageCards}
        </div>
    )
}

AlertMessagesView.propTypes = {
    open_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    open_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessagesView)
