import { CHANGE_FLAG, OPEN_MODAL, CLOSE_MODAL, CHANGE_PAGE, SET_REPORT_1, SET_REPORT_2, SET_REPORT_3, CLEAR_REPORT_DATA, GET_INDCUTION_QUESTION, SET_INDUCTION_ANSWER, SET_INDUCTION_ANSWERS, CLEAR_INDUCTION_QUESTION, CLEAR_REQ_INDUCTION_ANSWER, ADD_KNOWMOREMESSAGE, CLEAR_KNOWMOREMESSAGE, SET_MAIN_PANEL_STATS, SET_VISITORS, SET_HOSTS, SET_SERVICE_PROVIDERS, SET_SERVICE_PROVIDERS_WORKERS, SET_LANGUAGE } from "../actions/types";
import { REQUEST_PANEL } from "../utils/constants";

const initialState = {
    flag: false,
    page: REQUEST_PANEL,
    modal: '',
    lang: 'pt',
    modal_data: null,
    reportrn: null,
    reports: [{code: 1, name: "Relatorio Tabelado (visitas)"}, {code: 2, name: "Relatorio Grafico (visitas)"}, {code: 3, name: "Relatorio tabelado (estado de visitas)"}],
    rep_user_group: [{code: -1, name:"Grupo"},{code: -1, name:"Departamento"},{code: -2, name:"Terminal"} ],
    inductionAnswers: null,
    NewInductionQuestions: null,
    induction_answers: {},
    induction_gabarito: {},
    knowMoreMessages: [],
    mainPanelStats: {},
    visitors: [],
    hosts: [],
    serviceProviders: [],
    serviceProviderWorkers: [],

}

export default function(state=initialState, action) {
    const {type,payload} = action;
    switch(type) {
        case CHANGE_FLAG: return {...state, flag: !state.flag}
        case OPEN_MODAL: 
            return {...state, modal: payload.modal_id, modal_data: payload.data }
        case CLOSE_MODAL: 
            return {...state, modal: payload, modal_data: null}
        case CHANGE_PAGE: 
            return {...state, page: payload}
        case SET_REPORT_1:
            return {...state, reportrn: payload.length}
        case SET_REPORT_2:
            return {...state, reportrn: payload.length}
        case SET_REPORT_3:
            return {...state, reportrn: payload.length}
        case CLEAR_REPORT_DATA: 
            return {...state, reportrn: null}
        case SET_INDUCTION_ANSWER: 
            return {...state, induction_answers: payload}
        case SET_INDUCTION_ANSWERS: 
            return {...state, inductionAnswers: payload}
        case CLEAR_INDUCTION_QUESTION:
            return {...state, NewInductionQuestions: null, inductionAnswers: null}
        case CLEAR_REQ_INDUCTION_ANSWER:
            return {...state, induction_answers: {}}
        case GET_INDCUTION_QUESTION:
            return {...state, NewInductionQuestions: payload}
        case ADD_KNOWMOREMESSAGE: 
            return {...state, knowMoreMessages: [payload]}
        case CLEAR_KNOWMOREMESSAGE: 
            return {...state, knowMoreMessages: []}
        case SET_MAIN_PANEL_STATS:
            return {...state, mainPanelStats: payload}
        case SET_VISITORS:
            return {...state, visitors: payload}
        case SET_HOSTS: 
            return {...state, hosts: payload}
        case SET_SERVICE_PROVIDERS:
            return {...state, serviceProviders: payload}
        case SET_SERVICE_PROVIDERS_WORKERS: 
            return {...state, serviceProviderWorkers: payload}
        case SET_LANGUAGE:
            return {...state, lang: payload}
        default: return state;

    }
}

