import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
const LanguageSelector = () => {
    const {i18n} = useTranslation();
    const [state, setState] = useState({
        lan:'pt',
    });
    const {lan} = state;
    
    const changeLanguage = (e) => {
        const name = e.target.getAttribute('name');
        setState({...state, lan: name});
        i18n.changeLanguage(name);
    }

    return (
        <div className="language-selector text-white">
            <span name="pt" className={`px-2 ${lan === 'pt'? "selected": ''}`}  onClick={e => changeLanguage(e)}>PT</span>
            <span className="mx-2">/</span>
            <span name="en" className={`px-2 ${lan === 'en'? "selected": ''}`} onClick={e => changeLanguage(e)}>EN</span>
        </div>
    )
}

export default LanguageSelector;
