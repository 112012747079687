import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import AlertMsg from '@model/Settings/AlertMsg'
import { getInputDateFormatFromString } from '@utils/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'


const AddAlertMsgModal = ({setMessage, toShow, closeModal}) => {
    const { t } = useTranslation('settings');
    const [state, setState] = useState({
        alertMsg: new AlertMsg(),
        editorContent: '',
    });

    const {alertMsg} = state;
    const {p_title, p_msg, p_start, p_end } = alertMsg;

    // ::: EVENT HANDLERS
    const onSubmit = async e => { 
        const isSuccessful = await alertMsg.save();
        if(isSuccessful){ 
            AppFunctionalityUsable.getAlertMessages();
            closeModal();
            return setState({...state, alertMsg: new AlertMsg()})
        } 
    }

    const close = e => { 
        setState({...state, alertMsg: new AlertMsg()});
        closeModal(e);
    }
    

    return (
        <Modal isOpen={toShow} size="lg" className="no-border modal-xl" centered>
            {/* ::: TITLE OF MODAL */}
            <h2 className="title-of-modal">{ t('add_ale')}</h2>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form>
                    {/* ::: ALERT MESSAGE TITLE */}
                    <FormGroup>
                        <Label>{t('title')}</Label>
                        <Input type="text" name="p_title" value={p_title} placeholder={t('title')} className="required" onChange={e => alertMsg.set(e.target.name, e.target.value)} />
                    </FormGroup>
                    <FormGroup row>
                        {/* ::: ALERT MESSAGE START DATE */}
                        <Col md={6}>
                            <Label>{t('dat_ini')}</Label>
                            <Input type="date" name="p_start" value={getInputDateFormatFromString(p_start)} onChange={e => alertMsg.set(e.target.name, e.target.value)}/>
                        </Col>
                        {/* ::: ALERT MESSAGE END DATE */}
                        <Col md={6}>
                            <Label>{t('dat_fim')}</Label>
                            <Input type="date" name="p_end" value={getInputDateFormatFromString(p_end)} onChange={e => alertMsg.set(e.target.name, e.target.value)}/>
                        </Col>
                    </FormGroup>
                    {/* ::: ALERT MESSAGE MESSAGE */}
                    <FormGroup>
                        <Label>{t('message')}</Label>
                        <SunEditor enableToolbar={true} name="p_msg" value={p_msg} onChange={content =>  alertMsg.set('p_msg', content)} setOptions={{
                            buttonList: [
                                ['undo', 'redo'],
                                ['font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                ['fontColor', 'hiliteColor', 'textStyle'],
                                ['align', 'horizontalRule', 'list', 'lineHeight'],
                                ['fullScreen'],
                            ],
                            height: 400,
                        }} />
                    </FormGroup>
                    {/* ::: FORM BUTTONS */}
                    <FormGroup>
                        <div className="d-flex justify-content-between my-2">
                            <Button color="secondary" onClick={e => close(e)}>{t('close')}</Button>
                            <Button color="success" onClick={e => onSubmit(e)}>{t('add')}</Button>
                        </div>
                    </FormGroup>
                </Form>                
            </ModalBody>
        </Modal>
    )
}

export default AddAlertMsgModal
