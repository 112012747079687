
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addMaterialToVisit, addVehicleToVisit, getAllVisitMaterials, getAllVisitVehicles, removeVisitMaterialsFromVisit } from '@actions/visits';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Fragment } from 'react';


const VisitorFastRequestPage4 = ({flag,visit, user}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
      flag: false,
      show_modal: false,
    });

    const {show_modal} = state;
    const {terms_condition} = user;

    const addObjectToVisit = (e, functionToExecute) => { 
      functionToExecute(visit);
      setState({...state, flag: !state.flag});
    }

    const removeObjectFromVisit = (e, materialName) => { 
      removeVisitMaterialsFromVisit(visit, materialName);
      setState({...state, flag: !state.flag});
    }

    const changeTermsAndCondition = (e, val) => {
      setState({...state, terms_condition: val, show_modal: false});
      user['terms_condition'] = val;
    }

    const openTermsCond = (e) => {
        setState({...state, show_modal: true})
    }

    // :: HTML GENERATED FORM ELEMENTS
    const generateMaterialsList = () => {
       const materials = getAllVisitMaterials(visit);
        return materials.map((material, i) => {
          return (
            <div className="form-group row" key={i}>
              <div className="col-md-5" >
                <input
                  type="text"
                  name="material_name"
                  className="form-control dark-input"
                  value={material.p_oname}
                  onChange={(e) => material.changeName(e.target.value)}
                  placeholder={t("name")}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  name="material_qtd"
                  className="form-control dark-input"
                  value={material.p_qnt}
                  onChange={(e) => material.changeQuantity(e.target.value)}
                  placeholder={t("qtd")}
                />
              </div>
              <div className="col-md-5">
                <span
                  name="accept"
                  className={`float-right faded-button error`}
                  onClick={(e) => removeObjectFromVisit(e, material.p_oname)}
                >
                  {t("remove")}
                </span>
              </div>
            </div>
          );
        });    
    };
    
    const generateVehiclesList = () => {
      const vehicles = getAllVisitVehicles(visit);
      return vehicles.map((vehicle, i) => {
      return (
          <div className="form-group row" key={i}>
              <div className="col-sm-7">
              <input
                  type="text"
                  name="vehicle"
                  className="form-control dark-input"
                  value={vehicle.p_oname}
                  onChange={(e) => vehicle.changeName(e.target.value)}
                  placeholder={t("license")}
              />
              </div>
              <div className="col-sm-5">
              <span
                  name="remove"
                  className={`faded-button error float-right`}
                  onClick={(e) => removeObjectFromVisit(e, vehicle.p_oname)}>
                  {t("remove")}
              </span>
              </div>
          </div>
          );
      });
    }

     // :: HTML FORM ELEMENTS
     const termsAndConditions = (
      <div>
        <Modal isOpen={show_modal} size="xl" className="no-border" tabIndex="-1" centered >
          <h3 className="title-of-modal"> {t("ter_and_cond")} </h3>
          <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto", padding:"20px 70px" }} >
            <div>
              <p>A entrada nas instalações do Porto é por conta e risco próprio e está sujeita às seguintes condições gerais:</p>
              <ul style={{listStyleType:"lower-latin"}}>
                  <li className="text-justify my-4">A MPDC reserva-se ao direito de restringir a entrada no, e de controlar a saída do Porto;</li>
                  <li className="text-justify my-4">A MPDC reserva-se ao direito de recusar a entrada a qualquer pessoa, veículo ou empresa sem notificação ou explicação prévia;</li>
                  <li className="text-justify my-4">É obrigatório participar no processo de indução de segurança da MPDC antes de aceder às instalações do Porto;</li>
                  <li className="text-justify my-4">Sem prejuízo das legislações existentes, pessoas não autorizadas encontradas no recinto Portuário serão sujeitas a procedimentos criminais;</li>
                  <li className="text-justify my-4">A entrada de crianças no Porto será permitida somente para propósitos educacionais, após aprovação da MPDC e quando forem familiares de um membro da tripulação, para propósitos de visita / juntar-se ao Navio. Em ambas as situações, as crianças devem estar sempre acompanhadas por um adulto;</li>
                  <li className="text-justify my-4">Não são permitidos animais no recinto do Porto, excepto para propósitos de Importação/Exportação mediante intervenção de entidades de fiscalização;</li>
                  <li className="text-justify my-4">Não são permitidas no recinto do Porto quaisquer tipo de armas ou munições de qualquer natureza, excepto a Polícia e Unidades Militares quando em serviço;</li>
                  <li className="text-justify my-4">O Porto tem a política de tolerância zero ao álcool e realizará testagens obrigatórias a todos que entrarem nas suas instalações, ao pessoal com resultado positivo, o acesso será recusado e emitido um relatório para tomada de medidas;</li>
                  <li className="text-justify my-4">É proibido tirar fotografias, excepto com autorização do departamento de Segurança;</li>
                  <li className="text-justify my-4">É obrigatório ligar as luzes do veículo e usar EPI / PPE em áreas operacionais</li>
                  <li className="text-justify my-4">O uso de telefones móveis durante a condução e/ou enquannto caminha é proibido;</li>
                  <li className="text-justify my-4">A MPDC através do departamento de Segurança reserva-se ao direito de revistar qualquer pessoa ou veículo que entrar ou sair do Porto. </li>
                  <li className="text-justify my-4">Os sinais e marcações de tráfego rodoviário, incluindo o limite de velocidade de 30 km/h na estrada principal e de 10 km/h nas bancas e Cais, são de cumprimento obrigatório;</li>
                  <li className="text-justify my-4">A circulação de veículos no Porto é feita através das vias devidamente indicadas e sinalizadas;</li>
                  <li className="text-justify my-4">Exceder o limite de velocidade ou qualquer violação das normas do Porto está sujeito a penalizações;</li>
                  <li className="text-justify my-4">Os veículos devem estar equipados com pirilampo;</li>
                  <li className="text-justify my-4">Fumar é somente permitido em áreas designadas;</li>
                  <li className="text-justify my-4">Somente veículos em boas condições de circulação e ou com certificado de inspecção de veículos válido serão autorizados a aceder ao Porto;</li>
                  <li className="text-justify my-4">Os agentes que visitarem navios deverão estacionar os seus veículos em áreas de estacionamento designadas;</li>
                  <li className="text-justify my-4">O estacionamento durante a noite, sem ser autorizado, ou após concluir o carregamento ou o descarregamento de carga, bem como reparações nas instalações do Porto ou estacionamento por tempo superior ao autorizado, não é permitido. Nos casos de não cumprimento, serão aplicadas taxas em conformidade com a tarifa do Porto e os veículos / camiões serão libertados somente após o pagamento ser confirmado com o escritório de facturação do Porto; e</li>
                  <li className="text-justify my-4">Quaisquer danos às infra-estruturas do Porto, causados por terceiros, estão sujeitos a reparação ou substituição obedecendo os padrões, termos e condições do Porto. As intervenções devem ser realizadas num prazo máximo de 8 horas após o incidente, salvo nos casos em que apresentar confirmação da seguradora. </li>
              </ul>
              <ul style={{listStyleType: "none"}}>
                <li className="bold">Por este meio confirmo que tomei conhecimento das condições em que tenho autorização de acesso ao Porto de Maputo.</li>
              </ul>

            </div>
            <div className="d-flex justify-content-between my-4">
            <Button color="neutral" name="show_modal" onClick={(e) => changeTermsAndCondition(e, false)} > Reject </Button>
            <Button color="success" name="show_modal" onClick={(e) => changeTermsAndCondition(e, true)} > Accept </Button> 
            </div>
          </ModalBody>

        </Modal>
      </div>
    );

    const termsAndConditionCheckBox =   
      <Fragment>
        {/* :: TERMS AND CONDITIONS INPUT */}
        <div className="form-group my-4">
            <div className="custom-control custom-checkbox dark-check-container">
                <input type="checkbox" className="custom-control-input dark-check" id="terms-and-conditions" checked={terms_condition} name="terms_condition" onChange={(e) => openTermsCond(e)}/>
                <label className="custom-control-label dark-label" htmlFor="terms-and-conditions">{t("read_tc")}{" "}<span className="text-highlighted-dark-form">{t("ter_and_cond")}</span></label>
            </div>
        </div>
        {termsAndConditions}
      </Fragment>
    
    return (
      <div className="form-page">
            {/* :: FORM TITLE */}
            <h2 className="form-header">{t('req_dat')}</h2>
            {/* :: MATERIAL MANAGEMENT FORM ELEMENTS */}
            <div className="form-group text-left">
              <span className=" text-white" col={1}>{t("material")}</span>
              <span name="accept" className={`faded-button success float-right`}  onClick={(e) => addObjectToVisit(e, addMaterialToVisit)}>{t("add_mat")} +{" "}</span>
            </div>
            {generateMaterialsList()}
            {/* :: VEHICLE MANAGEMENT FORM ELEMETNS */}
            <div className="form-group mt-5 text-left">
              <span className=" text-white" col={1}>{t("vehicle")}</span>
              <span name="accept" className={`faded-button success float-right`}  onClick={(e) => addObjectToVisit(e, addVehicleToVisit)}>{t("add_veh")} +{" "}</span>
            </div>
            {generateVehiclesList()}
            {termsAndConditionCheckBox}
      </div>
    )
}
VisitorFastRequestPage4.propTypes = {
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitorFastRequestPage4);