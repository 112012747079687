import { ROOT_URL,SUCCESS_TOAST} from "@utils/constants";
import { extractExtraFields, createStoreLoader, sendRequestDataWithDefaultConfig, checkApiResponseForErrors } from "@utils/utils";
import { setMessage, toastErrorMessage } from "@actions/toast";
import { update } from "@actions/admin";
import store from '@components/../store';

class Extra { 

    constructor(extra) {
        this.setUpExtra(extra);
    }
    
    /**
     * Creates extra fields.
     * @param {object} extra 
     */
    setUpExtra (extra) { 
        Object.keys(extra).forEach(key => this[key] = extra[key]);
        this.active = this.p_active;
    }

    set(key, val) { 
        this[key] = val;
        store.dispatch(update());
    }

    get(key) { 
        return this[key] || [];
    }

    /**
     * Updates a field and submits it to the backend.
     * @param {string} key 
     * @param {*} val 
     */
    setAndSubmit(key, val) { 
        this[key] = val;
        if(key==="active") this.p_active = val;
        store.dispatch(update());
        this.save();
    }

 
    /**
     * Sends the request to save the new extra.
     */
    async save ()  {
        const loader = createStoreLoader(store);
        let isSuccessful = true;
        try {
            const requestData = extractExtraFields(this);
            const requestBody = JSON.stringify(requestData);
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/extras/editExtra`, requestBody);
            checkApiResponseForErrors(response);

            store.dispatch(setMessage('Success', SUCCESS_TOAST));
            store.dispatch(update());        
          } catch (err) {
              store.dispatch(toastErrorMessage(err.message));
              isSuccessful = false;
          }
          
          loader.stopLoading();
          return isSuccessful;
    }

    

    static async getFuncOfTerm(ter_cod) { 
        try {
            const body = JSON.stringify({ p_cod_company: ter_cod });
            const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/extras/getFuncionarioByCompany`, body);
            checkApiResponseForErrors(res);
            //::>> FORMAT THE RESPONSE TO BE ACOMODATED IN THE TEXTFIELD HINTS
            Extra.usersOfTerminal = res;
            store.dispatch(update());
        } catch (err) {
            store.dispatch(toastErrorMessage(err.message));
        }
       
    }


    

}


export default Extra;