import React, { useState } from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {sendVerificationEmail, verify_user} from '@actions/auth';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import NavigationBar from '@sharedComponents/Navbar';
import { Button } from 'reactstrap';
import { doubleDigit } from '@utils/utils';


const VerifyAccount = (props)  => {
    const { t } = useTranslation(['verifyAccount']);
    const [state, setState] = useState({
        timer: 90,
        start: false,
    })

    const token = props.match.params.token;
const isVerified = props.isVerified;

    useEffect(() => { 
        let id;
        if(state.start) { 
            id = setInterval(() => { 
                if(state.timer === 1){
                    setState({...state, timer: 90, start: false})
                } else { 
                    setState({...state, timer: state.timer - 1})
                }
            }, 1000);
            return () => clearInterval(id);
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.timer, state.start]);
 
    const startTimer = () => { 
        setState({...state, start: true});
    }

    const resendVerification = async e => { 
        const isSuccessful = await props.sendVerificationEmail(props.p_nid);
        if (isSuccessful) startTimer();
    }
 
    let message;
    useEffect(() => { 
        if (token) props.verify_user(token); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isVerified) return <Redirect to="/login"/>;

    if (true) { 
        message = (<div>
                    <p className="text-white text-center px-4">{t('cred_sent')}</p>
                    <Link className="btn btn-success mx-2" to="/login">{t('login')}</Link>
                  </div>)
    } else if (token) {
        message = <h4 className="text-white text-center px-4">{t('acc_bei_ver')}</h4>
    } else { 
        const countDown = Math.floor(state.timer / 60);
        message = (<div className="text-center">
                        <p className="text-center text-white px-4">{t('em_mes')}</p> 
                        <Button color="success" disabled={state.timer < 90} onClick={e => resendVerification(e)} >{t('resend')}</Button>
                        <Link className="btn btn-secondary mx-2" to="/login">{t('back')}</Link>
                        <span className="d-block text-white my-3">{`${doubleDigit(countDown)}:${doubleDigit(state.timer % 60)} `}</span>
                    </div>)
    }
   


    return (
        <div className="container-front-page">
        <NavigationBar />
        <main className="main-content auth-container login-container">
            <div className="form-container">
                <form className="auth-form dark-form">
                    <h2 className="form-header">{t('acc_ver')}</h2>
                    {message}
                </form>
            </div>
        </main>
    </div>
    )
}

VerifyAccount.propTypes = {
    isVerified: PropTypes.bool.isRequired,
    verify_user: PropTypes.func.isRequired,
    p_nid: PropTypes.string,
    sendVerificationEmail: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isVerified: state.auth.isVerified,
    flag: state.admin.flag,
    p_nid: state.auth.p_nid,
})
export default connect(mapStateToProps, {verify_user, sendVerificationEmail})(VerifyAccount);
