import { LOADING_TOAST } from "@utils/constants";
import { SET_MESSAGE, REMOVE_MESSAGE, SET_LOADING_MESSAGE, REMOVE_LOADING_MESSAGE } from "../actions/types";
import { composeToasts, isEmptyObject } from "../utils/utils";

const initialState = {
    messages: [],
    loadingMessage: {}
};

export default function(state=initialState, action) { 
    const {type, payload} = action;

    const createLoadingMessage = code => { 
        let newLoadingMessage = {};
        if(existsLoadingMessage()) 
            newLoadingMessage = buildLoadingMessageWithExistingOne(code)
        else 
            newLoadingMessage = buildNewLoadingMessage(code);
            
        return newLoadingMessage;
    }

    const existsLoadingMessage = () => { 
        return !isEmptyObject(state.loadingMessage);
    }

    const buildLoadingMessageWithExistingOne = code => { 
        return {...state.loadingMessage, id: code}
    }

    const buildNewLoadingMessage = code => { 
        return {message: '', id: code, msgType: LOADING_TOAST}
    }

    const removeLoadingMessage = code => { 
        let newLoadingMessage = state.loadingMessage;
        if(isLoadingMessageOfCode(code))
            newLoadingMessage = {};
        return newLoadingMessage;
    }

    const isLoadingMessageOfCode = code => { 
        return state.loadingMessage.id === code;
    }
    

    switch(type) { 
        case SET_MESSAGE: return {...state, messages: composeToasts([...state.messages, payload])};
        case REMOVE_MESSAGE: return {...state, messages: state.messages.filter(toast => toast.id !== payload)};
        case SET_LOADING_MESSAGE: return {...state, loadingMessage: createLoadingMessage(payload)}
        case REMOVE_LOADING_MESSAGE: return {...state, loadingMessage:removeLoadingMessage(payload)}
        default: return state;
    }

    
}

