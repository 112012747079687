import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { doesVariableExist} from '@utils/utils';
import { getAppConfig } from '@utils/configurations'
import { Fragment } from 'react'
import SettingsItemTable from '../Shared/SettingsItemTable'
import { Filter } from 'react-feather'
import { fetchServiceProviderCompanies, filterServiceProvider, sendCompanyRegistrationLink } from '@actions/admin'

const ServicesSettingsTab = ({user,fetchServiceProviderCompanies, sendCompanyRegistrationLink, filterServiceProvider, serviceProviders, flag}) => {
    const {t} = useTranslation('settings');
    const { FILTER_NAME_TO_OBJECT_PROPERTY_COMPANY } = getAppConfig();
    const [state, setState] = useState({
        email: '',
        page: 1,
        filter: 'Nome',
        filter_val: ''
    });
    
    const {email, filter, filter_val} = state;

    useEffect(() => {
        fetchServiceProviderCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    
    if(doesVariableExist(filter_val) && (serviceProviders)) {
        const filterValue = filter_val.toLowerCase();
        let companyValue;
        serviceProviders = serviceProviders.filter((comp) => {
            companyValue = comp[FILTER_NAME_TO_OBJECT_PROPERTY_COMPANY[filter]].toLowerCase();
            return (!filter_val || companyValue.startsWith(filterValue))
        });
    }

    // ::: EVENT HANDLERS
    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if(nodeName === 'filter'){
            setState({...state, filter: nodeValue, filter_val: ''});
        } else if(nodeName === 'filter_type') { 
            setState({...state, filter_val: e.target.value});
        } else  {
            setState({...state, [e.target.name]: e.target.value});
        }
        
    }

    const onSubmit = e =>  { 
        sendCompanyRegistrationLink(user, email);
    }

    // ::: CHILDREN PROPERTIES
    const tableOptions = {
        title: t('services'),
        showAddBtn: false,
        canEdit: false
    }

    return (
        <Fragment>
            <div className="my-2 d-flex justify-content-between">
                <div>
                    {/* ::: EMAIL INPUT FOR REGISTRATION */}
                    <Form inline>
                            <Input type="email" name="email" placeholder="email" value={email} className="required small-form-ele mr-4" onChange={e => onChange(e)} />
                            <Button color="success" onClick={e => onSubmit(e)}>{t('send')}</Button>
                    </Form>
                    <small>Send a link email for an external company to register.</small>
                </div>
                {/* ::: FILTERS */}
                <Form inline>
                    <FormGroup>
                        <Filter size="20px" className="mx-3"/>
                        <Input type="text" name="filter_type" placeholder={t('name')} value={filter_val} className="d-inline-block small-form-ele" onChange={e => onChange(e)} />
                    </FormGroup>
                </Form>
            </div>
            {/* ::: ALL SERVICES TABLE */}
            <SettingsItemTable items={serviceProviders} options={tableOptions}  />
        </Fragment>
    )
}

ServicesSettingsTab.propTypes = {
    user: PropTypes.object,
    flag: PropTypes.bool,
    fetchServiceProviderCompanies: PropTypes.func.isRequired,
    filterServiceProvider: PropTypes.func.isRequired,
    sendCompanyRegistrationLink: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    flag: state.admin.flag,
    serviceProviders: state.admin.serviceProviders,
})

const mapDispatchToProps = {
    fetchServiceProviderCompanies,
    filterServiceProvider,
    sendCompanyRegistrationLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesSettingsTab)
