import Extra from "@model/Extras/Extra";
import { getAppConfig } from "./configurations";
import { DEFAULT_ARRIVAL_HOUR, DEFAULT_COMBOBOX_CODE, DEFAULT_COUNTRY_CODE, PASSWORD_MIN_LENGTH, USER_LOCALSTORAGE_KEY } from "./constants";

const randomString = require('randomstring');
const { isOnlyNum, isOnlyWords, removeAllSpaces, containsSpecialCharacters, isEmptyValue, isEmptyObject, isUndefinedOrNull, getToday, getMomentFromString, validateObjectParameter, buildEmployeeNameToIdMapViceVersa, validateArrayParameter, canValidateHostVisitorOfVisit, getObjectFromLocalStorage } = require("./utils")

export const fixInputNames = (name) => { 
    let fixedName = name;
    if(isEmptyValue(name))
        fixedName =  '';
    else if(isOnlyNum(name))
        throw new Error("Names should not contain numbers.");
    else if(!isOnlyWords(name))
        throw new Error("Names should only contain alphabetic characters");
    return fixedName;
}

export const fixInputCompanyNames = (name) => { 
    let fixedName = name;
    if(isEmptyValue(name))
        fixedName =  '';
    return fixedName;    
}

export const fixInputEmails = (email) => {
    let fixedEmail = email;
    if(isEmptyValue(email))
        fixedEmail =  '';
    return removeAllSpaces(fixedEmail);
}

export const fixInputPhoneNumbers = (phoneNumber) => { 
    let fixedPhoneNumber = phoneNumber;
    let firstCharacter = ''
    if(containsSpecialCharacters(phoneNumber)){
        fixedPhoneNumber = phoneNumber.substring(1);
        firstCharacter = phoneNumber.substring(0,1);
    }

    if(isEmptyValue(fixedPhoneNumber))
        fixedPhoneNumber = '';
    else if(isOnlyWords(fixedPhoneNumber))
        throw new Error("Phone numbers should only contain digits!");
    else if(containsSpecialCharacters(fixedPhoneNumber))
        throw new Error("Phone numbers can not contain special characters!");
    else if (!isOnlyNum(fixedPhoneNumber))
        throw new Error("Phone numbers should only contain numeric characters");

    return firstCharacter + fixedPhoneNumber;
}

export const fixInputIdNumbers = (idNumber) => { 
    let fixedIdNumber = idNumber;
    if(isEmptyValue(idNumber))
        fixedIdNumber = '';
    else if(containsSpecialCharacters(idNumber) && false) // :: Just deactivating
        throw new Error("ID numbers Should not contain special characters");
    return fixedIdNumber;
}

export const fixInputCountryCodes = (userToBeFixed) => { 
    let cCode1 = userToBeFixed.country_code_p1;
    let cCode2 = userToBeFixed.country_code_p2;
    cCode1 = fixInputPhoneNumbers(cCode1);
    cCode2 = fixInputPhoneNumbers(cCode2);

    if(isEmptyValue(cCode1))
        cCode1 = DEFAULT_COUNTRY_CODE;
    if(isEmptyValue(cCode2))
        cCode2 = DEFAULT_COUNTRY_CODE;

    userToBeFixed.country_code_p1 = cCode1;
    userToBeFixed.country_code_p2 = cCode2;
}

export const fixAllFieldsToInputFixFunction = (object, fieldsArray, inputFixFunction) => { 
    fieldsArray.forEach(field => { 
        object[field] = inputFixFunction(object[field]);
    });
}

export const fixNewUserFields = (userObject) => { 
    let userToBeFixed = userObject;
    if(isUndefinedOrNull(userObject))
        userToBeFixed = {};
    
    fixAllFieldsToInputFixFunction(userToBeFixed, ["p_fname", "p_lname"], fixInputNames);
    fixAllFieldsToInputFixFunction(userToBeFixed, ["p_email"], fixInputEmails);
    fixAllFieldsToInputFixFunction(userToBeFixed, ["p_nid"], fixInputIdNumbers);
    fixAllFieldsToInputFixFunction(userToBeFixed, ["p_pnumber_1", "p_pnumber_2"], fixInputPhoneNumbers); 
    fixInputCountryCodes(userToBeFixed);

    return userToBeFixed;
}

export const fixNewCompanyFields = (companyObject) => { 
    let companyToBeFixed = validateObjectParameter(companyObject);

    fixAllFieldsToInputFixFunction(companyToBeFixed, ["p_cname"], fixInputCompanyNames);
    fixAllFieldsToInputFixFunction(companyObject, ["p_cemail"], fixInputEmails)
    fixAllFieldsToInputFixFunction(companyObject, ["p_cpnumber_1", "p_cpnumber_2"], fixInputPhoneNumbers);
    fixInputCountryCodes(companyToBeFixed);

    return companyToBeFixed;
}

export const getCountryCodeByCountryName = (countryName) => { 
    const {COUNTRY_LIST} = getAppConfig();
    let cCode = DEFAULT_COUNTRY_CODE;
    const countryObject = COUNTRY_LIST.find(country => country.name === countryName);
    if(!isEmptyObject(countryObject))
        cCode = countryObject.dial_code;
    return cCode;
}

export const resolveUserCountryCode = (userObject, countryName) => { 
    const cCode = getCountryCodeByCountryName(countryName);
    setUserCountryCode(userObject, cCode);
}

export const setUserCountryCode = (userObject, countryCode) => { 
    userObject['country_code_p1'] = countryCode;
    userObject['country_code_p2'] = countryCode;
}

export const setVisitorProvenace = (visitorObject, provenaceValue) => { 
    if (+provenaceValue === +DEFAULT_COMBOBOX_CODE) 
        visitorObject['provenance'] = undefined;
    else  
    visitorObject['provenance'] = provenaceValue;
}

export const validateName = (name) => { 
    const { NAME_MIN_CHARACTERS } = getAppConfig();
    let validatedName = fixInputNames(name);
    if(isEmptyValue(validatedName))
        throw new Error(`Names are required and should be at least ${NAME_MIN_CHARACTERS} characters long.`);
    else if(validatedName.length < NAME_MIN_CHARACTERS)
        throw new Error(`Names are required and should be at least ${NAME_MIN_CHARACTERS} characters long.`);
    return true;
} 

export const validateCompanyName = (name) => { 
    let validatedName = fixInputCompanyNames(name);
    if(isEmptyValue(validatedName))
        throw new Error(`Company Name is required!`);
    return true;
}

export const validateEmail = (email) => { 
    fixInputEmails(email);
}

export const validateCompanyEmail = (email) => { 
    const fixedEmail = fixInputEmails(email);
    if(isEmptyValue(fixedEmail))
        throw new Error("Company Email is required!");
}

export const validatePhoneNumbers = (number1, number2) => { 
    fixAllFieldsToInputFixFunction({}, ["p_pnumber_1", "p_pnumber_2"], fixInputPhoneNumbers);
    if(isEmptyValue(number1))
        throw new Error("Phone Number (1) is required");
    else if(isEmptyValue(number2))
        throw new Error("Phone Number (2) is required");
    else if(number1 === number2) 
        throw new Error("Phone Numbers should not be the same");
    return true;
}

export const validateIdNumber = (idNumber) => { 
    fixInputIdNumbers(idNumber);
    if(isEmptyValue(idNumber))
        throw new Error("ID Number is required");
    return true;
}

export const validatePasswords = (password1, confirmationPassword) => { 
    if(isEmptyValue(password1)) 
        throw new Error("Password is required!");
    else if (password1.length < PASSWORD_MIN_LENGTH)
        throw new Error("PasswordS should be at least 6 characters long!");
    else if (password1 !== confirmationPassword) 
        throw new Error("Passwords do not Match!");
    else if (removeAllSpaces(password1).length === 0)
        throw new Error("Password is required")
    return true;
}

export const validateIdDocumentDate = (date) => { 
    if(isEmptyValue(date))
        throw new Error("ID Document Expiring date is required");

    const visitDate = getMomentFromString(date)
    if(visitDate.isBefore(getToday(), 'day')) 
        throw new Error("ID Document is expired");
}

export const validateVisitDates = (date) => { 
    if(isEmptyValue(date))
        throw new Error("Visit date is required");

    const visitDate = getMomentFromString(date)
    if(visitDate.isBefore(getToday(), 'day')) 
        throw new Error("Visits can only be dated from this day forward!");
}

export const validateTermsAndConditions = (termsAndCondition) => { 
    if(isEmptyValue(termsAndCondition))
        throw new Error("Terms and Conditions need to be accepted to proceed to registration");
    if(!termsAndCondition)
        throw new Error("Terms and Conditions need to be accepted to proceed to registration");
    return true;
}

export const validateAllFieldsToInputValidationFunction = (object, fieldsArray, validationFunction) => { 
    fieldsArray.forEach(field => { 
        validationFunction(object[field]);
    });
}

export const validateNewUserFields = (userObject) => { 
    let userToBeValidated = userObject;
    if(isUndefinedOrNull(userObject))
        userToBeValidated = {};
    
    validateUserBasicFields(userToBeValidated);
    validateTermsAndConditions(userToBeValidated.terms_condition);

    return true;
}

export const validateLoggedInUserFields = (userObject) => { 
    let userToBeValidated = validateObjectParameter(userObject);
    validateUserBasicFields(userToBeValidated);
    return true;
}

export const resolveNewPasswordUpdate = (userObject) => { 
    if (isEmptyValue(userObject.p_psw))
        setPrevPasswordToUser(userObject);
    else
        flagUserWithPasswordUpdate(userObject);
}

const setPrevPasswordToUser = userObject => { 
    const localStorageUser = getObjectFromLocalStorage(USER_LOCALSTORAGE_KEY);
    userObject.p_psw = localStorageUser.p_psw;
    userObject.p_psw_2 = localStorageUser.p_psw;
    
}

const flagUserWithPasswordUpdate = userObject => { 
    userObject.hasUpdatedPassword = 1;
}

const validateUserBasicFields = (userToBeValidated) => { 
    validateAllFieldsToInputValidationFunction(userToBeValidated, ["p_fname", "p_lname"], validateName);
    validateAllFieldsToInputValidationFunction(userToBeValidated, ["p_email"], validateEmail);
    validateAllFieldsToInputValidationFunction(userToBeValidated, ["p_nid"], validateIdNumber);
    validateIdDocumentDate(userToBeValidated.p_expiration_date_id);
    validatePhoneNumbers(userToBeValidated.p_pnumber_1, userToBeValidated.p_pnumber_2);
    validatePasswords(userToBeValidated.p_psw, userToBeValidated.p_psw_2);
}

export const validateNewCompanyFields = (userObject) => { 
    const userToBeValidated = validateObjectParameter(userObject);
    
    validateAllFieldsToInputValidationFunction(userToBeValidated, ["p_cname"], validateCompanyName);
    validateAllFieldsToInputValidationFunction(userToBeValidated, ["p_cemail"], validateCompanyEmail);
    validatePhoneNumbers(userToBeValidated.p_cpnumber_1, userToBeValidated.p_cpnumber_2);
    
    return true;
}

export const fixNewVisitorVisitFields = (visitObject) => { 
    const visitToBeFixed = validateObjectParameter(visitObject);

    fixNewVisitComonFields(visitObject);
    fixAllFieldsToInputFixFunction(visitToBeFixed, ["p_period"], fixInputPeriod);
    visitToBeFixed.p_id_host = fixHostIdOfVisit(visitToBeFixed.p_id_host);
    return visitToBeFixed;
}

export const fixNewVisitorVisitFieldsWithSkippableParams = (visitObject, skippableParams=[]) => { 
    const visitToBeFixed = validateObjectParameter(visitObject);
    fixNewVisitComonFields(visitObject);
    if(!skippableParams.includes('p_period'))
        fixAllFieldsToInputFixFunction(visitToBeFixed, ["p_period"], fixInputPeriod);
    if(!skippableParams.includes('p_id_host'))
        visitToBeFixed.p_id_host = fixHostIdOfVisit(visitToBeFixed.p_id_host);

    return visitToBeFixed;
}

export const fixNewHostVisitFields = (visitObject) => { 
    const visitToBeFixed = validateObjectParameter(visitObject);
    fixNewVisitComonFields(visitToBeFixed);
    fixAllFieldsToInputFixFunction(visitToBeFixed, ["p_period"], fixHostInputPeriod);
    visitObject = visitToBeFixed;
    return visitToBeFixed;
}

const fixNewVisitComonFields = (visitToBeFixed) => { 
    fixAllFieldsToInputFixFunction(visitToBeFixed, ["p_detail"], fixInputDetails);
    visitToBeFixed.p_date_time = fixInputDateTime(visitToBeFixed.p_date_time, visitToBeFixed.p_time);
}

export const fixInputPeriod = (inputPeriod) => { 
    const { MAX_TIME_PERIOD } = getAppConfig();
    let periodToCheck = +inputPeriod;
    if(isEmptyValue(inputPeriod))
        throw new Error("Visit Period is required");
    if(periodToCheck > MAX_TIME_PERIOD)
        throw new Error(`Visit Periods must be between 1hour to ${MAX_TIME_PERIOD}hours`);
    else if(periodToCheck < 1) 
        throw new Error(`Visit Periods must be between 1hour to ${MAX_TIME_PERIOD}hours`)
    
    return periodToCheck;
}

export const fixHostInputPeriod = (inputPeriod) => { 
    let periodToCheck = +inputPeriod;
    if(isEmptyValue(inputPeriod))
        throw new Error("Visit Period is required");
    else if(periodToCheck < 1) 
        throw new Error(`Visit Periods must be over an hour`);

    return periodToCheck
}

export const fixHostIdOfVisit = (hostId) => { 
    let fixedHostId = hostId;
    if(!isOnlyNum(fixedHostId)) {
        const empList = buildEmployeeNameToIdMapViceVersa(Extra.usersOfTerminal);
        fixedHostId = empList[fixedHostId.toLowerCase()];
    }
    return fixedHostId || "";
}

export const fixInputDetails = (inputDetails) => { 
    let inputToBeFixed = inputDetails;
    if(isEmptyValue(inputDetails))
        inputToBeFixed = '';
    return inputToBeFixed;
}

export const fixInputDateTime = (inputDate, inputTime) => { 
    let time = inputTime;
    let date = inputDate;
    if(isEmptyValue(inputTime))
        time = DEFAULT_ARRIVAL_HOUR;
    if(isEmptyValue(inputDate))
        date = getToday().toISOString();

    const fixedDate = combineDateTimeToMoment(date, time);
    return fixedDate.toISOString();
}

export const fixInputMaterialsQtd = (inputQtd) => { 
    let fixedQuantity = inputQtd;
    if(isEmptyValue(inputQtd))
        fixedQuantity = '';
    else if(!isOnlyNum(fixedQuantity))
        fixedQuantity = 1;

    return fixedQuantity;
}

const combineDateTimeToMoment = (date, time) => { 
    const [hour, minute] = time.split(":");
    const selDate = getMomentFromString(date);
    selDate.set("hour", hour);
    selDate.set("minute", minute);
    return selDate;
}

export const validateVisitorVisitFields = (visitObject) => { 
    const visit = validateObjectParameter(visitObject);
    validateFieldsComonToAllTypesOfVisit(visit);
    validateRequiredField(visit.p_period, 'Visit Period');
}

export const validateVisitorVisitFieldsWithSkippableParams = (visitObject, skippableParams=[]) => { 
    const visit = validateObjectParameter(visitObject);
    validateFieldsComonToAllTypesOfVisitWithSkippableParams(visit, skippableParams);
    if(!skippableParams.includes('p_period'))
        validateRequiredField(visit.p_period, 'Visit Period');
}

export const valdiateHostVisitFields = (visitObject) => { 
    const visit = validateObjectParameter(visitObject);
    const p_users = validateArrayParameter(visit.p_users);

    validateFieldsComonToAllTypesOfVisit(visit);
    if(canValidateHostVisitorOfVisit(visit)){
        generateRandomPasswordToUser(p_users[0]);
        validateUserBasicFields(p_users[0]);
    }
    visitObject.p_submited = visitObject.p_date_time;
    visitObject.type_visit = 1;
}


const validateFieldsComonToAllTypesOfVisit = (visitObject) => { 
    const visit = validateObjectParameter(visitObject);
    validateComboBox(visit.p_id_company, 'Terminal');
    validateComboBox(visit.p_id_department, 'Department');
    if(!visit.p_tocompany)
        validateRequiredField(visit.p_id_host, 'Colaborator');
    validateVisitDates(visit.p_date_time);
    validateComboBox(visit.p_id_rpp, 'Reasons of visit');
    // validateRequiredField(visit.p_detail, 'Visit details');
}

const validateFieldsComonToAllTypesOfVisitWithSkippableParams = (visitObject, skippableParams=[]) => { 
    const visit = validateObjectParameter(visitObject);
    if(!skippableParams.includes('p_id_company'))
        validateComboBox(visit.p_id_company, 'Terminal');

    if(!skippableParams.includes('p_id_department'))
        validateComboBox(visit.p_id_department, 'Department');
        
    if(!skippableParams.includes('p_id_host')) { 
        if(!visit.p_tocompany)
            validateRequiredField(visit.p_id_host, 'Colaborator');
    }

    if(!skippableParams.includes('p_date_time'))
        validateVisitDates(visit.p_date_time);

    if(!skippableParams.includes('p_id_rpp'))
        validateComboBox(visit.p_id_rpp, 'Reasons of visit');
}

const validateComboBox = (value, comboBoxOption) => {
    validateRequiredField(value, comboBoxOption);
    if (+value < 0) 
        throw new Error(`${comboBoxOption} selection is required!`);
}

const validateRequiredField = (value, fieldName) => { 
    if (isEmptyValue(value))
        throw new Error(`${fieldName} is required!`);
}

const generateRandomPasswordToUser = (userObject) => {
    const password = randomString.generate({length: 8});
    userObject.p_psw = password;
    userObject.p_psw_2 = password;
}

