import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap';
import { ERROR_TOAST, SUCCESS_TOAST, WARNING_TOAST, LOADING_TOAST, INFORMATION_TOAST } from '@utils/constants';
import { v4 } from 'uuid';
import { doesArrayExist, isEmptyObject } from '@utils/utils';

const ERROR_ICON = require(`@resources/icons/x-circle.svg`);
const SUCCESS_ICON = require(`@resources/icons/check-circle.svg`);
const WARNING_ICON = require(`@resources/icons/alert-triangle.svg`);
const INFO_ICON = require(`@resources/icons/info_icon.svg`);

const ToastM = ({messages, loadingMessage}) => {
    let allMessages = [...messages, loadingMessage ];
    if(!isEmptyObject(loadingMessage))  {
        const newMessages = messages.filter(msg => msg.msgType !== LOADING_TOAST);
        allMessages = [...newMessages, loadingMessage];
    }
        
    allMessages = filterForEmptyMessages(allMessages);
    const toast = allMessages.map(msg => { 
        let message, type, icon;
        switch(msg.msgType) { 
            case ERROR_TOAST: message = msg.message; icon=<img src={ERROR_ICON} alt="icon_type"/>; type="text-red"; break;
            case SUCCESS_TOAST: message = [msg.message || "Success"]; icon=<img src={SUCCESS_ICON} alt="icon_type"/>; type="text-green";break;
            case WARNING_TOAST: message = msg.message; icon=<img src={WARNING_ICON} alt="icon_type"/>; type="text-yellow";break;
            case INFORMATION_TOAST: message = msg.message; icon=<img src={INFO_ICON} alt="icon_type"/>; type="text-blue";break;
            case LOADING_TOAST: message = ["Loading..."]; icon=<Spinner size="sm" />; break;
            default: message = msg.message; icon="info";
        }
        return (
            <Toast key={v4()}>
                <ToastHeader icon={icon} >
                    <span  className={type}>{msg.msgType}</span>
                </ToastHeader>
                <ToastBody>
                    {doesArrayExist(message) && message.map((msg, index) => { 
                        return <span key={index + '' +  msg.msgType} className={`${((msg.msgType === ERROR_TOAST) || (msg.msgType === WARNING_TOAST))? "separated-toast ":''} my-1 pl-2 d-block w-100`}>{msg}<br/></span>
                    })}
                </ToastBody>
            </Toast>
        )
    })

    return (
        <div style={{ position: "absolute", zIndex: 2000, right: 10, top: 10 }}>
            {toast}
        </div >
       
    )
}

const filterForEmptyMessages = messages => { 
    return messages.filter(msg => !isEmptyObject(msg));
}

ToastM.propTypes = {
    messages: PropTypes.array,
    loadingMessage: PropTypes.object,
}

const mapStateToProps = (state) => ({
    messages : state.toast.messages,
    loadingMessage: state.toast.loadingMessage
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastM)
