import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom';
import NavigationBar from '@sharedComponents/Navbar';
import { performUserRegistrationProcess, getNewUserObject, changeIsRegisteredStoreFlag } from '@actions/auth';
import UserPersonalDataForm from '@sharedComponents/Auth/UserPersonalDataForm';
import UserAccountDataForm from '@sharedComponents/Auth/UserAccountDataForm';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';

const VisitorRegistrationPage = ({changeIsRegisteredStoreFlag, isRegistered, performUserRegistrationProcess, getNewUserObject}) => {
    const {t} = useTranslation('auth');
    const [state, setState] = useState({
        page: 0,
        num_pag: 1,
        user: getNewUserObject({}),
    });

    useEffect(() => { 
        AppFunctionalityUsable.getIdDocumentTypes();
        AppFunctionalityUsable.getVisitorProvenance();
    }, []);

    const {page, num_pag, user} = state;
    
    if (isRegistered) {
        changeIsRegisteredStoreFlag(false);
        return <Redirect to="/accountVer" />
    }; 

    const next = e => {
        e.preventDefault(); 
        setState({...state, page: page >= num_pag? num_pag : page + 1});
    }

    const prev = e => {
        setState({...state, page: page <= 0? 0:page - 1});
    }

    const onSubmit = e => { 
        e.preventDefault();
        performUserRegistrationProcess(user);        
    }

    const NEXT_BUTTON_TEXT = (page >= num_pag)? t('submit'):t('next');
    const PREVIOUS_BUTTON_TEXT = t('previous');
    const IS_PREVIOUS_BUTTON_DISABLED = page <= 0;

    return (
        <div className="container-front-page">
            <NavigationBar />
            <main className="main-content auth-container registration-container">
                <div className="form-container paged-form-container">
                    {/* :: FORM */}
                    <form className="auth-form paged-form dark-form">
                        {/* :: REGISTRATION PAGE 01  */}
                        {page === 0 && <UserPersonalDataForm newUser={user}/>}
                        {/* :: REGISTRATION PAGE 02 */}
                        {page === 1 && <UserAccountDataForm newUser={user}/>}
                        {/* :: PAGE INDICATORS */}
                        <div className="page-indicators-container">
                            <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                            <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                        </div>
                        {/* :: FORM BUTTONS */}
                        <div className="form-buttons clearfix">
                            <Link type="button" to="/login" className="button link-button left-sided-button">{t('cancel')}</Link>
                            <button type="submit" className="button dark-button submit-button right-sided-button" onClick={e => (page >= num_pag)? onSubmit(e):next(e)}>{NEXT_BUTTON_TEXT}</button>
                            <button type="button" className={`${IS_PREVIOUS_BUTTON_DISABLED? 'disabled-dark-button':''} button link-button text-no-underline mr-3 right-sided-button`} disabled={IS_PREVIOUS_BUTTON_DISABLED} onClick={e => prev(e)} >{PREVIOUS_BUTTON_TEXT}</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    )
}

VisitorRegistrationPage.propTypes = {
    performUserRegistrationProcess: PropTypes.func.isRequired,
    getNewUserObject: PropTypes.func.isRequired,
    changeIsRegisteredStoreFlag: PropTypes.func.isRequired,
    isRegistered: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
   isRegistered: state.auth.isRegistered
})

const mapDispatchToProps = {
    changeIsRegisteredStoreFlag,
    performUserRegistrationProcess,
    getNewUserObject
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorRegistrationPage)
