import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {setMessage, toastErrorMessage} from '@actions/toast';
import {send_recover_password} from '@actions/admin'
import {getUserByToken, performUserDataUpdateProcess, update_user_data} from '@actions/auth'
import { useTranslation } from 'react-i18next';
import NavigationBar from '@sharedComponents/Navbar';
import { isNotEmptyObject } from '@utils/utils';

const PASSWORD_INPUT_ICON = require("@resources/icons/padlock.svg");


const RecoverPassword = ({match, token_user, setMessage, toastErrorMessage, getUserByToken, performUserDataUpdateProcess ,update_user_data}) => {
    let token = match.params.token;
    const { t } = useTranslation(['recoverPassword']);

    const [formData, setFormData] = useState({
        p_psw: '',
        p_psw_2: '',
        user: {},
        validSubmission: true,
        returnToLogin: false,
        sent: false
    })

    useEffect(() => { 
        const fetch = async () => {         
            const user = await getUserByToken(token);
            if(isNotEmptyObject(user)){
                setFormData({...formData, user});
            } else {
                setFormData({...formData, user: {}, returnToLogin: true});
            }
        }
       fetch();
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])
    
    const {p_psw, sent, p_psw_2, user} = formData;

       
    if(!token) return <Redirect to="/login"/>;
    if(formData.returnToLogin)  return <Redirect to="/login"/>; 
    if(sent) return  <Redirect to="/"/>;
    

    const onChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        user[targetName] = targetValue;
        setFormData({...formData, [targetName]: targetValue});
    }


    const onSubmit = async e => {
        e.preventDefault();
        try {
            const result = await performUserDataUpdateProcess(user);
            setFormData({...formData, validSubmission: result, sent: result});
        } catch (error) {
            toastErrorMessage(error.message);
            setFormData({...formData, validSubmission: false})
        }
    }


    return (

        <div className="container-front-page">
            <NavigationBar />
            <main className="main-content auth-container registration-container">
                <div className="form-container">
                    {/* :: FORM */}
                    <form className="auth-form dark-form">
                        {/* :: TITLE */}
                        <h2 className="form-header">{t('rec_pass')}</h2>
                        {/* :: PASSWORD INPUT */}
                        <div className="form-group iconed-input">
                            <img src={PASSWORD_INPUT_ICON} alt="lock_icon" />
                            <input type="password" className="form-control dark-input"  name={"p_psw"} value={p_psw} placeholder={t('new_pass')} onChange={e => onChange(e)} />
                        </div>
                        {/* :: PASSWORD CONFIRMATION INPUT */}
                        <div className="form-group iconed-input">
                            <img src={PASSWORD_INPUT_ICON} alt="lock_icon" />
                            <input type="password" className="form-control dark-input"  name={"p_psw_2"} value={p_psw_2} placeholder={t('conf_pass')} onChange={e => onChange(e)} />
                        </div>
                        {/* :: BUTTONS */}
                        <div className="form-buttons clearfix">
                            <Link type="button" to="/login" className="button link-button left-sided-button">{t('cancel')}</Link>
                            <button type="submit" className="button dark-button submit-button right-sided-button"  onClick={e => onSubmit(e)}>{t('send')}</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    )
}

RecoverPassword.propTypes = {
    setMessage: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
    send_recover_password: PropTypes.func.isRequired,
    token_user: PropTypes.object,
    getUserByToken: PropTypes.func.isRequired,
    performUserDataUpdateProcess: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    token_user: state.auth.token_user,
})

export default connect(mapStateToProps,{setMessage, toastErrorMessage, send_recover_password, getUserByToken, performUserDataUpdateProcess, update_user_data})(RecoverPassword);
