import store from "../../store"
import { update } from '@actions/admin';

class SubCompany  { 
    constructor() {
        this.p_scname = ''; 
    }

    set (key, val) { 
        this[key] = val; 
        store.dispatch(update())
    }

}

export default SubCompany;