import PropTypes from 'prop-types'
import { toastErrorMessage } from '@actions/toast';
import CustomSelectOption from '@components/Shared/FormElements/CustomSelectOption';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { getAppConfig } from '@utils/configurations';
import { fixNewUserFields, resolveUserCountryCode } from '@utils/validations';
import React, { useState } from 'react'
import { Fragment } from 'react';
import { connect } from 'react-redux';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Row from 'reactstrap/lib/Row';
import { useTranslation } from 'react-i18next';
import CustomInput from 'reactstrap/lib/CustomInput';

const SingleUserForm = ({user, toastErrorMessage}) => {
    const {t} = useTranslation('request');
    const { COUNTRY_LIST } = getAppConfig();
    const [state, setState] = useState({

    });

    const {p_fname,p_lname,p_nationality,p_id_type_id,p_expiration_date_id,p_nid,p_pnumber_1,p_pnumber_2, country_code_p1, country_code_p2} = user;

    // ::: EVENT HANDLERS
    const onSelectIdPicture = async (e) => { 
        const file = e.target.files[0];
        user['uid_data'] = file;
        setState({...state, flag: !state.flag});
    }

    const onChange = (e) => {
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        try {
            user[nodeName] = nodeValue;
            fixNewUserFields(user); //::>> Throws Error if a field is invalid
            if(nodeName === 'p_nationality')
                resolveUserCountryCode(user, nodeValue);
            setState({...state, flag: !state.flag});
        } catch (error) {
            user[nodeName] = '';
            toastErrorMessage(error.message);
        }
    };


    return (
        <Fragment>
                <Row form >
                    {/* ::: FIRST NAME INPUT */}
                    <Col md={6}>
                        <FormGroup>
                            <Input type="name" name="p_fname" placeholder={t("fir_nam")} className="required" value={p_fname || ''}  onChange={e => onChange(e)} />
                        </FormGroup>
                    </Col>
                    {/* ::: LAST NAME INPUT */}
                    <Col md={6}>
                        <FormGroup>
                            <Input type="name" name="p_lname" placeholder={t("surname")} className="required" value={p_lname || ''} onChange={e => onChange(e)} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    {/* ::: NATIONALITY SELECT INPUT */}
                    <Col md={4}>
                        <FormGroup>
                            <Input type="select" name="p_nationality" placeholder={t("nationality")} className="required" value={p_nationality || ''} onChange={e => onChange(e)}>
                                <CustomSelectOption options={COUNTRY_LIST}  hasId={false}/>
                            </Input>
                        </FormGroup>
                    </Col>
                    {/* ::: TYPE ID SELECT INPUT */}
                    <Col md={4}>
                        <FormGroup>
                            <Input type="select" name="p_id_type_id" placeholder={t("id_type")} className="required" value={p_id_type_id || ''} onChange={e => onChange(e)}>
                                <CustomSelectOption options={AppFunctionalityUsable.idDocumentTypes} hasId={true}/>
                            </Input>
                        </FormGroup>
                    </Col>
                    {/* ::: EXPIRATION DAY DATE PICKER INPUT */}
                    <Col md={4}>
                        <FormGroup>
                            <Input type="date" name="p_expiration_date_id" placeholder={t("exp_dat")} className="required" value={p_expiration_date_id || ''} onChange={e => onChange(e)} />
                            <small className="form-text text-muted">{t("exp_dat")}</small>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    {/* ::: TYPE ID INPUT */}
                    <Col md={8}>
                        <FormGroup>
                            <Input type="text" name="p_nid" placeholder={t("id")} className="required" value={p_nid || ''} onChange={e => onChange(e)}/>
                        </FormGroup>
                    </Col>
                    {/* ::: ID DOCUMENTO PHOTO FILE INPUT */}
                    <Col md={4}>
                        <FormGroup>
                            <CustomInput id="fileInputRegistrationUser" type="file" name="p_photo_id" placeholder={t("nationality")} onChange={e => onSelectIdPicture(e)} />
                            <small className="form-text text-muted">{t("pho_doc")}</small>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    {/* ::: FIRST NUMBER INPUT */}
                    <Col md={6}>
                        <FormGroup className="d-flex">
                            <Input type="tel" name="country_code_p1" value={country_code_p1} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                            <Input type="tel" name="p_pnumber_1" placeholder={t("cel_num")} className="required" value={p_pnumber_1 || ''} onChange={e => onChange(e)} />
                        </FormGroup>
                    </Col>
                    {/* ::: EMERGENCY NUMBER INPUT */}
                    <Col md={6}>
                        <FormGroup className="d-flex">
                            <Input type="tel" name="country_code_p2" value={country_code_p2} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                            <Input type="tel" name="p_pnumber_2" placeholder={t("eme_cel_num")} className="required" value={p_pnumber_2 || ''} onChange={e => onChange(e)} />
                        </FormGroup>
                    </Col>
                </Row>
            </Fragment>
    )
}


SingleUserForm.propTypes = {
    flag: PropTypes.bool,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    toastErrorMessage,
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleUserForm);
