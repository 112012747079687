import axios from 'axios';
import moment from 'moment';
import { setMessage, removeMessage } from '../actions/toast';
import { LOADING_TOAST, ACCEPT_BTN_MESSAGE, INDUCTION, LOADING_TOAST_CODE, USER_STR, EMPTY_USER, ACCEPTED, REJECTED, CANCELED, HOST, PENDING, HOST_ACT, THREAD_ACT, CORE_SEC_ACT, THREAD, CORE_SEC, ACCEPTED_BTN_MESSAGE, ERROR_TOAST, WARNING_TOAST, SUCCESS_TOAST, ERROR_MESSAGE_KEY, HOST_VISIT_STATE_CHANGE_CODE, THREAD_VISIT_STATE_CHANGE_CODE, CORE_SECURITY_VISIT_STATE_CHANGE_CODE, CREATE_REQUISITION_X_MODAL, CREATE_REQUISITION_SERVICE_PROVIDER_MODAL, CREATE_REQUISITION_HOST_MODAL, USER_LOCALSTORAGE_KEY, HAS_INDUCTION} from './constants';
import Material from '../model/Extras/Material';
import Vehicle from '../model/Extras/Vehicle';
import store from '../store';
import { getAppConfig } from './configurations';
import { DEFAULT_DATE_FORMAT_FOR_API_REQUESTS } from '@actions/types';
const sha1 = require('sha1');

/**
 * Creates default request configurations.
 */
export const getRequestConfigurations = () => {
    return {
        headers: {
        "Content-Type": "application/json"
        }
    };
}

export const createLoader = (dispatch) => { 
    dispatch(setMessage('', LOADING_TOAST, LOADING_TOAST_CODE));
    return {stopLoading: ()  => { dispatch(removeMessage(LOADING_TOAST_CODE))}}   
}

/**
 * Create a loader object to display the loading toast and to stop it using the stopLoading method.
 * @param {object} store 
 */
export const createStoreLoader = (store) => { 
  store.dispatch(setMessage('', LOADING_TOAST, LOADING_TOAST_CODE));
  return {stopLoading: () => {store.dispatch(removeMessage(LOADING_TOAST_CODE))}}
}

/**
 * Checks if the response came with an error
 * @param {object} response 
 */
export const checkResponseError = (response) => {
    if (response === false) {
      throw new Error("Process Failed. Internal Error!");
    } else { 
      if(response.clientError){
        throw new Error(response.clientError)
      }
    }
  }

export const getErrorMessage = err => { 

    if(err.response){ 
        if(err.response.status === 400){
          let messages = [];
          const toIterate = err.response.data.error || err.response.data.errors;
          toIterate.forEach( val => {
            messages = [...messages, `${val.msg} for ${val.param}`]
          });
          return messages;
        }
        return [err.response.data.root_err | err.response.data.error];
    }
    return [err.message];
}

/**
 * Gets a moment object for the current day.
 */
export const getToday = () => {
  return getMomentFromString(new Date(), ['YYYY-MM-DDTHH:mm:ss.sssZ']);
}

/**
 * Gets a moment object from a string representing a date.
 * @param {string} dateString 
 */
export const getMomentFromString = (dateString) => {
  const mom = moment(dateString, ['YYYY-MM-DDTHH:mm:ss.sssZ']);
  return mom;
}

export const getMomentFromStringWithFormat = (dateString, formatString) => {
  return moment(dateString, [formatString]);
}

export const exists = (val) => { 
    if(!val) return false;
    if(val.length === 0) return false;
    if(val === '') return false;
    if(Object.keys(val).length === 0) return false;
    return true;
  }

  export const saveToApp = (key, val, stringify) => { 
    const toSave = stringify? JSON.stringify(val): val;
    localStorage.setItem(key, toSave);
  }

  export const getFromApp = (key, parse) => { 
      const val = localStorage.getItem(key);
      return val? parse? JSON.parse(val):val : '';
  }

  export const removeFromApp = (key) => { 
    localStorage.removeItem(key);
  }

  export const getIconEquiv = (key) => { 
    if(key === ACCEPTED){
      return 'check_circle';
    } else if (key === REJECTED){
      return 'cancel';
    } else if (key === CANCELED){
      return 'remove_circle';
    } else { 
      return 'watch_later';
    }
  }

  export const getIconColorEquiv = (key) => { 
    if(key === ACCEPTED){
      return 'text-green';
    } else if (key === REJECTED){
      return 'text-red';
    } else if (key === CANCELED){
      return 'text-red';
    } else { 
      return 'text-yellow';
    }
  }
  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::>>> VALIDATION

  /**
   * Verifies if arguments passed are empty of do not have a value.
   * @param  {...any} args 
   */
  export const validateEmpty = (...args) => {
    for(let arg of args){
      if (!doesVariableExist(arg, true)) return false;
    }
    return true;
  }


  /**
   * Validates input field text input (names specially).
   * @param {string} name 
   */
  export const validateInputNames = (name) => {
    const { NAME_MIN_CHARACTERS } = getAppConfig();
    if(!doesVariableExist(name)) return false;
    if(name.length < (NAME_MIN_CHARACTERS)) return false;
    return true;
  }

  /**
   * Validates the period of the visits
   * @param {number} period 
   */
  export const validatePeriod = (period) => { 
    const { MAX_TIME_PERIOD } = getAppConfig();
    if(period <= 0) return false;
    if (!validateEmpty(period)) return false;
    if ((+period > MAX_TIME_PERIOD) || (+period < 1)) return false; 
    return true;
  }

  /**
   * Validates cellphone numbers. Checks if the numbers contain alphabetic characters.
   * @param {string} number1 
   * @param {string} number2 
   */
  export const validateCellphoneNumbers = (number1, number2) => {
    const regex = /[a-zA-Z]/g;
    if(regex.test(number1)) return false; 

    if(regex.test(number2)) return false; 

    if(!validateEmpty(number1, number2) || (number1 === number2)) return false; 

    return true;
  }

  /**
   * Verifies if passwords are equal
   * @param {string} psw1 
   * @param {string} psw2 
   */
  export const validatePasswords = (psw1, psw2) => {
    if (!validateEmpty(psw1, psw2) || (psw1 !== psw2)) return false;
    return true;
  }

  /**
   * Verifies if a combobox valid intem has beem selected
   * @param {*} value 
   */
  export const validateComboBox = (value) => {
    if (value === undefined) return false; 
    if (+value < 0) return false; 
    return true;
  }
  
/**
 * Verifies if a dataList (html) item has been selected
 * @param {*} value 
 */
  export const validateDataList = (value) => { 
    if (!doesVariableExist(value)) return false; 
    return true;
  }
  
  /**
   * Validates the dates entered in input date fields. 
   * @param {string} p_date_time 
   */
  export const validateInputDates = (p_date_time) => {
    if(!doesVariableExist(p_date_time)) return false;

    const visitDate = getMomentFromString(p_date_time)
    if(visitDate.isBefore(getToday(), 'day')) return false;

    return true;
  }

  export const removePropertyFromObject = (obj, property) => { 
    let copyObj = {...obj};
    delete copyObj[property];
    return copyObj;
}


export const getElementsInListAsString = (list) => {
  if(!list) return '';
  const lLength = list.length;
  let str = '';
  list.forEach((ele, index) => {
    if(index === (lLength - 1)){
      str += ele
    } else {
      str += ele + ','
    }
  })

  return str;
}

export const formReport1Body = (state) => {
  const { selectedTerminals , selectedDepartments, startDate, endDate } = state;
  return {
    report_type: 1,
    lst_cod_terminal: `(${getElementsInListAsString(selectedTerminals)})`,
    lst_cod_department: `(${getElementsInListAsString(selectedDepartments)})`,
    date_inicio: getInputDateFormatFromString(startDate.toISOString()),
    date_fim: getInputDateFormatFromString(endDate.toISOString()),
  }
}

export const formReport3Body = (state) => {
  const { selectedTerminals , selectedDepartments, startDate, endDate } = state;
  return {
    report_type: 3,
    lst_cod_terminal: `(${getElementsInListAsString(selectedTerminals)})`,
    lst_cod_department: `(${getElementsInListAsString(selectedDepartments)})`,
    date_inicio: getInputDateFormatFromString(startDate.toISOString()),
    date_fim: getInputDateFormatFromString(endDate.toISOString()),
  }
}

export const formReport2Body = (state) => {
  const { cod_entity , cod_id_group, startDate, endDate } = state;
  return {
    report_type: 2,
    cod_entity: `(${getElementsInListAsString(cod_entity)})`,
    cod_group: +cod_id_group,
    date_inicio: getInputDateFormatFromString(startDate.toISOString()),
    date_fim: getInputDateFormatFromString(endDate.toISOString()),
  }
}

export const checkReport1ValidFields = (state) => {
  const { selectedTerminals, selectedDepartments, report } = state;
  if(+report !== 1){
    return false;
  }
  if(selectedTerminals.length === 0){
    return false;
  }
  if (selectedDepartments.length === 0){
    return false;
  }

  return true;
}

export const checkReport3ValidFields = (state) => {
  const { selectedTerminals, selectedDepartments, report } = state;
  if(+report !== 3){
    return false;
  }
  if(selectedTerminals.length === 0){
    return false;
  }
  if (selectedDepartments.length === 0){
    return false;
  }

  return true;
}


export const checkReport2ValidFields = (state) => {
  const { report, cod_entity} = state;
  if(+report !== 2){
    return false;
  }
  if(cod_entity.length <= 0){
    return false;
  }
  return true;
}

export const answeredAllQuestion = (result, solution) => {
  let answeredAll = true;
  const resKeys = Object.keys(result);
  Object.keys(solution).forEach(key => {
     answeredAll = answeredAll && resKeys.includes(key);
     answeredAll = answeredAll && result[key]? true: false
  })

  return answeredAll;
}


export const parseInductionAnswers = (questions) => {
    
  let inductionAnswer = {};
  let answer, isMultiple;
  questions.forEach(question => {
      isMultiple = question.str_answer.split("|").length > 1;
      answer = isMultiple? question.str_answer.split("|"): question.str_answer;
      inductionAnswer = {...inductionAnswer, [question.int_id]: {sol: answer, score: question.points}}
  })

  return inductionAnswer;

}


export const parseNewInductionQuestions = (questions) => {
  let formatedQuestions = [];
  let answers, isMultiple;
  if(questions){
      questions.forEach(question => {
          isMultiple = question.str_answer.split("|").length > 1? true: false;
          answers = question.str_options.split("|");
          formatedQuestions = [...formatedQuestions,{question: question.str_question, isMultiple, options: answers, id: question.int_id, score: question.points}]
      })    
  }

  return formatedQuestions;

  
}

  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


  //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::>>> EXTRACTIONS

  
  export const extractBlockUserFields = (obj) => { 
    const fields = ['p_id', 'p_id_who', 'p_why', 'p_when', 'p_isblocked'];
    let toRet = extractFieldsFromObj(fields, obj);
    toRet.p_id_user = toRet.p_id;
    toRet = removePropertyFromObject(toRet, 'p_id');
    return toRet;
    
  }

  export const extractVExtraFields = (obj) => { 
    const fields = ["p_oname","p_qnt","p_ismtrl","extraArray"];
    let res = extractFieldsFromObj(fields, obj);
    return res = {...res, p_svisit: obj.p_id, p_id: obj.p_id_e}
  }
  
  export const extractExtraFields = (obj) => { 
    const fields = ["p_id","p_id_etype","p_name","p_active",]
    let res = extractFieldsFromObj(fields, obj);
    res.p_active = res.p_active? 1:0;
    return res;
  }
  
  export const extractUserFields = (obj) => { 
    let fields = ["p_id","p_fname","p_lname","p_nid", "p_psw", "p_email", "p_pnumber_1", "p_pnumber_2", "p_active", "p_isvisitor", "p_nationality", "p_id_type_id", "p_expiration_date_id"];
    
    let res = extractFieldsFromObj(fields, obj);
    return res;
  }
  
  export const extractCompanyFields = obj => { 
    let fields = [ "p_id","p_cname", "p_id_core","p_cemail","p_cpnumber_1","p_cpnumber_2","p_cpsw","p_cport","p_cactive","p_id_user","p_dtm", "p_is_ps"]
    let res = extractFieldsFromObj(fields, obj);
    res.p_is_ps = res.p_is_ps? 1:0;
    return res;
  }

export const isLoggedOn = () => { 
    const user = getFromApp(USER_STR, true);
    return exists(user);
}

  
export const getUser = () => {
  let user = getFromApp(USER_LOCALSTORAGE_KEY, true);
  return user? user: EMPTY_USER;
}

export const getUserFromStore = () => { 
  const st = store;
  const user = st.getState().auth.user;
  if(exists(user)){
    return user;
  };
  return getUser();
}


export const composeToasts = (toast) => { 
  let errors = [];
  let lastErrorId = '';
  let warnings = [];
  let lastWaringId = '';
  let success;
  let loading;
  let toReturn = [];

  toast.forEach(toast => { 
    if(toast.msgType === ERROR_TOAST) { 
      if(errors.indexOf(toast.message[0]) === -1){
        errors = [...errors, ...toast.message]
        lastErrorId = toast.id;
      }
    } else if (toast.msgType === WARNING_TOAST){
      if(warnings.indexOf(toast.message[0]) === -1){
        warnings = [...warnings, ...toast.message];
        lastWaringId = toast.id;
      }
    } else if (toast.msgType === SUCCESS_TOAST){
      success = toast;
    } else { 
      loading = toast;
    }
  });

  if(errors.length > 0){
    toReturn = [...toReturn, {message: errors, id: lastErrorId, msgType: ERROR_TOAST}]
  } 
  
  if(warnings.length > 0){
    toReturn = [...toReturn, {message: warnings, id: lastWaringId, msgType: WARNING_TOAST}]
  } 
  
  if(success){
    toReturn = [...toReturn, success]
  } 

  if(loading){ 
    toReturn = [...toReturn, loading]
  }

  return toReturn
}
 
  
  export const extractIndQuestFields = obj => { 
    let fields = ['p_question',
    'p_answer',
    'p_created',
    'p_options',
    'p_is_valid',
    'p_cod_extra',
    'p_points',]
    let res = extractFieldsFromObj(fields, obj);
    return res;
  }

  
export const getInputDateFormatFromString = (dateString) => {
  const mDate = getMomentFromString(dateString);
  return `${mDate.year()}-${mDate.month() + 1 >= 10 ? mDate.month() + 1 : "0" + (mDate.month() + 1) }-${ mDate.date() >= 10 ? mDate.date() : "0" + mDate.date() }`;
}

/**
 * Creates Material and Vehicle Objects to an array.
 * @param {object[]} extras 
 * @param {int} visitMaterials 
 */
export const createMaterialNdVehicleObjects = (visitMaterials, userId) => { 
  let materials = [];
  let object;
  let material;
  materials = visitMaterials.map( ex => { 
    object = { p_oname: ex.str_oname, p_qnt: ex.int_qnt, p_ismtrl: ex.bool_ismtrl? 1:0 , p_id: ex.int_id, p_is_active: ex.bool_is_active? 1: 0,  p_cod_user: userId};

    if(object.p_ismtrl){
      material = new Material();
      material.setVisitExtraProperties(object);
    } else { 
      material = new Vehicle();
      material.setVisitExtraProperties(object);
    }

    return material;
  })
  return materials;
}


export const extractEditIndQuestFields = obj => { 

  let fields =  ['p_cod',
  'p_question',
  'p_answer',
  'p_created',
  'p_options',
  'p_is_valid',
  'p_cod_extra',
  'p_points',]
  let res = extractFieldsFromObj(fields, obj);
  return res;
}




export const extractNewCompField = obj => { 
  const fields = ['p_cname',
	'p_cemail',
	'p_cpnumber_1',
	'p_cpnumber_2',
	'p_cpsw',
	'p_cport',
  'p_cactive',
  'p_is_ps',
	'p_id_cuser',
	'p_dmt',
  'p_cod_core',]
  let res = extractFieldsFromObj(fields, obj);
  return res;
}


  //::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: // COLABORATORS: Feliciano Junior Armando Mazoio && Chernomirdin Macuvele
  //:::: @nozotrox

  /**
   * Calculates the number of elements per page.
   * @param {number} numberOfElements
   * @param {number} numberOfPages
   */
  export const calculateNumberOfPages = (numberOfElements, numberOfPages) => { 
    return Math.ceil(numberOfElements / numberOfPages);
  }
  

  /**
   * Checks if the visit was accepted by the current user.
   * @param {string} currentUserType 
   * @param {object} visitStats 
   */
  export const acceptedByMe = (currentUserType, visitStats) => {
    if(!visitStats) return -1
    return visitStats[currentUserType] === ACCEPTED;
  }

/**
 * Checks if the visit was made by a Host user.
 * @param {object} visit 
 */
export const isHostRequest = (visit) => { 
  return visit.st_host_dtm === visit.p_submited;
}

/**
 * Checks if the visit made by a Thread user..
 * @param {object} visit 
 */
export const isThreadRequest = (visit) => {
  return visit.st_threa_dtm === visit.p_submited;
}

/**
 * Checks if the visit is made by a Core Security User.
 * @param {object} visit 
 */
export const isCoreSecurityRequest = (visit) => {
  return visit.st_core_dtm === visit.p_submited;
}

export const calculateLastTime = (dateToFeed) => {
  if(dateToFeed){
    const dated = getMomentFromString(dateToFeed);
    return dated.fromNow();
  }
  return "No date";
}

/**
 * Searches an object inside an array by its key and value pairs. (Only one pair)
 * @param {any[]} array 
 * @param {string} key 
 * @param {value} value 
 */
export const searchObjectInArrayByKeyVal = (array, key, value) => { 
  let found = {[key]: 'Not found!'};
  if(!doesArrayExist(array)) return found;
  
  array.forEach(object => { 
    if(object[key] === value) found = object;
  });
  return found;
}

export const getStateHostsNumber = () => {
  if(isHostUser()){
    return 0;
  } else if (isThreadUser()){ 
    return 1;
  } 
  return 2;
}

export const isMyStateNumber = (number) => { 
  return getStateHostsNumber() === number;
}

/**
 * Checks if the visit was made by the logged user.
 * @param {object} request 
 */
export const isVisitMadeByMe = (request) => {
  if(request.p_internal && (request.p_id_host === getUser().p_id)) { 
    if(isHostUser()){
      return isHostRequest(request);
    } else if (isThreadUser()){
      return isThreadRequest(request)
    } else { 
      return isCoreSecurityRequest(request)
    }
  }
  return false;
}

/**
 * Checks if the visit has been canceled by someone.
 * @param {object} visit 
 */
export const hasVisitBeenCanceled = visit => {
  for(let state of Object.values(visit.states)){
    if (state === CANCELED) return true;
  }
  return false;
}

/**
 * Checks if the visit has been rejected by someone.
 * @param {object} visit 
 */
export const hasVisitBeenRejected= visit => {
  for(let state of Object.values(visit.states)){
    if(state === REJECTED){
      return true;
    }
  }
  return false;
}


/**
 * Checks if the loggedUser has canceled the visit.
 * @param {object} visit 
 */
const hasUserCanceledVisit = (visit) => {
  if(isHostUser()){
    return visit.states[HOST] === CANCELED;
  } else if (isThreadUser()){
    return visit.states[THREAD] === CANCELED;
  } else { 
    return visit.states[CORE_SEC] === CANCELED;
  }
}

/**
 * Checks if the logged user has rejected the visit.
 * @param {object} visit 
 */
const hasUserRejectedVisit = (visit) => {
  if(isHostUser()){
    return visit.states[HOST] === REJECTED;
  } else if (isThreadUser()){
    return visit.states[THREAD] === REJECTED;
  } else { 
    return visit.states[CORE_SEC] === REJECTED;
  }
}

/**
 * Checks if the logged user has either accepted, rejected or canceled.
 * @param {object} visit 
 */
export const hasUserChangedVisitState = (visit) => {
  if(isHostUser()){
    return visit.states[HOST_ACT];
  } else if (isThreadUser()){
    return visit.states[THREAD_ACT];
  } else { 
    return visit.states[CORE_SEC_ACT];
  }
}

/**
 * Gets a state string representing the action that the user did to the visit state.
 * @param {bool} hasUserChangedState 
 * @param {object} visitState 
 */
export const getCurrentVisitorStateString = (hasUserChangedState, visitState) => {
  if((hasUserChangedState === true)) { 
    if(visitState === CANCELED){
      return 'canceled';
    } else if (visitState === REJECTED) { 
      return 'rejected';
    } else if (visitState === ACCEPTED) { 
      return 'cancel';
    }
  }
  return 'reject';
}

/**
 * Gets string for an accept button
 * @param {object} visit 
 */
export const getAcceptBtnString = (visit) => {

    
  const hasAccepted = hasUserChangedVisitState(visit);
  const hasUsrCanceled = hasUserCanceledVisit(visit);
  const hasUsrRejected = hasUserRejectedVisit(visit);
  const hasBeenCanceled = hasVisitBeenCanceled(visit);
  const hasBeenRejected = hasVisitBeenRejected(visit);
  const isMyVisit = isVisitMadeByMe(visit);

  if (hasBeenCanceled || hasBeenRejected) {

    if ((hasUsrRejected || hasUsrCanceled) || (!isMyVisit && !hasAccepted)) return ACCEPT_BTN_MESSAGE; 
    return ACCEPTED_BTN_MESSAGE
    
  } else if (!hasBeenRejected && !hasBeenCanceled){
    if (!hasUsrRejected && hasAccepted) return ACCEPTED_BTN_MESSAGE;
    return ACCEPT_BTN_MESSAGE;
  }

  return ACCEPT_BTN_MESSAGE;
}

/**
 * Checks if a user of the visit is in an induction process.
 * @param {object} visit 
 */
export const isInInductionProcess = (visit) => {
  return visit.states[INDUCTION];
}

/**
 * Checks if the visit date has expired.
 * @param {object} visit 
 */
export const hasVisitExpired = (visit) => {
  if(!doesObjectExist(visit)) return true;
  const today = getToday();
  const vDate = getMomentFromString(visit.p_date_time);
  // if (today.isAfter(vDate.add(6, 'hours')) && (visit.sv_is_done || visit.sv_is_done === null) && visit.state === ACCEPTED) return true;
  if (today.isAfter(vDate.add(6, 'hours')) && (visit.sv_is_done || visit.sv_is_done === null)) return true;
  return false;
}

export const isOnGoing = (visit) => { 
  return visit.sv_is_done === 0;
}

export const saveVisitState = visit => { 
  const visitField = extractVisitFields(visit);
  localStorage.setItem(visit.cod, sha1(JSON.stringify(visitField)));
}

/**
 * Checks if the visit data has change.(Doesn't check the materials)
 * @param {object} visit 
 */
export const hasVisitDataChanged = (visit) => { 
  const visitField = extractVisitFields(visit);
  const code = localStorage.getItem(visit.p_id);
  if(!doesVariableExist(code)) {
    saveVisitState(visit);
    return false;
  }
  return code !== sha1(JSON.stringify(visitField));
}

/**
 * Checks if the data of the visitors of the visit have changed.
 * @param {object} visit 
 */
export const hasVisitVisitorsChanged = (visit) => { 
  if(!doesArrayExist(visit.grouped)) return false;
  const code = localStorage.getItem(`${visit.p_id}_grouped`);
  if(!doesVariableExist(code)) { 
    saveVisitVisitorState(visit);
    return false;
  }
  try {
    return code !== sha1(JSON.stringify(visit.grouped));
  } catch (error) {
    return false; 
  }
 
}

/**
 * Saves the visitors data of a visit to the local storage. 
 * @param {object} visit 
 */
export const saveVisitVisitorState = visit => {
  try {
    if(doesArrayExist(visit.grouped)) localStorage.setItem(`${visit.p_id}_grouped`, sha1(JSON.stringify(visit.grouped)));
  } catch (error) {
    return false;
  }
  return true;
}

/**
 * Checks if its a fast request made by the hosts.
 * @param {object} visit 
 */
export const isInternalFastRequest = visit => { 
  return visit.p_id_host === visit.p_id_x;
}

/**
 * Checks if the host of the visit has canceled the visit.
 * @param {object} visit 
 */
export const hasHostOfVisitCanceled = visit => { 
  return visit.states[HOST] === CANCELED;
}

/**
 * Checks if the thread of the visit has canceled the visit.
 * @param {object} visit 
 */
export const hasThreadOfVisitCanceled = visit => { 
  return visit.states[THREAD] === CANCELED;
}

/**
 * Checks if the Core Security of the visit has canceled the visit.
 * @param {object} visit 
 */
export const hasCoreSecOfVisitCanceled = visit => { 
  return visit.states[CORE_SEC] === CANCELED;
}

/**
 * Checks if the host of the visit has accepted the visit.
 * @param {object} visit 
 */
export const hasHostOfVisitAccepted= visit => { 
  return visit.states[HOST] === ACCEPTED;
}

/**
 * Checks if the thread of the visit has accepted the visit.
 * @param {object} visit 
 */
export const hasThreadOfVisitAccepted= visit => { 
  return visit.states[THREAD] === ACCEPTED;
}

/**
 * Checks if the Core Security of the visit has accepted the visit.
 * @param {object} visit 
 */
export const hasCoreSecurityOfVisitAccepted= visit => { 
  return visit.states[CORE_SEC] === ACCEPTED;
}


/**
 * Returs a copy of the object. (With new reference)
 * @param {object} obj 
 */
export const getObjectCopy = obj => { 
  let newObj;
  try {
    newObj = JSON.stringify(obj);
    newObj = JSON.parse(newObj);
    return newObj;
  } catch (error) {
    const excluded = {};
    Object.keys(obj).forEach(key => { 
      if((typeof obj[key] === "object") && (obj[key] !== null)) { 
        excluded[key] = obj[key];
        obj[key] = "";
      }
    })
    newObj = JSON.stringify(obj);
    newObj = JSON.parse(newObj);
    Object.keys(excluded).forEach(key => { 
      newObj[key] = excluded[key];
      obj[key] = excluded[key];
    });
    return newObj;
  }
   
}

export const getVisitStateIconColor = visit => { 
  let toReturn = 'watch_later';
  let color = 'text-white';

  switch(visit.state) { 
    case PENDING: toReturn = 'watch_later'; color="text-yellow"; break;
    case ACCEPTED: toReturn = 'check_circle'; color="text-green"; break;
    case REJECTED: toReturn = 'cancel'; color="text-red" ;break;
    case CANCELED: toReturn = 'remove_circle'; color="text-red"; break;
    default: toReturn = 'watch_later'; color = "text-white"
  }

  return [toReturn, color]

}


export const getUsersOfVisit = (visit) => { 
  let users = [];
  users = [visit.p_id_x, ...users];
  if(exists(visit.grouped)) {
    visit.grouped.forEach(user => { 
      users = [...users, user.x_cod];
    })
  }
  return users;
}

/**
 * Returns an array of visitors that were rejected the entrance or exit in the visit.
 * @param {object} visit 
 * @param {array} visitors 
 */
export const getWhoWasRejected = (visit, visitors) =>  {
  let usersOfVisit = getUsersOfVisit(visit);
  let rejected = [];
  let user;
  usersOfVisit.forEach(id => { 
    user = searchObjectInArrayByKeyVal(visitors, "p_id", id);
    if((user.int_id_obs !== null) && user.int_id_svisit === visit.p_id) { 
      rejected = [user, ...rejected];
    }
  })
  return rejected;
}

export const getSumOfDate = (momentDate) => { 
  const day = +momentDate.get('day');
  const month = +momentDate.get('month');
  const year = +momentDate.get('year');
  return day + month +  year;
}

/**
 * Reads the induction state of the visitor and returns an array for the message state and a color coded string ('waring', 'danger', 'success').
 * @param {bool} induction 
 */
export const getInductionMessageNColor = induction => { 
  if(induction === null){ 
    return ['Inducting...', 'warning']
  }
  if(!induction) { 
    return ['Failed', 'danger']
  }

  return ['Aproved', 'success']
}


/**
 * Capitalizes Strings
 * @param {string} str 
 */
export const capitalize = (str) => {
  if(str) { 
    return str.replace(/\b\w/g, l => l.toUpperCase())
  }
}

/**
 * Checks if string contains only number
 * @param {string} str 
 */
export const isOnlyNum = (str) => {
  return /^[0-9]+$/.test(str);
}

export const containsSpecialCharacters = (str) => { 
  // eslint-disable-next-line no-useless-escape
  return /[!@#\$%\^\&*\)\(+=._-]+/g.test(str);
}

export const containsNumberCharacters = (str) => { 
  return /[0-9]+/g.test(str);
}

export const containsAlphaCharacters = (str) => { 
  return /[a-zA-Z]+/g.test(str);
}

export const isOnlyWords = (str) => { 
  return !isOnlyNum(str) && !containsNumberCharacters(str) && !containsSpecialCharacters(str);
}

export const makeItOnlyNumber = (value) => {
  if(value === '') return '';
  if (isOnlyNum(value)){
    return value;
  }
  return 1;
}

export const makeItOnlyString = (value) => {
  if(value === '') return '';
  if (!isOnlyNum(value)){
    return value;
  }
  return "";
}

  
/**
 * Checks if an object exists or if it has fields
 * @param {object} object 
 */
export const doesObjectExist = (object) => { 
  try {
    return !((object === null) || (object === undefined) || (object === {}) || (Object.keys(object).length === 0));
  } catch (error) {
    return false;
  }
}

/**
* Checks if an array exists or if it has content
* @param {any[]} array 
*/
export const doesArrayExist = (array) => { 
  try {
    return !((array === null) || (array === undefined) || (array === []) || (array.length === 0));
  } catch (error) {
    return false;
  }
}

/**
 * Check if a variable as an allowed value. Cannot be null or undefined.
 * @param {*} variable 
 * @param {bool} compareEmptyString 
 */
export const doesVariableExist = (variable, compareEmptyString = false) => { 
  return !(((variable === undefined) || (variable === null)) || (compareEmptyString && ((variable === '') || (variable.length === 0))));
}

export const clearUserCircularJSObjectData = (user) => { 
  return {...user,
    visits: undefined,
    allVisits: undefined, 
    alert_messages: undefined, 
    mutabilityCheck: undefined,
    comp_to_aprove: undefined,
    privileges: undefined,
    collaborators: undefined,
    collaborators_show: undefined,
    p_users: undefined,
    singleUser: undefined,
    stats: undefined,
    visitors: undefined,
    visitors_show: undefined,
    visitsStats: undefined
   }
}


export const doubleDigit = (number) => { 
  if (number / 10 < 1) return `0${number}`;
  return number;
}


//======================================== REFACTORING METHODS ==============================//

export const sendApiRequest = async (link, body, config) => { 
      const res = await axios.post(link,body,config);
      return res.data;
}

export const sendRequestDataWithDefaultConfig = async (link, body) => { 
  try {
    const config = getRequestConfigurations();
    return await sendApiRequest(link,body,config);
  } catch (error) {
    treatServerError(error);
  }
}

const treatServerError = error => { 
  if(!isEmptyObject(error.response)){
    if(!isEmptyValue(error.response.data.root_err))
      throw new Error(error.response.data.root_err);
    else if (!isEmptyValue(error.response.data.errors))
      throw new Error("Cannot Submit Request. Missing data!");
    else if (error.response.status === 404)
      throw new Error("Could not connect to server.");
  } else if(!isEmptyValue(error.message)){
      throw new Error(error.message);
  } else {
    throw new Error('An unknown error ocurred! Please, try again later!');
  }
}

export const validateLoginFields = (email_or_number, password) => {
  if(isEmptyValue(email_or_number))
    throw new Error("Email or Phone Number required for login");
  isPasswordValue(password);
}

export const isEmailValue = (email) => { 
  let isEmailFormat = true;
  isEmailFormat = isEmailFormat && !isEmptyValue(email);
  isEmailFormat = isEmailFormat && isEmailRegex(email);
  if(!isEmailFormat)
    throw new Error("Email format is not recognizable!");
}

export const isPhoneNumberValue = (phoneNumber) => {
  const phoneNumberToUse = removeAllSpaces(phoneNumber);
  const phoneNumberToUseAlt = phoneNumberToUse.includes("+")? phoneNumberToUse.substring(1):phoneNumberToUse; 
  let isPhoneNumberFormat = true;
  isPhoneNumberFormat = isPhoneNumberFormat && !isEmptyValue(phoneNumberToUse);
  isPhoneNumberFormat = isPhoneNumberFormat && (isPhoneNumberRegex(phoneNumberToUse) || isPhoneNumberRegex(phoneNumberToUseAlt));
  if(!isPhoneNumberFormat)
    throw new Error("Phone number format is not recognizable! Please use [Country Code][Phone Number] or +[Country Code][Phone Number]");
}

export const isPasswordValue = (password) => { 
  if(isEmptyValue(password))
    throw new Error("Password value is empty!");
}


export const isEmptyValue = (value) => { 
  return (value === '') || (value === undefined) || (value === null);
}

export const isNotEmptyValue = (value) => {
  return !isEmptyValue(value);
}

export const isPhoneNumberRegex = (str) => { 
  return /^\d+$/.test(str);
}

export const removeAllSpaces = (value) => {
  let chagedString;
  try {
    chagedString =  value.split(" ").join('');
  } catch (error) {
    chagedString = '';
  }
  return chagedString;
}

export const isEmailRegex = (str) => { 
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);
}

export const isEmptyObject = (object) => { 
  let isEmpty = isEmptyValue(object);
  isEmpty = isEmpty || (Object.keys(object).length === 0);
  return isEmpty;
}

export const isNotEmptyObject = (object) => { 
  return !isEmptyObject(object);
}

export const isEmptyArray = (array) => { 
  let isEmpty = isEmptyValue(array);
  isEmpty = isEmpty || (array.length === 0);
  return isEmpty;
}

export const isNotEmptyArray = (array) => { 
  return !isEmptyArray(array);
}


export const checkApiResponseForErrors = (response) => { 
  if(doesResponseContainError(response))
    throw new Error(response.ERROR_MESSAGE_KEY);
}

export const throwErrorIfApiResponseIsEmpty = (response, error_message) => { 
  if(isEmptyObject(response))
    throw new Error(error_message);
}

export const doesResponseContainError = (response) => {
  let hasError = false;
  if(getObjectKeys(response).includes(ERROR_MESSAGE_KEY))
    hasError = true;

  return hasError;
}

export const getObjectKeys = (object) => {
  try {
    return Object.keys(object);
  } catch (error) {
    return [];
  }
}

export const isUndefinedOrNull = (value) => { 
  return (value === undefined) || (value === null);
}

export const removeObjectFields = (object, fieldsToRemove) => { 
  const cleanObject = {};
  const objectToCheck = validateObjectParameter(object);
  const arrayToCheck = validateArrayParameter(fieldsToRemove);

  Object.keys(objectToCheck).forEach(key => { 
      if(!arrayToCheck.includes(key))
          cleanObject[key] = object[key];
  });

  return cleanObject;
}

export const validateObjectParameter = (object) => { 
    let objectToCheck = object;
    if(isEmptyObject(object)) 
      objectToCheck = {};
    return objectToCheck;
}

export const validateArrayParameter = (array) => { 
  let arrayToCheck = array;
  if(isEmptyArray(array))
    arrayToCheck = [];
  return arrayToCheck;
}

export const getFileUploadRequestConfig = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
    }, 
  }
}



/**
   * Checks if the logged user is a Service Provider type of user. If a user object is passed, checks if that user is a Service Provider type of user.
   * @param {object} usr 
   */
 export const isServiceProviderUser = (userObject) => { 
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_isvisitor && (userToCheck.p_id_entity !== null) && userToCheck.is_rep;
}

export const isServiceProviderUserWorker = (userObject) => { 
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_isvisitor && (userToCheck.p_id_entity !== null) && !userObject.is_rep;
}


/**
 * Checks if the logged user is a visitor type of user. If a user object is passed, checks if that user is a visitor type of user.
 * @param {object} usr 
 */
export const isVisitorUser = (userObject) => {
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_isvisitor && !userToCheck.is_rep;
}

/**
 * Checks if the logged user is a host type of user. If a user object is passed, checks if that user is a host type of user.
 * @param {object} usr 
 */
export const isHostUser = (userObject) => {
  const { COLABORATOR_CODE } = getAppConfig();
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_id_role === COLABORATOR_CODE;
}
/**
 * Checks if the logged user is a thread type of user. If a user object is passed, checks if that thread is a visitor type of user.
 * @param {object} usr 
 */
export const isThreadUser = (userObject) => {
  const { THREAD_CODE } = getAppConfig();
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_id_role === THREAD_CODE;
}

/**
 * Checks if the logged user is a thread type of user and if its from MPDC. If a user object is passed, checks if that thread is a visitor type of user.
 * @param {object} usr 
 */
export const isThreadUserFromMPDC = (userObject) => {
  const { THREAD_CODE, MPDC_CODE } = getAppConfig();
  const userToCheck = validateObjectParameter(userObject);
  return (userToCheck.p_id_role === THREAD_CODE) && userToCheck.p_id_company === MPDC_CODE;
}

/**
 * Checks if the logged user is a Core Security type of user. If a user object is passed, checks if that user is a Core Security type of user.
 * @param {object} usr 
 */
export const isCoreSecurityUser = (userObject) => {
  const { CORE_SECURITY_CODE } = getAppConfig();
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_id_role === CORE_SECURITY_CODE;
}

/**
 * Checks if the logged user is a CA Member type of user. If a user object is passed, checks if that user is a Core Security type of user.
 * @param {object} usr 
 */
 export const isCAMemberUser = (userObject) => {
  const { CA_MEMBER_CODE } = getAppConfig();
  const userToCheck = validateObjectParameter(userObject);
  return userToCheck.p_id_role === CA_MEMBER_CODE;
}

export const doesUserHavePrivilege = (userObject, privilege) => {
  if(isEmptyObject(userObject))
    return false;
    // throw new Error("Could not identify user. Please Contact Support!");
  else if(isEmptyArray(userObject.privileges)) 
    return false;
    // throw new Error("User does not have privileges. Please Contact Support!")
  return userObject.privileges.includes(privilege);
}

export const saveObjectToLocalStorage = (key, val) => { 
  const toSave = JSON.stringify(val);
  localStorage.setItem(key, toSave);
}

export const getObjectFromLocalStorage = (key) => { 
    const val = localStorage.getItem(key);
    return JSON.parse(val);
}

export const removeObjectFromLocalStorage = (key) => { 
  localStorage.removeItem(key);
}

export const getDefaultVisitsTimeFrame = () => { 
  const {VISIT_TO_SHOW_DAYS_RANGE} = getAppConfig();
  const currentDate = getToday();
  const startDate = currentDate.format(DEFAULT_DATE_FORMAT_FOR_API_REQUESTS);
  const endDate = currentDate.add(VISIT_TO_SHOW_DAYS_RANGE, 'days').format(DEFAULT_DATE_FORMAT_FOR_API_REQUESTS);
  return {p_dtm_start: startDate,p_dtm_end: endDate};
}

export const getVisitTimeFrameFromMoment = (startDate, endDate) => { 
  let timeFrame = {};

  if(isEmptyObject(startDate))
    timeFrame = getDefaultVisitsTimeFrame();
  if(isEmptyObject(endDate))
    timeFrame = getDefaultVisitsTimeFrame();
  
  const startDateStr = startDate.format(DEFAULT_DATE_FORMAT_FOR_API_REQUESTS);
  const endDateStr = endDate.format(DEFAULT_DATE_FORMAT_FOR_API_REQUESTS);
  timeFrame = {p_dtm_start: startDateStr,p_dtm_end: endDateStr};
  return timeFrame;
}

export const buildEmployeeNameToIdMapViceVersa = (employeeList) => {
  if(!doesArrayExist(employeeList)) return [];

  let employeeName;
  let employeeNameToIdMap = {};
  employeeList.forEach(employee => { 
      employeeName = `${employee.fname.toLowerCase()} ${employee.lname.toLowerCase()}`;
      employeeNameToIdMap = {
          ...employeeNameToIdMap,
          [employeeName]: employee.code,
          [employee.code]: employeeName
        };
  })
  return getObjectCopy(employeeNameToIdMap);
}

export const getEmployeeNameUsingId = (employeeId, employeesOfTerminal) => { 
  const empNameToIdMap = buildEmployeeNameToIdMapViceVersa(employeesOfTerminal);
  let hostName = empNameToIdMap[employeeId];
  if(isEmptyValue(hostName))
    throw new Error("Could not find the host of this request!");
  return capitalize(hostName);
}

export const extractFieldsFromObj = (fields, obj) => {
  let newObj = {};
  fields.forEach(field => {
    newObj = {...newObj, [field]: obj[field] }
  });
  return newObj;
}

/**
 * Extracts only the fields with respect to the visit data.
 * @param {object} visit 
 */
export const extractVisitFields = (visit) => { 
  const fields = ["p_id", "p_code",  "p_id_host",  "p_id_department", "p_id_rpp", "p_id_company",  "p_date_time",  "p_detail",  "p_submited",  "p_rule",  "p_period",  "p_multi_entry",  "p_access",  "p_tocompany",  "p_fast",  "p_id_gate"]
  return extractFieldsFromObj(fields, visit);
}

export const isVisitToUpdate = visit => { 
  const visitToCheck = validateObjectParameter(visit);
  return !isEmptyValue(visitToCheck.p_id);
}

export const canValidateHostVisitorOfVisit = visit => { 
  const visitToCheck = validateObjectParameter(visit);
  return !isEmptyArray(visitToCheck.p_users) && !visitToCheck.is_collective && !visitToCheck.p_fast;
}


export const getLoggedUserStateNumber = (loggedUser) => {
  let stateNumber = -1;
  if(isHostUser(loggedUser))
    stateNumber = HOST_VISIT_STATE_CHANGE_CODE;
  else if (isThreadUser(loggedUser))
    stateNumber = THREAD_VISIT_STATE_CHANGE_CODE;
  else if(isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser))
    stateNumber = CORE_SECURITY_VISIT_STATE_CHANGE_CODE;
  else 
    throw new Error("Could not find state change code for user. Please contact support!")
  
  return stateNumber;
}

export const getLoggedUserType = (loggedUser) => {
  let userType = -1;
  if(isHostUser(loggedUser))
    userType = HOST;
  else if (isThreadUser(loggedUser))
    userType = THREAD;
  else if(isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser))
    userType = CORE_SEC;
  else 
    throw new Error("Could not identify user type. Please contact support!")
  
  return userType;
}

export const pickVisitModalIDByUserType = (loggedUser) => { 
  let modalToOpen = '';
  if(isVisitorUser(loggedUser))
    modalToOpen = CREATE_REQUISITION_X_MODAL;
  else if (isServiceProviderUser(loggedUser))
    modalToOpen = CREATE_REQUISITION_SERVICE_PROVIDER_MODAL;
  else 
    modalToOpen = CREATE_REQUISITION_HOST_MODAL;
    
  return modalToOpen;
}

export const simulateVisitApproval = (visitorVisit, verdict) => { 
  visitorVisit.is_induction = verdict;
  visitorVisit.dtm_deep_induction = getToday().toISOString();
  visitorVisit.states[INDUCTION] = false;
  visitorVisit.states[HAS_INDUCTION] = 1;
}

export const isVisitorOfVisitInducting = (visit) => { 
  return visit.states[INDUCTION];
}

export const isVisitorOfVisitNotInducting = (visit) => { 
  return !isVisitorOfVisitInducting(visit);
}

export const removeDuplicatesOfArr = (array) => {
  const singleElement = [];
  let isDuplicated;
  const filtered = array.filter(obj => {
      isDuplicated = singleElement.includes(obj.code);
      if(!isDuplicated)
          singleElement.push(obj.code);
      return !isDuplicated;
  });
  return filtered;
}