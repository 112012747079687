import { getErrorMessage, isServiceProviderUser, isVisitorUser, isHostUser, isThreadUser, isCoreSecurityUser, getUser, validateLoginFields, sendApiRequest, checkApiResponseForErrors, throwErrorIfApiResponseIsEmpty, sendRequestDataWithDefaultConfig, validateObjectParameter, removeObjectFields, getToday, getFileUploadRequestConfig, isEmptyObject, saveObjectToLocalStorage, getObjectFromLocalStorage, removeObjectFromLocalStorage, isEmptyValue, getObjectCopy, isEmptyArray, createLoader, isCAMemberUser } from "../utils/utils";
import {buildAndStartLoader, setMessage, toastErrorMessage, toastSuccessMessage, toastWarningMessage} from './toast';
import { ROOT_URL, ERROR_TOAST, DEFAULT_NATIONALITY, DEFAULT_ID_TYPE, CREATE_REQUEST_PRIV, ADD_VISITOR_PRIV, CREATE_FAST_REQ_PRIV, CREATE_SERV_REQ_PRIV, MAIN_PANEL_PRIV, GRANT_ACCESS_PRIV, BLOCK_USER_PRIV, TERMINAL_PANEL_PRIV, SETTINGS_PRIV, REPORTS_PRIV, USER_LOCALSTORAGE_KEY, COMPANY_LOCALSTORAGE_KEY} from "../utils/constants";
import {} from '../utils/utils';
import { CLEAR_VISITS, GET_STATS, LOGIN_USER, LOGOUT_USER, SET_IS_LOGGED_IN, SET_IS_REGISTERED, SET_NEW_USER_ID, SET_TOKEN_USER, VERIFY_USER } from "./types";
import { resolveNewPasswordUpdate, validateLoggedInUserFields, validateNewCompanyFields, validateNewUserFields } from "@utils/validations";
import { getAppConfig } from "@utils/configurations";
import store from "src/store";

const randomString = require('randomstring');

const updateStoreWithApiResult = (reduxTypeToDispatch, apiResponse) => dispatch => { 
  checkApiResponseForErrors(apiResponse);
  dispatch(setStoreData(reduxTypeToDispatch, apiResponse));
}

export const performUserRegistrationProcess = (user) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  
  try {
    let userToSubmit = getObjectCopy(user);
    validateNewUserFields(userToSubmit);
    addUserCountryCodeToPhoneNumber(userToSubmit);
    userToSubmit = cleanNewUserFields(userToSubmit);
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/regUser`, userToSubmit);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());
    dispatch(changeIsRegisteredStoreFlag(true));
    dispatch(uploadDocumentPhotoIfExists(user));
    dispatch(setIdNumberToStoreForVerificationResend(user.p_nid));
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
  return false;
}

export const performBulkUsersRegistrationProcess = (users) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  let isSuccessful = true;
  let userToSubmit; 
  const usersToSend = [];

  try {

    try {
      for (let user of users) { 
        userToSubmit = getObjectCopy(user);
        validateNewUserFields(userToSubmit);
        addUserCountryCodeToPhoneNumber(userToSubmit);
        userToSubmit = cleanNewUserFields(userToSubmit);
        usersToSend.push(userToSubmit);
      }
    } catch (error) {
      dispatch(toastWarningMessage(`Data verification failed for ${userToSubmit.p_fname} ${userToSubmit.p_lname} : ${error.message}. User will not be registered.`));
    }
  
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/regBulkUsers`, {users: usersToSend});
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());
  } catch (error) {
    isSuccessful = false;
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
  return isSuccessful;
}

export const performHostRegistrationProccess = (user) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  
  try {
    let userToSubmit = getObjectCopy(user);
    validateNewUserFields(userToSubmit);
    addUserCountryCodeToPhoneNumber(userToSubmit);
    userToSubmit = cleanNewUserFields(userToSubmit);
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/grantAcc`, userToSubmit);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());
    dispatch(uploadDocumentPhotoIfExists(user));
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
  return false;
}

export const performBulkHostRegistrationProcess = (users) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  let isSuccessful = true;
  try {

      const usersToSend = [];
      let userToSubmit;
      for (let user of users) {
        try {
          userToSubmit = getObjectCopy(user);
          validateNewUserFields(userToSubmit);
          addUserCountryCodeToPhoneNumber(userToSubmit);  
          if(!user.error && !user.warning)
            usersToSend.push(user);
        } catch (error) {
          dispatch(toastWarningMessage(`Data verification failed for ${user.p_fname} ${user.p_lname} : ${error.message}. User will not be registered.`));
        }
       }


      if(isEmptyArray(usersToSend)) { 
        dispatch(toastWarningMessage('There are no users be added!'));
        loader.stop();
        isSuccessful = false;
      } else  { 
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/grantAccCol`, usersToSend);
        checkApiResponseForErrors(response);
        dispatch(toastSuccessMessage());
        loader.stop();
      }
      
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
  return isSuccessful;
}

const addDummyPasswordToObject = (object, pssFieldName) => {
  const password = randomString.generate({length: 8}); 
  object[pssFieldName] = password;
  return password;
}

export const addDummyPasswordToUser = (userToSubmit) => { 
  const genPassword = addDummyPasswordToObject(userToSubmit, 'p_psw');
  userToSubmit.p_psw_2 = genPassword;
}

export const performUserDataUpdateProcess = (user, options={verifyPassword: true}) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    if(options.verifyPassword)
      resolveNewPasswordUpdate(user);
    validateLoggedInUserFields(user);
    await dispatch(sendUpdatedUserData(user));
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
    loader.stop();
    return false;
  }
}

export const performNoValidationUserDataUpdateProcess = (user) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    await dispatch(sendUpdatedUserData(user));
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
    loader.stop();
    return false;
  }
}

const sendUpdatedUserData = (user) => async dispatch => { 
  const oldIdNumber = user.p_old_id;
  const userToSubmit = cleanNewUserFields(user);
  const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/editUser`, userToSubmit);
  checkApiResponseForErrors(response);
  dispatch(uploadDocumentPhotoIfExists(user));
  dispatch(updateIdDocumentPictureName(oldIdNumber, user.p_nid));
  dispatch(toastSuccessMessage());
}

export const updateIdDocumentPictureName = (oldIdNumber, newIdNumber) => async dispatch => {
  try {
    const requestBody = {p_nid: oldIdNumber, p_new_nid: newIdNumber}
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/files/renUid`, requestBody);
    checkApiResponseForErrors(response)
  } catch (error) {
    dispatch(toastWarningMessage("ID Document not updated properly. Contact Support!"));
  }

}

export const performCompanyRegistrationProcess = (userOfCompanyObject, companyObject) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  let userToSubmit = userOfCompanyObject;
  let companyToSubmit = companyObject;
  try {
    saveCompanyRegistrationDataForFallBack(userOfCompanyObject, companyObject);
    validateNewUserFields(userToSubmit);
    validateNewCompanyFields(companyToSubmit);
    addUserCountryCodeToPhoneNumber(userToSubmit);
    addCompanyCountryCodeToPhoneNumber(companyToSubmit);
    userToSubmit = cleanNewUserFields(userToSubmit);
    companyToSubmit = cleanNewCompanyFields(companyToSubmit);
    const requestBody = {...userToSubmit, ...companyToSubmit, p_cod_core: 9};
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/addEntity`, requestBody);
    checkApiResponseForErrors(response);
    dispatch(uploadDocumentPhotoIfExists(userToSubmit));
    dispatch(toastSuccessMessage('Credentials have been sent to your email or to your contact (text message). You can now log in.'));
    dispatch(changeIsRegisteredStoreFlag(true));
  } catch (error) {
    dispatch(toastErrorMessage(error.message))
    recoverCompanyRegistrationDataFromFallBack(userOfCompanyObject, companyObject);
  }
  loader.stop();
}

const saveCompanyRegistrationDataForFallBack = (userObject, companyObject) => { 
  saveUserDataForFallback(userObject);
  saveObjectToLocalStorage(COMPANY_LOCALSTORAGE_KEY,companyObject);
}

const recoverCompanyRegistrationDataFromFallBack = (userObject, companyObject) => { 
  userObject = recoverUserDataFromFallback();
  companyObject = getObjectFromLocalStorage(COMPANY_LOCALSTORAGE_KEY);
}

const saveUserDataForFallback = (userObject) => { 
  saveObjectToLocalStorage(USER_LOCALSTORAGE_KEY, userObject);
}

const recoverUserDataFromFallback = () => { 
  return getObjectFromLocalStorage(USER_LOCALSTORAGE_KEY);
}

export const uploadDocumentPhotoIfExists = (user) => dispatch => { 
  try {
    const idDocumentPicture = user.uid_data;
    if(!isEmptyValue(idDocumentPicture)){
      dispatch(uploadIdDocumentPhoto(idDocumentPicture, user.p_nid));
    }
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
}

const uploadIdDocumentPhoto = (file, name) => dispatch => {
  try {
    const config = getFileUploadRequestConfig();
    const link = `${ROOT_URL}/files/uploadId`;
    const requestBody = new FormData();
    const blob = file.slice(0, file.size, file.type);
    const documentPhotoFileToUpload = new File([blob], name , {type: file.type});
    requestBody.append("file", documentPhotoFileToUpload);
    requestBody.append("name", file.name);
    sendApiRequest(link,requestBody,config);
  } catch (error) {
    dispatch(toastWarningMessage("Document ID Photo could not be uploaded. Please update you document Id Photo after loging in."))
  }
}

const setIdNumberToStoreForVerificationResend = (idNumber) => dispatch => { 
  dispatch({
    type: SET_NEW_USER_ID,
    payload: idNumber
  });
}


export const addUserCountryCodeToPhoneNumber = (userObject) => { 
  userObject.p_pnumber_1 = `${userObject.country_code_p1 || ''}${userObject.p_pnumber_1}`;
  userObject.p_pnumber_2 = `${userObject.country_code_p2 || ''}${userObject.p_pnumber_2}`;
}

const addCompanyCountryCodeToPhoneNumber = (companyObject) => { 
  companyObject.p_cpnumber_1 = `${companyObject.country_code_p1}${companyObject.p_cpnumber_1}`;
  companyObject.p_cpnumber_2 = `${companyObject.country_code_p2}${companyObject.p_cpnumber_2}`;
}

const cleanNewUserFields = (userObject) => { 
  let newUserObj = validateObjectParameter(userObject);
  newUserObj = removeObjectFields(newUserObj, ['new_provenance', 'country_code_p1', 'country_code_p2']);
  return newUserObj;
}

const cleanNewCompanyFields = (userObject) => { 
  let newUserObj = validateObjectParameter(userObject);
  newUserObj = removeObjectFields(newUserObj, ['country_code_p1', 'country_code_p2']);
  return newUserObj;
}

export const getNewHostObject = (loggedUser, preExistingFields={}) => dispatch => {
  const { HOST_CODE } = getAppConfig(); 
  let userObject = {...preExistingFields}
  addUserDefaultFields(userObject);
  //:: This is only so it can validate, the definitive password is generated in the BE.
  if(isEmptyValue(userObject.p_psw) || isEmptyValue(userObject.p_psw_2)) 
    addDummyPasswordToUser(userObject);
  
  userObject.p_isvisitor = 0;
  userObject.p_active = 1;
  userObject.p_id_role = HOST_CODE;
  userObject.p_id_department = loggedUser.p_id_department;
  userObject.p_id_company = loggedUser.p_id_company;
  userObject.country_code_p1 = '';
  userObject.country_code_p2 = '';
  userObject.terms_condition = 1;
  userObject.p_is_toEmail = 1;
  return userObject;
}

export const getServProviderWorkerWithDefaultFields = (loggedUser, preExistingFields={}) => dispatch => { 
  const userObject = dispatch(getNewUserObject(preExistingFields));
  addDummyPasswordToUser(userObject);
  userObject.p_active = 1;;
  userObject.p_id_entity = loggedUser.p_id_entity;
  userObject.terms_condition = 1;
  return userObject;
}

export const getNewFastRequestUserObject = (preExistingFields={}) => dispatch => { 
  const userObject = dispatch(getNewUserObject(preExistingFields));
  addDummyPasswordToUser(userObject);
  userObject.p_istemp = 1;
  userObject.p_is_toEmail = 1;
  return userObject;
}

export const getNewCompanyObject = (preExistingFields={}) => dispatch => { 
  const companyObject = {...preExistingFields};
  companyObject.p_cport = 0;
  companyObject.p_cactive = 0;
  companyObject.p_is_ps = 0;
  addDummyPasswordToObject(companyObject, 'p_cpsw');
  return companyObject;
}

export const getNewCompanyUserObject = (preExistingFields={}) => dispatch => { 
  const userObject = {...preExistingFields};
  addUserDefaultFields(userObject);
  userObject.p_active = 1;
  userObject.terms_condition = 0;
  return userObject;
}

export const getNewUserObject = (preExistingFields={}) => dispatch => { 
  const userObject = {...preExistingFields};
  addUserDefaultFields(userObject);
  userObject.new_provenance = 0;
  userObject.terms_condition = 0;
  return userObject;
}

export const addUserDefaultFields = userObject => {
  userObject.p_registry = getToday().toISOString();
  userObject.p_nationality = DEFAULT_NATIONALITY;
  userObject.p_id_type_id = DEFAULT_ID_TYPE;
  userObject.p_isvisitor = 1;
  userObject.p_istemp = 0;
  userObject.p_active = 1;
}

export const changeIsRegisteredStoreFlag = flag => dispatch => { 
  dispatch({
    type: SET_IS_REGISTERED,
    payload: flag
  })
}



export const performUserLoginProcess = (email_or_number, password) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    validateLoginFields(email_or_number, password);
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/login`, {p_email_pnumber: email_or_number, p_psw: password, p_isvisitor: 1, prod: 1});
    throwErrorIfApiResponseIsEmpty(response, "Username or Password not valid!");
    dispatch(updateStoreWithApiResult(LOGIN_USER, response));
    assignPrivilegesAccordingToUserType(response);
    saveObjectToLocalStorage(USER_LOCALSTORAGE_KEY, response);
    dispatch(setStoreData(SET_IS_LOGGED_IN, true));
    dispatch(toastSuccessMessage());
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

export const setStoreData = (type, payload) => dispatch => { 
  dispatch({
    type,
    payload
  });
}

export const sendVerificationEmail = p_nid => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  let isSuccessful = true;
  try {
    const body = {p_nid};
    let res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/resVer`, body);
    checkApiResponseForErrors(res);
    dispatch(toastSuccessMessage("Verification Link Sent!"));   
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
    isSuccessful = false;
  }
  loader.stop();
  return isSuccessful;
}

export const assignPrivilegesAccordingToUserType = (userObject) => { 
  if(isServiceProviderUser(userObject))
    setUserPrivileges(userObject, [CREATE_REQUEST_PRIV, ADD_VISITOR_PRIV]);
  else if (isVisitorUser(userObject))
    setUserPrivileges(userObject, [CREATE_REQUEST_PRIV]);
  else if (isHostUser(userObject))
    setUserPrivileges(userObject, [CREATE_REQUEST_PRIV, CREATE_FAST_REQ_PRIV, CREATE_SERV_REQ_PRIV]);
  else if (isThreadUser(userObject))
    setUserPrivileges(userObject, [CREATE_REQUEST_PRIV, CREATE_FAST_REQ_PRIV, MAIN_PANEL_PRIV, GRANT_ACCESS_PRIV, CREATE_SERV_REQ_PRIV]);
  else if (isCoreSecurityUser(userObject) || isCAMemberUser(userObject))
    setUserPrivileges(userObject, [CREATE_REQUEST_PRIV, CREATE_FAST_REQ_PRIV, MAIN_PANEL_PRIV, GRANT_ACCESS_PRIV, BLOCK_USER_PRIV, TERMINAL_PANEL_PRIV, SETTINGS_PRIV, REPORTS_PRIV, CREATE_SERV_REQ_PRIV]);
  else 
    throw new Error("Could not identify type of user. Please Contact Support!");
}

const setUserPrivileges = (userObject, privilegesArray) => { 
  userObject['privileges'] = privilegesArray;
}

export const recoverUserDataToReduxStore = () => dispatch => { 

  let savedUser = getObjectFromLocalStorage(USER_LOCALSTORAGE_KEY);
  if(isEmptyObject(savedUser)) 
      savedUser = {};
    else 
      dispatch(setStoreData(LOGIN_USER, savedUser));
    return savedUser;
        
}

//=============================== REFACTORING METHODS CLOSE =========================//
export const getUserById =  p_id => async dispatch => { 
  let user = {};
  const loader = createLoader(dispatch);
  try {
    const body = JSON.stringify({p_id});
    user = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/getUser`, body);
    checkApiResponseForErrors(user);    
  } catch (err) {
    store.dispatch(toastErrorMessage(err.message));
    user = {};
  }
  loader.stopLoading();
  return user;
}


export const logout = () => dispatch => { 
  dispatch({
    type: LOGOUT_USER,
    payload: null
  });
  dispatch({
    type: CLEAR_VISITS,
    payload: [],
  })
  removeObjectFromLocalStorage(USER_LOCALSTORAGE_KEY);
}


export const getUserByTokken =  (token, isVisitor) =>  async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const body = {p_isvisitor: isVisitor, p_tokken: token, prod: 1 };
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/getUserByTkn`, body);
    checkApiResponseForErrors(response);
    loader.stop();
    return response;    
  } catch (error) {
    toastErrorMessage(error.message);
  }
  loader.stop();
}



  export const linkRegistrationToCoreSecurity = (userObject, tokken) => async dispatch => { 
      const coreSecurityId = await dispatch(getUserIdByTokken(tokken, 0));
      userObject['p_cod_core'] = coreSecurityId;
      dispatch(toastSuccessMessage("Registration link is valid!"));
  }

  export const getUserIdByTokken =  (tokken, isVisitor) => async dispatch => { 
    const coreSecurityObject = await dispatch(getUserByTokken(tokken, isVisitor));
    if(isEmptyObject(coreSecurityObject))
        throw new Error("Registration not authorized or registration link expired. Please, request a new link.")
    return coreSecurityObject.p_id;
  }


export const getTokenUser = (token) => async dispatch => {
    try {
      const body = JSON.stringify({ p_tokken: token, p_isvisitor: 1,prod: true});
      const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/getUserByTkn`, body);
      checkApiResponseForErrors(res);

      dispatch({
        type: SET_TOKEN_USER,
        payload: res? res: {}
      })

    } catch (err) {
      dispatch(toastErrorMessage(err.message));
    }
  }

export const getUserByToken = (token) => async dispatch => {
  let response = {};
  const loader = dispatch(buildAndStartLoader())
  try {
    const requestBody = {p_tokken: token, p_isvisitor: 1, prod: true};
    response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/getUserByTkn`, requestBody);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());

  } catch (err) {
    dispatch(toastErrorMessage(err.message));
  }

  loader.stop();
  return response;
}
  
export const verify_user = token => async dispatch => {
  const loader = dispatch(buildAndStartLoader());
  try {
    let requestBody = { p_isvisitor: 1, p_tokken: token, prod: true };
    const user = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/getUserByTkn`, requestBody);
    checkApiResponseForErrors(user);
    if(isEmptyObject(user))
      throw new Error('Verification Link might be outdated!');
    
    user.p_active = 1;
    const result = await sendRequestDataWithDefaultConfig( `${ROOT_URL}/auth/verifyUser`, user);
    checkApiResponseForErrors(result);
    dispatch(toastSuccessMessage('Your verification was successfull. Please Log In.'));
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  dispatch(setStoreData(VERIFY_USER, true));
  loader.stop();
};

export const update_user_data = (userData) => async dispatch => {
  try {
    const body = JSON.stringify(userData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/auth/editUser`, body);
    checkApiResponseForErrors(res);
    dispatch(toastSuccessMessage("User data Updated"));
  } catch (err) {
    dispatch(toastErrorMessage(err.message));
  }
};

export const getStats = () => async dispatch => { 
  try {
    const body = JSON.stringify({p_id_thread: getUser().p_id_company, prod: true});
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getStats`, body);
    checkApiResponseForErrors(res);

    dispatch({
      type: GET_STATS,
      payload: res,
    })
   
  } catch (err) {
    const errors = getErrorMessage(err);
    errors.forEach(err => {
      dispatch(setMessage(err, ERROR_TOAST));
    });
  }
}



export const setNewUserId = p_nid => async dispatch => { 

  try {
    dispatch({
      type: SET_NEW_USER_ID,
      payload: p_nid,
    })
   
  } catch (err) {
    const errors = getErrorMessage(err);
    errors.forEach(err => {
      dispatch(setMessage(err, ERROR_TOAST));
    });
  }
}