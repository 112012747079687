import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { open_modal } from '@actions/admin'
import AddExtraModal from './AddExtraModal'
import ExtraSettingsTable from './ExtraSettingsTable'


const ExtraSettingsTab = ({flag, open_modal, modal_data}) => {
    const [state, setState] = useState({
        toShowAddModal: false,
    })

    const openAddModal = e => { 
        setState({...state, toShowAddModal: true});
    }

    const closeAddModal = e => { 
        setState({...state, toShowAddModal: false});
    }
    
    return (
        <Fragment>
            <AddExtraModal toShow={state.toShowAddModal} closeModal={closeAddModal}/>
            <ExtraSettingsTable modal_data={modal_data} open_modal={open_modal} openAddModal={openAddModal} />
        </Fragment>
    )
}

ExtraSettingsTab.propTypes = {
    flag: PropTypes.bool,
    open_modal: PropTypes.func.isRequired,
    modal_data: PropTypes.object,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    open_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraSettingsTab)
