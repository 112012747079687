export const ROOT_URL = process.env.REACT_APP_ROOT_URL;
export const APP_NAME = 'PORT GATEWAY';
export const LOADING_TOAST = 'LOADING';
export const SUCCESS_TOAST = 'SUCCESS';
export const NEW = 'NEW';
export const CANCELED = 'CANCELED';
export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const ACCEPTED = 'ACCEPTED';
export const HOST = 'HOST';
export const THREAD = 'TRHEAD';
export const CORE_SEC = 'CORE_SEC';
export const HOST_ACT = 'HOST_ACT';
export const THREAD_ACT = 'THREAD_ACT';
export const CORE_SEC_ACT = 'CORE_SEC_ACT';
export const ACCEPTED_TODAY = 'ACCEPTED_TODAY';
export const REJECTED_TODAY = 'REJECTED_TODAY';
export const PENDING_TODAY = 'PENDING_TODAY';
export const CANCELED_TODAY = 'CANCELED_TODAY';
export const ERROR_TOAST = 'ERROR';
export const WARNING_TOAST = 'WARNING';
export const INFORMATION_TOAST = 'INFORMATION';
export const LOADING_TOAST_CODE = '004';
export const NEXT = 'NEXT';
export const PREV = 'PREV';
export const DEFAULT_COMBOBOX_CODE = -1;
export const USER_STR = 'USER';
export const REQUEST_PANEL = 'REQUEST_PANEL';
export const ALL = 'ALL';
export const PROVENANCE_TYPE = "PVC";
export const RPP_TYPE = 'RPP';
export const GATE_TYPE = 'GAT';
export const DEPARTMENT_TYPE = 'DPT';
export const TERMINAL_TYPE = 'TRL';
export const ID_TYPE = 'TID';
export const MRE_TYPE = 'MRE';
export const MRP_TYPE = 'MRP';
export const MRS_TYPE = 'MRS';
export const TIN_TYPE = 'TDI';
export const DEEP_INDUCTION_GROUP = 'DIP';
export const DEEP_INDUCTION_GROUP_EN = 'DIE';
export const DEFAULT_ARRIVAL_HOUR = "08:00";
export const CREATE_REQUISITION_X_MODAL = 'CREATE_REQUISITION_X_MODAL';
export const CREATE_ALERT_MSG_MODAL = 'CREATE_ALERT_MSG_MODAL';
export const BLOCK_USER_MODAL = 'BLOCK_USER_MODAL';
export const CHOOSE_VISITOR_MODAL = 'CHOOSE_VISITOR_MODAL';
export const CREATE_REQUISITION_HOST_MODAL = 'CREATE_REQUISITION_HOST_MODAL';
export const GRANT_ACCESS_MODAL = 'GRANT_ACCESS_MODAL';
export const MY_ACCOUNT_MODAL = 'MY_ACCOUNT_MODAL';
export const REJECT_MODAL = 'REJECT_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const TERMS_AND_CONDITION_MODAL = 'TERMS_AND_CONDITION_MODAL';
export const ADD_VISITOR_MODAL = 'ADD_VISITORS_MODAL';
export const VIEW_REQUISITION_MODAL = 'VIEW_REQUISITION_MODAL';
export const MAIN_PANEL_VIEW_VISIT_MODAL = 'MAIN_PANEL_VIEW_VISIT_MODAL';
export const CREATE_FAST_REQUEST_MODAL = 'CREATE_FASTP_REQUEST_MODAL';
export const CREATE_SERV_REQUEST_MODAL = 'CREATE_SERVICE_REQUEST_MODAL';
export const CREATE_REQUISITION_SERVICE_PROVIDER_MODAL = 'CREATE_REQUISITION_SERVICE_PROVIDER_MODAL';
export const SEE_ALERT_MSG_MODAL = 'SEE_ALERT_MSG_MODAL';
export const VIEW_USER_INFO_MODAL = 'VIEW_USER_INFO_MODAL';
export const NUMBER_REQUEST_PER_PAGE_REQUEST_PAGE = 12;
export const NUMBER_REQUEST_PER_PAGE_MAIN_PANEL = 6;
export const NUMBER_INDUCTION_QUESTION_PER_PAGE = 15;
export const VISITOR_REQ_PER_PAGE = 9;
export const NUMBER_USER_PER_PAGE = 8;
export const DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE = 15;
export const MIN_WINDOW_SIZE_FOR_TABLE_VIEW = 769;
export const CARD_VIEW_CARD_TRANSLATION = 120;
export const MIN_WINDOW_SIZE_FOR_ADMIN_PANELS = 425;
export const DEFAULT_TOAST_MESSAGE_TIMEOUT = 8000;
export const ACCEPT_BTN_MESSAGE = 'accept';
export const ACCEPTED_BTN_MESSAGE = 'accepted';
export const REJECT_BTN_MESSAGE = 'accepted';
export const REJECTED_BTN_MESSAGE = 'rejected';
export const CANCEL_BTN_MESSAGE = 'cancel';
export const CANCELED_BTN_MESSAGE = 'canceled';
export const NO_ACTION_BTN_MESSAGE = '--';
export const EDIT_BTN_MESSAGE = 'edit'; 
export const INDUCTION = 'INDUCTION';
export const MAIN_PANEL = 'MAIN_PANEL';
export const THREAD_PANEL = 'THREADS_PANEL';
export const REPORTS_PANEL = 'REPORTS_PANEL';
export const SETTINGS_PANEL= 'SETTINGS_PANEL';
export const VISIT_SETTINGS_TAB = 'Visitas';
export const INDUCTION_SETTINGS_TAB = 'Indução';
export const REGISTRATION_SETTINGS_TAB = 'Registros';
export const TERMINALS_SETTINGS_TAB = 'Terminais';
export const SERVICES_SETTINGS_TAB = 'Servicos';
export const ALERT_MESSAGES_TAB = 'Alert';
export const EMAIL_SETTINGS_TAB = 'Email';
export const HAS_INDUCTION = 'HAS_INDUCTION';
export const DEPARTMENTS_SETTINGS_TAB = 'Departamentos';
export const PROVENACNE_SETTINGS_TAB = 'Proveniencia';
export const RPP_SETTINGS_TAB = 'Razoes de Visita';
export const GATE_SETTINGS_TAB = 'Gates';
export const ID_SETTINGS_TAB = 'Tipos de Identificacao';
export const MRE_SETTINGS_TAB = 'Motivos para recusar entrada';
export const MRS_SETTINGS_TAB = 'Motivos para recusar saida';
export const MRP_SETTINGS_TAB = 'Motivos para recusar pedido'
export const TIN_SETTINGS_TAB = 'Tipos de Inducao';
export const DEFAULT_COUNTRY_CODE = '+258';
export const HOST_VISIT_STATE_CHANGE_CODE = 0;
export const THREAD_VISIT_STATE_CHANGE_CODE = 1;
export const CORE_SECURITY_VISIT_STATE_CHANGE_CODE = 2;
export const MIN_CHAR_FOR_SELECT_HOST = 3;
export const DEFAULT_COUNTRY = "Mozambique";
export const CONFIGURAITONS = "CONFIGURAITONS";
export const MAX_NUMBER_OF_NAVIGATION_INDICATORS = 6;
export const EMPTY_USER = {cod:0, fname:"", lname:"", nationality:"", cod_type_id:0, type_id:0, nid:"", exp_date_id:"", email:"", pnumber_1:"", pnumber_2:"", psw:"", }
export const SIMPLE_SUPPORT_EMAIL = ['p_email', 'p_detail'];
//::> PRIVILEGES
export const CREATE_REQUEST_PRIV = 'CREATE_REQUEST_PRIV';
export const CREATE_FAST_REQ_PRIV = 'CREATE_FAST_REQ_PRIV';
export const CREATE_SERV_REQ_PRIV = 'CREATE_SERV_REQ_PRIV';
export const BLOCK_USER_PRIV = 'BLOCK_USER_PRIV';
export const GRANT_ACCESS_PRIV = 'GRANT_ACCESS_PRIV';
export const MAIN_PANEL_PRIV = 'MAIN_PANEL_PRIV';
export const TERMINAL_PANEL_PRIV = 'TERMINAL_PANEL_PRIV';
export const SETTINGS_PRIV = 'SETTINGS_PRIV';
export const REPORTS_PRIV = 'REPORTS_PRIV';
export const ADD_VISITOR_PRIV = 'ADD_VISITOR_PRIVILEGE';
export const ERROR_MESSAGE_KEY = 'root_err'
//::>> REPORTS
export const REPORT_LOGIN= process.env.REPORT_LOGIN;
export const REPORT_1= 'rep_num_vis_in';
export const REPORT_2= 'rep_num_vis_in_graph';
export const PASSWORD_MIN_LENGTH = 6;
export const DEFAULT_NATIONALITY = 'Mozambique';
export const DEFAULT_ID_TYPE = 47;
export const USER_LOCALSTORAGE_KEY = 'USER_LOCALSTORAGE_KEY';
export const VISITS_LOCALSTORAGE_KEY = 'VISITS_LOCALSTORAGE_KEY';
export const COMPANY_LOCALSTORAGE_KEY = 'COMPANY_LOCALSTORAGE_KEY';
export const VISITORS_LOCALSTORAGE_KEY = 'VISITORS_LOCALSTORAGE_KEY';
export const HOSTS_LOCALSTORAGE_KEY = 'HOSTS_LOCALSTORAGE_KEY';
export const SERVICE_PROVIDER_LOCALSTORAGE_KEY = 'SERVICE_PROVIDER_LOCALSTORAGE_KEY';
export const SERVICE_PROVIDER_WORKER_LOCALTORAGE_KEY = 'SERVICE_PROVIDER_WORKER_LOCALTORAGE_KEY';


