import React, { Fragment, useState, useEffect } from 'react'
import { FormGroup, Col, CustomInput, Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import {DEFAULT_ARRIVAL_HOUR } from '@utils/constants';
import { getInputDateFormatFromString } from '@utils/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import CustomSelectOption from '@components/Shared/FormElements/CustomSelectOption';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { fixNewHostVisitFields } from '@utils/validations';
import { toastErrorMessage } from '@actions/toast';
import { relateAWorkerOfServiceProvToServiceRequest } from '@actions/visits';

const ServRequestDataHostPg1 = ({flag, toastErrorMessage, visit, toEdit, relateAWorkerOfServiceProvToServiceRequest}) => {
    const {t} = useTranslation('request');
    const [state, setState] = useState({
        p_id_company: -1
    });

    const {p_date_time, p_time, p_period, p_id_rpp, p_detail,p_id_gate, p_multi_entry, p_access, p_id_company } = visit || {};


    useEffect(() => { 
        if (p_id_company !== -1) {
            relateAWorkerOfServiceProvToServiceRequest(visit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [state.p_id_company])
    

    // ::: EVENT HANDLERS
    const onChange = (e) => {
        const nodeName = e.target.name;
        let nodeValue = e.target.value;
        const prevValue = visit[nodeName];
        try {
            visit[nodeName] = nodeValue;
            fixNewHostVisitFields(visit); //::>> Throws Error if a field is invalid
            if(nodeName === 'p_id_company')
                setState({...state, p_id_company: +nodeValue});
            else 
                setState({...state, visit: visit});
        } catch (error) {
            visit[nodeName] = prevValue;
            toastErrorMessage(error.message);
        } 
    };

    const onChangeMultyEntry = (e, value) => { 
        visit['p_multi_entry'] =  value;
        setState({...state, visit: visit});
    }

    const onChangePAccess = (e, value) => { 
        visit['p_access'] = value;
        setState({...state, visit: visit});
      }

    return (
        <Fragment>
            <FormGroup>
                {/* ::: COMPANY NAME INPUT */}
                <FormGroup>
                    <Input type="select" name="p_id_company" value={p_id_company} className="required" disabled={toEdit} onChange={e => onChange(e)}>
                        <CustomSelectOption options={AppFunctionalityUsable.companies} hasId={true}/>
                    </Input>
                </FormGroup>
                <FormGroup row>
                    {/* ::: DATE OF VISIT INPUT */}
                    <Label sm={1}>{t("date")}</Label>
                    <Col md={5}>
                        <Input type="date" name="p_date_time" className="required" value={getInputDateFormatFromString(p_date_time)} onChange={(e) => onChange(e)}  />
                    </Col>
                    {/* ::: HOUR OF VISIT INPUT */}
                    <Label sm={1}>{t("hour")}</Label>
                    <Col md={5}>
                        <Input type="time" className="required" value={p_time? p_time: DEFAULT_ARRIVAL_HOUR} name="p_time" onChange={(e) => onChange(e)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    {/* ::: PERIOD OF VISIT INPUT */}
                    <Label sm={2}>{t("period")}</Label>
                    <Col md={3}> 
                        <Input type="number" name="p_period" value={p_period || 1} className="required" onChange={(e) => onChange(e)} />
                    </Col>
                    {/* ::: GATE OF VISIT INPUT */}
                    <Label sm={1}>{t("gate")}</Label>
                    <Col md={3}> 
                        <Input type="select" name="p_id_gate" value={p_id_gate} className="required" onChange={(e) => onChange(e)}>
                            <CustomSelectOption options={AppFunctionalityUsable.gates} hasId={true}/>
                        </Input>
                    </Col>
                </FormGroup>
                <div className="separator transparent mb-4"/>
                <FormGroup>
                    {/* ::: REASON OF VISIT SELECT INPUT */}
                    <FormGroup>
                        <Input type="select" name="p_id_rpp" className="required" value={p_id_rpp} onChange={e=> onChange(e)} >
                            <CustomSelectOption options={AppFunctionalityUsable.reasonsOfVisit} hasId={true}/>
                        </Input>
                    </FormGroup>
                    {/* ::: REASON OF VISIT TEXTAREA INPUT */}
                    <FormGroup> 
                        <Input type="textarea" name="p_detail" className="required" placeholder={t("reason")} value={p_detail} onChange={(e) => onChange(e)} />
                    </FormGroup>
                </FormGroup>
                <div className="separator transparent mb-4"/>
                {/* ::: MULTIENTRY CHECKBOX INPUT */}
                <FormGroup>
                    <CustomInput type="checkbox" id="p_multi_entry" checked={p_multi_entry === 1? true: false} label={t("mult_ent")} onChange={(e) => onChangeMultyEntry(e, p_multi_entry === 1? 0: 1)} />
                    <small className="form-text text-muted">{t('mult_ent_desc')}</small>
                </FormGroup>
                <div className="separator transparent mb-4"></div>
                {/* ::: ENTRY/EXIT OR EXIT CHECKBOXES INPUT */}
                <FormGroup check inline>
                    <Label check>
                    <CustomInput id="chec1" label={t("entry_exit")} checked={p_access === 1? true: false} type="checkbox" onChange={(e) => onChangePAccess(e,1)} /> 
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                    <CustomInput id="chec13" label={t("exit")} checked={p_access === 1? false: true} type="checkbox" onChange={(e) => onChangePAccess(e, 0)} /> 
                    </Label>
                </FormGroup>
            </FormGroup>
        </Fragment>
    )
}

ServRequestDataHostPg1.propTypes = {
    flag: PropTypes.bool,
    relateAWorkerOfServiceProvToServiceRequest: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    relateAWorkerOfServiceProvToServiceRequest,
    toastErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServRequestDataHostPg1)
