import React from 'react'
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

const TableNavigation = ({componentData}) => {
    const {t} = useTranslation('main');
    const {pageIndicators, page, totalPages, totalItems, prev, next} = componentData;
    let pageIndexes = pageIndicators;
    const pgIndicatorLastItemIndex = pageIndexes.length - 1;


    const onClickIndicator = (e, indicator) => { 
        if(componentData.setPage)
            componentData.setPage(e, indicator);
    }

    // :::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const _pageIndexes = pageIndexes.map(indicator=> {
                            const isIndexOfCurrentPage = page === indicator;
                            const isNotFirstPage = page !== 1;
                            const isNotLastPage = page !== pageIndexes[pgIndicatorLastItemIndex];
                            const isPagesInMiddle = indicator === '...' && isNotFirstPage && isNotLastPage;
                            const pagendexActiveState = (isIndexOfCurrentPage || isPagesInMiddle)? 'active':'';
                            return <li key={v4()} className={`page-item ${pagendexActiveState}`} onClick={e => onClickIndicator(e, indicator)}>
                                        <span className={`${((page) === indicator)? "page-link": "page-link"}`} >{indicator}</span>
                                    </li>
                        })

    // :: CONDITIONAL HTML STYLING AND TEXT
    const prevBtnOnState = page === 1? "disabled": "";
    const nextBtnOnState = page === totalPages? "disabled": "";

    return (
        <div className="table-navigation clearfix">
            {/* :: INFO ABOUT THE NUMBER OF ITEMS */}
            <span className="navigation-info text-muted">{t('showing')} {page} {t('of')} {totalPages} {t('of')} {totalItems} {t('entries')}</span>
            <div className="navigation-container float-right ">
                <ul className="pagination">
                    {/* :: PREVIOUS BUTTON */}
                    <li className={`page-item ${prevBtnOnState}`}>
                        <span className="page-link" onClick={e => prev(e)}>{t('prev')}</span>
                    </li>
                    {/* :: PAGE INDEXES */}
                    {_pageIndexes}
                    {/* :: NEXT BUTTON */}
                    <li className={`page-item ${nextBtnOnState}`}>
                     <span className="page-link" onClick={e => next(e)}>{t('next')}</span>
                 </li>
                </ul>
            </div>
        </div>
    )
}

export default TableNavigation;