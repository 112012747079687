import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, FormGroup, CustomInput, Row, Col, Button, ModalFooter } from 'reactstrap';
import { close_modal, uploadTemplateFile } from '@actions/admin';
import { ROOT_URL, ERROR_TOAST } from '@utils/constants';
import { getAppConfig } from '@utils/configurations';
import { isCAMemberUser, isCoreSecurityUser, isEmptyArray } from '@utils/utils';
import { setMessage, toastErrorMessage } from '@actions/toast';
import GrantAccessMultipleUserForm from './GrantAccessMultipleUserForm';
import GrantAccessSingleUserForm from './GrantAccessSingleUserForm';
import { performHostRegistrationProccess, getNewHostObject, performBulkHostRegistrationProcess } from '@actions/auth';
import { validateNewUserFields } from '@utils/validations';


const GrantAccessModal = ({flag, close_modal, performHostRegistrationProccess, performBulkHostRegistrationProcess, toastErrorMessage, loggedUser, uploadTemplateFile, setMessage, getNewHostObject}) => {
    const {HOST_CODE, THREAD_CODE, CORE_SECURITY_CODE, GATE_KEEPER_CODE} = getAppConfig();
    const { t } = useTranslation('request');
    const [state, setState] = useState({ 
        disSub: false,
        is_collective: false,
        loading: false,
        userPrivCod: HOST_CODE,
        singleUser: getNewHostObject(loggedUser),
        multipleUsers: [],
    });

    const {disSub, is_collective, loading, singleUser, multipleUsers} = state;
    const { p_id_role } = singleUser;

    // ::: EVENT HANDLERS
    const onChangeIsCollective = e => { 
        setState({...state, is_collective: !is_collective});
    }

    const changeToHost = (e) => {
        const nodeName = e.target.name;
        if (nodeName === "isCoreSec") {
            multipleUsers.forEach((usr) => {
                usr["p_id_department"] = loggedUser.p_id_department;
                usr["p_id_role"] = CORE_SECURITY_CODE;
                usr["p_id_company"] = loggedUser.p_id_company;
            });
            if (singleUser) {
                singleUser["p_id_department"] =  loggedUser.p_id_department;
                singleUser["p_id_role"] =  CORE_SECURITY_CODE;
                singleUser["p_id_company"] =  loggedUser.p_id_company;
            }
            setState({...state, userPrivCod: CORE_SECURITY_CODE});
            return;
        }

        if (nodeName === "isHost") {
            multipleUsers.forEach((usr) => {
                usr["p_id_role"] = HOST_CODE;
            });
            if (singleUser) {
                singleUser["p_id_role"] =  HOST_CODE;
            }
            setState({...state, userPrivCod: HOST_CODE});
        } 
        
        if (nodeName === "isThread") {
            multipleUsers.forEach((usr) => {
                usr["p_id_department"] = loggedUser.p_id_department;
                usr["p_id_role"] = THREAD_CODE;
            });
            if (singleUser) {
                singleUser["p_id_department"] =  loggedUser.p_id_department;
                singleUser["p_id_role"] =  THREAD_CODE;
            }
            setState({...state, userPrivCod: THREAD_CODE});
        }

        if (nodeName === "isGateKeeper") { 
            multipleUsers.forEach((usr) => {
                usr["p_id_department"] = loggedUser.p_id_department;
                usr["p_id_role"] = GATE_KEEPER_CODE;
                usr["p_id_company"] = loggedUser.p_id_company;
            });
            if (singleUser) {
                singleUser["p_id_department"] =  loggedUser.p_id_department;
                singleUser["p_id_role"] =  GATE_KEEPER_CODE;
                singleUser["p_id_company"] =  loggedUser.p_id_company;
            }
            setState({...state, userPrivCod: GATE_KEEPER_CODE});
            return;
        }
    };

    const onSubmit = async e => { 
        let isSuccessful = true; 
        setState({...state, disSub: true});
        if(is_collective) {
            isSuccessful = await performBulkHostRegistrationProcess(multipleUsers);
        } else { 
            isSuccessful = await performHostRegistrationProccess(singleUser);
        }

        if(isSuccessful)          
            onClose();
        
        setState({...state, disSub: false});
    }

    const onClose = e => { 
        close_modal();
    }

    const onFileSelect = async (e) => { 
        const file = e.target.files[0];
        if(!file) return;
        setState({...state, selectedFile: file.name, loading: true});

        const data = new FormData();
        data.append("file", file);
        data.append("name", file.name);
        let usersOfFile = await uploadTemplateFile(data, 1); 
        if(isEmptyArray(usersOfFile) || !usersOfFile) return setMessage('No valid data to process. Invalid file.', ERROR_TOAST);
        usersOfFile = usersOfFile.map((usr) => {
            let newUser = usr;
            try {
                newUser = getNewHostObject(loggedUser, usr);
                validateNewUserFields(newUser);
            } catch (error) {
                // toastErrorMessage(error.message);
            }
            return newUser;
        });

        setState({...state, loading: false, multipleUsers: usersOfFile});
    }


    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            <h3 className="title-of-modal">{t("gra_acc")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                {/* ::: MULTIPLE USER / FILE UPLOAD SWITCH */}
                <FormGroup className="mt-4">
                    <CustomInput type="switch" name="is_collective" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" checked={is_collective} id="custonSwithd" onChange={(e) => onChangeIsCollective(e)} inline label={t("is_col")}/>
                    {loading && <img alt="loading" className="mx-auto my-2 loading-gif" src={require(`@resources/icons/loading.gif`)}/>}
                </FormGroup>
                {/* ::: FILE INPUT FOR USERS */}
                <FormGroup>
                    <CustomInput disabled={!is_collective} type="file" id="collectiveFile" label={t("cho_fil")} name="file" onChange={e => onFileSelect(e)}/>
                    <small><a className="text-green pl-1 link-text" href={`${ROOT_URL}/files/access/templates`}>{t('dow_tem_fil')}</a></small>
                </FormGroup>
                {/* ::: GRANT ACCESS FORM FOR MULTIPLE USER */}
                {is_collective && <GrantAccessMultipleUserForm loggedUser={loggedUser} singleUser={singleUser} multipleUsers={multipleUsers} parentState={state} setMessage={setMessage} onChangeIsCollective={onChangeIsCollective} /> }
                <div className="separator thin light my-2"></div>
                <div className="separator transparent mb-4"></div>
                {/* ::: GRANT ACCESSF FORM FOR SINGLE USER */}
                {!is_collective && <GrantAccessSingleUserForm loggedUser={loggedUser} singleUser={singleUser} toastErrorMessage={toastErrorMessage}/> }
                {/* ::: USER TYPE CHECKBOXEX */}
                <Row className="my-4">
                        <Col md={3}>
                            <CustomInput type="checkbox" id="isHost" name="isHost" checked={p_id_role === HOST_CODE} label={t("to_host")} onChange={e => changeToHost(e)}/>
                        </Col>
                        <Col md={3}>
                            <CustomInput type="checkbox" id="isThreadck" name="isThread" checked={p_id_role === THREAD_CODE} label={t("to_thread")} onChange={e => changeToHost(e)}/>
                        </Col>
                        {(isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser))&&    
                            <Fragment>
                                <Col md={3}>
                                    <CustomInput type="checkbox" id="isCoreSec" name="isCoreSec" checked={p_id_role === CORE_SECURITY_CODE} label={t("to_core_sec")} onChange={e => changeToHost(e)}/>
                                </Col>
                                <Col md={3}>
                                    <CustomInput type="checkbox" id="isGateKeeper" name="isGateKeeper" checked={p_id_role === GATE_KEEPER_CODE} label={t("to_gat_keeper")} onChange={e => changeToHost(e)}/>
                                </Col>
                            </Fragment>
                        }
                    </Row>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className="w-100 d-flex justify-content-between">
                    <Button className="btn btn-normal mx-2" onClick={e => onClose()}>{t('cancel')}</Button>
                    <div>
                        <Button className="mx-2" disabled={disSub} color='success' onClick={e => onSubmit(e)}>{t('submit')}</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

GrantAccessModal.propTypes = {
    flag: PropTypes.bool,
    loggedUser: PropTypes.object,
    setMessage: PropTypes.func.isRequired,
    getNewHostObject: PropTypes.func.isRequired,
    uploadTemplateFile: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
    performHostRegistrationProccess: PropTypes.func.isRequired,
    performBulkHostRegistrationProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    loggedUser: state.auth.user,
})

const mapDispatchToProps = {
    close_modal,
    setMessage,
    getNewHostObject,
    uploadTemplateFile,
    toastErrorMessage,
    performHostRegistrationProccess,
    performBulkHostRegistrationProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(GrantAccessModal);
