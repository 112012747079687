import React, {useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {DEFAULT_ARRIVAL_HOUR, MIN_CHAR_FOR_SELECT_HOST} from '@utils/constants';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { buildEmployeeNameToIdMapViceVersa, capitalize, getInputDateFormatFromString, isNotEmptyObject, isNotEmptyValue } from '@utils/utils';
import Extra from '@model/Extras/Extra';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadTemplateFile } from '@actions/admin';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { fixNewVisitorVisitFields } from '@utils/validations';
import { toastErrorMessage } from '@actions/toast';
import DataListFormForUsers from '@components/Shared/FormElements/DataListFormForUsers';


const COMPANY_NAME_INPUT_ICON = require("@resources/icons/user-input.svg");

function VisitorFastRequestPage2({flag, visit, uploadTemplateFile, toastErrorMessage}) {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
        loading: false,
        to_company: false,
        toShowDropdown: false,
        subTerminals: Object.keys(AppFunctionalityUsable.subTerminalNameTerminalIDMap),
        host_name: visit.host_fname?  `${visit.host_fname} ${visit.host_lname}` : '',
    });

    const {p_id_department, p_date_time, p_time, p_period, p_tocompany, to_edit} = visit || {};
    const {p_id_company, subTerminals} = state;
     
    useEffect(() => {
        console.log(`TERMINAL_CODE(3): ${visit.p_id_company}`);
        if(isNotEmptyValue(visit.p_id_company)) Extra.getFuncOfTerm(visit.p_id_company);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [p_id_company]);

    useEffect(() => { 
        if(isNotEmptyValue(visit.p_id_host)) { 
            const employeeMapCopy = buildEmployeeNameToIdMapViceVersa(Extra.usersOfTerminal);
            if(isNotEmptyObject(employeeMapCopy)){
                const hostName = employeeMapCopy[visit.p_id_host];
                setState({...state, host_name: capitalize(hostName)})
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {toShowDropdown, host_name} = state;

    const onSelectHost = (e, hostName) => {
        try {
            visit.p_id_host = hostName;
            fixNewVisitorVisitFields(visit)
            setState({...state, toShowDropdown: false, host_name: capitalize(hostName)});
        } catch (error) {
            toastErrorMessage(error.message);
        }
    }

    const onChange = (e) => {
        const nodeName = e.target.name;
        let nodeValue = e.target.value;
        const prevValue = visit[nodeName];
        try {
            visit[nodeName] = nodeValue;
            fixNewVisitorVisitFields(visit); //::>> Throws Error if a field is invalid
            if (nodeName === "p_id_host"){
                setState({...state, host_name: nodeValue, toShowDropdown: isToShowDataList(nodeValue)});
            } else if (nodeName === "p_id_company"){ 
                const selectedOption = AppFunctionalityUsable.terminals.find(terminal => +terminal.code === +nodeValue);
                if(subTerminals.includes(selectedOption.name)) { 
                    const terminalCode = AppFunctionalityUsable.subTerminalNameTerminalIDMap[selectedOption.name];
                    setState({...state, [nodeName]: nodeValue});
                    console.log(`TERMINAL_CODE: ${terminalCode}`);
                    visit[nodeName] = terminalCode;
                    console.log(`TERMINAL_CODE(2): ${visit.p_id_company}`);
                    visit["p_id_subcompany"] = +nodeValue;
                } else  {
                    setState({ ...state, [nodeName]: [nodeValue]});
                }
            } else {
                setState({...state, visit: visit});
            }
        } catch (error) {
            visit[nodeName] = prevValue;
            toastErrorMessage(error.message);
        }       
    };
    
    const changeToHost = (e, value) => {
        visit['p_tocompany'] = value? 1:0;
        setState({...state, to_company: value});
    };

    const isToShowDataList = (host_name) => {
        let toShow = true;
        try {
            toShow = toShow && isNotEmptyValue(host_name);
            toShow = toShow && (host_name.length >= MIN_CHAR_FOR_SELECT_HOST);
        } catch (error) {
            toShow = false;
        }
        return toShow;
    }
    
    // :: CONDITIONAL FORM ELEMENTS
    const hostSelectionInput = 
        <div className="form-group iconed-input">
            <img src={COMPANY_NAME_INPUT_ICON} alt="icon" />
            <DataListFormForUsers toShow={toShowDropdown} inputFieldName="p_id_host" elements={Extra.usersOfTerminal} inputValue={host_name} onAction={onSelectHost}/>
            <input autoComplete="off"  name="p_id_host" disabled={to_edit} list="employees" id="employee" placeholder={t("com_nam")} className="form-control dark-input" value={host_name} onChange={e => onChange(e)}/>
        </div>

    return (
        <div className="form-page">
            {/* :: FORM TITLE */}
            <h2 className="form-header">{t('req_dat')}</h2>
            <div className="form-group inline-select-container">
                {/* :: CHECKBOX FOR COLABORATOR ONLY REQUEST */}
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="to_host_smt" checked={!visit.p_tocompany} onChange={e => changeToHost(e, false)}/>
                    <label className="custom-control-label dark-label" htmlFor="to_host_smt">{t('to_hos')}</label>
                </div>
                {/* :: CHECKBOX FOR TERMINAL ONLY REQUEST */}
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="to_terminal" checked={visit.p_tocompany} onChange={e => changeToHost(e, true)}/>
                    <label className="custom-control-label dark-label" htmlFor="to_terminal">{t('to_thr')}</label>
                </div>
            </div>
            {/* :: SELECT FOR TERMINAL TO VISIT */}
            <div className="form-group">
                <select type="select"  name="p_id_company" className="form-control dark-select required" value={p_id_company} onChange={e => onChange(e)}>
                    <CustomSelectOption options={AppFunctionalityUsable.terminals} hasId={true}/>
                </select>
            </div>
            {/* :: SELECT FOR DEPARTMENT TO VISIT */}
            <div className="form-group">
                <select type="select" name="p_id_department" className="form-control dark-select required" value={p_id_department} onChange={e => onChange(e)}>
                    <CustomSelectOption options={AppFunctionalityUsable.departments} hasId={true}/>
                </select>
            </div>
            {/* :: CONDITIONAL INPUT */}
            {!p_tocompany && hostSelectionInput}
            {/* :: DATE AND TIME INPUT */}
            <div className="form-group row">
                {/* :: DATE OF THE VISIT */}
                <div className="col-md-6">
                    <label htmlFor="visitDate" className="text-white text-align-start">{t("vis_dat")}</label>
                    <input type="date" name="p_date_time" placeholder={t("exp_dat")} className="form-control dark-select required" id="visitDate" value={getInputDateFormatFromString(p_date_time)} onChange={(e) => onChange(e)}/>
                </div>
                {/* :: TIME OF THE VISIT */}
                <div className="col-md-6">
                    <label htmlFor="visitTime" className="text-white text-align-start">{t("vis_hou")}</label>
                    <input type="time" name="p_time" placeholder={t("exp_dat")} className="form-control dark-select required" id="visitTime" value={p_time? p_time: DEFAULT_ARRIVAL_HOUR} onChange={(e) => onChange(e)}/>
                </div>
            </div>
            {/* :: VISIT PERIOD INPUT */}
            <div className="form-group row">
                <div className="col-sm-4">
                    <label htmlFor="visitPeriod" className="text-white text-align-start">{t("period_hour")}</label>
                    <input type="number" name="p_period" value={p_period || 1} id="visitPeriod" className="form-control dark-select" onChange={(e) => onChange(e)} />
                </div>
            </div>
        </div>
    )
}


VisitorFastRequestPage2.propTypes = {
    flag: PropTypes.bool,
    uploadTemplateFile: PropTypes.func.isRequired,
    fixNewVisitorVisitFields: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    uploadTemplateFile,
    fixNewVisitorVisitFields,
    toastErrorMessage,
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitorFastRequestPage2);
