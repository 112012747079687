import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CustomSelectOption from "@sharedComponents/FormElements/CustomSelectOption";
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { getAppConfig } from '@utils/configurations';
import { useState } from 'react';
import { toastErrorMessage } from '@actions/toast';
import { fixNewUserFields, getCountryCodeByCountryName, resolveUserCountryCode, setUserCountryCode } from '@utils/validations';
import { useEffect } from 'react';
import { DEFAULT_NATIONALITY } from '@utils/constants';

const NAME_INPUT_ICON = require("@resources/icons/user-input.svg");
const P_NUMBER_INPUT_ICON = require("@resources/icons/pnumber.svg");
const ID_INPUT_ICON = require("@resources/icons/id_doc.svg");


const UserPersonalDataForm = ({flag, newUser, toastErrorMessage}) => {
    const { t } = useTranslation('auth');
    const [state, setState] = useState({
        user: newUser,
    })
    const { COUNTRY_LIST } = getAppConfig();
    const {p_fname,p_lname,p_nationality,p_id_type_id,p_expiration_date_id,p_nid,p_pnumber_1,p_pnumber_2, country_code_p1, country_code_p2} = newUser;

    useEffect(() => {
        fixNewUserFields(newUser);
        const cCode = getCountryCodeByCountryName('');
        setUserCountryCode(newUser, cCode);
        setState({...state, user: newUser});
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (e) => { 
        const nodeName = e.target.name;
        let nodeValue = e.target.value;

        try {
            newUser[nodeName] = nodeValue;
            fixNewUserFields(newUser); //::>> Throws Error if a field is invalid
            if(nodeName === 'p_nationality')
                resolveUserCountryCode(newUser, nodeValue);
            setState({...state, user: newUser});
        } catch (error) {
            newUser[nodeName] = '';
            toastErrorMessage(error.message);
        }
    }

    const onFileSelect = e => {
        const file = e.target.files[0];
        newUser['uid_data'] = file;
    };

    return (
        <div className="form-page">
            {/* :: TITLE */}
            <h2 className="form-header">{t('per_dat')}</h2>
            {/* :: FIRST NAME INPUT */}
            <div className="form-group iconed-input required">
                <img src={NAME_INPUT_ICON} alt="user_icon" />
                <input type="name" name="p_fname" placeholder={t("fir_nam")} className="form-control dark-input" value={p_fname || ''}  onChange={e => onChange(e)}/>
            </div>
            {/* :: LAST NAME INPUT */}
            <div className="form-group iconed-input required">
                <img src={NAME_INPUT_ICON} alt="user_icon" />
                <input type="name" name="p_lname"  placeholder={t("surname")} className="form-control dark-input" value={p_lname || ''}  onChange={e => onChange(e)}/>
            </div>
            <div className="form-group row ss-inputs">
                 {/* :: SELECT COUNTRY INPUT */}
                <div className="col-lg-6">
                    <select type="select"  placeholder={t("nationality")} name="p_nationality"  className="form-control dark-select required" value={p_nationality || DEFAULT_NATIONALITY } onChange={e => onChange(e)}>
                        <CustomSelectOption options={COUNTRY_LIST}  hasId={false}/>
                    </select>
                </div>
                {/* :: SELECT TYPE ID INPUT */}
                <div className="col-lg-6">
                    <select type="select" name="p_id_type_id" className="form-control dark-select required" value={p_id_type_id || ''} onChange={e => onChange(e)}>
                        <CustomSelectOption options={AppFunctionalityUsable.idDocumentTypes} hasId={true}/>
                    </select>
                </div>
            </div>
            {/* :: DOB INPUT FIELD */}
            <div className="form-group mt-4">
                <label htmlFor="registrationDateDOB" className="text-white text-align-start">{t("exp_dat")}</label>
                <input type="date" name="p_expiration_date_id" placeholder={t("exp_dat")} className="form-control dark-select required" id="registrationDateDOB" value={p_expiration_date_id || ''} onChange={e => onChange(e)}/>
            </div>
            <div className="form-group row  ss-inputs">
                {/* :: ID NUMBER INPUT */}
                <div className="col-lg-6">
                    <div className="iconed-input required">
                        <img src={ID_INPUT_ICON} alt="user_icon" />
                        <input type="text" name="p_nid" placeholder={t("id")}  className="form-control dark-input" value={p_nid || ''} onChange={e => onChange(e)}/>        
                    </div>
                </div>
                {/* :: ID DOCUMENT PHOTO INPUT */}
                <div className="col-lg-6">
                    <div className="dark-file-input clearfix">
                        <input type="file" name="p_photo_id" className="" placeholder="input_text" onChange={e => onFileSelect(e)}/>  
                    </div>
                    <small className="form-text text-left text-white">{t("pho_doc")}</small>
                </div>
            </div>
            <div className="form-group row  ss-inputs">
                <div className="col-lg-6">
                    {/* :: PHONE NUMBER 01 INPUT */}
                    <div className="phone-number-input-container iconed-input required">
                        <img src={P_NUMBER_INPUT_ICON} alt="user_icon"/>
                        <input type="tel" name="country_code_p1" value={country_code_p1 || ''} className="form-control dark-input small-input" onChange={e => onChange(e)}/>
                        <input type="tel" name="p_pnumber_1" placeholder={t("cel_num")} className="form-control dark-input" value={p_pnumber_1 || ''} onChange={e => onChange(e)}/>
                    </div>
                </div>
                <div className="col-lg-6">
                    {/* :: PHONE NUMBER 02 INPUT */}
                    <div className="phone-number-input-container iconed-input required">
                        <img src={P_NUMBER_INPUT_ICON} alt="user_icon"/>
                        <input type="tel" name="country_code_p2" value={country_code_p2 || ''} className="form-control  dark-input small-input" onChange={e => onChange(e)}/>
                        <input type="tel" name="p_pnumber_2" placeholder={t("eme_cel_num")} className="form-control dark-input" value={p_pnumber_2 || ''} onChange={e => onChange(e)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

UserPersonalDataForm.propTypes = {
    flag: PropTypes.bool,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag
})

const mapDispatchToProps = {
    toastErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPersonalDataForm)
