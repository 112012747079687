import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMomentFromString, getIconEquiv, getIconColorEquiv, calculateNumberOfPages, isServiceProviderUser, doesArrayExist, validateArrayParameter } from '@utils/utils';
import { HOST, CORE_SEC, THREAD, VISITOR_REQ_PER_PAGE, ROOT_URL } from '@utils/constants'
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import TableNavigation from '@components/MainPage/Shared/TableNavigation';

const VisitorsVistsTable = ({visits, loggedUser, actionsForChildren}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        page: 1
    });
    const allVisits = validateArrayParameter(visits);
    const {updateVisitData, onSelectVisitVisitor} = actionsForChildren;

    const {page} = state;
    
    // :::::::::: CONTENT FOR TABLE PAGE NAVIGATION
    const numberOfVisits = doesArrayExist(visits)? visits.length : 1;
    let  totalPages =  calculateNumberOfPages(numberOfVisits, (VISITOR_REQ_PER_PAGE - 2));;
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const setPage = (e, page) => {setState({...state, page })}
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);
    const componentData = {
        pageIndicators: indicators,
        totalPages,
        totalItems: numberOfVisits,
        page,
        next,
        prev,
        setPage
    }

    // :::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    // :: TABLE ROWS
    const lowerBoundPageNum = (page-1) * (VISITOR_REQ_PER_PAGE - 2);
    const upperBoundPageNum = page * (VISITOR_REQ_PER_PAGE - 2);
    const visitsOfPage = allVisits.filter((_, index) => (index >= lowerBoundPageNum) && (index < upperBoundPageNum));
    const visitRows = visitsOfPage.map(visit => { 
        const isInternalRequest = visit.p_internal === 1;
        const canSelectVisitors = isServiceProviderUser(loggedUser) && isInternalRequest;
        return (
            <tr key={v4()}>
                    <td onClick={e => updateVisitData(visit)}>{`${visit.host_fname} ${visit.host_lname}`}</td>
                    <td onClick={e => updateVisitData(visit)}>{`${visit.terminal} ${visit.department}`}</td>
                    <td onClick={e => updateVisitData(visit)}>{`${getMomentFromString(visit.p_date_time).format('ddd, MMMM Do HH:mm')}`}</td>
                    <td onClick={e => updateVisitData(visit)}>{`${visit.p_code}`}</td>
                    <td onClick={e => updateVisitData(visit)}>{`${visit.gate}`}</td>
                    <td>
                        <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[HOST])}`} >{getIconEquiv(visit.states[HOST])}</span>
                        <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[THREAD])}`} >{getIconEquiv(visit.states[THREAD])}</span>
                        <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[CORE_SEC])}`} >{getIconEquiv(visit.states[CORE_SEC])}</span>
                        {canSelectVisitors && <span className={`default-button`} onClick={e => onSelectVisitVisitor(e, visit)}>{t('sel_vis')}</span> }
                    </td>
                </tr>
            )
    })
    



    return (
        <div className="elevated-container table-container"> 
            {/* :: TABLE TITLE */}
            <div className="clearfix">
                <span className="elevated-contaniner-title">{t('my_req')}</span>
                <a href={`${ROOT_URL}/files/visitors/manual`} type="button" className="btn mt-3 mr-3 btn-secondary btn-sm float-right">{t('dow_use_gu')}</a>
            </div>
            <table className="striped-table">
                <thead>
                    {/* :: TABLE HEADER */}
                    <tr>
                        <th>{t('name')}</th>
                        <th>{t('terminal')}</th>
                        <th>{t('dat_tim')}</th>
                        <th>{t('vis_cod')}</th>
                        <th>{t('gate')}</th>
                        <th>{t('state')}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* :: TABLE ROWS */}
                    {visitRows}
                </tbody>
            </table>
            {/* ::: TABLE NAVIGATION */}
            <TableNavigation componentData={componentData} />
        </div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorsVistsTable)
