import { ALL } from '@utils/constants';
import { getToday } from '@utils/utils';
import React from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
const TODAY_STRING_FORMAT = `${getToday().format('MMMM Do')} -  ${getToday().endOf('month').format('MMMM Do')}`;

const StatsDisplay = ({stats, visitors}) => {
    const {t} = useTranslation('main');
    return (
        <Fragment>
            <div className="elevated-container main-box box  col">
                <span className="text-center d-block"> {TODAY_STRING_FORMAT}</span>
                <div className="separator light thin"/>
                <div className="box-content">
                    <h1 className="m-0 p-0 text-center">{stats[ALL]}</h1>
                    <span>{t('visits')}</span>
                </div>
            </div>
            <div className="elevated-container box  col">
                <span className="text-center d-block">{t('hap_vis')}</span>
                <div className="separator light thin"/>
                <div className="box-content">
                    <h1 className="p-0 m-0 text-center">{stats.HAPPENING}</h1>
                    <span>{t('visits')}</span>
                </div>
            </div>
            <div className="elevated-container box  col">
                <span className="text-center d-block">{t('vis_in')}</span>
                <div className="separator light thin"/>
                <div className="box-content">
                    <h1 className="p-0 m-0 text-center">{visitors.length}</h1>
                    <span>{t('visits')}</span>
                </div>
            </div>
        </Fragment>
    )
}

export default StatsDisplay
