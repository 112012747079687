import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, Input, ModalFooter, Button } from 'reactstrap'
import { close_modal, open_modal } from '@actions/admin'
import { toastErrorMessage } from '@actions/toast'
import { useTranslation } from 'react-i18next'
import { CONFIRMATION_MODAL, DEFAULT_COMBOBOX_CODE } from '@utils/constants'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import CustomSelectOption from '@components/Shared/FormElements/CustomSelectOption'
import { buildVisitStateObj, fetchLoggedUserVisits, performRejectOrCancelVisitProcess } from '@actions/visits'

const RejectModal = ({flag, modal, modal_data, open_modal, close_modal,user, toastErrorMessage, fetchLoggedUserVisits,performRejectOrCancelVisitProcess }) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        reason: '',
        p_cod_why: '',
        
    });


    const {reason, p_cod_why} = state;

    const onChange = e => {
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if ((+nodeValue === DEFAULT_COMBOBOX_CODE)) return setState({...state, p_cod_why: -1, reason: ''});

        const reasonObject = AppFunctionalityUsable.visitRejectionReasons.find(obj => obj.code === +nodeValue)
        const reason = reasonObject.name;
        setState({...state, [nodeName]: +nodeValue, reason})
    }

    const onClose = e => {
        close_modal();
    }

    const onSend = async e => {
       if(!reason) { 
           toastErrorMessage('Specify a reason first');
           return;
       }

       const visitStateObj = buildVisitStateObj(0, reason, p_cod_why);

       open_modal(CONFIRMATION_MODAL, { 
        funcToExecute: () => performRejectOrCancelVisitProcess(visitStateObj, modal_data, user), 
        onSuccessFunc: () => fetchLoggedUserVisits(user),
        actionName: "rej_canc_vis"}
       );
   }

    return (
        <Modal isOpen={true} size="m" className="no-border" centered>
            <h3 className="title-of-modal">{t("rej_req")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
              <Form>
                <Input type="select" name="p_cod_why" className="required" value={p_cod_why} onChange={e=> onChange(e)} >
                    <CustomSelectOption options={AppFunctionalityUsable.visitRejectionReasons} hasId={true}/>
                </Input>
              </Form>
            </ModalBody>
            <ModalFooter>
              <div className=" w-100 d-flex align-items-center justify-content-between">
                <div>
                  <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                </div>
                <div>
                  <Button name='submit' color="success" onClick={e => onSend(e)} className="mx-3"> {t('send')} </Button>
                </div>
              </div>
            </ModalFooter>
        </Modal>
    )
}

RejectModal.propTypes = {
    modal_data: PropTypes.object,
    toastErrorMessage: PropTypes.func.isRequired,
    user: PropTypes.object,
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    performRejectOrCancelVisitProcess: PropTypes.func.isRequired,
    open_modal: PropTypes.func.isRequired,
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data,
    user: state.auth.user,
})

const mapDispatchToProps = {
    close_modal,
    toastErrorMessage,
    performRejectOrCancelVisitProcess,
    fetchLoggedUserVisits,
    open_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal)
