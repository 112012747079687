import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { calculateNumberOfPages, doesArrayExist, getMomentFromString, getVisitStateIconColor, getWhoWasRejected, searchObjectInArrayByKeyVal, validateArrayParameter } from '@utils/utils'
import { NUMBER_REQUEST_PER_PAGE_MAIN_PANEL, MAIN_PANEL_VIEW_VISIT_MODAL } from '@utils/constants'
import { v4 } from 'uuid'
import { open_modal } from '@actions/admin';
import TableNavigation from '@components/MainPage/Shared/TableNavigation'

const PUBLIC_SERVICE_ICON = require('@resources/icons/public_service.svg');

const MainPanelVistsTable = ({visits, visitors,  open_modal, title}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        page: 1,
    })

    const {page} = state;
    const validatedVisits = validateArrayParameter(visits);

    // ::: NAVIGATION PARAMETERS
    let  totalPages =  calculateNumberOfPages(doesArrayExist(visits)? visits.length: 1, NUMBER_REQUEST_PER_PAGE_MAIN_PANEL);
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1});};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const setPage = (e, page) => {setState({...state, page })};

    // :: EVENT HANDLERS
    const onSelectVisit = (e, visit)=> { 
        const user = searchObjectInArrayByKeyVal(visitors, 'p_id', visit.p_id_x);
        user.toNotShow = true;
        open_modal(MAIN_PANEL_VIEW_VISIT_MODAL, [visit, user]);
    }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    let iconColor;
    const visitsToLoad = validatedVisits.filter((req, index) => (index >= ((page-1) * NUMBER_REQUEST_PER_PAGE_MAIN_PANEL)) && (index < (page * NUMBER_REQUEST_PER_PAGE_MAIN_PANEL)));
    const listOfVisits = visitsToLoad.map(visit => { 
        const rejected = getWhoWasRejected(visit, visitors);
        visit.rejected = rejected;
        iconColor = getVisitStateIconColor(visit);
        return (
            <tr key={v4()} className={`${doesArrayExist(rejected)? 'rej': ''} py-1`}  onClick={e => onSelectVisit(e, visit)}>
                <td className=" text-truncate pl-4"  data-toggle="tooltip" data-placement="top" title={`${visit.v_name}`} >
                    {visit.v_name}
                    {visit.sv_is_ps && <img alt="public_service_svg" className="float-right mr-4 mt-1" src={PUBLIC_SERVICE_ICON}/>}
                </td>
                <td>{visit.host_fname + ' ' + visit.host_lname}</td>
                <td>{getMomentFromString(visit.p_date_time).format('ddd, MMMM')}</td>
                <td>{getMomentFromString(visit.p_date_time).format('HH:mm')}</td>
                <td><span className={`material-icons md-16 mr-1 ${iconColor[1]}`}>{iconColor[0]}</span></td>
                <td>{visit.grouped.length? visit.grouped.length + 1: 1}</td>
                <td className="text-truncate"  data-toggle="tooltip" data-placement="top" title={`${visit.terminal}`}>{visit.terminal}</td>
            </tr>
        )
    });

    const _tableForVisitsView = <table className="striped-table default-line-height">
                                <thead>
                                    <tr>
                                        <th className="pl-4">{t('name')}</th>
                                        <th>{t('host')}</th>
                                        <th>{t('date')}</th>
                                        <th>{t('time')}</th>
                                        <th>{t('state')}</th>
                                        <th>{t('grouped')}</th>
                                        <th>{t('terminal')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listOfVisits}
                                </tbody>
                            </table>;

    const _noVisitsView = <p className="w-100 text-center py-4">{t('no_vis')}</p>;

    // ::: HTML CONDITIONALS 
    const isToShowVisitsTable = validatedVisits.length > 0
    
    // ::: CHILDREN PARAMETERS
    const componentData = {
        pageIndicators: indicators,
        totalPages,
        totalItems: doesArrayExist(visits)? visits.length: 1,
        page,
        next,
        prev,
        setPage
    }

    return (
            <div className="elevated-container table-container">
                {/* ::: TITLE OF TABLE */}
                <span className="pl-3 d-inline-block py-3">{title}</span>
                {/* ::: TABLE FOR THE VISITS */}
                {isToShowVisitsTable? _tableForVisitsView : _noVisitsView}
                {/* ::: TABLE NAVIGATION */}
                <TableNavigation componentData={componentData}/>
            </div>
    )
}

MainPanelVistsTable.propTypes = {
    open_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    open_modal
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPanelVistsTable)
