import CustomSelectOption from '@components/Shared/FormElements/CustomSelectOption'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import { DEFAULT_COUNTRY_CODE } from '@utils/constants'
import { isCAMemberUser, isCoreSecurityUser } from '@utils/utils'
import { fixNewUserFields, resolveUserCountryCode } from '@utils/validations'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, CustomInput, FormGroup, Input, Row } from 'reactstrap'

const GrantAccessSingleUserForm = ({loggedUser, singleUser, toastErrorMessage}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        countryCode: DEFAULT_COUNTRY_CODE,
        flag: false,
    });

    useEffect(() => { 
        fixNewUserFields(singleUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const {THREAD_CODE, CORE_SECURITY_CODE, GATE_KEEPER_CODE, COUNTRY_LIST} = getAppConfig();

    const {p_fname, p_lname, p_nid, p_id_company, p_id_department, 
        p_email, p_pnumber_1, p_pnumber_2, p_nationality, 
        p_id_type_id, p_expiration_date_id, p_id_role, country_code_p1, country_code_p2} = singleUser;
    
    const onChange = e => { 
        const nodeName = e.target.name;
        let nodeValue = e.target.value;
        try {
            singleUser[nodeName] = nodeValue;
            fixNewUserFields(singleUser); //::>> Throws Error if a field is invalid
            if(nodeName === 'p_nationality')
                resolveUserCountryCode(singleUser, nodeValue);
            setState({...state, user: singleUser});
        } catch (error) {
            singleUser[nodeName] = '';
            toastErrorMessage(error.message);
        }
        setState({...state, flag: !state.flag})
    }

    const onSelectIdPicture = async (e) => { 
        const file = e.target.files[0];
        singleUser['uid_data'] = file;
    }


    return (
        <Fragment>
            <Row form >
                <Col md={6}>
                    <FormGroup>
                        <Input type="name" name="p_fname" placeholder={t("fir_nam")} className="required" value={p_fname || ''}  onChange={e => onChange(e)} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input type="name" name="p_lname" placeholder={t("surname")} className="required" value={p_lname || ''} onChange={e => onChange(e)} />
                    </FormGroup>
                </Col>
            </Row>
            
            <Row form>
                <Col md={4}>
                    <FormGroup>
                        <Input type="select" name="p_nationality" placeholder={t("nationality")} className="required" value={p_nationality || ''} onChange={e => onChange(e)}>
                            <CustomSelectOption options={COUNTRY_LIST}  hasId={false}/>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Input type="select" name="p_id_type_id" placeholder={t("id_type")} className="required" value={p_id_type_id || ''} onChange={e => onChange(e)}>
                            <CustomSelectOption options={AppFunctionalityUsable.idDocumentTypes} hasId={true}/>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Input type="date" name="p_expiration_date_id" placeholder={t("exp_dat")} className="required" value={p_expiration_date_id || ''} onChange={e => onChange(e)} />
                        <small className="form-text text-muted">{t("exp_dat")}</small>
                    </FormGroup>
                </Col>
            </Row>

            <Row form>
                <Col md={8}>
                    <FormGroup>
                        <Input type="text" name="p_nid" placeholder={t("id")} className="required" value={p_nid || ''} onChange={e => onChange(e)}/>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <CustomInput id="fileInputRegistrationUser"  type="file" name="p_photo_id" placeholder={t("nationality")} onChange={e => onSelectIdPicture(e)} />
                        <small className="form-text text-muted">{t("pho_doc")}</small>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Input type="text" name="p_email" placeholder={t("email")}  value={p_email || ''} onChange={e => onChange(e)}/>
                    </FormGroup>
                </Col>
            </Row>

            <Row form>
                <Col md={6}>
                    <FormGroup className="d-flex">
                        <Input type="tel" name="country_code_p1" value={country_code_p1} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                        <Input type="tel"  name="p_pnumber_1" placeholder={t("cel_num")} className="required-r"  value={p_pnumber_1 || ''} onChange={e => onChange(e)} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup className="d-flex">
                        <Input type="tel" name="country_code_p2" value={country_code_p2} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                        <Input type="tel" name="p_pnumber_2" placeholder={t("eme_cel_num")} className="required-r" value={p_pnumber_2 || ''} onChange={e => onChange(e)} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                {(isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser)) && <Col md={6}>
                                        <Input type="select" name="p_id_company" className="required" value={p_id_company} onChange={e => onChange(e)}>
                                            <CustomSelectOption options={AppFunctionalityUsable.terminals} hasId={true}/>
                                        </Input>
                                    </Col >
                }
                
                <Col md={6}>
                    <Input type="select" name="p_id_department" className="required" disabled={(p_id_role === THREAD_CODE) || (p_id_role === CORE_SECURITY_CODE) || (p_id_role === GATE_KEEPER_CODE)} value={p_id_department} onChange={e => onChange(e)}>
                        <CustomSelectOption options={AppFunctionalityUsable.departments} hasId={true}/>
                    </Input>
                </Col>
            </Row>
        </Fragment>
    )
}

export default GrantAccessSingleUserForm
