
import { isEmptyArray } from '@utils/utils';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'reactstrap/lib/CustomInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import ListGroup from 'reactstrap/lib/ListGroup';
import { v4 } from 'uuid';

const ChooseVisitorForm = ({loggedUser, visit, filterServiceProviderWorkers, addServiceProviderWorkerToVisitToggle, serviceProviderWorkers}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
        searchValue: '',
        flag: false,
    })

    const {searchValue,} = state;

    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;

        if (nodeName === 'search') {
            filterServiceProviderWorkers('p_fname', nodeValue);
            setState({...state, searchValue: nodeValue});
        } else {
            setState({...state, [nodeName]: nodeValue});
        }
    }

    const onAddVisitorToggle = (e, visitor) => { 
        addServiceProviderWorkerToVisitToggle(visit, visitor);
        setState({...state, flag: !state.flag});
    }

    return (
        <Fragment>
            <FormGroup>
                <FormGroup>
                    <Input type="text" name="search" value={searchValue} placeholder={t('search')} onChange={e => onChange(e)} />
                </FormGroup>
                {!isEmptyArray(serviceProviderWorkers) && (
                    <FormGroup>
                        <ListGroup>
                            {serviceProviderWorkers.map((employee) => {
                                return (
                                    <li key={ v4()} className="list-group-item list-group-item-action d-flex w-100" onClick={e => onAddVisitorToggle(e,employee)}>
                                        <div className="col-4 w-100 d-inline ">
                                            <span className="-span mr-1" name={employee.p_fname}> {`${employee.p_fname} ${employee.p_lname}`}</span>
                                        </div>
                                        <div className="col-8 w-100  d-inline">
                                        <div className="d-flex flex-row-reverse">
                                            <CustomInput type="checkbox" id={`userRadioSelect${employee.p_id}`} checked={visit.id_users.includes(employee.p_id)} onChange={e => function(){}}  />
                                        </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ListGroup>
                    </FormGroup>
                )}
            </FormGroup>
        </Fragment>
    )
}

export default ChooseVisitorForm;
