import { isEmptyArray, isNotEmptyArray, removeAllSpaces, validateArrayParameter } from '@utils/utils';
import React from 'react'
import { useTranslation } from 'react-i18next';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { v4 } from 'uuid';

const DataListFormForUsers = ({elements, inputFieldName, inputValue, toShow, onAction}) => {
    let dropDownItems = validateArrayParameter(elements);
    const {t} = useTranslation('request');
    dropDownItems = dropDownItems.filter(item => { 
        const userName = `${item.fname}${item.lname}`;
        const inputName = removeAllSpaces(inputValue);
        return userName.toLowerCase().startsWith(inputName.toLowerCase());
    });
    const domEle = document.getElementsByName(inputFieldName)[0];
    const isActive = (document.activeElement === domEle);

    return (
        <Dropdown direction='down' className="hidden-dropdown-container" isOpen={toShow && isActive} toggle={e => {}} >
            <DropdownToggle className="custom-dropdown hidden-dropdown">
            </DropdownToggle>
            <DropdownMenu right>
                {isNotEmptyArray(dropDownItems) &&  <DropdownItem header>{t('sel_use')}</DropdownItem>}
                {isEmptyArray(dropDownItems) && <DropdownItem header>{t('no_use')}</DropdownItem>}
               
                {dropDownItems.map( item => { 
                    const name = `${item.fname} ${item.lname}`;
                    return <DropdownItem key={v4()} onClick={e => onAction(e, name)}>{name}</DropdownItem>
                })}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DataListFormForUsers;