import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { clear_induction_questions, get_induction_questions, submit_induction_result } from '@actions/admin';
import { getTokenUser } from '@actions/auth';
import { clear_user_induction_answer } from '@actions/admin';
import { setMessage, toastErrorMessage, toastSuccessMessage } from '@actions/toast';
import { DEEP_INDUCTION_GROUP, DEEP_INDUCTION_GROUP_EN, ERROR_TOAST, ROOT_URL} from '@utils/constants';
import {getAppConfig} from '@utils/configurations'
import { doesVariableExist, isEmptyObject, validateArrayParameter } from '@utils/utils';
import {getServerConfig} from '@utils/configurations';
import InductionRadioGroupAnswer from './InductionRadioGroupAnswer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { Fragment } from 'react';
import NavigationBar from '@components/Shared/Navbar';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import { v4 } from 'uuid';
import { UserCheck } from 'react-feather';
import { Redirect } from 'react-router';



const  InductionPage = ({match, flag, toastSuccessMessage, token_user,getTokenUser,submit_induction_result,toastErrorMessage}) => {
    getServerConfig();
    const { MAX_SEEK_TIME, MIN_SEEK_TIME, VIDEO_MIN_ACCEPTED_PERCENTAGE_TO_SEE, MIN_POINTS_PER_INDUCTION} = getAppConfig();
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        canSubmit: false,
        seeked: false,
        returnToLogin: false,
        sent: false,
        inductionGroup: DEEP_INDUCTION_GROUP,
        indQuestions: [],
        typeInduction: 0,
        isToShowQuestions: false,
    });
    
    const {canSubmit,typeInduction, isToShowQuestions,  seeked, sent, returnToLogin, inductionGroup, indQuestions} = state;
    let linkToken = match.params.token;
    linkToken = linkToken? linkToken.split("_"): [];
    const token = linkToken[0];
    const sVisit = linkToken[1];

    useEffect(() => { 
        const fetch = async () => { 
            await AppFunctionalityUsable.getInductionQuestions(false, typeInduction);
            setState({...state, indQuestions: AppFunctionalityUsable.getInductionQuestionsToAnswer(typeInduction)});
        }
        if (isToShowQuestions) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeInduction, isToShowQuestions])

    useEffect(() => {
        AppFunctionalityUsable.getInductionTypes();
    }, [])
 
   
    if(!doesVariableExist(token_user) && doesVariableExist(token)) getTokenUser(token); 
    if(!token || sent || returnToLogin) return <Redirect to="/login/"/>; 

    // ::: Event handlers
    const selectInductionLanguage = (e, value) => {
        const inductionLanguageGroup = e.target.getAttribute('name');
        setState({...state, inductionGroup: inductionLanguageGroup, indQuestions: AppFunctionalityUsable.getInductionQuestionsToAnswer(value)});
    }

    const selectTypeInduction = (e, val) => {
        setState({...state, typeInduction: val, isToShowQuestions: true});
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(isEmptyObject(token_user)){
            toastErrorMessage(t('inv_tok'));
            setState({...state, returnToLogin: true, inductionGroup: inductionGroup})
           
        } else { 
            let answeredAllQuestions = true;
            indQuestions.forEach(qst => { 
                if (isEmptyObject(qst.selectedOption)) 
                    answeredAllQuestions = false;
            });

            if (!answeredAllQuestions) 
                toastErrorMessage(t('rep_to_all'));
            else { 
                const inductionUserData = {p_id_x: token_user.p_id,p_tokken: token, p_id_svisit: sVisit, p_id_extra: typeInduction, minPointsToPass: MIN_POINTS_PER_INDUCTION}
                submit_induction_result({inductionUserData, inductionQuestionsResult: indQuestions});
                toastSuccessMessage(t('sub_ans'));
                setState({...state, sent: true, inductionGroup: inductionGroup});
            }
        }
    }

    const onProgress = (pState) => {
        const percentagePlayed = pState.played * 100;
        if((percentagePlayed > VIDEO_MIN_ACCEPTED_PERCENTAGE_TO_SEE) && !seeked ){
            setState({...state, canSubmit: true, inductionGroup: inductionGroup});
        }
    }

    const onSeek = seekVal => {
       if(seekVal > MAX_SEEK_TIME){
           setState({...state, seeked: true, inductionGroup: inductionGroup });
           setMessage(t('adv_vid_err'), ERROR_TOAST);
           return;
       }

       if(seekVal <= MIN_SEEK_TIME){ 
            setState({...state, seeked: false, inductionGroup: inductionGroup });
            return;
       }

    }

    // ::: TYPE OF VIDEO TO LOAD SELECTION
    const IS_SUBMIT_BUTTON_DISABLED = canSubmit? '': 'disabled';
    const LOADING_GIF = require('@resources/icons/loading.gif');
    let induction_video = `${ROOT_URL}/files/videos/inductionVideo/INDUCTION_VISITORS`;
    if( typeInduction === 3181 ) { 
        induction_video = `${ROOT_URL}/files/videos/inductionVideo/INDUCTION_OP`;
    } else if ( typeInduction === 157 ) { 
        induction_video = `${ROOT_URL}/files/videos/inductionVideo/INDUCTION_TRUCKS`;
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const validatedIndQuestion = validateArrayParameter(indQuestions);
    const questions = validatedIndQuestion.map((question, index) => {
            return <div key={v4()} className="custom-card d-flex px-3 py-4 my-4 flex-column">
                        <InductionRadioGroupAnswer key={index} questNum={index+1} name={question.id} indQuestion={question} />
                    </div>
        });

    
    const _typeOfQuestionsListView = AppFunctionalityUsable.inductionTypes.map( (inductionType, index) => {
                                            return <ListGroupItem onClick={e => selectTypeInduction(e, inductionType.code)}  active={index === 0} className={`${index === 0? "text-center": ""}`} key={v4()} action>
                                                        {(index !== 0) && <UserCheck size="20px" className="mr-3" />}
                                                        {inductionType.name}
                                                    </ListGroupItem>
                                        })

    const _typeQuestionsSelectionView = <ListGroup className="w-50">
                                            {_typeOfQuestionsListView}
                                        </ListGroup>

    const _questionsToAnswerView = <Fragment>
                                        <h2 className="px-4 text-center mt-5">{t('quiz')}</h2>
                                        <div className="d-flex justify-content-center">
                                            <ul className="list-group list-group-horizontal">
                                                <li className={`list-group-item list-group-item-action ${inductionGroup === DEEP_INDUCTION_GROUP? 'active': ''}`} name={DEEP_INDUCTION_GROUP} onClick={e => selectInductionLanguage(e, typeInduction)}>PT</li>
                                                <li className={`list-group-item list-group-item-action ${inductionGroup === DEEP_INDUCTION_GROUP_EN? 'active': ''}`} name={DEEP_INDUCTION_GROUP_EN} onClick={e => selectInductionLanguage(e, typeInduction + 1)} >EN</li>
                                            </ul>
                                        </div>
                                        <div className="d-flex flex-column  align-items-center ">
                                            <div>
                                                {questions || <img alt="loading" className="my-4" src={LOADING_GIF}/>}
                                            </div>
                                            <div className=" my-2">
                                                <button  className={`btn btn-success`} disabled={false && IS_SUBMIT_BUTTON_DISABLED}  onClick={e => onSubmit(e)}>Submit</button>
                                            </div>
                                        </div> 
                                    </Fragment>;

    const _videoPlayerOfQuestions = <ReactPlayer 
                                        className="react-player text-center"
                                        url={induction_video}
                                        controls={true}
                                        onSeek= {e => onSeek(e)}
                                        onProgress={state => onProgress(state)}
                                        />;
    
    return (
        <div className="container-front-page">
            <NavigationBar/>
            <div className="main-content overflow-y">
                <h2 className="px-4 py-4 text-center">{t('typ_ind')}</h2>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {!isToShowQuestions && _typeQuestionsSelectionView}
                    {isToShowQuestions && _videoPlayerOfQuestions}
                </div>
                {isToShowQuestions && _questionsToAnswerView}
            </div>
        </div>
    )
}

InductionPage.propTypes = {
    induction_answers: PropTypes.object,
    toastErrorMessage: PropTypes.func.isRequired,
    clear_induction_questions: PropTypes.func.isRequired,
    induction_gabarito: PropTypes.object,
    match: PropTypes.object,
    clear_user_induction_answer: PropTypes.func.isRequired,
    get_induction_questions: PropTypes.func.isRequired,
    submit_induction_result: PropTypes.func.isRequired,
    induction_questions: PropTypes.array,
    flag: PropTypes.bool,
    token_user: PropTypes.object,
    getTokenUser: PropTypes.func.isRequired,
    toastSuccessMessage: PropTypes.func.isRequired,
    
}

const mapStateToProps = state => ({
    induction_answers: state.admin.induction_answers,
    induction_questions: state.admin.NewInductionQuestions,
    induction_gabarito: state.admin.inductionAnswers,
    token_user: state.auth.token_user,
    flag: state.admin.flag
})

export default connect(mapStateToProps, {toastErrorMessage, toastSuccessMessage, get_induction_questions,submit_induction_result, getTokenUser, clear_induction_questions, clear_user_induction_answer})(InductionPage);
