import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin';
import { Button, CustomInput, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { getAppConfig } from '@utils/configurations'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { performUserDataUpdateProcess } from '@actions/auth';
import { useState } from 'react';

const UserSettingsTabEditModal = ({modal_data,performUserDataUpdateProcess, flag, close_modal}) => {
    const { t } = useTranslation('settings');
    const [state, setState] = useState({
        user: {}
    });
    const {HOST_CODE, THREAD_CODE, CORE_SECURITY_CODE, GATE_KEEPER_CODE, CA_MEMBER_CODE} = getAppConfig();

    // ::: EVENT HANDLERS
    const onUpdate = (e) => { 
        performUserDataUpdateProcess(modal_data, {verifyPassword: false});
        close_modal();
    }

    const onChange = (e) => { 
        const nodeName = e.target.name;
        if(nodeName === 'isHost')
            modal_data['p_id_role'] = HOST_CODE;
        else if(nodeName === 'isThread')
            modal_data['p_id_role'] = THREAD_CODE;
        else if(nodeName === 'isCoreSec')
            modal_data['p_id_role'] = CORE_SECURITY_CODE;
        else if(nodeName === 'isGateKeeper')
            modal_data['p_id_role'] = GATE_KEEPER_CODE;
        else if(nodeName === 'isCaMember')
            modal_data['p_id_role'] = CA_MEMBER_CODE;
        
        setState({...state, user: modal_data});
    }

    return (
        <Modal isOpen={true} size="m" className="no-border" centered>
            <h3 className="title-of-modal">{modal_data.p_fname + ' ' + modal_data.p_lname}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form>
                    <FormGroup>
                        {/* ::: DEPARTMENT SELECTION */}
                        <Input
                            type="select"
                            name="p_id_department"
                            value={modal_data.p_id_department}
                            className="required"
                            onChange = {e => {modal_data[e.target.name] = e.target.value; onChange(e)}}
                            placeholder={t("department")}>
                            <CustomSelectOption options={AppFunctionalityUsable.departments} hasId={true}/>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        {/* ::: COMPANY SELECTION */}
                        <Input
                            type="select"
                            name="p_id_company"
                            value={modal_data.p_id_company}
                            className="required"
                            onChange = {e => {modal_data[e.target.name] = e.target.value; onChange(e)}}
                            placeholder={t("terminal")}>
                            <CustomSelectOption options={AppFunctionalityUsable.terminals} hasId={true} isToFilter={false} />
                        </Input>
                    </FormGroup>
                    {/* ::: TYPE OF USER SELECTION */}
                    <FormGroup>
                        <CustomInput type="checkbox" id="isHost" name="isHost" checked={+modal_data.p_id_role === HOST_CODE} label={t("to_host")} onChange={e => onChange(e)}/>
                        <CustomInput type="checkbox" id="isThread" name="isThread" checked={+modal_data.p_id_role === THREAD_CODE} label={t("to_thread")} onChange={e => onChange(e)}/>
                        <CustomInput type="checkbox" id="isCoreSec" name="isCoreSec" checked={+modal_data.p_id_role === CORE_SECURITY_CODE} label={t("to_core_sec")} onChange={e => onChange(e)}/>
                        <CustomInput type="checkbox" id="isCaMember" name="isCaMember" checked={+modal_data.p_id_role === CA_MEMBER_CODE} label={t("to_ca_member")} onChange={e => onChange(e)}/>
                        <CustomInput type="checkbox" id="isGateKeeper" name="isGateKeeper" checked={+modal_data.p_id_role === GATE_KEEPER_CODE} label={t("to_gat_keeper")} onChange={e => onChange(e)}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className=" w-100 d-flex align-items-center justify-content-between">
                    <Button color="secondary" onClick={e => close_modal()}> {t("cancel")} </Button>
                    <Button name='submit' color={`success`} onClick={e => onUpdate(e)} className="mx-3"> {t('save')} </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

UserSettingsTabEditModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
    performUserDataUpdateProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
    performUserDataUpdateProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsTabEditModal)
