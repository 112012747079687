import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, FormGroup, Row, Input, Col, CustomInput, Button, ModalFooter } from 'reactstrap'
import { ROOT_URL } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption'
import { getInputDateFormatFromString, isEmptyObject, isHostUser } from '@utils/utils'
import { performUserDataUpdateProcess } from '@actions/auth'
import { close_modal } from '@actions/admin'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import Label from 'reactstrap/lib/Label'

const MyAccountModal = ({modal, loggedUser, close_modal, performUserDataUpdateProcess}) => {
    const {t} = useTranslation('auth');
    const { COUNTRY_LIST } = getAppConfig();
    const [state, setState] = useState({
        nid: '',
        user: {},
    })

    const {p_fname,p_lname,p_nationality,p_isnotification, p_id_type_id,p_expiration_date_id, p_nid,p_pnumber_1,p_pnumber_2, p_email, p_psw_2, p_psw } = loggedUser || {};
    const {nid} = state;

    useEffect(() => { 
        if(!isEmptyObject(loggedUser)){
            loggedUser["p_old_id"] = loggedUser.p_nid;
            loggedUser.p_psw = '';
            loggedUser.p_psw_2 = '';
            setState({...state, nid: loggedUser.p_nid})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    // ::: EVENT HANDLERS
    const onChange = e => { 
        let nodeName = e.target.name;
        nodeName = nodeName ? nodeName : e.target.getAttribute("name");
        const targetValue = e.target.value;
        loggedUser[nodeName] = targetValue;
        setState({...state, user: loggedUser});
    }

    const onChangeNotification = (e, value) => { 
        loggedUser.p_isnotification = value? 1:0;
        setState({...state, user: loggedUser});
    }

    const onClose = e => {  
        loggedUser.hasUpdatedPassword = 0;
        close_modal();
    }

    const onFileSelect = e => {
        const file = e.target.files[0];
        loggedUser['uid_data'] = file;
      };

    const onSubmit = async e => { 
        e.preventDefault();
        await performUserDataUpdateProcess(loggedUser);
        onClose();        
    }

    return (
        <Modal isOpen={true} size="lg" className="no-border" centered> 
            {/* ::: TITLE */}
            <h2 className="title-of-modal">{t("my_acc")}</h2>
            <ModalBody className="paged-form-container px-5">
                <Form>
                    {/* ::: SUBTITLE */}
                    <h5 className="text-center my-4">{t("per_data")}</h5>
                    <div className="d-flex justify-content-center my-3">
                        <img src={`${ROOT_URL}/files/uId/${nid}`} alt="id_picture" style={{maxWidth: "400px", maxHeight: "400px"}}/>
                    </div>
                    <FormGroup>
                        <FormGroup>
                            <Row form>
                                {/* ::: FIRST NAME INPUT */}
                                <Col md={6}>
                                    <Input type="text" name="p_fname" value={p_fname} onChange={(e) => onChange(e)} className="required" placeholder={t("first_name")}></Input>
                                </Col> 
                                {/* ::: LAST NAME INPUT */}
                                <Col md={6}>
                                    <Input type="text" name="p_lname" value={p_lname} onChange={(e) => onChange(e)} className="required" placeholder={t("surname")}></Input>
                                </Col> 
                            </Row>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Row form>
                            {/* ::: NATIONALITY SELECT INPUT */}
                            <Col md={4}>
                                <Input type="select" name="p_nationality" value={p_nationality} onChange={(e) => onChange(e)} className="required" placeholder={t("nacionality")} >
                                    <CustomSelectOption options={COUNTRY_LIST} hasId={false}/>
                                </Input>
                            </Col>
                            {/* ::: TYPE ID SELECT INPUT */}
                            <Col md={4}>
                                <Input type="select" name="p_id_type_id" value={p_id_type_id} onChange={(e) => onChange(e)} className="required"  >
                                    <CustomSelectOption options={AppFunctionalityUsable.idDocumentTypes} hasId={true}/>
                                </Input>
                            </Col>
                            {/* ::: EXPIRATION DATE DATE PICKER INPUT */}
                            <Col md={4}>
                                <Input type="date" id="expiration_date" name="p_expiration_date_id" value={getInputDateFormatFromString(p_expiration_date_id)} onChange={(e) => onChange(e)} className="required"/>
                                <small className="form-text text-muted">{t("exp_date")}</small>
                            </Col>
                            {/* ::: ID NUMBER INPUT */}
                            <Col md={8}>
                                <Input type="text"  name="p_nid" value={p_nid} onChange={(e) => onChange(e)} className="required" placeholder={t("id")}/>
                            </Col>
                            {/* ::: ID PHOTO FILE INPUT */}
                            <Col md={4}>
                                <CustomInput type="file" id="fileInput" name="p_photo_id" placeholder={t("id")} onChange={(e) => onFileSelect(e)} />
                                <small className="form-text text-muted">{t("pho_doc")}</small>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className="separator transparent my-3" />
                    <FormGroup>
                        <Row form>
                            {/* ::: FIRST NUMBER INPUT */}
                            <Col md={6}>
                                <Input type="tel" name="p_pnumber_1" value={p_pnumber_1} onChange={(e) => onChange(e)} className="required" placeholder={t("cel_num")}></Input>
                            </Col> 
                            {/* ::: EMERGENCY NUMBER INPUT */}
                            <Col md={6}>
                                <Input type="tel" name="p_pnumber_2" value={p_pnumber_2} onChange={(e) => onChange(e)} className="required" placeholder={t("eme_num")}></Input>
                            </Col> 
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        {/* ::: EMAIL INPUT */}
                        <FormGroup>
                            <Input type="email" name="p_email" value={p_email} placeholder={t("email")}onChange={(e) => onChange(e)}/>
                        </FormGroup>
                        {/* ::: ENALBLE/DISABLE NOTIFICATION */}
                        {isHostUser(loggedUser) && <FormGroup check inline>
                            <Label check>
                            <CustomInput id="chec13" label={t("ena_not")} checked={p_isnotification} type="checkbox" onChange={(e) => onChangeNotification(e, !p_isnotification)} /> 
                            </Label>
                        </FormGroup>}
                        <div className="separator transparent my-3" />
                        {/* ::: NEW PASSWORD INPUT */}
                        <FormGroup>
                            <Input type="password"  onChange={(e) => onChange(e)} className="required"  name="p_psw"  value={p_psw} placeholder={t("password")} />
                        </FormGroup>
                        {/* ::: CONFIRM PASSWORD INPUT */}
                        <FormGroup>
                            <Input type="password" name="p_psw_2" value={p_psw_2} onChange={(e) => onChange(e)} className="required" placeholder={t("confirm_password")} />
                        </FormGroup>
                    </FormGroup>
                </Form>
            </ModalBody>
            {/* ::: FORM BUTTONS */}
            <ModalFooter>
                <div className=" w-100 d-flex align-items-center justify-content-between">
                    <div>
                        <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                    </div>
                    <div>
                        <Button name='submit' color="success" onClick={e => onSubmit(e)} className="mx-3"> {t('update')} </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

MyAccountModal.propTypes = {
    close_modal: PropTypes.func.isRequired,
    user: PropTypes.object,
    performUserDataUpdateProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loggedUser: state.auth.user,
})

const mapDispatchToProps = {
    close_modal,
    performUserDataUpdateProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountModal)
