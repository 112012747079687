import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CompanyDataForm from './CompanyDataForm'
import { toastErrorMessage } from '@actions/toast'
import { changeIsRegisteredStoreFlag, linkRegistrationToCoreSecurity, performCompanyRegistrationProcess, getNewCompanyObject, getNewCompanyUserObject } from '@actions/auth'
import UserAccountDataForm from '@sharedComponents/Auth/UserAccountDataForm'
import UserPersonalDataForm from '@sharedComponents/Auth/UserPersonalDataForm'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import NavigationBar from '@components/Shared/Navbar'

const CompanyRegistrationPage = ({match, isRegistered, toastErrorMessage, linkRegistrationToCoreSecurity, getNewCompanyUserObject, getNewCompanyObject, changeIsRegisteredStoreFlag, performCompanyRegistrationProcess}) => {
    const { t } = useTranslation('auth');
    const [state, setState] = useState({
        page: 0,
        num_pag: 2,
        isValidUser: true,
        user: getNewCompanyUserObject({}),
        company: getNewCompanyObject({}),
    });

    const {page, num_pag, user, company, isValidUser} = state;

    useEffect(() => { 
        AppFunctionalityUsable.getIdDocumentTypes();
        AppFunctionalityUsable.getVisitorProvenance();
    }, []);

    const token = match.params.token;
    useEffect(() => {

        const verify = async () => { 
            try {
                await linkRegistrationToCoreSecurity(user, token);
             } catch (error) {
                toastErrorMessage(error.message);
                setState({...state, isValidUser: false});
             }
        }
        
        verify();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    

    if (!isValidUser) return <Redirect to="/login"/>;
    if (isRegistered) { 
        changeIsRegisteredStoreFlag(false);
        return <Redirect to="/" /> 
    }; 


    const next = e => { 
        e.preventDefault();
        setState({...state, page: page >= num_pag? num_pag : page + 1});
    }
    const prev = e => {
        setState({...state, page: page <= 0? 0:page - 1});
    }

    const onSubmit = e => { 
        e.preventDefault();
        performCompanyRegistrationProcess(user, company);
    }

    const onExitForm = () => { 
        AppFunctionalityUsable.clearAllFuncUsables();
    }

    const NEXT_BUTTON_TEXT = (page >= num_pag)? t('submit'):t('next');
    const PREVIOUS_BUTTON_TEXT = t('previous');
    const IS_PREVIOUS_BUTTON_DISABLED = page <= 0;

    return (
        <div className="container-front-page">
        <NavigationBar />
        <main className="main-content auth-container new-request-container">
            <div className="form-container paged-form-container">
              {/* :: FORM */}
              <form className="auth-form paged-form dark-form">
                    {/* :: COMPANY REGISTRATION PAGE 01 */}
                    {page === 0 && <UserPersonalDataForm newUser={user} />}
                    {/* :: COMPANY REGISTRATION PAGE 02 */}
                    {page === 1 && <UserAccountDataForm newUser={user} forCompany={true} />}
                    {/* :: COMPANY REGISTRATION PAGE 03 */}
                    {page === 2 && <CompanyDataForm newUser={user} newCompany={company} />}
                    {/* :: PAGE INDICATORS */}
                    <div className="page-indicators-container">
                        <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                        <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                        <span className={`page-indicator ${page === 2 ? "active" : ""}`}></span>
                    </div>
                    {/* :: FORM BUTTONS */}
                    <div className="form-buttons clearfix">
                        <Link type="button" to="/login" className="button link-button left-sided-button" onClick={e => onExitForm(e)}>{t('cancel')}</Link>
                        <button type="submit" className="button dark-button submit-button right-sided-button" onClick={e => (page >= num_pag)? onSubmit(e):next(e)}>{NEXT_BUTTON_TEXT}</button>
                        <button type="button" className={`${IS_PREVIOUS_BUTTON_DISABLED? 'disabled-dark-button':''} button link-button text-no-underline mr-3 right-sided-button`} disabled={IS_PREVIOUS_BUTTON_DISABLED} onClick={e => prev(e)} >{PREVIOUS_BUTTON_TEXT}</button>
                    </div>
              </form>
            </div>
        </main>
        </div>
    )
}

CompanyRegistrationPage.propTypes = {
    linkRegistrationToCoreSecurity: PropTypes.func.isRequired,
    getNewCompanyUserObject: PropTypes.func.isRequired,
    getNewCompanyObject: PropTypes.func.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    changeIsRegisteredStoreFlag: PropTypes.func.isRequired,
    performCompanyRegistrationProcess: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    isRegistered: state.auth.isRegistered,
})

const mapDispatchToProps = {
    toastErrorMessage,
    linkRegistrationToCoreSecurity,
    getNewCompanyUserObject,
    performCompanyRegistrationProcess,
    changeIsRegisteredStoreFlag,
    getNewCompanyObject,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegistrationPage)
