import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bell, SkipBack, SkipForward } from 'react-feather'
import { getIconColorEquiv, getIconEquiv, getMomentFromString, isServiceProviderUser, isVisitorUser, validateArrayParameter } from '@utils/utils'
import { useTranslation } from 'react-i18next'
import { CARD_VIEW_CARD_TRANSLATION, CORE_SEC, EDIT_BTN_MESSAGE, HOST, THREAD } from '@utils/constants'
import { buildVisitsTableUtilsObject } from '@utils/visitsDisplay'
import { Fragment } from 'react'
import { forceNotificationToUser } from '@actions/visits'

const VisitorsVisitsCardView = ({loggedUser, visits, actionsForChildren, forceNotificationToUser}) => {
    const {t} = useTranslation('main');
    const [state, setState] = useState({
        currentCardIndex: 0,
    })
    const allVisits = validateArrayParameter(visits);
    const {updateVisitData, onSelectVisitVisitor, onClickAcceptReject, onClickToUpdateVisit} = actionsForChildren;
    const {currentCardIndex} = state;

    useEffect(() => { 
        setCardsXTranslation(currentCardIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [visits]);

    // :: EVENT HANDLERS
    // ::: NAVIGATION FUNCTIONS
    const onClickNext = () => { 
        const hasNoVisits = visits.length === 0;
        const isLastCard = currentCardIndex === (visits.length - 1);
        const nextCardIndex = currentCardIndex + 1;
        if(isLastCard || hasNoVisits) return;
        setCardsXTranslation(nextCardIndex);
        setState({...state, currentCardIndex: nextCardIndex});
    };
    
    const onClickPrev = () => { 
        const isFirstCardIndex = currentCardIndex === 0;
        const prevCardIndex = currentCardIndex - 1;
        if (isFirstCardIndex) return;
        setCardsXTranslation(prevCardIndex);
        setState({...state, currentCardIndex: prevCardIndex})
    };

    const setCardsXTranslation = (currentCardIndex) => { 
        const cards = document.querySelectorAll(".card");
        cards.forEach((card, index) => { 
            const indexDif = index - currentCardIndex;
            const xTranslation = CARD_VIEW_CARD_TRANSLATION * indexDif;
            card.style.transform = `translateX(${xTranslation}%)`;
            if(xTranslation !== 0)
                card.style.opacity = "0.5";
            else 
                card.style.opacity = "1";
        });
    }

    // :::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const visitCards = allVisits.map((visit, index) =>{ 
        const isInternalRequest = visit.p_internal === 1;
        const canSelectVisitors = isServiceProviderUser(loggedUser) && isInternalRequest;
        const isFirstCard = index === 0;
        const cardPositionStyle = isFirstCard? '':'';
        const cardStyle = {transform: `translateX(${CARD_VIEW_CARD_TRANSLATION * index}%)`};

        const tableUtilsFlagsAndMessages = buildVisitsTableUtilsObject(visit, loggedUser);
        const {acceptBtnColor, rejectBtnColor, editBtnColor, isMyVisit, acceptButtonString, rejectBtnString, canEdit } = tableUtilsFlagsAndMessages;

        const isToShowVisitorsActions = isVisitorUser(loggedUser);
        const isToShowHostActions = !isToShowVisitorsActions;
        const isToShowEditButton = canEdit || isMyVisit;
        
        // ::: HTML ELEMENTS
        const _visitorsActions = <a href="#nowhere" className="card-link" onClick={e => updateVisitData(visit)}>Edit</a>
        const _hostActions = <Fragment>
                                <span name="accept" className={`faded-button ${acceptBtnColor} rounded-sm px-2 py-1 mr-1`} onClick={e => onClickAcceptReject(e, visit)}>{t(acceptButtonString)}</span>
                                <span name="reject" className={`faded-button min-30 ${rejectBtnColor} text-thin rounded-sm px-2 py-1 mr-1`} onClick={e => onClickAcceptReject(e, visit)}>{t(rejectBtnString)}</span>
                                {isToShowEditButton && <span className={`faded-button w-20 ${editBtnColor} rounded-sm px-2 py-1 mr`} onClick={e => onClickToUpdateVisit(visit, canEdit)}>{t(EDIT_BTN_MESSAGE)}</span>}
                                <Bell size="18px" onClick={e => forceNotificationToUser(visit, loggedUser)} className="ml-5"/>
                            </Fragment>;
        return <div key={index} className={`card slide-item ${cardPositionStyle}`} style={cardStyle}>
                    <div className="card-body">
                        <h5 className="card-title">{`${t('to_dest')}: ${visit.host_fname} ${visit.host_lname}`}</h5>
                        <p className="card-text text-truncate w-100">{visit.p_detail}</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><span className="lable text-left">{t('terminal')}</span> <span className="content float-right">{`${visit.terminal} ${visit.department}`}</span></li>
                        <li className="list-group-item"><span className="lable text-left">{t('dat_tim')}</span> <span className="content float-right">{`${getMomentFromString(visit.p_date_time).format('ddd, MMMM Do HH:mm')}`}</span></li>
                        <li className="list-group-item"><span className="lable text-left">{t('vis_cod')}</span> <span className="content float-right">{`${visit.p_code}`}</span></li>
                        <li className="list-group-item"><span className="lable text-left">{t('gate')}</span> <span className="content float-right">{`${visit.gate}`}</span></li>
                        <li className="list-group-item"><span className="lable text-left">{t('state')}</span> <span className="content float-right">                                                                                                          
                                                                                                            <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[HOST])}`} >{getIconEquiv(visit.states[HOST])}</span>
                                                                                                            <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[THREAD])}`} >{getIconEquiv(visit.states[THREAD])}</span>
                                                                                                            <span className={`material-icons md-16 mr-1 ${getIconColorEquiv(visit.states[CORE_SEC])}`} >{getIconEquiv(visit.states[CORE_SEC])}</span>
                                                                                                            {!!canSelectVisitors && <span className={`default-button`} onClick={e => onSelectVisitVisitor(e, visit)}>{t('sel_vis')}</span>}
                                                                                                        </span>
                    </li>
                    </ul>
                    <div className="card-body">
                        {!!isToShowVisitorsActions && _visitorsActions}
                        {!!isToShowHostActions && _hostActions}

                    </div>
                </div>
        
    })
    // ::::: HTML USED VARS
    const totalElements = allVisits.length;

    return (
        <div className="card-display-container"> 
                    <h5 className="text-bold mb-3">My Requests</h5>
                    <div className="card-slide-container">
                        {visitCards}
                    </div>
                    <div className="card-navigation">
                        <div className="prev-card-button" onClick={e => onClickPrev()}>
                            <SkipBack/>
                        </div>
                        <span>{`${currentCardIndex + 1} ${t('of')} ${totalElements}`}</span>
                        <div className="next-card-button" onClick={e => onClickNext()}>
                            <SkipForward/>
                        </div>
                    </div>
                </div>
    )
}

VisitorsVisitsCardView.propTypes = {
    forceNotificationToUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    forceNotificationToUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorsVisitsCardView)
