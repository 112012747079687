import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {close_modal} from '@actions/admin'
import { Modal, ModalBody, Container, Button, ModalFooter, Badge } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { doesArrayExist, getMomentFromString, isNotEmptyValue, validateArrayParameter } from '@utils/utils'
import { v4 } from 'uuid'

const HOST_ICON = require(`@resources/icons/host.svg`);
const DATE_ICON = require(`@resources/icons/date.svg`);
const CODE_ICON = require(`@resources/icons/code.svg`);
const GATE_ICON = require(`@resources/icons/gate.svg`);

const ViewUserRejectedModal = ({modal_data,close_modal}) => {
    const {t} = useTranslation('main')

    const visit = modal_data[0];
    const visitor  = modal_data[1];

    const visitorName = `${visitor.p_fname} ${visitor.p_lname}`;
    const hostName = `${visit.host_fname} ${visit.host_lname}`;

    // ::: EVENT HANDLERS
    const onClose = (e, visitor) => { 
        visitor.toNotShow = false;
        close_modal();
    }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const _visitorRejectionMessageView = validateArrayParameter(visit.rejected).map(vis =>  {
        return <div key={v4()} className="my-4 px-3">
                    <Badge color="danger" className="d-block text-center py-1">{t('rej_can')}</Badge>
                    <span><b>{vis.p_fname + ' ' + vis.p_lname}</b>{` - ${vis.observation}`}</span>
                </div>
    });

    const _observationMessageView = <div className="my-4 px-3">
                                        <Badge color="danger" className="d-block text-center py-1">{t('rej_can')}</Badge>
                                        <span>{visitor.observation}</span>
                                    </div>


    // ::: HTML CONDITIONALS
    const isToShowRejectionMessage = doesArrayExist(visit.rejected) && (visitor.toNotShow === true);
    const isToShowObservationMessage = (visitor.int_id_obs !== null) && !visitor.toNotShow;

    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal"> {visitorName} </h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Container fluid={true} className="overflow-auto">
                    {/* ::: HOST NAME */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <div>
                            <img src={HOST_ICON} className="mr-2" alt="host"/>
                            <span>{t('colaborator')}</span>
                        </div>
                        <span>{hostName}</span>
                    </div>
                    {/* ::: VISIT DATE AND TIME */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <div>
                            <img src={DATE_ICON} className="mr-2" alt="host"/>
                            <span>{t('dat_tim')}</span>
                        </div>
                        <span>{getMomentFromString(visit.p_date_time).format('ddd, MMMM Do')}</span>
                    </div>
                    {/* ::: VISIT CODE */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <div>
                            <img src={CODE_ICON} className="mr-2" alt="code"/>
                            <span>{t('code')}</span>
                        </div>
                        <span>{visit.p_code}</span>
                    </div>
                    {/* ::: VISIT ENTRANCE GATE */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <div>
                            <img src={GATE_ICON} className="mr-2" alt="get"/>
                            <span>{t('gate')}</span>
                        </div>
                        <span>{visit.gate}</span>
                    </div>
                    {/* ::: VISIT DESTINATION TERMINAL */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <span>{t('terminal')}</span>
                        <span>{visit.terminal} {isNotEmptyValue(visit.subTerminal)? ` - ${visit.subTerminal}`:""}</span>
                    </div>
                    {/* ::: VISIT DESTINATION DEPARTMENT */}
                    <div className="d-flex justify-content-between my-2 view-req-row px-3">
                        <span>{t('department')}</span>
                        <span>{visit.department}</span>
                    </div>
                    {/* ::: OBSSERVATION MESSAGE VIEW */}
                    {isToShowObservationMessage && _observationMessageView}
                    {/* ::: REJECTION MESSAGE VIEW */}
                    {isToShowRejectionMessage && _visitorRejectionMessageView}       
                </Container>
            </ModalBody>
            <ModalFooter>
                {/* ::: MODAL BUTTONS */}
                <div className="w-100 d-flex justify-content-between">
                    <Button className="btn btn-normal mx-2" onClick={e => onClose(e, visitor)}>{t('close')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

ViewUserRejectedModal.propTypes = {
    close_modal: PropTypes.func.isRequired,
    modal_data: PropTypes.array,
}

const mapStateToProps = (state) => ({
    modal_data: state.admin.modal_data,
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserRejectedModal)
