import { SET_IS_LOGGED_IN, GET_STATS, LOGIN_USER, LOGOUT_USER, SET_NEW_USER_ID, SET_TOKEN_USER, VERIFY_USER, SET_IS_REGISTERED } from "../actions/types";

const initialState = {
    user: null,
    from_login: false,
    token_user: null,
    isVerified: false,
    isLoggedIn: false,
    isRegistered: false,
    toLogOut: false,
    p_nid: null,
}

export default function(state=initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case LOGIN_USER: return {...state, user: payload, from_login: true, toLogOut: false};
        case LOGOUT_USER: return {...state, user: null, toLogOut: true, isLoggedIn: false};
        case VERIFY_USER: return {...state, isVerified: true};
        case SET_IS_REGISTERED: return {...state, isRegistered: payload}
        case GET_STATS: state.user.stats = payload; return state;
        case SET_NEW_USER_ID: return {...state, p_nid: payload};
        case SET_TOKEN_USER:
            return {...state, token_user: payload}
        case SET_IS_LOGGED_IN:
            return {...state, isLoggedIn: payload}
        default: return state;
    }
}