import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { calculateNumberOfPages, searchObjectInArrayByKeyVal, validateArrayParameter } from '@utils/utils'
import { NUMBER_USER_PER_PAGE, MAIN_PANEL_VIEW_VISIT_MODAL } from '@utils/constants'
import { v4 } from 'uuid'
import { fetchStatsOfSelectedTerminal, open_modal } from '@actions/admin'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import Badge from 'reactstrap/lib/Badge'
import TableNavigation from '@components/MainPage/Shared/TableNavigation'

const PUBLIC_SERVICE_ICON = require('@resources/icons/public_service.svg');

const MainPanelVisitorsTable = ({visitors,fetchStatsOfSelectedTerminal, visits, user, open_modal}) => {
    const {t} = useTranslation('main');
    const [state, setState] = useState({
        page: 1,
        terminalFilter: '',
    });

    useEffect(() => { 
        AppFunctionalityUsable.getTerminals();
    }, [state])

    const validatedVisitors = validateArrayParameter(visitors);
    const {page} = state;

    // ::: NAVIGATION
    const numberOfVisitors = validatedVisitors.length || 1;
    const  totalNumOfPages =  calculateNumberOfPages(numberOfVisitors, NUMBER_USER_PER_PAGE + 5);
    const indicators = Array.from(Array(totalNumOfPages)).map((e, i) => i + 1);
    const next = e => {setState({...state, page: page === totalNumOfPages? totalNumOfPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})}
    const setPage = (e, page) => {setState({...state, page })}

    // ::: EVENT HANDLERS

    const onSelectUser = (e, user)=> { 
        const visitId = user.int_id_svisit;
        const visit = searchObjectInArrayByKeyVal(visits, 'p_id', visitId);
        open_modal(MAIN_PANEL_VIEW_VISIT_MODAL, [visit, user]);
    }


    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS

    const visitorsOfPage = validatedVisitors.filter((req, index) => (index >= ((page-1) * (NUMBER_USER_PER_PAGE + 5))) && (index < (page * (NUMBER_USER_PER_PAGE + 5))));
    let toolTipName, toolTipDocumentId, visitorName;
    const listOfVisitors = visitorsOfPage.map(visitor => { 
        toolTipName = `${visitor.p_fname + ' ' + visitor.p_lname}`;
        toolTipDocumentId = `${visitor.p_nid}`;
        visitorName = visitor.p_fname + ' ' + visitor.p_lname;
        return (
            <tr key={v4()} className={`tr ${visitor.int_id_obs !== null?  "rej":""} py-1"`} onClick={e => onSelectUser(e, visitor)}>
                <td className="pl-4 text-truncate" data-toggle="tooltip" data-placement="top" title={toolTipName} >
                    {visitorName}
                    {visitor.sv_is_ps && <img alt="public_service_svg" className="float-right mr-4 mt-1" src={PUBLIC_SERVICE_ICON}/>}
        </td>
                <td className="text-truncate" data-toggle="tooltip" data-placement="top" title={toolTipDocumentId}>{visitor.p_nid}</td>
            </tr>
        )
    });

    const _visitorInsideTableView = <table className="striped-table default-line-height">
                                    <thead>
                                        <tr>
                                            <th className="pl-4">{t('name')}</th>
                                            <th>{t('id')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listOfVisitors}
                                    </tbody>
                                </table>;

    const _noVisitorsInsideTableView = <p className="text-center w-100 my-4">{t('no_vis_in')}</p>;
    
    // ::: HTML CONDITIONALS
    const isToShowVisitorsInsideTable = listOfVisitors.length !== 0;
    

    // ::: CHILDREN PROPERTIES
    const componentData = {
        pageIndicators: indicators,
        totalPages: totalNumOfPages,
        totalItems: numberOfVisitors,
        maxNumIndicators: 5,
        page,
        next,
        prev,
        setPage
    }


    return (
            <div className="elevated-container table-container h-75">
                {/* ::: TABLE TITLE AND COUNT */}
                <div className="custom-card-header px-4 py-4 d-flex justify-content-between align-items-center">
                    <span>{t("vis_in")}</span>
                    <Badge color="primary">{listOfVisitors.length}</Badge>
                </div>
                {/* ::: VISITORS INSIDE TABLE AND ALTERNATIVE FOR NOT VISITORS INSIDE */}
                {isToShowVisitorsInsideTable? _visitorInsideTableView : _noVisitorsInsideTableView}
                <TableNavigation componentData={componentData}/>
            </div>
    )
}

MainPanelVisitorsTable.propTypes = {
    user: PropTypes.object,
    open_modal: PropTypes.func.isRequired,
    fetchStatsOfSelectedTerminal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    open_modal,
    fetchStatsOfSelectedTerminal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPanelVisitorsTable)
