import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const MPDC_LOGO = require('@resources/img/nav_logo.png');

const NavigationBar = () => {
    const {t} = useTranslation('main');

    const onToggleNav = e => { 
        const hamburguerButton = document.querySelector(".hamb-btn-container");
        const frontPageNav = document.querySelector(".front-page-nav");
        hamburguerButton.classList.toggle("open");
        frontPageNav.classList.toggle("open");
    }

    return (
      <div className="front-page-nav-container ">
            <div className="front-page-nav">
                {/* NAVIGATION LOGO */}
                <img src={MPDC_LOGO} alt="company_logo" className="company-logo"/>
                {/* NAVIGATION LIST */}
                <div className="nav-items">
                    <a href="https://www.portmaputo.com/" target="_blank" rel="noopener noreferrer">{t("home")}</a>
                    <a href="https://www.portmaputo.com/category/port-news/" target="_blank" rel="noopener noreferrer">{t("por_inf")}</a>
                    <a href="https://gateway.portmaputo.com/" target="_blank" rel="noopener noreferrer">{t("visits")}</a>
                    <a href="https://gateway.portmaputo.com/contactUs" target="_blank" rel="noopener noreferrer" >{t("con_us")}</a>
                </div>
                {/* LANGUAGE SELECTOR */}
                <LanguageSelector/>
            </div>
            {/* RESPONSIVE NAVIGATION LOGO (DISPLAYS ON SMALL SCREENS) */}
            <div className="responsive-logo">
                <img src={MPDC_LOGO} alt="company_logo" className="company-logo"/>
            </div>
            <div className="hamb-btn-container" onClick={e => onToggleNav(e)}>
                <div className="hamb-btn-lines"></div>
            </div>
        </div>
    )
}

export default NavigationBar;
