import store from "@components/../store";
import { toastErrorMessage, toastSuccessMessage } from "@actions/toast";
import { ROOT_URL } from "@utils/constants";
import { update } from "@actions/admin";
import SubCompany from "./SubCompany";
import { extractCompanyFields, getToday, validateInputNames, validateEmpty, validateCellphoneNumbers, validatePasswords, getUser, createStoreLoader, doesArrayExist, doesVariableExist, doesObjectExist, extractNewCompField, sendRequestDataWithDefaultConfig, checkApiResponseForErrors } from "@utils/utils";

class Company {
    constructor(responseData) { 
        this.p_dtm = getToday().toISOString();
        if (doesObjectExist(responseData)) this.setUpCompany(responseData);
        //this.sub_companies = []
        if(doesArrayExist(this.sub_companies)) { 
          this.sub_companies.forEach(subComp => { 
            subComp.set = function (key, val) {
              this[key] = val;
              store.dispatch(update())
            }
          })
        }
    }

    
    set(key, val) { 
        if((key === "p_is_ps") && val) this.p_has_sub_comp = false;
        if((key === "p_has_sub_comp") && val) this.p_is_ps = false;
        this[key] = val;
        store.dispatch(update());
    }

    get(key){
        return this[key] || '';
    }

    /**
     * Prepares the object fields to create the subCompany.
     */
    createNewCompany(userId) { 
      this.p_cport = 1;
      this.p_cactive = 0;
      this.p_is_ps = 0;
      this.p_id_cuser = null;
      this.p_cod_core = userId;
      this.p_dmt = getToday().toISOString();
      this.p_scname = '';
      this.sub_companies = [new SubCompany()];
      this.p_has_sub_comp = false;
      this.p_cname = '';
      this.p_is_active = 1; 
      this.p_cemail = '';
      this.p_cpnumber_1 = '';
      this.p_cpsw2 = '';
      this.p_cpnumber_2 = '';
      this.p_cpsw = '';
    }

    /**
     * Adds a new empty company to the newly created company. Only use when the company is being created.
     */
    addNewSubCompany() { 
      this.sub_companies = [...this.sub_companies, new SubCompany()]
      store.dispatch(update());
    }

    /**
     * Gets Subcompany of the company
     */
    async getSubCompanies() { 
      try {
        const requestBody = JSON.stringify({p_id_company: this.p_id});
        const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getSubCompanies`, requestBody);
        checkApiResponseForErrors(response);
        
        if (doesArrayExist(response)){
          const id_father = this.p_id
          const emptySubCompany = {
            p_id_company: this.int_id, 
            p_is_active: 1, 
            p_scname: '', 
            set(key, val) {
              this[key] = val;
              store.dispatch(update())
            }
          }

          const subCompanies = response.map( sub => { 
            return { p_scname: sub.str_scname,
                p_id: sub.int_id ,
                p_id_company: id_father ,
                p_is_active: sub.bool_is_active? 1:0,
                set (key, val) {
                  this[key] = val;
                  store.dispatch(update())
                }
              }
          })

          this.sub_companies =[...subCompanies,emptySubCompany];
          store.dispatch(update());
        } else { 
          this.sub_companies = [];
        } 
    
      } catch (err) {
        store.dispatch(toastErrorMessage(err.message));
      }
    }

    /**
     * Adds a subcompany to the current company
     */
    addSubCompany() { 
      if (this.sub_companies === null) return;
      const emptySubCompany = {
        p_id_company: this.int_id, 
        p_is_active: 1, 
        p_scname: '', 
        set(key, val) {
          this[key] = val;
          store.dispatch(update())
        }
      }
      this.sub_companies = [...this.sub_companies,emptySubCompany]
      store.dispatch(update());
    }


    /**
     * Create the company fields
     * @param {object} responseData 
     */
    setUpCompany(responseData) { 
        Object.keys(responseData).forEach( key => this[key] = responseData[key]);
        this.p_cpsw2 = this.p_cpsw;
        this.p_id_core = getUser().p_id;
        this.name = this.p_cname; //::>> For settings table
        this.active = this.p_cactive;  //::>> For settins table
        // this.getSubCompanies(); #TODO: Talk to cherno about ths.getSubCompanies
    }

    /**
     * Validates the company fields.
     */
    validate() { 
      let name, email, num1, num2, pass, pass2;

      name = validateInputNames(this.p_cname);
      email = validateEmpty(this.p_cemail);
      num1 = validateEmpty(this.p_cpnumber_1);
      num2 = validateCellphoneNumbers(this.p_cpnumber_1, this.p_cpnumber_2);
      pass = validateEmpty(this.p_cpsw);
      pass2 = validatePasswords(this.p_cpsw, this.p_cpsw2);

      //if(!this.p_has_sub_comp) { sub_com = true}

      if(!name) return [name, 'Invalid First Name and Last Name'];
      if(!email) return [email, 'Invalid Email'];
      if(!num1) return [num1, 'Invalid Cellphone Number'];
      if(!num2) return [num2, 'Invalid Cellphone Number'];
      if(!pass) return [pass, 'Invalid Password'];
      if(!pass2) return [pass2, 'Passwords Do not Match!'];
      //if(!sub_com) return [sub_com, 'invalid Subcompany Name'];
      
      return [true, ''];    
    }

    /**
     * Updates a field and directly submits to the backend.
     * @param {string} key 
     * @param {*} val 
     */
    async setAndSubmit(key, val) { 
      this[key] = val;
      if(key==="active") this.p_cactive = val;
      await this.update();
      store.dispatch(update());
    }


    /**
     * Sends a request to update the company and the subcompanies.
     */
    async update () { 
       const loader = createStoreLoader(store);
       let savedSuccessfully = true;
        try {
            const [isValid, message] = this.validate();
            if (!isValid)
                throw new Error(message);
            
            const requestData = extractCompanyFields(this);
            let requestBody = JSON.stringify(requestData);
            let requestUrl = `${ROOT_URL}/admin/editCompany`;
            let response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);       
            checkApiResponseForErrors(response);
                   
            for (let sub of this.sub_companies) {
              requestBody = JSON.stringify({...sub, p_id_company: this.p_id});
              
              if (doesVariableExist(sub.p_id)) {
                  requestUrl = `${ROOT_URL}/admin/editSubCompany`;
                  response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
              } else { 
                  if(doesVariableExist(sub.p_scname)) {
                    requestUrl = `${ROOT_URL}/admin/addSubComp`;
                    response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
                  } 
              }
            }

            checkApiResponseForErrors(response);
            store.dispatch(toastSuccessMessage('success'));
            store.dispatch(update());        
          } catch (err) {
            savedSuccessfully = false;
            store.dispatch(toastErrorMessage(err.message));
          }

          loader.stopLoading();
          return savedSuccessfully;
    }

    
    /**
     * Sends a request to create the company and the subcompanies.
     */
    async save() { 
      let savedSuccessfully = true;
      const loader = createStoreLoader(store);
      try { 
        const [isValid, message] = this.validate();
        if (!isValid)
            throw new Error(message);
        
          const requestData = extractNewCompField(this);
          let requestBody = JSON.stringify(requestData);
          let requestUrl = `${ROOT_URL}/admin/addCompany`;

          let response = await sendRequestDataWithDefaultConfig(requestUrl,requestBody);
          checkApiResponseForErrors(response);
          
          if(this.p_has_sub_comp){
            const id_com = response.int_id;
            this.sub_companies.forEach(async subComp => { 
              if(!this.p_has_sub_comp) return;
              requestUrl = `${ROOT_URL}/admin/addSubComp`;
              requestBody = JSON.stringify({ p_id_company: id_com, p_scname: subComp.p_scname, p_is_active: this.p_is_active});
              response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
              checkApiResponseForErrors(response);
            })
          }

          loader.stopLoading();
          store.dispatch(toastSuccessMessage());
        } catch (err) {
          savedSuccessfully = false;
          store.dispatch(toastErrorMessage(err.message));
        }

        loader.stopLoading();
        return savedSuccessfully;
    }


}

export default Company;