import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toastErrorMessage } from '@actions/toast'
import { close_modal } from '@actions/admin'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, Form, ModalFooter, Button } from 'reactstrap'
import HostsVisitDataPage1 from '@colaboratorsPages/Shared/Visits/Shared/HostsVisitDataPage1';
import HostsVisitDataPage2 from '@colaboratorsPages/Shared/Visits/Shared/HostsVisitDataPage2';
import HostsVisitDataPage3 from '@colaboratorsPages/Shared/Visits/Shared/HostsVisitDataPage3';
import { fetchLoggedUserVisits, performHostVisitSubmissionProcess, performVisitUpdateProcess } from '@actions/visits'
import { isEmptyObject, isVisitToUpdate } from '@utils/utils'
import { CREATE_REQUISITION_HOST_MODAL } from '@utils/constants'

const HostsSimpleVisitModal = ({loggedUser, request, modal_data, reqUser, performVisitUpdateProcess, fetchLoggedUserVisits, toastErrorMessage, performHostVisitSubmissionProcess, close_modal}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
        page: 0,
        num_page: 2, 
        disSub: false
    });
    
    const {page, num_page, disSub} = state;

    const visit = isEmptyObject(request)? modal_data:request;

    const next = e => { setState({...state, page: page >= num_page? num_page : page + 1}); }
    const prev = e => { setState({...state, page: page <= 0? 0:page - 1}); }

    const onSubmit = async e => { 
        let isSuccessful = false;
        visit.singleUser = reqUser;
        setState({...state, disSub: true});
        if(isVisitToUpdate(visit))
            isSuccessful = await performVisitUpdateProcess(visit, loggedUser);
        else 
            isSuccessful = await performHostVisitSubmissionProcess(visit);

        if(isSuccessful) {
            onClose();
            fetchLoggedUserVisits(loggedUser);
        }
        setState({...state, disSub: false});
    }

    const onClose = e => {
        close_modal(CREATE_REQUISITION_HOST_MODAL);
    }

    const submitButtonColor = page >= num_page? 'success':'primary';
    const submitButtonString = page >= num_page? t('submit'):t('next'); 
    
    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            {/* ::: MODAL TITLE */}
            <h3 className="title-of-modal"> {t("cre_req")} </h3>
            <ModalBody className="modal-body paged-form-container">
                {/* ::: FORM PAGES */}
                <Form className="modal-paged-form">
                    {page === 0 && <HostsVisitDataPage1 visit={visit} reqUser={reqUser} />}
                    {page === 1 && <HostsVisitDataPage2 visit={visit} />}
                    {page === 2 && <HostsVisitDataPage3 visit={visit} />}
                </Form>
                {/* ::: PAGE INDICATORS */}
                <div className="page-indicators-container blue-version">
                    <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 2 ? "active" : ""}`}></span>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: PAGE BUTTONS */}
                <div className="modal-footer-button-container">
                    <Button className="left-sided-button" onClick={e => onClose()}>{t('cancel')}</Button>
                    <Button className="right-sided-button" disabled={disSub} color={submitButtonColor}  onClick={e => page >= num_page? onSubmit(e):next(e)}>{submitButtonString}</Button>
                    <Button className="right-sided-button"  color="primary" disabled={page <= 0} onClick={e => prev(e)}>{t('previous')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

HostsSimpleVisitModal.propTypes = {
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    close_modal: PropTypes.func.isRequired,
    flag: PropTypes.bool,
    loggedUser: PropTypes.object,
    performHostVisitSubmissionProcess: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
    performVisitUpdateProcess: PropTypes.func.isRequired,
    modal_data: PropTypes.object,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    loggedUser: state.auth.user,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    fetchLoggedUserVisits,
    close_modal,
    performHostVisitSubmissionProcess,
    toastErrorMessage,
    performVisitUpdateProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(HostsSimpleVisitModal)
