import NewExtra from '@model/Extras/NewExtra'
import { getAppConfig } from '@utils/configurations'
import { DEPARTMENT_TYPE } from '@utils/constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Row, Button } from 'reactstrap'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import { v4 } from 'uuid'

const AddExtraModal = ({closeModal, toShow}) => {
    const { t } = useTranslation('settings');
    const { ETYPE_BY_SETTINGS } = getAppConfig();
    const [state, setState] = useState({
        newExtra: new NewExtra(DEPARTMENT_TYPE),
        p_id_etype: DEPARTMENT_TYPE,
    });

    const {newExtra,p_id_etype} = state;

    // ::: EVENT HANDLERS
    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if (nodeName === 'p_id_etype') { 
            newExtra.set(e.target.name, e.target.value);
            setState({...state, p_id_etype: nodeValue})
        } else { 
            setState({...state, filterTypeValue: e.target.value});
        }
    }

    const onSaveExtra = async e => { 
        const isSuccessful = await newExtra.save();
        if(isSuccessful)
            closeModal(e);
    }

    return (
        <Modal isOpen={toShow} size="lg" className="no-border" centered>
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal">{t('add')}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }} className="px-4" >
                <div>
                    <Form>
                        <Row>
                            {/* ::: TYPE OF EXTRA */}
                            <Col md={4}>
                                <Input type="select" name="p_id_etype" value={p_id_etype}  onChange={e => onChange(e)}>
                                    {Object.keys(ETYPE_BY_SETTINGS).map(key => { 
                                        return <option key={v4()} value={ETYPE_BY_SETTINGS[key]}>{key}</option>
                                    })}
                                </Input>
                            </Col>
                            {/* ::: EXTRA NAME */}
                            <Col md={8}>
                                <Input type="text" name="p_name" placeholder={t('name')} value={newExtra.p_name} onChange={e => newExtra.set(e.target.name, e.target.value)}/>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className="w-100 d-flex justify-content-between">
                    <Button color="secondary" onClick={e => closeModal(e)}>{t('close')}</Button>
                    <Button color="success" onClick={e => onSaveExtra(e)} >{t('add')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default AddExtraModal
