import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Container, Button, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Extra from '@model/Extras/Extra'
import { ROOT_URL } from '@utils/constants'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'

const VisitorInfoViewModal = ({flag, fromModal, modal_info, modal_data}) => {
    const {t} = useTranslation('main');
    useEffect(() => { 
        Extra.getIdTypes();
    }, []);

    const visitor  =  fromModal? modal_info[1]: modal_data[1]; 
    const closeFunction  =  fromModal? modal_info[2]: modal_data[2]; 

    let visitorName = `${visitor.p_fname} ${visitor.p_lname}`;
    let documentType = AppFunctionalityUsable.idDocumentTypes.find((obj) => obj.p_id === visitor.p_id_type_id);
    documentType = documentType? documentType.p_name : '-';

    const onClose = (e) => { 
        closeFunction();
    }
    
    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            <h2 className="tag t5x px-5 text-center w-75 m-auto position-relative "> {visitorName} </h2>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            <Container fluid={true} className="overflow-auto">
                <div className="d-flex justify-content-center my-2">
                    <img src={`${ROOT_URL}/files/uId/${visitor.p_nid}`} alt="id_picture" style={{maxWidth: "400px", maxHeight: "400px"}}/>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('fir_nam')}</b></span>
                    </div>
                    <span>{visitor.p_fname}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('surname')}</b></span>
                    </div>
                    <span>{visitor.p_lname}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('id_typ')}</b></span>
                    </div>
                    <span>{documentType}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('id')}</b></span>
                    </div>
                    <span>{visitor.p_nid}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('nationality')}</b></span>
                    </div>
                    <span>{visitor.p_nationality}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('pho_num_1')}</b></span>
                    </div>
                    <span>{visitor.p_pnumber_1}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('pho_num_2')}</b></span>
                    </div>
                    <span>{visitor.p_pnumber_2}</span>
                </div>
                <div className="d-flex justify-content-between my-2 view-req-row px-3">
                    <div>
                        <span><b>{t('email')}</b></span>
                    </div>
                    <span>{visitor.p_email}</span>
                </div>
            </Container>

            </ModalBody>
            <ModalFooter>
                <div className="w-100 d-flex justify-content-between">
                    <Button className="btn btn-normal mx-2" onClick={e => onClose(e, visitor)}>{t('close')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

VisitorInfoViewModal.propTypes = {
    modal_data: PropTypes.object,
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorInfoViewModal)
