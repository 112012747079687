import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom';
import NavigationBar from '@sharedComponents/Navbar';
import UserPersonalDataForm from '@sharedComponents/Auth/UserPersonalDataForm';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import VisitorFastRequestPage2 from './VisitorFastRequestPage2';
import VisitorFastRequestPage3 from './VisitorFastRequestPage3';
import VisitorFastRequestPage4 from './VisitorFastRequestPage4';
import { getNewFastRequestUserObject } from '@actions/auth';
import { performVisitorFastVisitSubmissionProcess, setNewVisitorFastRequestDefaultFields } from '@actions/visits';

const VisitorFastVisitForm = ({flag,getNewFastRequestUserObject, setNewVisitorFastRequestDefaultFields, performVisitorFastVisitSubmissionProcess}) => {
    const { t } = useTranslation('auth');
    const [state, setState] = useState({
        page: 0,
        num_pag: 3,
        user: getNewFastRequestUserObject({}),
        isRegistered: false,
        visitorVisit: setNewVisitorFastRequestDefaultFields({}),
        disSub: false,
    });

    useEffect(() => { 
        AppFunctionalityUsable.getIdDocumentTypes();
        AppFunctionalityUsable.getVisitorProvenance();
        AppFunctionalityUsable.getTerminals();
        AppFunctionalityUsable.getReasonOfVisit();
        AppFunctionalityUsable.getDepartments();
    }, []);

    const {page, num_pag, user, isRegistered, visitorVisit, disSub} = state;
    if (isRegistered) return <Redirect to="/" />; 

    const next = e => { e.preventDefault(); setState({...state, page: page >= num_pag? num_pag : page + 1}); }
    const prev = e => { setState({...state, page: page <= 0? 0:page - 1}); }

    const onSubmit = async e => {  
      e.preventDefault();
      setState({...state, disSub: true});
      let isSuccessful = await performVisitorFastVisitSubmissionProcess(visitorVisit, user);

      if(isSuccessful) { 
        setState({...state, isRegistered: true, visitorVisit: setNewVisitorFastRequestDefaultFields({}), user: getNewFastRequestUserObject({})});
        onExitForm();
      }

      setState({...state, disSub: false});
      
    }

    const onExitForm = () => { 
      AppFunctionalityUsable.clearAllFuncUsables();
    }
   
    const submitButtonString = page >= num_pag? t('submit'):t('next');
    const IS_PREVIOUS_BUTTON_DISABLED = page <= 0;

    return (
      <div className="container-front-page">
        <NavigationBar />
        <main className="main-content auth-container new-request-container">
            <div className="form-container paged-form-container">
              {/* :: FORM */}
              <form className="auth-form paged-form dark-form">
                {/* :: NEW FAST REQUEST PAGE 01 */}
                {page === 0 && <UserPersonalDataForm newUser={user} />}
                {/* :: NEW FAST REQUEST PAGE 02 */}
                {page === 1 && <VisitorFastRequestPage2 visit={visitorVisit}/>}
                {/* :: NEW FAST REQUEST PAGE 03 */}
                {page === 2 && <VisitorFastRequestPage3 visit={visitorVisit} />}
                {/* :: NEW FAST REQUEST PAGE 04 */}
                {page === 3 && <VisitorFastRequestPage4 visit={visitorVisit} user={user} />}
                {/* :: PAGE INDICATORS */}
                <div className="page-indicators-container">
                    <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 2 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 3 ? "active" : ""}`}></span>
                </div>
                {/* :: FORM BUTTONS */}
                <div className="form-buttons clearfix">
                    <Link type="button" to="/login" className="button link-button left-sided-button" onClick={() => onExitForm()} >{t('cancel')}</Link>
                    <button type="submit" disabled={disSub} className="button dark-button submit-button right-sided-button" onClick={e => (page >= num_pag)? onSubmit(e):next(e)}>{submitButtonString}</button>
                    <button type="button" className={`${IS_PREVIOUS_BUTTON_DISABLED? 'disabled-dark-button':''} button link-button text-no-underline mr-3 right-sided-button`} disabled={IS_PREVIOUS_BUTTON_DISABLED} onClick={e => prev(e)} >{t('previous')}</button>
                </div>
              </form>
            </div>
        </main>
      </div>
    )
}

VisitorFastVisitForm.propTypes = {
    flag: PropTypes.bool,
    performVisitorFastVisitSubmissionProcess: PropTypes.func.isRequired,
    getNewFastRequestUserObject: PropTypes.func.isRequired,
    setNewVisitorFastRequestDefaultFields: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag
})

const mapDispatchToProps = {
    performVisitorFastVisitSubmissionProcess,
    getNewFastRequestUserObject,
    setNewVisitorFastRequestDefaultFields
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorFastVisitForm)
