import React, {Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Badge, Button, Col, CustomInput, FormGroup, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { close_modal, fetchAllWorkersOfServiceProvider, uploadTemplateFile } from '@actions/admin'
import { useTranslation } from 'react-i18next'
import { ROOT_URL } from '@utils/constants'
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption'
import { toastErrorMessage } from '@actions/toast'
import { doesArrayExist } from '@utils/utils'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import { performUserRegistrationProcess, getServProviderWorkerWithDefaultFields, performBulkUsersRegistrationProcess } from '@actions/auth'
import { fixNewUserFields, resolveUserCountryCode, validateNewUserFields } from '@utils/validations'

const AddWorkerModal = ({loggedUser, getServProviderWorkerWithDefaultFields, fetchAllWorkersOfServiceProvider, performUserRegistrationProcess, toastErrorMessage, performBulkUsersRegistrationProcess, uploadTemplateFile, close_modal}) => {
    const { t } = useTranslation('request');
    const { COUNTRY_LIST } = getAppConfig();
    const [state, setState] = useState({
        is_collective: false,
        employees: [],
        file_name: '',
        disSub: false,
        flag: false,
        loading: false,
        singleUser: getServProviderWorkerWithDefaultFields(loggedUser),
    });

    const {is_collective, file_name, loading, employees, singleUser, disSub} = state;
    const {p_fname,p_lname,p_nationality,p_id_type_id,p_expiration_date_id,p_nid,p_pnumber_1,p_pnumber_2, country_code_p1, country_code_p2} = singleUser;

    useEffect(() => { 
        fixNewUserFields(singleUser);
        setState({...state, flag: !state.flag});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    //::: EVENT HANDLERS
    const onChange = (e) => {
        const nodeName = e.target.name;
        let nodeValue = e.target.value;

        try {
            singleUser[nodeName] = nodeValue;
            fixNewUserFields(singleUser); //::>> Throws Error if a field is invalid
            if(nodeName === 'p_nationality')
                resolveUserCountryCode(singleUser, nodeValue);
            setState({...state, user: singleUser});
        } catch (error) {
            singleUser[nodeName] = '';
            toastErrorMessage(error.message);
        }
    }

    const onSelectIdPhoto = e => { 
        const file = e.target.files[0];
        singleUser['uid_data'] = file;
    }

    const onChangeSwitch = e => { 
        setState({...state, is_collective: !is_collective})
    }
       
    const onFileSelect = async (e) => { 
        const file = e.target.files[0];
        if(!file) return;
        setState({...state, selectedFile: file.name, loading: true});

        const data = new FormData();
        data.append("file", file);
        data.append("name", file.name);
        const usersOfFile = await uploadTemplateFile(data, 1); 
        setState({...state, loading: false});
        try {
            if(doesArrayExist(usersOfFile)){
                const entityEmployees = usersOfFile.map(worker =>{
                    let employee;
                    try {
                        employee = getServProviderWorkerWithDefaultFields(loggedUser, worker);
                        validateNewUserFields(employee);
                    } catch (error) {
                        // employee.failed = true;
                        // toastErrorMessage(error.message);
                    }
                     return employee;
                    });
                setState({...state, employees: entityEmployees});
            }
        } catch (error) {
            toastErrorMessage('Could not load file!');
        }
    }

    const onSubmit = async e => { 
        setState({...state, disSub: true});
        let isSuccessful = false;
        
        if(is_collective) 
            isSuccessful = await performBulkUsersRegistrationProcess(employees);
        else 
            isSuccessful = await performUserRegistrationProcess(singleUser);

        if(isSuccessful) {
            close_modal();
            fetchAllWorkersOfServiceProvider(loggedUser);
        }
        setState({...state, disSub: false});
    }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const _usersFromFileView = <Fragment>
                                    <FormGroup>
                                        <ListGroup>
                                            {employees && employees.map(vis => { 
                                                return <ListGroupItem key={vis.p_nid} className="justify-content-between">{vis.p_fname + ' ' + vis.p_lname} <Badge color={vis.error? "danger": vis.warning? "warning": "success"}>{vis.error? t(vis.messages.error) : vis.warning? t(vis.messages.warning) : t('success')}</Badge></ListGroupItem>
                                                })}
                                            </ListGroup>
                                    </FormGroup>
                                </Fragment>;

    const _singleUserForm = <Fragment>
                                <div className="separator thin light my-2 mb-4"></div>
                                <Row form >
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input type="name" name="p_fname" placeholder={t("fir_nam")} className="required" value={p_fname || ''}  onChange={e => onChange(e)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input type="name" name="p_lname" placeholder={t("surname")} className="required" value={p_lname || ''} onChange={e => onChange(e)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="select" name="p_nationality" placeholder={t("nationality")} className="required" value={p_nationality || ''} onChange={e => onChange(e)}>
                                                <CustomSelectOption options={COUNTRY_LIST}  hasId={false}/>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="select" name="p_id_type_id" placeholder={t("id_type")} className="required" value={p_id_type_id || ''} onChange={e => onChange(e)}>
                                                <CustomSelectOption options={AppFunctionalityUsable.idDocumentTypes} hasId={true}/>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="date" name="p_expiration_date_id" placeholder={t("exp_dat")} className="required" value={p_expiration_date_id || ''} onChange={e => onChange(e)} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Input type="text" name="p_nid" placeholder={t("id")} className="required" value={p_nid || ''} onChange={e => onChange(e)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <CustomInput id="fileInputRegistrationUser" type="file" name="p_photo_id" placeholder={t("nationality")} onChange={e => onSelectIdPhoto(e)} />
                                            <small className="form-text text-muted">{t("pho_doc")}</small>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={6}>
                                        <FormGroup className="d-flex">
                                        <Input type="tel" name="country_code_p1" value={country_code_p1} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                                            <Input type="tel" name="p_pnumber_1" placeholder={t("cel_num")} className="required" value={p_pnumber_1 || ''} onChange={e => onChange(e)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="d-flex">
                                            <Input type="tel" name="country_code_p2" value={country_code_p2} className="country-code w-25 d-flex align-items-center"  onChange={e => onChange(e)} />
                                            <Input type="tel" name="p_pnumber_2" placeholder={t("eme_cel_num")} className="required" value={p_pnumber_2 || ''} onChange={e => onChange(e)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Fragment>;

    // ::: HTML CONDITIONALS
    const isToShowUsersFromFile = is_collective;
    const isToShowSingleUserForm = !is_collective;

    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            <h3 className="title-of-modal"> {t("add_vis")} </h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                <Fragment>
                    <FormGroup className="mt-4">
                        {/* ::: IS COLLECTIVE SELECTION INPUT  */}
                        <CustomInput type="switch" name="is_collective" checked={is_collective ? "checked" : false} id="custonSwithd" onChange={(e) => onChangeSwitch(e)}  inline label={file_name? file_name:t("is_col")} />
                        {loading && <img alt="loading" className="mx-auto my-2 loading-gif" src={require(`@resources/icons/loading.gif`)}/>}
                    </FormGroup>
                    <Fragment>
                        <FormGroup>
                            {/* ::: FILE INPUT FOR COLLECTIVE USERS */}
                            <CustomInput disabled={!is_collective} type="file" id="collectiveFile" label={t("cho_fil")} name="file" onChange={e => onFileSelect(e)}/>
                            <small><a className="text-green pl-1 link-text" href={`${ROOT_URL}/files/access/templates`}>{t('dow_tem_fil')}</a></small>
                        </FormGroup>
                    </Fragment>
                    {/* ::: LOADED USERS VIEW */}
                    {isToShowUsersFromFile && _usersFromFileView}
                    {/* ::: SINGLE USER FORM */}
                    {isToShowSingleUserForm && _singleUserForm}
                </Fragment>
            </ModalBody>
            <ModalFooter>
                {/* ::: MODAL BUTTONS */}
                <div className="modal-footer-button-container">
                    <Button className="left-sided-button" onClick={e => close_modal()}>{t('cancel')}</Button>
                    <Button className="right-sided-button" disabled={disSub} color={`success`}  onClick={e => onSubmit(e)}>{t('submit')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

AddWorkerModal.propTypes = {
    flag: PropTypes.bool.isRequired,
    uploadTemplateFile: PropTypes.func.isRequired,
    close_modal: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
    performUserRegistrationProcess: PropTypes.func.isRequired,
    fetchAllWorkersOfServiceProvider: PropTypes.func.isRequired,
    getServProviderWorkerWithDefaultFields: PropTypes.func.isRequired,
    performBulkUsersRegistrationProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    uploadTemplateFile,
    close_modal,
    toastErrorMessage,
    performUserRegistrationProcess,
    fetchAllWorkersOfServiceProvider,
    getServProviderWorkerWithDefaultFields,
    performBulkUsersRegistrationProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkerModal)
