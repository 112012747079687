import React, { Fragment, useState } from 'react'
import { FormGroup, Input, CustomInput, Label, Col } from 'reactstrap'
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { addMaterialToVisit, addVehicleToVisit, getAllVisitMaterials, getAllVisitVehicles, removeVisitMaterialsFromVisit } from '@actions/visits';


function VisitDataPage2({visit}) {
    const {t} = useTranslation('request');
    const [state, setState] = useState({
      flag: false,
    })
    const {p_id_rpp, p_detail, p_multi_entry, p_access} = visit || {};

    // ::: EVENT HANDLERS
    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        visit[nodeName] = nodeValue;
        setState({...state, flag: !state.flag});
    }

    const onChangeMultyEntry = (e, value) => { 
        visit['p_multi_entry'] = value;
        setState({...state, flag: !state.flag});
    }

    const onChangePAccess = (e, value) => { 
        visit['p_access'] = value;
        setState({...state, flag: !state.flag});
      }
    
    const addObjectToVisit = (e, functionToExecute) => { 
      functionToExecute(visit);
      setState({...state, flag: !state.flag});
    }

    const removeObjectFromVisit = (e, materialName) => { 
      removeVisitMaterialsFromVisit(visit, materialName);
      setState({...state, flag: !state.flag});
    }



    // :::::::::::::::::::::: GENERATED HTML ELEMENTS
    // ::: GENERATOR FUNCTIONS
    const generateMaterialsList = () => {
        const materials = getAllVisitMaterials(visit);
        return materials.map((mat, i) => {
          return (
            <FormGroup key={i} row>
              <Col md={5}>
                <Input type="text" name="material_name" value={mat.p_oname} onChange={(e) => mat.changeName(e.target.value)} placeholder={t("nam_mat")} />
              </Col>
              <Col md={3}>
                <Input type="text" name="material_qtd" value={mat.p_qnt} onChange={(e) => mat.changeQuantity(e.target.value)} placeholder={t("quantity")} />
              </Col>
              <Col md={2}>
                <span name="accept" className="faded-button error" onClick={(e) => visit.removeExtra(mat.p_oname)} >{t("remove")} </span>
              </Col>
            </FormGroup>
          );
        });
    };
  
    const generateVehiclesList = () => {
        const vehicles =  getAllVisitVehicles(visit);
        return vehicles.map((ve, i) => {
          return (
          <FormGroup key={i} row>
              <Col md={5}>
                <Input type="text" name="vehicle" value={ve.p_oname} onChange={(e) => ve.changeName(e.target.value)} placeholder={t("license")} />
              </Col>
              <Col md={2}>
                <span name="remove" className="faded-button error" onClick={(e) => removeObjectFromVisit(ve.p_oname)} > {t("remove")} </span>
              </Col>
          </FormGroup>
          );
      });
    }


    return (
      <Fragment>
          <FormGroup>
            {/* ::: REASON OF VISIT SELECT INPUT */}
              <FormGroup>
                  <Input type="select" name="p_id_rpp" className="required" value={p_id_rpp} onChange={e=> onChange(e)} >
                      <CustomSelectOption options={AppFunctionalityUsable.reasonsOfVisit} hasId={true}/>
                  </Input>
              </FormGroup>
              {/* ::: REASON OF VISIT TEXTAREA INPUT */}
              <FormGroup> 
                  <Input type="textarea" name="p_detail" className="required" placeholder={t("reason")} value={p_detail} onChange={(e) => onChange(e)} />
              </FormGroup>
          </FormGroup>
          <div className="separator light thin  mb-4"/>
          {/* ::: MULTIPLE ENTRY CHECKBOXES */}
          <FormGroup>
              <CustomInput type="checkbox" id="p_multi_entry" checked={p_multi_entry === 1? true: false} label={t("mult_ent")} onChange={(e) => onChangeMultyEntry(e, p_multi_entry === 1? 0: 1)} />
              <small className="form-text text-muted">{t('mult_ent_desc')}</small>
          </FormGroup>
          <div className="separator light thin mb-4"></div>
          {/* ::: ENTRY/EXIT OR EXIT CHECKBOXES */}
          <FormGroup check inline>
              <Label check>
                <CustomInput id="chec1" label={t("entry_exit")} checked={p_access === 1? true: false} type="checkbox" onChange={(e) => onChangePAccess(e,1)} /> 
              </Label>
          </FormGroup>
          <FormGroup check inline>
              <Label check>
              <CustomInput id="chec13" label={t("exit")} checked={p_access === 1? false: true} type="checkbox" onChange={(e) => onChangePAccess(e, 0)} /> 
              </Label>
          </FormGroup>
          <div className="separator light thin my-4"></div>
          {/* ::: INPUTS FOR MATERIALS AND VEHICLES */}
          <div className="my-4 d-block"></div>
            {/* ::: MATERIALS INPUT */}
              <div>
                  <p className="d-inline-block mx-2" col={1}> {t("material")} </p>
                  <span name="accept" className="faded-button success" onClick={(e) => addObjectToVisit(e, addMaterialToVisit)} > {t("add_mat")} +{" "} </span>
              </div>
              {generateMaterialsList()}
            <div className="separator transparent my-3"></div>
            {/* ::: VEHICLES INPUT */}
              <div>
                  <p className="d-inline-block mx-2" col={1}> {t("vehicle")} </p>
                  <span name="accept" className="faded-button success" onClick={(e) => addObjectToVisit(e, addVehicleToVisit)} > {t("add_veh")} +{" "} </span>
              </div>
              {generateVehiclesList()}
      </Fragment>
    )
}

VisitDataPage2.propTypes = {
    flag: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
})

const mapDispatchToProps = {
    
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitDataPage2);
