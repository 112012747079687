import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, Form, ModalFooter, Button } from 'reactstrap'
import ServiceRequestVisitPage1 from './ServiceRequestVisitPage1'
import HostsVisitDataPage3 from '../Shared/HostsVisitDataPage3'
import { fetchLoggedUserVisits, performHostServiceRequestSubmissionProcess } from '@actions/visits'
import { CREATE_SERV_REQUEST_MODAL } from '@utils/constants'

const ServicesVisitModal = ({loggedUser, visit, fetchLoggedUserVisits,performHostServiceRequestSubmissionProcess, close_modal}) => {
    const {t} = useTranslation('request');
    const [state, setState] = useState({
        page: 0,
        num_page: 1,
        disSub: false,
    });

    // ::: FOR NAVIGATION
    const {page, num_page, disSub} = state;
    const next = e => { setState({...state, page: page >= num_page? num_page : page + 1});}
    const prev = e => { setState({...state, page: page <= 0? 0:page - 1});}

    // ::: EVENT HANDLERS
    const onSubmit = async e => {
        setState({...state, disSub: true});
        let isSuccessful = false;
        isSuccessful = await performHostServiceRequestSubmissionProcess(visit, loggedUser);
        if(isSuccessful) {
            onClose();
            fetchLoggedUserVisits(loggedUser);
        }
        setState({...state, disSub: false});
    }

    const onClose = e => {
        close_modal(CREATE_SERV_REQUEST_MODAL);
    }

    // :::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    // ::: ELEMENTS FOR HTML
    const submitButtonColor = (page >= num_page)? 'success':'primary';
    const submitButtonText = (page >= num_page)? t('submit'):t('next');

    
    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            {/* ::: MODAL TITLE */}
            <h3 className="title-of-modal">{t("cre_req")}</h3>
            <ModalBody className="modal-body paged-form-container" >
                <Form className="modal-paged-form">
                    {page === 0 && <ServiceRequestVisitPage1 visit={visit}  />}
                    {page === 1 && <HostsVisitDataPage3 visit={visit} />}
                </Form>
                {/* ::: PAGE INDICATORS */}
                <div className="page-indicators-container blue-version">
                    <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: PAGE BUTTONS */}
                <div className="modal-footer-button-container">
                    <Button className="left-sided-button" onClick={e => onClose()}>{t('cancel')}</Button>
                    <Button className="right-sided-button" color={submitButtonColor} disabled={disSub}  onClick={e => (page >= num_page)? onSubmit(e):next(e)}>{submitButtonText}</Button>
                    <Button className="right-sided-button"  color="primary" disabled={page <= 0} onClick={e => prev(e)}>{t('previous')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

ServicesVisitModal.propTypes = {
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    close_modal: PropTypes.func.isRequired,
    loggedUser: PropTypes.object,
    flag: PropTypes.bool,
    performHostServiceRequestSubmissionProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    loggedUser: state.auth.user,
})

const mapDispatchToProps = {
    fetchLoggedUserVisits,
    close_modal,
    performHostServiceRequestSubmissionProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesVisitModal)
