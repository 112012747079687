import { toastErrorMessage } from '@actions/toast';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        const {toastErrorMessage} = props;
        this.toastErrorMessage = toastErrorMessage;
      }
    
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error) {
        this.toastErrorMessage(error.message);
        this.toastErrorMessage("Something Went Wrong. Please write the steps for the error in the form bellow so support can fix it.");
    }

    render() {
        if (this.state.hasError) {
            return <Redirect to="/contactUs"/>;
          }
          return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    toastErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
