import React, { useEffect } from 'react'
import { Badge } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { getMomentFromString, searchObjectInArrayByKeyVal, hasVisitBeenCanceled, hasVisitBeenRejected, exists, doesObjectExist, isNotEmptyValue } from '@utils/utils';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';

const HOST_ICON = require(`@resources/icons/host.svg`);
const DATE_ICON = require(`@resources/icons/date.svg`);
const TIME_ICON = require(`@resources/icons/time.svg`);
const PERIOD_ICON = require(`@resources/icons/period.svg`);
const CODE_ICON = require(`@resources/icons/code.svg`);
const GATE_ICON = require(`@resources/icons/gate.svg`);
const GROUP_ICON = require(`@resources/icons/grouped.svg`);

const VisitViewPage1 = ({visit, onOpenModal}) => {
    const {t} = useTranslation('main');
    const { v_name, subTerminal ,host_fname,gate,st_why_cencel, st_why, host_lname,p_code,grouped ,p_internal, terminal, department,p_access, p_date_time, p_period, p_id_rpp, p_detail, p_multi_entry, provenace, p_id_x} = visit || {};

    useEffect(() => {
        if(AppFunctionalityUsable.reasonsOfVisit.length === 1)
            AppFunctionalityUsable.getReasonOfVisit();
    }, [])
    
    // :::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const _colaboratorLabelInfo = <div className="visit-info">
                                        <img src={HOST_ICON} className="mr-2" alt="host"/>
                                        <span className="label">{t('colaborator')}</span>
                                        <span className="label-info">{host_fname + ' ' + host_lname}</span>
                                    </div>

    const _visitPeriodLabelInfo = <div className="visit-info">
                                        <img src={PERIOD_ICON} className="mr-2" alt="period"/>
                                        <span className="label">{t('period')}</span>
                                        <span className="label-info">{p_period}</span>
                                    </div>

    const _visitDepartmentLabelInfo =  <div className="visit-info">
                                            <span className="label">{t('department')}</span>
                                            <span className="label-info">{department}</span>
                                        </div>

    const _visitRejectionReasonsView = <div className="my-4 px-3">
                                            <Badge color="danger" className="d-block text-center py-1">{t('rej_can')}</Badge>
                                            <span>{exists(st_why)? st_why : st_why_cencel}</span>
                                        </div>

    const isNotPublicServiceVisit = !visit.sv_is_ps;
    const isToShowVisitRejectionReasons = doesObjectExist(visit) && (hasVisitBeenCanceled(visit) || hasVisitBeenRejected(visit));

    return (
        <div className="visit-info-container overflow-auto">
            <div className="d-flex flex-column align-items-center vprofile mb-5">
                {/* ::: VISITOR NAME */}
                <h2 className="m-0 p-0 under-dotted" onClick={e => onOpenModal(p_id_x)} >{v_name}</h2>
                <p className="m-0 p-0 text-muted">{provenace || t('visitor')}</p>
            </div>
            {/* ::: COLABORATOR NAME */}
            {!!isNotPublicServiceVisit && _colaboratorLabelInfo}
            {/* ::: VISIT DATE */}
            <div className="visit-info">
                <img src={DATE_ICON} className="mr-2" alt="host"/>
                <span className="label">{t('dat_tim')}</span>
                <span className="label-info">{getMomentFromString(p_date_time).format('ddd, MMMM Do')}</span>
            </div>
            {/* ::: VISIT TIME */}
            <div className="visit-info">
                <img src={TIME_ICON} className="mr-2" alt="time"/>
                <span className="label">{t('time')}</span>
                <span className="label-info">{getMomentFromString(p_date_time).format('HH:mm')}</span>
            </div>
            {/* ::: VISIT PERIOD */}
            {!!isNotPublicServiceVisit && _visitPeriodLabelInfo}
            {/* ::: VISIT CODE */}
            <div className="visit-info">
                <img src={CODE_ICON} className="mr-2" alt="code"/>
                <span className="label">{t('code')}</span>
                <span className="label-info">{p_code}</span>
            </div>
            {/* ::: VISIT GATE */}
            <div className="visit-info">
                <img src={GATE_ICON} className="mr-2" alt="get"/>
                <span className="label">{t('gate')}</span>
                <span className="label-info">{gate}</span>
            </div>
            {/* ::: VISIT NUMBER OF VISITORS */}
            <div className="visit-info">
                <img src={GROUP_ICON} className="mr-2" alt="grouped"/>
                <span className="label">{t('grouped')}</span>
                <span className="label-info">{grouped? grouped.length: 0}</span>
            </div>
            {/* ::: VISIT TERMINAL DESTINATION */}
            <div className="visit-info">
                <span className="label">{t('terminal')}</span>
                <span className="label-info">{terminal}{isNotEmptyValue(subTerminal)? ` - ${subTerminal}`:""}</span>
            </div>
            {/* ::: VISIT DEPARTMENT */}
            {!!isNotPublicServiceVisit && _visitDepartmentLabelInfo}
            {/* ::: VISIT TYPE OF VISIT */}
            <div className="visit-info">
                <span className="label">{t('typ_vis')}</span>
                <span className="label-info">{p_access? t('ent_exi'):t('exit')}</span>
            </div>
            {/* ::: VISIT MULTY ENTRY */}
            <div className="visit-info">
                <span className="label">{t('mult_ent')}</span>
                <span className="label-info">{p_multi_entry? t('yes'):t('no')}</span>
            </div>
            {/* ::: VISIT IS INTERNAL */}
            <div className="visit-info">
                <span className="label">{t('is_int')}</span>
                <span className="label-info">{p_internal? t('yes'):t('no')}</span>
            </div>
            {/* ::: VISIT REASON */}
            <div className="mt-4">
                <h5 className="text-center text-bold">{t('reason')}</h5>
                {/* <h4 className="text-center text-bold">{t('reason')}</h4> */}
                <ul className="no-decoration px-2 m-0 text-underline"><li>{searchObjectInArrayByKeyVal(AppFunctionalityUsable.reasonsOfVisit, 'code', p_id_rpp).name}</li></ul>
                <ul className="no-decoration px-4"><li>{p_detail}</li></ul>
            </div>
            {!!isToShowVisitRejectionReasons && _visitRejectionReasonsView}
        </div>
    )
}

export default VisitViewPage1
