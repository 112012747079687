import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { open_modal } from '@actions/admin'
import AddInductionModal from './AddInductionModal'
import InductionListingTable from './InductionListingTable'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'

const InductionSettingsTab = ({flag, modal_data,open_modal }) => {

    const [state, setState] = useState({
        toShowAddModal: false,
    })

    useEffect(() => { 
        AppFunctionalityUsable.getInductionQuestions(1);
    }, []);

    const openAddModal = e => { 
        setState({...state, toShowAddModal: true});
    }

    const closeAddModal = e => { 
        setState({...state, toShowAddModal: false});
    }
    
    return (
        <Fragment>
            <AddInductionModal toShow={state.toShowAddModal} closeModal={closeAddModal}/>
            <InductionListingTable modal_data={modal_data} open_modal={open_modal} openAddModal={openAddModal}/>
        </Fragment>
    )
}

InductionSettingsTab.propTypes = {
    flag: PropTypes.bool,
    modal_data: PropTypes.object,
    open_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    open_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(InductionSettingsTab)
