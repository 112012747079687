import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE } from '@utils/constants';
import {v4} from 'uuid';
import {setMessage} from '@actions/toast'
import { calculateNumberOfPages, doesArrayExist } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import InternalNavBar from '@mainPage/Shared/InternalNavBar';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import TableNavigation from '@components/MainPage/Shared/TableNavigation';

const Reports = ({flag}) => {
    const { t } = useTranslation(['reports'])
    const [state, setState] = useState({
        page: 1
    })

    const {page} = state;

    useEffect(() => { 
        AppFunctionalityUsable.getReports();
    }, []);

    const items = AppFunctionalityUsable.reports;
    const  totalPages =  calculateNumberOfPages( doesArrayExist(items)? items.length: 1, DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE);;
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);

    let reportName, reportDescription;

    let listOfreports = items.map(item =>  { 
        [reportName, reportDescription] = item.INFO.split("*");
        return <tr key={v4()}>
                    <td className="pl-4">{reportName}</td>
                    <td>{reportDescription}</td>
                    <td  className="text-center hoverable" ><a href={item.PATH} rel="noopener noreferrer" target="_blank" >{t('view')}</a></td>
                </tr>
    });


    const componentData = { 
        pageIndicators: indicators,
        totalPages,
        totalItems: items? items.length: 0,
        page,
        next,
        prev
    }

    
    return (
        <Fragment>
            <InternalNavBar/>
            <div className="main-display-container">
                <div className="elevated-container table-container"> 
                    {/* :: TABLE TITLE */}
                    <span className="elevated-contaniner-title">{t('reports')}</span>
                    <table className="striped-table">
                        {/* :: TABLE HEADER */}
                        <thead>
                            <tr>
                                <th className="pl-4">{t('name')}</th>
                                <th>{t('description')}</th>
                                <th className="text-center">{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* :: TABLE ROWS */}
                            {listOfreports}
                        </tbody>
                    </table>
                    {/* :: TABLE NAVIGATION */}
                    <TableNavigation componentData={componentData}/>
                </div>
            </div>
        </Fragment> 
    )
}

Reports.propTypes = {
    flag: PropTypes.bool,
    setMessage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    flag: state.admin.flag,
})

export default connect(mapStateToProps, { setMessage})(Reports);

