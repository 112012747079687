import { CLEAR_VISITS, SET_GENERAL_VISIT_STATE, SET_VISITS, SET_VISIT_ON_MODAL } from "@actions/types";

const initialState = {
    visits: [],
    generalState: {},
    visitOnModal: {},
};


export default function(state=initialState, action) { 
    const {type, payload} = action;

    switch(type) { 
        case SET_VISITS: return {...state, visits: payload}
        case CLEAR_VISITS: return {...state, visits: payload}
        case SET_GENERAL_VISIT_STATE: return {...state, generalState: payload}
        case SET_VISIT_ON_MODAL: return {...state, visitOnModal: payload}
        default: return state;
    }
}
