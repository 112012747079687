import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { open_modal, changeContentPage, fetchMainPanelStatsOfAccount, fetchAllVisitors } from '@actions/admin'
import { CREATE_REQUISITION_X_MODAL, MY_ACCOUNT_MODAL,CREATE_SERV_REQUEST_MODAL, ACCEPTED, REJECTED, CANCELED, ALL, PENDING, CREATE_REQUISITION_HOST_MODAL, CREATE_SERV_REQ_PRIV, CREATE_FAST_REQ_PRIV, CREATE_FAST_REQUEST_MODAL, GRANT_ACCESS_PRIV, GRANT_ACCESS_MODAL, BLOCK_USER_PRIV, BLOCK_USER_MODAL, MAIN_PANEL_PRIV, REQUEST_PANEL, SETTINGS_PRIV, SETTINGS_PANEL, MAIN_PANEL, REPORTS_PRIV, REPORTS_PANEL, CREATE_REQUISITION_SERVICE_PROVIDER_MODAL, ADD_VISITOR_MODAL, ADD_VISITOR_PRIV, MIN_WINDOW_SIZE_FOR_ADMIN_PANELS, APP_NAME} from '@utils/constants'
import { pickVisitModalIDByUserType, doesUserHavePrivilege, doesVariableExist, isVisitorUser, validateObjectParameter } from '@utils/utils';
import { FilePlus, FileText, Slash, TrendingUp, User as UserIcon, UserPlus, Settings as SettingsIcon, BarChart2, LogOut} from 'react-feather';
import { filterMainPagevisitsByState, setNewHostFastRequestDefaultFields, setNewHostServiceRequestDefaultFields, setNewHostSimpleVisitDefaultFields, setNewVisitorVisitDefaultFields } from '@actions/visits'
import { logout, getNewHostObject } from '@actions/auth';

const contentPagesActiveState = {REQUEST_PANEL, MAIN_PANEL, SETTINGS_PANEL, REPORTS_PANEL};
const requestFilterActiveState = {ACCEPTED, REJECTED, CANCELED, PENDING, ALL}

const SideNavigation = ({page ,open_modal, logout, generalState, fetchAllVisitors, loggedUser, changeContentPage,fetchMainPanelStatsOfAccount, getNewHostObject, filterMainPagevisitsByState, setNewHostFastRequestDefaultFields, setNewHostSimpleVisitDefaultFields, setNewVisitorVisitDefaultFields, setNewHostServiceRequestDefaultFields}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        isReqOptionCollapsed: true,
        windowSize: window.innerWidth
    });

    useEffect(() => {
        const handleResize = (e) => setState({...state, windowSize: window.innerWidth});
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize)};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const visitStats = validateObjectParameter(generalState);
    const {isReqOptionCollapsed} = state;

    const openModal = (modalID) => { 
        if (page !== REQUEST_PANEL) 
            onDirectSelectOption(REQUEST_PANEL);
        
        if ((modalID === CREATE_REQUISITION_X_MODAL) || (modalID === CREATE_REQUISITION_HOST_MODAL)){
            if(isVisitorUser(loggedUser)) 
                setNewVisitorVisitDefaultFields({}, loggedUser);
            else 
                setNewHostSimpleVisitDefaultFields({}, loggedUser);
        } else if (modalID === CREATE_SERV_REQUEST_MODAL){
                setNewHostServiceRequestDefaultFields({}, loggedUser);
        } else if (modalID === CREATE_FAST_REQUEST_MODAL){
                setNewHostFastRequestDefaultFields({}, loggedUser);
        } else if (modalID === GRANT_ACCESS_MODAL)  {
                // getNewHostObject({}, loggedUser)
        } else if (modalID === BLOCK_USER_MODAL){
            fetchAllVisitors();
        } else if (modalID === CREATE_REQUISITION_SERVICE_PROVIDER_MODAL) { 
            // loggedUser.createVisit();
        } else if (modalID === ADD_VISITOR_MODAL) { 
            // loggedUser.createEntityWorker();
        }
        
        open_modal(modalID);
    }

    const onDirectSelectOption = (page) => { 
        changeContentPage(page);
    }

    const onSelectContentPage = (pageID) => {
        if(doesVariableExist(pageID)){
            changeContentPage(pageID);
            onFilterVisits(ALL);
            if(pageID === MAIN_PANEL)
                fetchMainPanelStatsOfAccount(loggedUser);
        } 
    }
    
    const onCollapseToggle = () => { 
        const element = document.querySelector('.sub-items.collapsible');
        const style = getComputedStyle(element);
        if (style.maxHeight === "0%")
            element.style.maxHeight = "100%";
        else 
            element.style.maxHeight = "0%";
        
        setState({...state, isReqOptionCollapsed: element.style.maxHeight === "0%" });
    }

    const onFilterVisits = (filterID) => { 
        filterMainPagevisitsByState(filterID);
        setState({...state, filterID});
        Object.keys(requestFilterActiveState).forEach(key => { 
            requestFilterActiveState[key] = key === filterID? 'active-option':'';
        })
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS
     // ::::::::::::::::::::::::::::::: CSS FLAGS
    Object.keys(contentPagesActiveState).forEach(key => { 
        contentPagesActiveState[key] = page === key? 'active-option': '';
    });
        
    // ::::::::::::::::::::::::::::::: CONDITIONAL ELEMENTS
    const addColaboratorOption = 
        <div className="nav-item" onClick={e => openModal(ADD_VISITOR_MODAL)}>
            <UserPlus/>
            <span>{t('add_col')}</span>
        </div>

    const createServiceRequestOption = 
        <div className="nav-item" onClick={e => openModal(CREATE_SERV_REQUEST_MODAL)}>
            <FilePlus/>
            <span>{t('cre_serv_req')}</span>
        </div>
    
    const createFastRequestOption = 
        <div className="nav-item" onClick={e => openModal(CREATE_FAST_REQUEST_MODAL)}>
            <FilePlus/>
            <span>{t('cre_fas_req')}</span>
        </div>

    const grantAccessOption = 
        <div className="nav-item" onClick={e => openModal(GRANT_ACCESS_MODAL)}>
            <UserPlus/>
            <span>{t('gra_acc')}</span>
        </div>

    const blockUserOption = 
        <div className="nav-item" onClick={e => openModal(BLOCK_USER_MODAL)}>
            <Slash/>
            <span>{t('blo_use')}</span>
        </div>
    
    const mainPanelOption = 
        <div className={`nav-item ${contentPagesActiveState[MAIN_PANEL]}`} onClick={e => onSelectContentPage(MAIN_PANEL)}>
            <BarChart2/>
            <span>{t('mai_pan')}</span>
        </div>

    const settingsPanelOption = 
        <div className={`nav-item ${contentPagesActiveState[SETTINGS_PANEL]}`} onClick={e => onSelectContentPage(SETTINGS_PANEL)}>
            <SettingsIcon/>
            <span>{t('set_pan')}</span>
        </div>

    const reportsPanelOption = 
        <div className={`nav-item ${contentPagesActiveState[REPORTS_PANEL]}`} onClick={e => onSelectContentPage(REPORTS_PANEL)}>
            <TrendingUp/>
            <span>{t('rep_pan')}</span>
        </div>

    // ::::::::::::::::::::::::::::::: HTML CONDITIONAL TEXT AND STATE
    const reqCollapseIconText = isReqOptionCollapsed? "+":"-";
    const isAllowedWidth = state.windowSize > MIN_WINDOW_SIZE_FOR_ADMIN_PANELS;
    const isToShowReportsPanelOption = doesUserHavePrivilege(loggedUser, REPORTS_PRIV) && isAllowedWidth;
    const isToShowSettingsPanelOption = doesUserHavePrivilege(loggedUser, SETTINGS_PRIV) && isAllowedWidth;
    const isToShowMainPanelOption = doesUserHavePrivilege(loggedUser, MAIN_PANEL_PRIV) && isAllowedWidth;

    return (
        <div className="internal-side-nav-container">
            <div className="app-name">
                <h2 className="text-bold text-center">{APP_NAME}</h2>
            </div>
            <nav className="internal-side-nav">
                {/* :: MAIN PANEL OPTION */}
                { isToShowMainPanelOption && mainPanelOption}
                {/* :: SETTINGS PANEL OPTION */}
                { isToShowSettingsPanelOption && settingsPanelOption}
                {/* :: REPORTS PANEL OPTION */}
                { isToShowReportsPanelOption && reportsPanelOption}
                {/* :: MY REQUEST OPTION */}
                <div className={`nav-item ${contentPagesActiveState[REQUEST_PANEL]}`} onClick={e => onSelectContentPage(REQUEST_PANEL)}>
                    <FileText/>
                    <span>{t('requests')}</span>
                    <span className="badge badge-primary">{visitStats[ALL]}</span>
                    <span className="badge badge-light ml-4 p-1" onClick={e => onCollapseToggle()}>{reqCollapseIconText}</span>
                </div>
                {/* :: MY REQUEST OPTION SUB OPTIONS */}
                <div className="sub-items collapsible">
                    <div className={`nav-item ${requestFilterActiveState[ALL]}`} onClick={e => onFilterVisits(ALL)}>
                        <span>{t('all')}</span>
                        <span className="badge badge-light">{visitStats[ALL]}</span>
                    </div>
                    <div className={`nav-item ${requestFilterActiveState[PENDING]}`} onClick={e => onFilterVisits(PENDING)}>
                        <span>{t('pending')}</span>
                        <span className="badge badge-light">{visitStats[PENDING]}</span>
                    </div>
                    <div className={`nav-item ${requestFilterActiveState[ACCEPTED]}`} onClick={e => onFilterVisits(ACCEPTED)}>
                        <span>{t('accepted')}</span>
                        <span className="badge badge-light">{visitStats[ACCEPTED]}</span>
                    </div>
                    <div className={`nav-item ${requestFilterActiveState[REJECTED]}`} onClick={e => onFilterVisits(REJECTED)}>
                        <span>{t('rejected')}</span>
                        <span className="badge badge-light">{visitStats[REJECTED]}</span>
                    </div>
                    <div className={`nav-item ${requestFilterActiveState[CANCELED]}`} onClick={e => onFilterVisits(CANCELED)}>
                        <span>{t('canceled')}</span>
                        <span className="badge badge-light">{visitStats[CANCELED]}</span>
                    </div>
                </div>
                <div className="separator bg-faded my-2"></div>
                {/* :: CREATE REQUISITION OPTION */}
                <div className="nav-item" onClick={e => openModal(pickVisitModalIDByUserType(loggedUser))}>
                    <FilePlus/>
                    <span>{t('cre_req')}</span>
                </div>
                {/* :: ADD COLABORATOR (VISITOR SIDE) OPTION */}
                {doesUserHavePrivilege(loggedUser, ADD_VISITOR_PRIV) && addColaboratorOption}
                {/* :: CREATE SERVICE REQUEST OPTION */}
                {doesUserHavePrivilege(loggedUser, CREATE_SERV_REQ_PRIV) && createServiceRequestOption}
                {/* :: CREATE FAST REQUEST OPTION */}
                {doesUserHavePrivilege(loggedUser, CREATE_FAST_REQ_PRIV) && createFastRequestOption}
                {/* :: GRANT ACCESS OPTION */}
                {doesUserHavePrivilege(loggedUser, GRANT_ACCESS_PRIV) && grantAccessOption}
                {/* :: BLOCK USER OPTION */}
                {doesUserHavePrivilege(loggedUser, BLOCK_USER_PRIV) && blockUserOption}
                {/* :: MY ACCOUNT OPTION */}
                <div className="nav-item" onClick={e => openModal(MY_ACCOUNT_MODAL)}>
                    <UserIcon/>
                    <span>{t('my_acc')}</span>
                </div>
                {/* :: LOGOUT OPTION */}
                <div className="nav-item" onClick={e => logout()}>
                    <LogOut/>
                    <span>{t('exit')}</span>
                </div>
            </nav>
        </div>
    )
}

SideNavigation.propTypes = {
    flag: PropTypes.bool,
    open_modal: PropTypes.func.isRequired,
    loggedUser: PropTypes.object,
    changeContentPage: PropTypes.func.isRequired,
    page: PropTypes.string,
    generalState: PropTypes.object,
    setNewVisitorVisitDefaultFields: PropTypes.func.isRequired,
    setNewHostSimpleVisitDefaultFields: PropTypes.func.isRequired,
    setNewHostServiceRequestDefaultFields: PropTypes.func.isRequired,
    setNewHostFastRequestDefaultFields: PropTypes.func.isRequired,
    filterMainPagevisitsByState: PropTypes.func.isRequired,
    getNewHostObject: PropTypes.func.isRequired,
    fetchAllVisitors: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loggedUser: state.auth.user,
    page: state.admin.page,
    generalState: state.visits.generalState,
})

const mapDispatchToProps = {
    open_modal,
    changeContentPage,
    setNewVisitorVisitDefaultFields,
    setNewHostSimpleVisitDefaultFields,
    setNewHostServiceRequestDefaultFields,
    setNewHostFastRequestDefaultFields,
    filterMainPagevisitsByState,
    getNewHostObject,
    fetchMainPanelStatsOfAccount,
    fetchAllVisitors,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation)
