import { toastErrorMessage } from '@actions/toast';
import PropTypes from 'prop-types';
import { fixNewCompanyFields } from '@utils/validations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';

const COMPANY_NAME_INPUT_ICON = require("@resources/icons/company.svg");
const EMAIL_INPUT_ICON = require("@resources/icons/email.svg");
const PHONE_NUMBER_INPUT_ICON = require("@resources/icons/phone.svg");

const CompanyDataForm = ({newCompany, newUser, toastErrorMessage}) => {
    const { t } = useTranslation('auth');
    const [state, setState] = useState({
        company: {},
        show_modal: false,
    });

    const {show_modal} = state;
    const {p_cemail, p_cname, p_cpnumber_1, p_cpnumber_2, country_code_p1, country_code_p2} = newCompany;
    const {terms_condition} = newUser;

    useEffect(() => { 
        fixNewCompanyFields(newCompany);
        setState({...state, company: newCompany});
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const onChange = e => { 
        const nodeName = e.target.name || e.target.getAttribute('name');
        const nodeValue = e.target.value;
        try {
            if (nodeName === 'terms_condition') {
                newUser['terms_condition'] = !terms_condition;
            } else {
                newCompany[nodeName] = nodeValue;
                fixNewCompanyFields(newCompany);
            }
            setState({...state, company: newCompany});
        } catch (error) {
            newCompany[nodeName] = '';
            toastErrorMessage(error.message);
        }
    }

    const openTermsAndConditions = (e) => {
        setState({...state, show_modal: true})
    }

    const toggleTermsAndCondition = (e, val) => {
        setState({...state, terms_condition: val, show_modal: false});
        newUser['terms_condition'] = val;
    }

    // :::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const TERMS_AND_CONDITION = (
      <div>
        <Modal isOpen={show_modal} size="xl" className="no-border" tabIndex="-1" centered >
          <h2 className="title-of-modal"> {t("ter_and_cond")} </h2>
          <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto", padding:"20px 70px" }} >
            <div>
              <p>A entrada nas instalações do Porto é por conta e risco próprio e está sujeita às seguintes condições gerais:</p>
              <ul style={{listStyleType:"lower-latin"}}>
                <li className="text-justify my-4">A MPDC reserva-se ao direito de restringir a entrada no, e de controlar a saída do Porto;</li>
                <li className="text-justify my-4">A MPDC reserva-se ao direito de recusar a entrada a qualquer pessoa, veículo ou empresa sem notificação ou explicação prévia;</li>
                <li className="text-justify my-4">É obrigatório participar no processo de indução de segurança da MPDC antes de aceder às instalações do Porto;</li>
                <li className="text-justify my-4">Sem prejuízo das legislações existentes, pessoas não autorizadas encontradas no recinto Portuário serão sujeitas a procedimentos criminais;</li>
                <li className="text-justify my-4">A entrada de crianças no Porto será permitida somente para propósitos educacionais, após aprovação da MPDC e quando forem familiares de um membro da tripulação, para propósitos de visita / juntar-se ao Navio. Em ambas as situações, as crianças devem estar sempre acompanhadas por um adulto;</li>
                <li className="text-justify my-4">Não são permitidos animais no recinto do Porto, excepto para propósitos de Importação/Exportação mediante intervenção de entidades de fiscalização;</li>
                <li className="text-justify my-4">Não são permitidas no recinto do Porto quaisquer tipo de armas ou munições de qualquer natureza, excepto a Polícia e Unidades Militares quando em serviço;</li>
                <li className="text-justify my-4">O Porto tem a política de tolerância zero ao álcool e realizará testagens obrigatórias a todos que entrarem nas suas instalações, ao pessoal com resultado positivo, o acesso será recusado e emitido um relatório para tomada de medidas;</li>
                <li className="text-justify my-4">É proibido tirar fotografias, excepto com autorização do departamento de Segurança;</li>
                <li className="text-justify my-4">É obrigatório ligar as luzes do veículo e usar EPI / PPE em áreas operacionais</li>
                <li className="text-justify my-4">O uso de telefones móveis durante a condução e/ou enquannto caminha é proibido;</li>
                <li className="text-justify my-4">A MPDC através do departamento de Segurança reserva-se ao direito de revistar qualquer pessoa ou veículo que entrar ou sair do Porto. </li>
                <li className="text-justify my-4">Os sinais e marcações de tráfego rodoviário, incluindo o limite de velocidade de 30 km/h na estrada principal e de 10 km/h nas bancas e Cais, são de cumprimento obrigatório;</li>
                <li className="text-justify my-4">A circulação de veículos no Porto é feita através das vias devidamente indicadas e sinalizadas;</li>
                <li className="text-justify my-4">Exceder o limite de velocidade ou qualquer violação das normas do Porto está sujeito a penalizações;</li>
                <li className="text-justify my-4">Os veículos devem estar equipados com pirilampo;</li>
                <li className="text-justify my-4">Fumar é somente permitido em áreas designadas;</li>
                <li className="text-justify my-4">Somente veículos em boas condições de circulação e ou com certificado de inspecção de veículos válido serão autorizados a aceder ao Porto;</li>
                <li className="text-justify my-4">Os agentes que visitarem navios deverão estacionar os seus veículos em áreas de estacionamento designadas;</li>
                <li className="text-justify my-4">O estacionamento durante a noite, sem ser autorizado, ou após concluir o carregamento ou o descarregamento de carga, bem como reparações nas instalações do Porto ou estacionamento por tempo superior ao autorizado, não é permitido. Nos casos de não cumprimento, serão aplicadas taxas em conformidade com a tarifa do Porto e os veículos / camiões serão libertados somente após o pagamento ser confirmado com o escritório de facturação do Porto; e</li>
                <li className="text-justify my-4">Quaisquer danos às infra-estruturas do Porto, causados por terceiros, estão sujeitos a reparação ou substituição obedecendo os padrões, termos e condições do Porto. As intervenções devem ser realizadas num prazo máximo de 8 horas após o incidente, salvo nos casos em que apresentar confirmação da seguradora. </li>
              </ul>
              <ul style={{listStyleType: "none"}}>
                <li className="bold">Por este meio confirmo que tomei conhecimento das condições em que tenho autorização de acesso ao Porto de Maputo.</li>
              </ul>
            </div>
          </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
            <Button color="secondary" name="show_modal" onClick={(e) => toggleTermsAndCondition(e, false)} > Reject </Button>
            <Button color="success" name="show_modal" onClick={(e) => toggleTermsAndCondition(e, true)} > Accept </Button> 
        </ModalFooter>
        </Modal>
      </div>
    );
  

    return (
        <div className="form-page">
            {/* :: TITLE */}
            <h2 className="form-header">{t('com_dat')}</h2>
            {/* :: COMPANY NAME INPUT */}
            <div className="form-group iconed-input required">
                <img src={COMPANY_NAME_INPUT_ICON} alt="user_icon" />
                <input type="name" className="form-control dark-input" name="p_cname" placeholder={t("com_nam")} value={p_cname} onChange={e => onChange(e)} />
            </div>
            {/* :: COMPANY EMAIL INPUT */}
            <div className="form-group iconed-input required">
                <img src={EMAIL_INPUT_ICON} alt="user_icon" />
                <input type="email" className="form-control dark-input" name="p_cemail" placeholder={t("email")} value={p_cemail} onChange={e => onChange(e)}/>
            </div>
            {/* :: COMPANY PHONE NUMBERS */}
            <div className="form-group row  ss-inputs">
                <div className="col-lg-6">
                    {/* :: PHONE NUMBER 01 INPUT */}
                    <div className="phone-number-input-container iconed-input required">
                        <img src={PHONE_NUMBER_INPUT_ICON} alt="user_icon"/>
                        <input type="tel" className="form-control  dark-input small-input" name="country_code_p1" value={country_code_p1} onChange={e => onChange(e)}/>
                        <input type="tel" className="form-control dark-input"  name="p_cpnumber_1" placeholder={t("cel_num")} value={p_cpnumber_1} onChange={e => onChange(e)}/>
                    </div>
                </div>
                <div className="col-lg-6">
                    {/* :: PHONE NUMBER 02 INPUT */}
                    <div className="phone-number-input-container iconed-input required">
                        <img src={PHONE_NUMBER_INPUT_ICON} alt="user_icon"/>
                        <input type="tel" className="form-control  dark-input small-input" name="country_code_p2" value={country_code_p2} onChange={e => onChange(e)} />
                        <input type="tel" className="form-control dark-input" name="p_cpnumber_2" placeholder={t("cel_num")} value={p_cpnumber_2} onChange={e => onChange(e)} />
                    </div>
                </div>
            </div>
            {/* :: TERMS AND CONDITIONS INPUT */}
            <div className="form-group">
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="terms-and-conditions" checked={terms_condition} name="terms_condition" onChange={(e) => openTermsAndConditions(e)} />
                    <label className="custom-control-label dark-label" htmlFor="terms-and-conditions">{t("read_tc")}{" "}<span className="text-highlighted-dark-form">{t("terms_and_condition")}</span></label>
                </div>
            </div>
            {TERMS_AND_CONDITION}
        </div>
    )
}
CompanyDataForm.propTypes = {
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    toastErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDataForm)

