import { isVisitToLoggedUser } from "@actions/visits";
import { getAppConfig } from "./configurations";
import {
  ACCEPTED,
  ACCEPTED_BTN_MESSAGE,
  ACCEPT_BTN_MESSAGE,
  CANCELED,
  CANCELED_BTN_MESSAGE,
  CANCEL_BTN_MESSAGE,
  CORE_SEC,
  CORE_SEC_ACT,
  HOST,
  HOST_ACT,
  INDUCTION,
  REJECTED,
  REJECTED_BTN_MESSAGE,
  THREAD,
  THREAD_ACT,
} from "./constants";
import {
  getLoggedUserType,
  getMomentFromString,
  getToday,
  isCAMemberUser,
  isCoreSecurityRequest,
  isCoreSecurityUser,
  isEmptyObject,
  isHostRequest,
  isHostUser,
  isThreadRequest,
  isThreadUser,
  isThreadUserFromMPDC,
} from "./utils";

export const buildVisitsTableUtilsObject = (visit, loggedUser) => {
    let hostState, threadState, coreSecState;
    let hostIcon, threadIcon, coreSecIcon;

    let visitDate, numberOfVisitors, noActionText;
    let isToShowHostState, isToShowThreadState, canTakeAction;
    let acceptBtnColor, rejectBtnColor, editBtnColor;

    const { grouped, p_date_time } = visit;
    const hasUserChangedState = hasLoggedUserChangedVisitState(visit,loggedUser);
    const hasBeenCanceled = isVisitCanceled(visit);
    const rejectBtnString = getLoggedUserRejectString(hasUserChangedState,visit.states[getLoggedUserType(loggedUser)]);
    const acceptButtonString = getAcceptBtnString(visit, loggedUser);
    const isInducting = isInInductionProcess(visit);
    const hasExpired = hasVisitExpired(visit);
    const hasVisitorsChanged = hasVisitVisitorsChanged(visit);
    const isInternalFastVisit = isInternalFastRequest(visit);
    const isMyVisit = isVisitMadeByMe(visit, loggedUser);

     //::>> Colors for the state icons
     hostState = isVisitStateOfTypeUserInVisit(visit, HOST, ACCEPTED)? 'text-green': (isVisitStateOfTypeUserInVisit(visit, HOST_ACT, false))? 'text-yellow': 'text-red';
     threadState = isVisitStateOfTypeUserInVisit(visit, THREAD, ACCEPTED)? 'text-green': (isVisitStateOfTypeUserInVisit(visit, THREAD_ACT, false))? 'text-yellow': 'text-red';
     coreSecState = isVisitStateOfTypeUserInVisit(visit, CORE_SEC, ACCEPTED)? 'text-green': (isVisitStateOfTypeUserInVisit(visit, CORE_SEC_ACT, false))? 'text-yellow': 'text-red';

     //::>> The State icons Selection 
     hostIcon = isVisitStateOfTypeUserInVisit(visit, HOST, ACCEPTED)? 'check_circle': (isVisitStateOfTypeUserInVisit(visit, HOST_ACT, false))? 'watch_later': 'cancel';
     threadIcon = isVisitStateOfTypeUserInVisit(visit, THREAD, ACCEPTED)? 'check_circle': (isVisitStateOfTypeUserInVisit(visit, THREAD_ACT, false))? 'watch_later': 'cancel';
     coreSecIcon = isVisitStateOfTypeUserInVisit(visit, CORE_SEC, ACCEPTED)? 'check_circle': (isVisitStateOfTypeUserInVisit(visit, CORE_SEC_ACT, false))? 'watch_later': 'cancel';

     //::>> Changes the state icons if one of these conditions evaluates true
     hostIcon = isVisitStateOfTypeUserInVisit(visit, HOST, CANCELED)? 'remove_circle': hostIcon;
     threadIcon = isVisitStateOfTypeUserInVisit(visit, THREAD, CANCELED)? 'remove_circle': threadIcon;
     coreSecIcon = isVisitStateOfTypeUserInVisit(visit, CORE_SEC, CANCELED)? 'remove_circle': coreSecIcon;

     //::>> Changes the state icons color if one of these conditions evaluates true
     hostState = isVisitStateOfTypeUserInVisit(visit, HOST, CANCELED)? 'text-red': hostState;
     threadState = isVisitStateOfTypeUserInVisit(visit, THREAD, CANCELED)? 'text-red': threadState;
     coreSecState = isVisitStateOfTypeUserInVisit(visit, CORE_SEC, CANCELED)? 'text-red': coreSecState;

     const canEdit = (!hasBeenCanceled && !hasVisitBeenRejected(visit) && isMyVisit) || (!visit.p_internal && (visit.p_id_host === loggedUser.p_id));
     visitDate = ` ${getMomentFromString(p_date_time).format("ddd, MMMM Do")}`;

    //  let visitors = grouped? grouped.filter( visit => loggedUser.p_id !== visit.p_id_x): [];
    let visitors = grouped? grouped: [];
    numberOfVisitors = visitors.length;

     isToShowHostState = (!isThreadRequest(visit) && !isCoreSecurityRequest(visit));
     isToShowThreadState = !isCoreSecurityRequest(visit);
     canTakeAction = ((!hasExpired && !isInducting) || (!hasExpired && (grouped && isInducting))) && !visit.sv_is_ps;
    

     acceptBtnColor = (acceptButtonString === ACCEPTED_BTN_MESSAGE)? 'btn-green-active': 'btn-green';
     rejectBtnColor = ((rejectBtnString === CANCELED_BTN_MESSAGE) || (rejectBtnString === REJECTED_BTN_MESSAGE))? 'btn-error-active': 'btn-error';
     editBtnColor = canEdit? "btn-yellow" : "disabled-span-btn ";
     noActionText = hasExpired? 'expired': !visit.sv_is_ps? 'inducting': '-';
     
     //:: Detailed conditions
     if(isThreadUserFromMPDC(loggedUser)) {
      canTakeAction = canTakeAction && (visit.p_id_company === loggedUser.p_id_company);
      noActionText = "-"
    }

    if(isCAMemberUser(loggedUser)) { 
      canTakeAction = canTakeAction && isVisitToLoggedUser(visit,loggedUser)
      noActionText = '-'
    }

     return {visitDate, numberOfVisitors,noActionText, isToShowHostState, isToShowThreadState, canTakeAction, acceptBtnColor, rejectBtnColor, editBtnColor,
            rejectBtnString, acceptButtonString, hasExpired, hasVisitorsChanged, isInternalFastVisit, isMyVisit, hostState, threadState, coreSecState,
            hostIcon, threadIcon, coreSecIcon, canEdit }

};

const isVisitStateOfTypeUserInVisit = (visit, typeUser, state) => { 
    return visit.states[typeUser] === state;
}

const hasVisitVisitorsChanged = (visit) => { 
    //TODO: implement
    return false;
}

const hasLoggedUserChangedVisitState = (visit, loggedUser) => {
  if (isHostUser(loggedUser)) {
    return visit.states[HOST_ACT];
  } else if (isThreadUser(loggedUser)) {
    return visit.states[THREAD_ACT];
  } else {
    return visit.states[CORE_SEC_ACT];
  }
};

const isVisitCanceled = (visit) => {
  let hasBeenCanceled = false;
  for (let state of Object.values(visit.states)) {
    if (state === CANCELED) hasBeenCanceled = true;
  }
  return hasBeenCanceled;
};

const getLoggedUserRejectString = (hasUserChangedState, visitState) => {
  if (hasUserChangedState === true) {
    if (visitState === CANCELED) {
      return CANCELED_BTN_MESSAGE;
    } else if (visitState === REJECTED) {
      return REJECTED_BTN_MESSAGE;
    } else if (visitState === ACCEPTED) {
      return CANCEL_BTN_MESSAGE;
    }
  }
  return "reject";
};

const getAcceptBtnString = (visit, loggedUser) => {
  let message = ACCEPT_BTN_MESSAGE;
  const hasAccepted = hasUserChangedVisitState(visit, loggedUser);
  const hasUsrCanceled = hasUserCanceledVisit(visit, loggedUser);
  const hasUsrRejected = hasUserRejectedVisit(visit, loggedUser);
  const hasBeenCanceled = hasVisitBeenCanceled(visit);
  const hasBeenRejected = hasVisitBeenRejected(visit);
  const isMyVisit = isVisitMadeByMe(visit, loggedUser);

  if (hasBeenCanceled || hasBeenRejected) {
    if (hasUsrRejected || hasUsrCanceled || (!isMyVisit && !hasAccepted))
      message = ACCEPT_BTN_MESSAGE;
    else message = ACCEPTED_BTN_MESSAGE;
  } else if (!hasBeenRejected && !hasBeenCanceled) {
    if (!hasUsrRejected && hasAccepted) message = ACCEPTED_BTN_MESSAGE;
    else message = ACCEPT_BTN_MESSAGE;
  }

  return message;
};

const hasUserCanceledVisit = (visit, loggedUser) => {
  return checkVisitStateInVisit(visit, CANCELED, loggedUser);
};

const hasUserRejectedVisit = (visit, loggedUser) => {
  return checkVisitStateInVisit(visit, REJECTED, loggedUser);
};

const hasVisitBeenCanceled = (visit) => {
  return checkIfStateOccurredInVisit(visit, CANCELED);
};

const hasVisitBeenRejected = (visit) => {
  return checkIfStateOccurredInVisit(visit, REJECTED);
};

const checkIfStateOccurredInVisit = (visit, stateToCheck) => {
  for (let state of Object.values(visit.states)) {
    if (state === stateToCheck) return true;
  }
  return false;
};

const isInInductionProcess = (visit) => {
  return visit.states[INDUCTION];
};

// NOTE: This method will retrun false if visit has ended.
const hasVisitStarted = (visit) => { 
    let hasStarted = false;
    if((visit.sv_is_done === 0))
      hasStarted = true;
    return hasStarted;
}

const isLongPeriodVisit = (visit) => {
  const { LONG_PERIOD_VISIT_BY_HOUR } = getAppConfig(); 
  return visit.p_period >= LONG_PERIOD_VISIT_BY_HOUR;
}


export const hasVisitExpired = (visit) => {
  
  let hasExpired = false;
  if (isEmptyObject(visit)) 
    hasExpired = true;

  const periodToAdd = 6 || visit.p_period;
  const today = getToday();
  const vDate = getMomentFromString(visit.p_date_time);
  // if (today.isAfter(vDate.add(6, 'hours')) && (visit.sv_is_done || visit.sv_is_done === null) && visit.state === ACCEPTED) return true;
  if (isLongPeriodVisit(visit)) { 
    if (today.isAfter(vDate.add(1, "days").add(periodToAdd, "hours")) && !hasVisitStarted(visit))
        hasExpired = true;
  } else {
    if (today.isAfter(vDate.add(periodToAdd, "hours")) && !hasVisitStarted(visit))
        hasExpired = true;
  }
 
  return hasExpired;
};

const isVisitMadeByMe = (visit, loggedUser) => {
  if (visit.p_internal && visit.p_id_host === loggedUser.p_id) {
    if (isHostUser(loggedUser)) {
      return isHostRequest(visit);
    } else if (isThreadUser(loggedUser)) {
      return isThreadRequest(visit);
    } else {
      return isCoreSecurityRequest(visit);
    }
  }
  return false;
};

const hasUserChangedVisitState = (visit, loggedUser) => {
  let changedVisitState = "";
  if (isHostUser(loggedUser)) {
    changedVisitState = visit.states[HOST_ACT];
  } else if (isThreadUser(loggedUser)) {
    changedVisitState = visit.states[THREAD_ACT];
  } else if (isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser)) {
    changedVisitState = visit.states[CORE_SEC_ACT];
  } else throw new Error("Could not identify state of visit!");

  return changedVisitState;
};

const checkVisitStateInVisit = (visit, state, loggedUser) => {
  let hasChanged = false;
  if (isHostUser(loggedUser)) {
    hasChanged = visit.states[HOST] === state;
  } else if (isThreadUser(loggedUser)) {
    hasChanged = visit.states[THREAD] === state;
  } else if (isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser)) {
    hasChanged = visit.states[CORE_SEC] === state;
  } else {
    throw new Error("Could not identify state of visit!");
  }
  return hasChanged;
};

const isInternalFastRequest = visit => { 
    return visit.p_id_host === visit.p_id_x;
}