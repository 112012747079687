import React, { Fragment, useState } from 'react'
import { FormGroup, CustomInput, ListGroup, ListGroupItem, Badge } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { ROOT_URL } from '@utils/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { uploadTemplateFile } from '@actions/admin';
import { v4 } from 'uuid';
import { doesArrayExist, isEmptyValue, isVisitToUpdate } from '@utils/utils';
import { fixNewUserFields } from '@utils/validations';
import { toastErrorMessage } from '@actions/toast';
import { useEffect } from 'react';
import SingleUserForm from './SingleUserForm';

const TEMPLATE_FILE_URL = `${ROOT_URL}/files/visitors/templates`;

const HostsVisitDataPage1 = ({visit, reqUser, toastErrorMessage, uploadTemplateFile}) => {
    const {t} = useTranslation('request');
    const [state, setState] = useState({
        loading: false,
        flag: false,
    });

    const {loading} = state;
    const {is_collective, file_name} = visit;
    const to_edit = isVisitToUpdate(visit);

    useEffect(() => { 
        try {
            fixNewUserFields(reqUser);
        } catch (error) {
            toastErrorMessage(error.message);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    // ::: EVENT HANDLERS
    const changeSwitch = e => { 
        visit['is_collective'] = !is_collective;
        setState({...state, flag: !state.flag});
    }

    const generateVisitorsOfFileList = (e) => { 
        let listOfVisitors = visit.p_users;
      
        if((visit.p_users[0].p_nid === reqUser.p_nid) || isEmptyValue(visit.p_users[0].p_nid))
            listOfVisitors = listOfVisitors.slice(1);

        let visitorName, visitorDataStatus, visitorDataStatusColor;
        listOfVisitors = listOfVisitors.map(vis => {
            visitorName = vis.p_fname + ' ' + vis.p_lname;
            visitorDataStatus = vis.error? t(vis.messages.error) : vis.warning? t(vis.messages.warning):t('success');
            visitorDataStatusColor = vis.error? "danger": vis.warning? "warning": "success";

            return <ListGroupItem key={vis.p_nid} className="justify-content-between"> {visitorName}
                        <Badge color={visitorDataStatusColor}>{visitorDataStatus}</Badge>
                    </ListGroupItem>
            })

        return listOfVisitors;
    }
    
    const onFileSelect = async (e) => { 
        const file = e.target.files[0];
        if (!file) return;
        setState({...state, selectedFile: file.name, loading: true});
        const data = new FormData();
        data.append("file", file);
        data.append("name", file.name);
        const res = await uploadTemplateFile(data, 1); 
        setState({...state, loading: false});

        if (res) {
            res.forEach(usr => {
                usr.p_is_toEmail = 1;
            });
            visit['p_users'] = res;
        } 
        setState({...state, flag: !state.flag});
    }

    // ::::::::::::::::::::::::::::::::: HTML ELEMENTS
    // :::: HTML CONDITIONAL VARIABLES
    const isToShowAddVisitorForm = !to_edit;
    const isToShowViewOnlyVisitorsList = to_edit;
    const istToShowUploadeVisitorsList = is_collective;
    const isToShowSingleUserForm = !is_collective;

    const _selectedVisitorsList =  <FormGroup>
                                        <ListGroup>
                                            {doesArrayExist(visit.p_users) && generateVisitorsOfFileList()}
                                        </ListGroup>
                                    </FormGroup>    
                                    
    const _viewOnlyVisitorsListGroup = <FormGroup>
                                            <ListGroup>
                                                {doesArrayExist(visit.grouped) && visit.grouped.map(vis => { 
                                                    return <ListGroupItem key={v4()} className="justify-content-between">{vis.v_name}</ListGroupItem>
                                                    })}
                                            </ListGroup>
                                        </FormGroup>

    const _addVisitorsForm =    <Fragment>
                                    {/* ::: COLLECTIVE VISITOR SWITCH INPUT */}
                                    <FormGroup className="mt-4">
                                        <CustomInput type="switch" name="is_collective" checked={!isToShowSingleUserForm ? "checked" : false} id="custonSwithd" onChange={(e) => changeSwitch(e)}  inline label={file_name? file_name:t("is_col")}/>
                                        {loading && <img alt="loading" className="mx-auto my-2 loading-gif" src={require(`@resources/icons/loading.gif`)}/>}
                                    </FormGroup>
                                    {/* ::: VISITORS FILE INPUT */}
                                    <FormGroup>
                                        <CustomInput disabled={isToShowSingleUserForm} type="file" id="collectiveFile" label={t("cho_fil")} name="file" onChange={e => onFileSelect(e)}/>
                                        <small><a className="text-green pl-1 link-text" href={TEMPLATE_FILE_URL}>{t('dow_tem_fil')}</a></small>
                                    </FormGroup>
                                    {/* ::: SELECTED VISITORS LIST */}
                                    {istToShowUploadeVisitorsList && _selectedVisitorsList}
                                    <div className="separator light thin my-4"/>
                                    <div className="separator transparent mb-4"/>
                                    {isToShowSingleUserForm && <SingleUserForm user={reqUser}/>} 
                                </Fragment>

    

    return (
        <Fragment>
            {isToShowAddVisitorForm && _addVisitorsForm}
            {isToShowViewOnlyVisitorsList && _viewOnlyVisitorsListGroup}
        </Fragment>
    )
}


HostsVisitDataPage1.propTypes = {
    flag: PropTypes.bool,
    uploadTemplateFile: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    uploadTemplateFile,
    toastErrorMessage,
}


export default connect(mapStateToProps, mapDispatchToProps)(HostsVisitDataPage1);

