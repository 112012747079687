import store from "../../store";
import { update } from "@actions/admin";
import { sendApiRequest, checkApiResponseForErrors } from "@utils/utils";
import { toastErrorMessage } from "@actions/toast";
import { ROOT_URL } from "@utils/constants";

class InductionOption {

	constructor(id, option, isCorrect) {
        this.qid = `q_${id}_${option}`;
        this.isCorrect = isCorrect;
        this.file_name = 'new_file.jpg';
        this.img_name = 'Choose file...';
        this.altered = false;
	}

	set(key, val) {
		this[key] = val;
		store.dispatch(update());
	}

	get(key) {
		return this[key] || "";
	}
	

	/**
	 * Creates the induction options object
	 * @param {string} key 
	 * @param {bool} isCorrect 
	 */
    setInductionOption (key, isCorrect) { 
        this.qid = key;
        this.isCorrect = isCorrect;
        this.file_name = key + ".jpg";
        this.img_name = 'Choose new file...';
        this.altered = false;
    }
	
	/**
	 * Sets the induction option image.
	 * @param {file} file 
	 */
    setImage (file){
        this.q_img = file;
        this.img_name = file.name;
        store.dispatch(update());
        this.uploadImg();
    }

	/**
	 * Changes the induciton option image
	 * @param {file} file 
	 */
    async changeImage (file) { 
        this.file_name = 'new_file.jpg'
        store.dispatch(update());

        this.q_img = file;
        this.img_name = file.name;

        await this.uploadImg();
        this.file_name = this.qid + ".jpg";
        store.dispatch(update());
        
    }


	/**
	 * Uploads the induction option image.
	 */
	async uploadImg() {
		try {
			const requestConfig = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			const requestUrl = `${ROOT_URL}/files/uploadIndQuest`;
			const requestBody = new FormData();
			const file = this.q_img;
			const blob = file.slice(0, file.size, file.type);
			const newFile = new File([blob], this.qid , { type: file.type });
			requestBody.append("file", newFile);
			requestBody.append("name", file.name);

			const response = await sendApiRequest(requestUrl, requestBody, requestConfig);
            checkApiResponseForErrors(response);
            
            this.file_name = this.qid + '.jpg';
            this.altered = true;
            store.dispatch(update());
		} catch (err) {
			store.dispatch(toastErrorMessage(err.message));
		}
	}
}

export default InductionOption;
