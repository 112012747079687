import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getMomentFromString, getToday, isCAMemberUser, isCoreSecurityUser, isNotEmptyObject, validateArrayParameter, validateObjectParameter } from '@utils/utils'
import { DEFAULT_COMBOBOX_CODE, MAIN_PANEL_VIEW_VISIT_MODAL } from '@utils/constants'
import InternalNavBar from '@mainPage/Shared/InternalNavBar'
import ViewUserRejectedModal from '@colaboratorsPages/Shared/MainPanel/ViewUserRejectedModal'
import MainPanelVistsTable from '@colaboratorsPages/Shared/MainPanel/MainPanelVistsTable'
import StatsDisplay from './StatsDisplay'
import { fetchMainPanelStatsOfAccount, fetchStatsOfSelectedTerminal } from '@actions/admin'
import CustomSelectOption from '@components/Shared/FormElements/CustomSelectOption'
import Input from 'reactstrap/lib/Input'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import FormGroup from 'reactstrap/lib/FormGroup'
import MainPanelVisitorsTable from './MainPanelVisitorsTable'
import { useState } from 'react'

const REFRESH_ICON = require(`@resources/icons/cached.svg`);

const MainPanel = ({flag, user,fetchMainPanelStatsOfAccount, fetchStatsOfSelectedTerminal, mainPanelStats, modal}) => {
    const {t} = useTranslation('main');
    const [state, setState] = useState({
        tableNum: 0,
        selectedTerminal : DEFAULT_COMBOBOX_CODE,
    })
    const {tableNum, selectedTerminal} = state;

    // ::: HTML CONDITIONALS
    const isToShowPanelStats = isNotEmptyObject(mainPanelStats);
    const isCoreSecurity =(isCoreSecurityUser(user) || isCAMemberUser(user));

    // ::: STATS DATA ORGANIZATION
    const visitors = validateArrayParameter(mainPanelStats.visitors);
    const allVisitors = validateArrayParameter(mainPanelStats.allVisitors);
    let visits = validateArrayParameter(mainPanelStats.visits);
    visits = (isCoreSecurityUser(user) || isCAMemberUser(user))? visits : visits.filter(vis => !vis.sv_is_ps);
    const stats = validateObjectParameter(mainPanelStats.stats);
    const visitorsToShow = isCoreSecurity? allVisitors : visitors;

    const visitsToday = visits.filter(vis => {
        const isFromToday = getMomentFromString(vis.p_date_time).isSame(getToday(), 'day');
        const isDone = vis.sv_is_done === 0;
        const isPubServ = vis.sv_is_ps && isCoreSecurity;
        return isFromToday || isDone || isPubServ;
    });
    

    // ::: EVENT HANDLERS
    const refresh = (e) => { 
        fetchMainPanelStatsOfAccount(user);
    }

    const onSwitchTable = (tableNum) => { 
        setState({tableNum});
    }

    const onFilterTerminal = (e) => { 
        const terminalCode = e.target.value;
        setState({...state, selectedTerminal: terminalCode});
        fetchStatsOfSelectedTerminal(+terminalCode);
        
    }

    const visitsToShow = tableNum === 0? visitsToday : visits;
    const titleToShow = tableNum === 0? "vis_tod": "vis_mon";
   

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS 
    const _statsPanel = <div className="h-100 row px-4">
                            <div className="col-lg-8 col-md-12">
                                <div className="d-block w-100">
                                    <FormGroup>
                                        {isCoreSecurity && <Input type="select" className="w-25" name="p_id_department" value={selectedTerminal} onChange={e => onFilterTerminal(e)}>
                                            <CustomSelectOption options={AppFunctionalityUsable.terminals} hasId={true}/>
                                        </Input>}
                                    </FormGroup>
                                </div>
                                {/* ::: STATS FOR TOTAL VISITS, RECIEVED VISITS, VISITORS INSIDE */}
                                <div className="stats-panel row">
                                    <StatsDisplay stats={stats} visitors={visitors}/>
                                </div>
                                <div className="separator light thin mt-5 mb-3"/>
                                {/* ::: TABLES NAVIGATION */}
                                <div className="mb-1 d-flex justify-content-center">
                                    <nav>
                                        <ul className="pagination pagination-sm">
                                            <li className={`page-item hoverable ${tableNum === 0? "active": ""}`} onClick={e => onSwitchTable(0)} ><span className="page-link">{t("vis_tod")}</span></li>
                                            <li className={`page-item hoverable ${tableNum === 1? "active": ""}`} onClick={e => onSwitchTable(1)} ><span className="page-link">{t("vis_mon")}</span></li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* ::: VISITS OF TODAY */}
                                <div>
                                    <MainPanelVistsTable visits={visitsToShow} title={t(titleToShow)} visitors={visitorsToShow} />
                                </div>
                            </div>
                            {/* ::: VISITORS INSIDE TABLE AND TERMINAL SELECTION */}
                            <div className={`col-lg-4 col-md-12 ${isCoreSecurity? "pt-5": "pt-3"}`}>
                                <div className="separator transparent mb-3"></div>
                                <MainPanelVisitorsTable visitors={visitors} visits={[...visits, ...visitsToday]} />
                            </div>
                        </div>;

    const _loadingAnimation = <div className="my-4"><img src={require(`@resources/icons/loading.gif`)} className="d-block m-auto" style={{width: "45px", height: "45px"}} alt="laoding_gif"/></div>;
    
   

    return (
        <Fragment>
            <InternalNavBar/>
            <div className="main-display-container">
                <div className="clearfix">
                    <span className="default-button right-sided-button" onClick={e => refresh(e)}><img alt="refresh" className="mb-1" src={REFRESH_ICON} />Refresh</span>
                </div>
                <div className="separator transparent my-2"/>
                {/* ::: MAIN PANEL */}
                {isToShowPanelStats? _statsPanel : _loadingAnimation}
            </div>
            {modal === MAIN_PANEL_VIEW_VISIT_MODAL && <ViewUserRejectedModal/>}
        </Fragment>
    )
}

MainPanel.propTypes = {
    flag: PropTypes.bool,
    user: PropTypes.object,
    modal: PropTypes.string,
    fetchMainPanelStatsOfAccount: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
    modal: state.admin.modal,
    mainPanelStats: state.admin.mainPanelStats,
})

const mapDispatchToProps = {
    fetchMainPanelStatsOfAccount,
    fetchStatsOfSelectedTerminal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel)
