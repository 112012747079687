import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { getAppConfig } from '@utils/configurations';
import { doesArrayExist, doesObjectExist, isThreadUser } from '@utils/utils';
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Badge, CustomInput, FormGroup, ListGroup } from 'reactstrap';
import { v4 } from 'uuid';

const GrantAccessMultipleUserForm = ({loggedUser, singleUser, multipleUsers, parentState, setMessage, onChangeIsCollective}) => {
    const {HOST_CODE, CORE_SECURITY_CODE} = getAppConfig();
    const { t } = useTranslation('request');
    const [state, setState] = useState({
        selectedUsers: [],
        checkAllUsers: false,
        selectedFile: '',
    })

    const {selectedUsers, checkAllUsers} = state;
    
    const onCheckUsers = (e, visitor) => {
        if (!doesObjectExist(visitor)) return;
        
        let newUsersList = [];
        if (selectedUsers.includes(visitor.p_nid)) {
            newUsersList = selectedUsers.filter((p_nid) => p_nid !== visitor.p_nid);
        } else {
            newUsersList = [...selectedUsers, visitor.p_nid ];
        }
        setState({ ...state, selectedUsers: newUsersList});
    };

    const onCheckAllUsers = (e) => {
        let newCheckedUsrs = [];
        multipleUsers.forEach((usr, index) => {
            newCheckedUsrs = [...newCheckedUsrs,usr.p_nid ];
        });

        setState({
            ...state,
            checkAllUsers: !checkAllUsers,
            selectedUsers: checkAllUsers ? [] : newCheckedUsrs
        });
    };

    const onUsrSelectOptionChanged = (e, vis, selctIndex) => {
        let key = selctIndex === 7 ? "p_id_department" : "p_id_company";
        if(!selectedUsers.includes(vis.p_nid)){
            vis[key] = e.target.value;
        } else {
            multipleUsers.forEach((usr) => {
                if (selectedUsers.includes(usr.p_nid)) {
                    usr[key] = e.target.value;
                }
            });
        }
       
        setState({...state});

    };

    
    return (
        <Fragment>
            <FormGroup>
                <ListGroup>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck-1" checked={checkAllUsers ? "checked" : false} onChange={(e) => onCheckUsers(e)}/>
                            <label className="custom-control-label" name="selectAllUsers" htmlFor="customCheck1" onClick={(e) => onCheckAllUsers(e)}>{t("sel_all")}</label>
                    </div>
                    {doesArrayExist(multipleUsers) && (multipleUsers[0] !== singleUser) && multipleUsers.map((vis, id) => {
                        const hasFaultyData = vis.error || vis.warning;
                        const canSelectDepartment = (vis.p_id_role === HOST_CODE) && !(vis.p_id_role === CORE_SECURITY_CODE);
                        const canSelectTerminal = !(vis.p_id_role === CORE_SECURITY_CODE);
                        return (
                            <li key={v4()} className="list-group-item list-group-item-action d-flex flex-row w-100">
                                <div className="custom-control custom-checkbox col-6 w-100 d-inline-block ">
                                    <CustomInput type="checkbox" className="d-inline-block" id={`customCheck${v4()}`}  checked={selectedUsers.includes(vis.p_nid,) ? "checked" : false}  onChange={(e) => onCheckUsers(e, vis)}/>
                                    
                                    <label className="custom-control-label mr-1 text-truncate" name={vis.p_fname} htmlFor="customCheck1" onClick={(e) => onCheckUsers(e, vis,)}> {" "} {vis.p_fname + " " + vis.p_lname} </label> 
                                    
                                    <Badge color={vis.error ? "danger" : vis.warning ? "warning" : "success"} className="ml-2">  {vis.error ? t(vis.messages.error) : vis.warning? t(vis.messages.warning) : t('success')} </Badge>
                                </div>
                                <div className="col-6 w-100  d-inline-block">
                                    <div className="d-flex flex-row-reverse">
                                        {  !hasFaultyData && canSelectDepartment && ( <select className="custom-select  my-0 mr-sm-2 py-0 w-50 h-50" name={vis.p_fname} value={vis.p_id_department ? vis.p_id_department : 1} onChange={ (e,) => onUsrSelectOptionChanged(e, vis, 7,) }> 
                                                                                        {AppFunctionalityUsable.departments.map((dp,) => ( <option key={v4()} value={ dp.code}> {t(dp.name,)} </option> ),) } 
                                                                                    </select> )
                                        }

                                        { !hasFaultyData && canSelectTerminal && ( <select className="custom-select  my-0 mr-sm-2 py-0 w-50 h-50" name={vis.p_fname} value={vis.p_id_company} disabled={isThreadUser(loggedUser) ? "disabled" : false} onChange={(e,) => onUsrSelectOptionChanged(e, vis, 6,)}> 
                                                                                        {AppFunctionalityUsable.terminals.map((tr,) => (<option key={v4()} value={tr.code}> {t(tr.name,)} </option> ),)} 
                                                                                    </select>)
                                        }
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ListGroup>
            </FormGroup>
        </Fragment>
    )
}

export default GrantAccessMultipleUserForm
