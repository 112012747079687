import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalFooter, Form, Button, FormGroup, Input, ListGroup, CustomInput } from 'reactstrap'
import { NEXT } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { addServiceProviderWorkerToVisitToggle, close_modal } from '@actions/admin'
import { doesArrayExist, isEmptyArray } from '@utils/utils'
import { performWorkerToVisitAssociation } from '@actions/visits'

const ChooseVisitorModal = ({loggedUser, modal_data,performWorkerToVisitAssociation, addServiceProviderWorkerToVisitToggle, serviceProviderWorkers, flag, close_modal}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        search_val: '',
        disSub: false,
        flag: false,
    })

    const { search_val, disSub } = state;

    let employees;
    if (search_val) { 
        employees = serviceProviderWorkers.filter(vs => (vs.p_fname + vs.p_lname).toLowerCase().startsWith(search_val.toLocaleLowerCase()));
        employees = employees.length === 0? serviceProviderWorkers: employees;
    } else { 
        employees = isEmptyArray(serviceProviderWorkers)? [] : serviceProviderWorkers;
    }

    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if (nodeName === 'search') return setState({...state, search_val: nodeValue});
    }

    const onAddWorkerToVisitToggle = (e, worker) => { 
      addServiceProviderWorkerToVisitToggle(modal_data, worker);
      setState({...state, flag: !state.flag})
    } 
    
    const onClose = e => { 
        close_modal();
    }

    const onSubmit = async e =>  {
        setState({...state, disSub: true});
        const isSuccessful = await performWorkerToVisitAssociation(modal_data);
        if(isSuccessful) {
          onClose();
          setState({...state, disSub: false});
        }
    }

    return (
        <Modal isOpen={true} size="lg" className="no-border" centered >
          <h2 className="tag t5x px-5 text-center w-75 m-auto position-relative "> {t("cho_vis")} </h2>
          <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            <Form>
              <FormGroup>
                    <FormGroup>
                        <Input type="text" name="search" value={search_val} placeholder={t('search')} onChange={e => onChange(e)} />
                    </FormGroup>
                    {doesArrayExist(serviceProviderWorkers) && (
                            <FormGroup>
                                <ListGroup>
                                {employees.map((vis) => {
                                  return (
                                    <li key={ v4()} className="list-group-item list-group-item-action d-flex w-100" onClick={e => onAddWorkerToVisitToggle(e, vis)}>
                                      <div className="col-4 w-100 d-inline ">
                                        <span className="-span mr-1" name={vis.p_fname}> {`${vis.p_fname}  ${vis.p_lname}`}</span>
                                      </div>                      
                                      <div className="col-8 w-100  d-inline">
                                        <div className="d-flex flex-row-reverse">
                                          <CustomInput type="checkbox" id={`userRadioSelect${vis.p_id}`} checked={modal_data.id_users.includes(vis.p_id)} onChange={e => function(){}}/> 
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                                </ListGroup>
                            </FormGroup>
                        )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div className=" w-100 d-flex align-items-center justify-content-between">
              <div>
                <Button color="secondary" onClick={(e) => onClose(e)}>{t("cancel")}</Button>
              </div>
              <div>
                <Button name={NEXT} disabled={disSub} color={`success`} className="mx-3" onClick={e => onSubmit(e)}> {t('send')} </Button>
              </div>
            </div>
          </ModalFooter>
      </Modal>
    )
}

ChooseVisitorModal.propTypes = {
    visOfEntity: PropTypes.array,
    loggedUser: PropTypes.object,
    flag: PropTypes.bool,
    modal: PropTypes.string,
    close_modal: PropTypes.func.isRequired,
    serviceProviderWorkers: PropTypes.array.isRequired,
    addServiceProviderWorkerToVisitToggle: PropTypes.func.isRequired,
    modal_data: PropTypes.object.isRequired,
    performWorkerToVisitAssociation: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.admin.modal,
    loggedUser: state.auth.user,
    flag: state.admin.flag,
    serviceProviderWorkers: state.admin.serviceProviderWorkers,
    modal_data: state.admin.modal_data,
    
})

const mapDispatchToProps = {
    close_modal,
    addServiceProviderWorkerToVisitToggle,
    performWorkerToVisitAssociation,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseVisitorModal)
