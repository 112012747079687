import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin';
import { Button, Card, CardBody, CardImg, Col, CustomInput, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ROOT_URL } from '@utils/constants';
import { doesArrayExist } from '@utils/utils';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import Label from 'reactstrap/lib/Label';


const InductionEditModal = ({modal_data, flag, close_modal}) => {
    const { t } = useTranslation('settings')
    const question = modal_data;

    let translation = AppFunctionalityUsable.ind_quest.find(qst => {
        const isSameAnswer = (qst.p_answer === question.p_answer);
        const hasTypeIndArray = doesArrayExist(qst.p_cod_extra);
        if(!hasTypeIndArray) { 
            return true;
        }
        const hasSameType = qst.p_cod_extra.includes(question.p_cod_extra[0]);
        return isSameAnswer && !hasSameType;
    });
    translation = translation || {};

    // ::: EVENT HANDLERS
    const onSave = async e => { 
        let isSuccessful = await question.update();
        translation.set("p_points", question.p_points);
        translation.p_cod_extra = question.p_cod_extra;
        isSuccessful = await translation.update();
        if (isSuccessful) close_modal();
    }

    const onUpdatePoints = (e) => { 
        const nodeValue = e.target.value;
        const nodeName = e.target.name;
        question.set(nodeName, nodeValue);
    }

    const onClose = e => { 
        close_modal();
    }

    const onChangeInductionType = (indCode) => { 
        question.set('p_cod_extra', indCode)
        translation.setTransCodExtra(question.p_cod_extra);
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const inductionTypes = !AppFunctionalityUsable.inductionTypes? [] : AppFunctionalityUsable.inductionTypes.map((indType, index) => { 
        if (index === 0) return <span key={v4()}></span>;
        return <FormGroup key={v4()} check inline>
                    <Label check>
                    <Input type="checkbox" checked={question.isOfInductionExtra(indType.code)} onChange = {e => onChangeInductionType(indType.code)}/> {indType.name}
                    </Label>
                </FormGroup>
    })

    const generateQuestionOptions = () => { 
        let options = <div></div>
        if (doesArrayExist(question.options)){
            options = question.options.map(option => { 
                return <Col md={4} key={v4()}>
                            <Card className={`${option.isCorrect? 'border-success': 'border-danger'}`}>
                                <CardImg top width="100%"  style={{backgroundSize: "auto", maxHeight: "200px"}} src={`${ROOT_URL}/files/induction/${option.file_name}`}></CardImg>
                                <CardBody>
                                    <FormGroup>
                                        <CustomInput
                                            id={`fileInput_${v4()}`}
                                            type="file"
                                            label={option.img_name}
                                            name="q_img"
                                            placeholder={t("id")}
                                            onChange={e => option.setImage(e.target.files[0])}
                                            hint="aldkjf"/>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
            })
        }
        return options;
    }


    return (
        <Modal isOpen={true} size="xl" className="no-border modal-xl" centered>
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal">{t("edi_ind")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <div>
                    <Form  className="my-4">
                        {/* ::: INDUCTION QUESTION IN PT */}
                        <FormGroup row>
                            <Col md={12}>
                                <Input type="text" className="required" name="p_question" value={question.p_question || ''} placeholder={t('question')} onChange={e => question.set(e.target.name, e.target.value)}/>
                            </Col>
                        </FormGroup>
                        {/* ::: INDUCTION QUESTION IN EN */}
                        <FormGroup row>
                            <Col md={12}>
                                <Input type="text" className="required" name="p_question" value={translation.p_question || ''} placeholder={t('question_en')} onChange={e => translation.set(e.target.name, e.target.value)}/>
                            </Col>
                        </FormGroup>
                        {/* ::: INDUCTION POINTS */}
                        <FormGroup row>
                            <Col md={4}>
                                <Input type="text" className="required" name="p_points" value={question.p_points || 0} placeholder={t('points')} onChange={e => onUpdatePoints(e)} />
                            </Col>
                        </FormGroup>
                        {/* ::: INDUCTION TYPE */}
                        <FormGroup>
                            <p>{t('typ_ind')}</p>
                            {inductionTypes}
                        </FormGroup>
                        {/* ::: INDUCTION QUESTION IMAGES TO ANSWER */}
                        <FormGroup>
                            <Row>
                                {generateQuestionOptions()}
                            </Row>
                        </FormGroup>
                    </Form>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                    <Button name='submit' color="success" onClick={e => onSave(e)} className="mx-3"> {t('save')} </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

InductionEditModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(InductionEditModal)
