import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { getAppConfig } from '@utils/configurations'
import { v4 } from 'uuid';
import { removeMessage } from '@actions/toast'
import { getServerSavedConfigurations, writeServerConfig } from '@actions/admin'
import { Edit2 } from 'react-feather'
import { isNotEmptyObject, isNotEmptyValue } from '@utils/utils'


const ParameterSettingsTab = ({getServerSavedConfigurations, writeServerConfig, user }) => {
    const {t} = useTranslation('settings');
    const constants = getAppConfig();
    const {PARAMETERS_BY_TYPE_SETTINGS} = constants;
    const [state, setState] = useState({
        ...constants,
        currentParameter: '',
    });

    const {currentParameter} = state;
    const parameterTypes = Object.keys(PARAMETERS_BY_TYPE_SETTINGS);
    

    // ::: EVENT HANLDERS
    const onClose = e => { 
        setState({...state, currentParameter: ''});
    }

    const onSubmit = async e => {
        const isSuccessful = await writeServerConfig(state);
        if(isSuccessful) { 
            setState({...state, currentParameter: ''});
            await getServerSavedConfigurations();
        } 
    }

    const onChange = e => {
        const parameterName = e.target.name;
        setState({...state, [parameterName]: +e.target.value})
    }

    const onSelectParam = (e, param) => { 
        setState({...state, currentParameter: param})
    }

    // ::::::::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS
    const _listOfParameterSettings = parameterTypes.map(parameterType => { 
        return (
            <div key={v4()} className="elevated-container parameters-container pb-0">
                {/* :: PARAMETER SETTINGS TITLE */}
                <span className="pl-3">{parameterType}</span>
                {/* :: PARAMETER SETTINGS CONTENT */}
                <table className="striped-table">
                    <thead>
                        <tr>
                            <th className="pl-4">{t('name')}</th>
                            <th className="text-center">{t('value')}</th>
                            <th className="text-center">{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {PARAMETERS_BY_TYPE_SETTINGS[parameterType].map( val => { 
                            return  <tr key={v4()} >
                                        <td className="pl-4" data-bs-toggle="tooltip" data-bs-placement="top" title={t(constants['CONFIG_EXP'][val].description)} >{t(constants['CONFIG_EXP'][val].name)}</td>
                                        <td className="text-center">{state[val]}</td>
                                        <td className="text-center hoverable" onClick={e => onSelectParam(e, val)}> <Edit2 size="20px"/></td>
                                    </tr>
                        })}
                    </tbody>
                </table>
            </div>
        )
    })

    const modalName = isNotEmptyObject(constants['CONFIG_EXP'][currentParameter])? constants['CONFIG_EXP'][currentParameter].name : '';
    const _editParameterModal = <Modal isOpen={true} size="m" className="no-border" centered>
                                    {/* ::: MODAL TITLE */}
                                    <h3 className="title-of-modal">{t(modalName)}</h3>
                                    <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                                        <Form>
                                            <FormGroup>
                                                <Input type="number" name={currentParameter} className="required" placeholder={t("reason")} value={state[currentParameter]} onChange={(e) => onChange(e)} />
                                            </FormGroup>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className=" w-100 d-flex align-items-center justify-content-between">
                                            <div>
                                                <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                                            </div>
                                            <div>
                                                <Button name='submit' color="success" onClick={e => onSubmit(e)} className="mx-3"> {t('save')} </Button>
                                            </div>
                                        </div>
                                    </ModalFooter>
                                </Modal>;

    // :::: HTML CONDITIONALS
    const isToShowModal = isNotEmptyValue(currentParameter);

    return (
            <Fragment>
                {/* ::: PARAMETERS TABLES */}
                {_listOfParameterSettings}
                {/* ::: MODALS TO SHOW IF PARAMETER IS CLICKED */}
                {isToShowModal && _editParameterModal}
            </Fragment>
    )
}

ParameterSettingsTab.propTypes = {
    flag: PropTypes.bool,
    removeMessage: PropTypes.func.isRequired,
    writeServerConfig: PropTypes.func.isRequired,
    getServerSavedConfigurations: PropTypes.func.isRequired,
    user: PropTypes.object,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
})

const mapDispatchToProps = {
    removeMessage,
    writeServerConfig,
    getServerSavedConfigurations,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterSettingsTab)
