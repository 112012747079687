import { setMessage, toastErrorMessage, toastSuccessMessage } from "@actions/toast";
import { ERROR_TOAST, ROOT_URL } from "@utils/constants";
import store from "@components/../store";
import InductionOption from "./InductionOption";
import { update } from "@actions/admin";
import { extractEditIndQuestFields, createStoreLoader, doesVariableExist, extractIndQuestFields, getToday, doesObjectExist, doesArrayExist, sendRequestDataWithDefaultConfig, checkApiResponseForErrors } from "@utils/utils";
import AppFunctionalityUsable from "@model/Extras/AppFunctionalityUsable";

class InductionQuestion  { 
    constructor(responseData) { 
        this.selectedOption = null;
        if(doesObjectExist(responseData)) return  this.setUpInduction(responseData);
        this.p_cod_extra = [];
    }

    set (key, val) {
        if(key === "p_cod_extra") return this.addInductionExtra(val);
        this[key] = val;
		store.dispatch(update());
    }

    setTransCodExtra(originalCodeExtra) { 
        this.p_cod_extra = originalCodeExtra.map(extra => extra+1);
    }
    
    get(key) {
        if(key === "p_cod_extra") { 
            return this[key] || [];
        }
		return this[key] || "";
    }

    addInductionExtra(code_extra) { 
        if (this.p_cod_extra.includes(+code_extra)) {
            return this.removeInductionExtra(code_extra);
        }
        this.p_cod_extra.push(+code_extra)
        store.dispatch(update());
    }

    removeInductionExtra(code_extra) { 
        if(this.p_cod_extra.length === 1) return;
        this.p_cod_extra = this.p_cod_extra.filter(code => code !== code_extra);
        store.dispatch(update());
    }

    isOfInductionExtra(code_extra) { 
        if(this.p_cod_extra) return this.p_cod_extra.includes(code_extra);
        return false;
    }

    /**
     * Prepares fields for a new Induction Question.
     */
    createNewQuestion(qid) { 
        this.qid = qid; 
        this.p_cod_extra = [147];
        this.p_points = 2;
        this.p_is_valid = 1;
        this.p_created = getToday().toISOString();
        const opt_a = new InductionOption(this.qid, 'a', true);
        const opt_b = new InductionOption(this.qid, 'b', false);
        const opt_c = new InductionOption(this.qid, 'c', false);
        this.p_options = `${opt_a.qid}|${opt_b.qid}|${opt_c.qid}`
        this.p_answer = opt_a.qid;
        this.options = [opt_a, opt_b, opt_c];
        store.dispatch(update());
    }

    /**
     * Validates the induction Question Fields
     */
    validate() { 
        if(!doesVariableExist(this.p_question)) return [false, 'No Question.'];
        if(!doesVariableExist(this.p_question_en)) return [false, 'No Question Translation'];
        for (let opt of this.options) {
            if (!opt.altered) return [false, 'cho_img'];
        }

        if(this.p_cod_extra === -1) return [false, 'cho_ind_typ'];
        return [true, ''];
    }


    /**
     * Updates a field and submits it to the backend.
     * @param {string} key 
     * @param {*} val 
     */
    async setAndSubmit (key, val) { 
        this[key] = val;
        await this.update();
        store.dispatch(update());
    }


    /**
     * Mars the recived option as selected
     * @param {object} inductionOption 
     */
    selectOption(inductionOption) { 
        this.selectedOption = inductionOption;
        store.dispatch(update());
    }

    /**
     * Creates Induction Questio Fields.
     * @param {object} responseData 
     */
    setUpInduction (responseData) { 
        Object.keys(responseData).forEach(key => this[key] = responseData[key]);
        const questionOptions = this.p_options.split('|');
        let indOpt, isCorrectOption;
        this.options = questionOptions.map((qst, indx) => { 
            indOpt = new InductionOption();
            isCorrectOption = indx === 0;
            if (isCorrectOption){
                indOpt.setInductionOption(qst, true);
                return indOpt;
            } else { 
                indOpt.setInductionOption(qst);
                return indOpt;
            }
        });
        if (!doesArrayExist(this.p_cod_extra)) this.p_cod_extra = [];
        this.options.sort(() => Math.random() - 0.5); //::>> Suffle the options
    }

    /**
     * Shows an error message toast
     * @param {string} message 
     */
    giveErrorMessage (message) { 
        store.dispatch(setMessage(message, ERROR_TOAST));
    }


    /**
     * Updates the induction question sending the request to the backend.
     */
    async update()  {
        let isSuccessfull = true;
        const loader = createStoreLoader(store);
        try {
            const requestData = extractEditIndQuestFields(this);
            const requestBody = JSON.stringify(requestData);
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/editIndQuest`, requestBody);
            checkApiResponseForErrors(response);

            store.dispatch(toastSuccessMessage('Success'));
          } catch (err) {
              isSuccessfull = false;
              store.dispatch(toastErrorMessage(err.message));
          }

          loader.stopLoading();
          return isSuccessfull;
    }

    /**
     * Creates the induction question sending the request to the backend.
     */
    async save () {
        let savedSuccessfully = true;
        const loader = createStoreLoader(store);
        try {
            const [isValid, message] = this.validate();
            if(!isValid)
                throw new Error(message);
            
            //::>> SAVE IN THE ORIGINAL LANGUAGE
            let requestUrl = `${ROOT_URL}/admin/addIndQuest`;
            let requestData = extractIndQuestFields(this);
            let requestBody = JSON.stringify(requestData);
            let response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
            checkApiResponseForErrors(response);

            //::>> SAVE THE TRANSLATION 
            requestUrl = `${ROOT_URL}/admin/addIndQuest`;
            const translationInductionExtras = this.p_cod_extra.map(defaultLangIndType => { 
                return AppFunctionalityUsable.ind_type_trans[ AppFunctionalityUsable.ind_type_trans[defaultLangIndType] + '_EN']})
            requestData = {...requestData, p_question: this.p_question_en, p_cod_extra: translationInductionExtras};
            requestBody = JSON.stringify(requestData);
            response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
            checkApiResponseForErrors(response);
        
            store.dispatch(toastSuccessMessage('Success'));
            store.dispatch(update());
          } catch (err) {
              savedSuccessfully = false;
              store.dispatch(toastErrorMessage(err.message));
          }

          loader.stopLoading();
          return savedSuccessfully;
    }
}

export default InductionQuestion;