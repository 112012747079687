export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CHANGE_FLAG = 'CHANGE_FLAG';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SET_REPORT_1 = 'SET_REPORT_1';
export const SET_REPORT_2 = 'SET_REPORT_2';
export const SET_REPORT_3 = 'SET_REPORT_3';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';
export const SET_INDUCTION_ANSWER = 'SET_INDUCTION_ANSWER';
export const CLEAR_INDUCTION_QUESTION = 'CLEAR_INDUCTION_QUESTION';
export const SET_TOKEN_USER = 'SET_TOKEN_USER';
export const CLEAR_REQ_INDUCTION_ANSWER = 'CLEAR_REQ_INDUCTION_ANSWER';
export const SET_INDUCTION_ANSWERS = 'SET_INDUCTION_ANSWERS';
export const GET_INDCUTION_QUESTION = 'GET_INDUCTION_QUESTIONS';
export const VERIFY_USER = 'VERIFY_USER';
export const GET_STATS = 'GET_STATS';
export const SET_NEW_USER_ID = 'SET_NEW_USER_ID';
export const ADD_KNOWMOREMESSAGE = 'ADD_KNOWMOREMESSAGE';
export const CLEAR_KNOWMOREMESSAGE = 'CLEAR_KNOWMOREMESSAGE';
//========================== REFACTORING NEW TYPES ==================================//
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const REMOVE_LOADING_MESSAGE = 'REMOVE_LOADING_MESSAGE'; 
export const SET_IS_REGISTERED = 'SET_IS_REGISTERED';
export const SET_VISITS = 'SET_VISITS';
export const DEFAULT_DATE_FORMAT_FOR_API_REQUESTS = 'YYYY-MM-DD';
export const SET_GENERAL_VISIT_STATE = 'SET_GENERAL_VISIT_STATE';
export const SET_VISIT_ON_MODAL = 'SET_VISIT_ON_MODAL';
export const CLEAR_VISITS = 'CLEAR_VISITS';
export const SET_MAIN_PANEL_STATS = 'SET_MAIN_PANEL_STATS';
export const SET_VISITORS = 'SET_VISITORS';
export const SET_HOSTS = 'SET_HOSTS';
export const SET_SERVICE_PROVIDERS = 'SET_SERVICE_PROVIDERS';
export const SET_SERVICE_PROVIDERS_WORKERS = 'SET_SERVICE_PROVIDERS_WORKERS';
export const SET_LANGUAGE = 'SET_LANGUAGE';