import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, ModalFooter, Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { close_modal, filterVisitors } from '@actions/admin'
import { getMomentFromString, calculateNumberOfPages, validateArrayParameter } from '@utils/utils'
import { v4 } from 'uuid'
import { NUMBER_USER_PER_PAGE } from '@utils/constants'
import VisitorBlockReasonsModal from './VisitorBlockReasonsModal'
import { getAppConfig } from '@utils/configurations'
import TableNavigation from '@components/MainPage/Shared/TableNavigation'

const BLOCKED_USER_ICON = require(`@resources/icons/blocked_user.svg`);
const VERIFIED_ICON = require(`@resources/icons/verified.svg`);
const FILTER_ICON = require(`@resources/icons/filter.svg`);

const BlockVisitorModal = ({flag, user, filterVisitors, visitors, close_modal}) => {
    const {t} = useTranslation('request');
    const { USER_FILTER } = getAppConfig();
    const [state, setState] = useState({
        filter: 'Primeiro Nome',
        filter_val: '',
        selectedVisitor: null,
        isToOpenReasonsModal: false,
        page: 1, 
    });

    const {page, filter_val, filter, isToOpenReasonsModal, selectedVisitor} = state;
    const validatedVisitors = validateArrayParameter(visitors);
    // ::: NAVIGATION
    const numberOfUsers = validatedVisitors.length;
    const  totalPages =  calculateNumberOfPages( numberOfUsers, NUMBER_USER_PER_PAGE);
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})}
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);
    const setPage = (e, page) => {setState({...state, page })}

    // ::: EVENT HANDLERS 
    const toggleShowReasonsModal = e => { 
        setState({...state, isToOpenReasonsModal: !isToOpenReasonsModal})
    }

    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;

        if(nodeName === 'filter_type') { 
            setState({...state, filter_val: nodeValue});
            filterVisitors(USER_FILTER[filter], nodeValue);
        } else if(nodeName === 'filter') { 
            setState({...state, [nodeName]: nodeValue, filter_val: ''});
            filterVisitors(USER_FILTER[filter], '');
        } else if (nodeName === 'page_num'){
            setState({...state, page: +nodeValue? +nodeValue : 1})
        }
    }

    const onRowClicked = visitor => { 
        setState({...state, selectedVisitor: visitor, isToOpenReasonsModal: true});
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const filteredVisitors = validatedVisitors.filter((req, index) => (index >= ((page-1) * NUMBER_USER_PER_PAGE)) && (index < (page * NUMBER_USER_PER_PAGE)));
    const _listOfvisitors = filteredVisitors.map(visitor => { 
        const visitorName = `${visitor.p_fname} ${visitor.p_lname}`;
        const visitorStateIcon = visitor.p_isblocked? <img src={BLOCKED_USER_ICON} alt="blocked_icon"/> : <img src={VERIFIED_ICON} alt="verified_icon"/>;
        return (
                <tr key={v4()} >
                    <td className="hoverable text-truncate td" onClick={e => onRowClicked(visitor)} >{visitorName}</td>
                    <td className="hoverable td" onClick={e => onRowClicked(visitor)} >{visitor.p_pnumber_1}</td>
                    <td className="hoverable text-truncate td" onClick={e => onRowClicked(visitor)} >{visitor.p_nid}</td>
                    <td className="hoverable td" onClick={e => onRowClicked(visitor)} >{visitorStateIcon}</td>
                    <td className="hoverable td" onClick={e => onRowClicked(visitor)}> {getMomentFromString(visitor.p_registry).format("ddd, MMMM Do")}</td>
                </tr>)
    });

    // ::: PROPERTIES FOR CHILDREN
    const componentData = {
        pageIndicators: indicators,
        totalPages,
        totalItems: numberOfUsers,
        page,
        next,
        prev,
        setPage,
    }
    
    return (
        <Modal isOpen={true} className="no-border" centered size="xl">
            <h3 className="title-of-modal "> {t("blo_use")} </h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }} className="px-4">
                {/* ::: FORM FILTER */}
                <Form className="form-inline" >
                    <img src={FILTER_ICON} alt="alert_icon" className="mr-4 mb-3"/>
                    {/* ::: FILTER CRITERIA */}
                    <Input type="select" name="filter" className="mr-2" value={filter} onChange={e => onChange(e)}>
                        {Object.keys(USER_FILTER).map(key => { return <option key={v4()} value={key}>{key}</option>})}
                    </Input>
                    {/* ::: FILTER VALUE */}
                    <Input type="text" name="filter_type" placeholder={t('fil_val')} value={filter_val} onChange={e => onChange(e)} />
                </Form>
                {/* ::: TABLE OF VISITORS */}
                <div className="table-container block-user-container mt-3">
                    <table className="striped-table ">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th>{t('cell_num')}</th>
                                <th>{t('id')}</th>
                                <th>{t('state')}</th>
                                <th>{t('reg_dat')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_listOfvisitors}
                        </tbody>
                    </table>
                    {/* ::: TABLE NAVIGATION */}
                    <TableNavigation componentData={componentData}/>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className="w-100 d-flex justify-content-between">
                    <Button className="btn btn-normal mx-2" onClick={e => close_modal()}>{t('cancel')}</Button>
                </div>
            </ModalFooter>
            {isToOpenReasonsModal && <VisitorBlockReasonsModal visitor={selectedVisitor} toggleShowReasonsModal={toggleShowReasonsModal}/>}
    </Modal>
    )
}

BlockVisitorModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
    user: PropTypes.object,
    visitors: PropTypes.array.isRequired,
    filterVisitors: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
    visitors: state.admin.visitors,
})

const mapDispatchToProps = {
    close_modal,
    filterVisitors,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockVisitorModal)
