import TableNavigation from '@components/MainPage/Shared/TableNavigation';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { getAppConfig } from '@utils/configurations';
import { NUMBER_INDUCTION_QUESTION_PER_PAGE } from '@utils/constants'
import { calculateNumberOfPages, doesArrayExist, doesVariableExist } from '@utils/utils';
import React, { Fragment, useState } from 'react'
import { Edit3, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import CustomInput from 'reactstrap/lib/CustomInput';
import { v4 } from 'uuid';
import InductionEditModal from './InductionEditModal';


const InductionListingTable = ({modal_data, open_modal, openAddModal}) => {
    const { t } = useTranslation('settings');
    const { FILTER_NAME_TO_OBJECT_PROPERTY_INDUCTION } = getAppConfig();
    const[state, setState] = useState({
        page: 1,
        filter: 'Questao',
        filter_val: ''
    });
    const {page,filter, filter_val} = state;

    // ::::: TABLE NAVIGATION
    const INDUCTION_TYPE_FILTER = Object.keys(FILTER_NAME_TO_OBJECT_PROPERTY_INDUCTION)[1];
    let  totalPages =  calculateNumberOfPages(doesArrayExist(AppFunctionalityUsable.ind_quest)? (AppFunctionalityUsable.ind_quest.length / 2): 1, NUMBER_INDUCTION_QUESTION_PER_PAGE);
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const setPage = (e, page) => {setState({...state, page })}

    // ::: FILTERS
    const filteredInductionQuestions = doesArrayExist(AppFunctionalityUsable.ind_quest)? AppFunctionalityUsable.ind_quest.filter((quest) => {
        return quest.p_cod === AppFunctionalityUsable.ind_trans_map[quest.p_answer][0]
    }) : [];
    let inductionQuestions = filteredInductionQuestions?  filteredInductionQuestions.filter((req, index) => (index >= ((page-1) * NUMBER_INDUCTION_QUESTION_PER_PAGE)) && (index < (page * NUMBER_INDUCTION_QUESTION_PER_PAGE))) : [];

    if (doesVariableExist(filter_val)) {
        inductionQuestions = inductionQuestions.filter((quest) => {
            if (filter === INDUCTION_TYPE_FILTER) return quest[FILTER_NAME_TO_OBJECT_PROPERTY_INDUCTION[filter]] === filter_val;
            return quest[FILTER_NAME_TO_OBJECT_PROPERTY_INDUCTION[filter]].toLowerCase().startsWith(filter_val.toLowerCase())
        })
    }   

    // ::: EVENT HANDLERS
    const onEditQUestion = (e, question) => {
        open_modal('_', question);
    }

    // const onChange = e => { 
    //     const nodeName = e.target.name;
    //     const nodeValue = e.target.value;

    //     if (nodeName === 'filter') return setState({...state, filter: nodeValue, filter_val: ''}); 
        
    //     if (filter === 'Tipo de Inducao') { 
    //         setState({...state, filter_val: +nodeValue});
    //     } else { 
    //         setState({...state, filter_val: nodeValue});
    //     }
    // }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    // let inputOfFilterType;
    // if (filter === INDUCTION_TYPE_FILTER) { 
    //     const inductionTypes = AppFunctionalityUsable.inductionTypes;
    //     inputOfFilterType = (
    //         <Input type="select" name="filter_type" className="d-inline-block small-form-ele" value={filter_val} onChange={e => onChange(e)}>
    //             {inductionTypes.map(extra => { 
    //                 return <option key={v4()} value={extra.code}>{extra.name}</option>
    //             })}
    //         </Input>
    //     )
    // } else  { 
    //     inputOfFilterType = <Input type="text" name="filter_type" placeholder={t('fil_val')} value={filter_val} className="d-inline-block small-form-ele" onChange={e => onChange(e)} />
    // }
    
    let switchLabel;
    const _listOfInductionQuestions = inductionQuestions.map((quest, idx) => {
        const toggledActivation = quest.p_is_valid? 0:1;
        switchLabel = !quest.p_is_valid? t('deactivated'):t('active');
        return <tr key={v4()}>
                    <td className="long-td text-truncate"><span className="text-truncate">{quest.p_question}</span></td>
                    <td><CustomInput 
                                type="switch"
                                name="p_is_valid"
                                checked={quest.p_is_valid ? "checked" : false} 
                                id={`customSwitch${idx}`} 
                                onChange={e => quest.setAndSubmit(e.target.name, toggledActivation)}
                                inline
                                label={switchLabel} />
                    </td>
                    <td onClick={e => onEditQUestion(e, quest)} className="text-center hoverable">
                        <Edit3 size="20px"/>
                    </td>
                </tr>
    })


    const componentData = { 
        pageIndicators: indicators,
        totalPages,
        totalItems: AppFunctionalityUsable.ind_quest.length,
        page,
        next,
        prev,
        setPage
    }

    return (
        <Fragment>
            <div className="elevated-container table-container mt-2">
                <div>
                    {/* ::: TITLE AND ACTIONS */}
                    <span className="pl-3">{t('questions')}</span>
                    <span className="faded-button success right-sided-button mr-2" onClick={e => openAddModal(e)}>
                        <Plus size="14px" className="mr-2"/>
                        {t('add')}
                    </span>
                </div>
                {/* ::: TABLE FOR CONTENT */}
                <table className="striped-table default-line-height">
                    <thead>
                        <tr>
                            <th>{t('question')}</th>
                            <th>{t('act_dea')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* ::: ALL INDUCTION QUESTIONS */}
                        {_listOfInductionQuestions}
                    </tbody>
                </table>
                {/* ::: TABLE NAVIGATION */}
                <TableNavigation componentData={componentData}/>
            </div> 
            {/* ::: MODAL TO OPEN WHEN OPTION CLICKED */}
            {modal_data && <InductionEditModal/>}
        </Fragment>
    )
}

export default InductionListingTable
