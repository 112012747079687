import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import { doesArrayExist, doesVariableExist } from '@utils/utils'
import React, { Fragment, useState } from 'react'
import { Filter } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'
import ServicesEditModal from '../Services/ServicesEditModal';
import SettingsItemTable from '../Shared/SettingsItemTable'

const TerminalSettingsTable = ({modal_data, open_modal, openAddModal}) => {
    const { t } = useTranslation('settings');
    const { FILTER_NAME_TO_OBJECT_PROPERTY_TERMINAL } = getAppConfig();
    const [state, setState] = useState({
        filter: 'Nome',
        filter_val: ''
    });

    const { filter, filter_val} = state;

    // ::: FILTERS
    let items = AppFunctionalityUsable.editableTerminals;
    if(doesVariableExist(filter_val) && doesArrayExist(items)) {
        const filterValue = filter_val.toLowerCase();
        let companyValue;
        items = items.filter((comp) => {
            companyValue = comp[FILTER_NAME_TO_OBJECT_PROPERTY_TERMINAL[filter]].toLowerCase();
            return (!filter_val || companyValue.startsWith(filterValue))
        });
    }


    //::: EVENT HANDLERS
    const onEditComp = (e, comp) => { 
        open_modal('_', comp )
    }

    const onChange = e => { 
        const targetName = e.target.name;
        const targetValue = e.target.value;
        if(targetName === 'filter'){
            setState({...state, filter: targetValue, filter_val: ''})
        } else { 
            setState({...state, filter_val: e.target.value});
        }
    }

    // ::: CHILDREN PROPERTIES
    const tableOptions =  { 
        title: t('terminal'),
        canEdit: true,
        showAddBtn: true,
    }


    return (
        <Fragment>
            {/* ::: FILTER */}
             <div className="my-2 d-flex justify-content-end">
                <Form inline>
                    <FormGroup>
                        <Filter size="20px" className="mx-3"/>
                        <Input type="text" name="filter_type" placeholder={t('name')} value={filter_val} className="d-inline-block small-form-ele" onChange={e => onChange(e)} />
                    </FormGroup>
                </Form>
            </div>
            {/* ::: ALL TERMINALS TABLE */}
            <SettingsItemTable items={items} openEditModal={onEditComp} openAddModal={openAddModal} options={tableOptions}/>
            {modal_data && <ServicesEditModal/>}
        </Fragment>
    )
}

export default TerminalSettingsTable
