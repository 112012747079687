import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { getInputDateFormatFromString } from '@utils/utils';
import SunEditor from 'suneditor-react';


const AlertMsgEditModal = ({modal_data, flag, close_modal}) => {
    const { t } = useTranslation('settings')
    const alertMsg = modal_data;
    const {p_title, p_msg, p_start, p_end } = alertMsg;
  
    // ::: EVENT HANDLERS
    const onSave = async e => { 
        // eslint-disable-next-line no-unused-vars
        const isSuccessful = await alertMsg.update();
        if (isSuccessful) 
            close_modal();
    }

    const onClose = e => { 
        close_modal();
    }

    return (
        
        <Modal isOpen={true} size="xl" className="no-border modal-xl" centered>
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal">{t("edi_ale_mes")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form>
                    {/* ::: ALERT MESSAGE TITLE */}
                    <FormGroup>
                        <Input type="text" name="p_title" value={p_title} placeholder={t('title')} className="required" onChange={e => alertMsg.set(e.target.name, e.target.value)} />
                    </FormGroup>
                    <FormGroup row>
                        {/* ::: ALERT MESSAGE START DATE */}
                        <Col md={6}>
                            <Label>{t('dat_ini')}</Label>
                            <Input type="date" name="p_start" value={getInputDateFormatFromString(p_start)} onChange={e => alertMsg.set(e.target.name, e.target.value)}/>
                        </Col>
                        {/* ::: ALERT MESSAGE END DATE */}
                        <Col md={6}>
                            <Label>{t('dat_fim')}</Label>
                            <Input type="date" name="p_end" value={getInputDateFormatFromString(p_end)} onChange={e => alertMsg.set(e.target.name, e.target.value)}/>
                        </Col>
                    </FormGroup>
                    {/* ::: ALERT MESSAGE MESSAGE */}
                    <FormGroup>
                        <SunEditor enableToolbar={true} name="p_msg" defaultValue={p_msg} value={p_msg} onChange={content =>  alertMsg.set('p_msg', content)} setOptions={{
                            buttonList: [
                                ['undo', 'redo'],
                                ['font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                ['fontColor', 'hiliteColor', 'textStyle'],
                                ['align', 'horizontalRule', 'list', 'lineHeight'],
                                ['fullScreen'],
                            ],
                            height: 400,
                            
                        }} />
                    </FormGroup>
                </Form>
            </ModalBody>
            {/* ::: FORM BUTTONS */}
            <ModalFooter>
                <div className=" w-100 d-flex align-items-center justify-content-between">
                    <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                    <Button name='submit' color="success" onClick={e => onSave(e)} className="mx-3">{t('submit')}</Button>
                </div>
            </ModalFooter>
        </Modal>       
    )
}

AlertMsgEditModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMsgEditModal)
