import { combineReducers } from 'redux';
import toast from './toast';
import auth from './auth';
import admin from './admin';
import visits from './visits';

export default combineReducers ({
    toast,
    auth,
    admin,
    visits
})
