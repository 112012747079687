import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, Button } from 'reactstrap';
import CustomSelectOption from '@sharedComponents/FormElements/CustomSelectOption';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { toastErrorMessage } from '@actions/toast';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fixNewUserFields, setVisitorProvenace } from '@utils/validations';
import ModalFooter from 'reactstrap/lib/ModalFooter';

const PASSWORD_INPUT_ICON = require("@resources/icons/padlock.svg");
const EMAIL_INPUT_ICON = require("@resources/icons/email.svg");

const UserAccountDataForm = ({newUser, forCompany, toastErrorMessage})  => {
    const { t } = useTranslation('auth');
    const { p_email,p_psw, p_psw_2, provenance, terms_condition, p_id_provenance, new_provenance} = newUser || {};

    const [state, setState] = useState({
        show_modal: false,
    });
    
    const {show_modal} = state;
    
    const onChange = (e) => { 
        const nodeName = e.target.name || e.target.getAttribute('name');
        const nodeValue = e.target.value;

        try {
            if (nodeName === 'new_provenance'){
                newUser['new_provenance'] = !new_provenance;
            }
            else if (nodeName === 'terms_condition'){
                newUser['terms_condition'] = !terms_condition;
            }
            else if (nodeName === 'provenance') {
                setVisitorProvenace(newUser, nodeValue);
            }
            else {
                newUser[nodeName] = nodeValue;
                fixNewUserFields(newUser); //::>> Throws Error if a field is invalid
            }
            setState({...state, user: newUser});
        } catch (error) {
            newUser[nodeName] = '';
            toastErrorMessage(error.message);
        }
    }

    const changeTermsAndCondition = (e, val) => {
        setState({...state, terms_condition: val, show_modal: false});
        newUser['terms_condition'] = val;
    }

    const openTermsCond = (e) => {
        setState({...state, show_modal: true})
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HTML ELEMENTS

    const termsAndConditions = (
        <div>
          <Modal isOpen={show_modal} size="xl" className="no-border" tabIndex="-1" centered >
            <h2 className="title-of-modal" > {t("ter_and_cond")} </h2>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto", padding:"20px 70px" }} >
              <div>
                <p>A entrada nas instalações do Porto é por conta e risco próprio e está sujeita às seguintes condições gerais:</p>
                <ul style={{listStyleType:"lower-latin"}}>
                    <li className="text-justify my-4">A MPDC reserva-se ao direito de restringir a entrada no, e de controlar a saída do Porto;</li>
                    <li className="text-justify my-4">A MPDC reserva-se ao direito de recusar a entrada a qualquer pessoa, veículo ou empresa sem notificação ou explicação prévia;</li>
                    <li className="text-justify my-4">É obrigatório participar no processo de indução de segurança da MPDC antes de aceder às instalações do Porto;</li>
                    <li className="text-justify my-4">Sem prejuízo das legislações existentes, pessoas não autorizadas encontradas no recinto Portuário serão sujeitas a procedimentos criminais;</li>
                    <li className="text-justify my-4">A entrada de crianças no Porto será permitida somente para propósitos educacionais, após aprovação da MPDC e quando forem familiares de um membro da tripulação, para propósitos de visita / juntar-se ao Navio. Em ambas as situações, as crianças devem estar sempre acompanhadas por um adulto;</li>
                    <li className="text-justify my-4">Não são permitidos animais no recinto do Porto, excepto para propósitos de Importação/Exportação mediante intervenção de entidades de fiscalização;</li>
                    <li className="text-justify my-4">Não são permitidas no recinto do Porto quaisquer tipo de armas ou munições de qualquer natureza, excepto a Polícia e Unidades Militares quando em serviço;</li>
                    <li className="text-justify my-4">O Porto tem a política de tolerância zero ao álcool e realizará testagens obrigatórias a todos que entrarem nas suas instalações, ao pessoal com resultado positivo, o acesso será recusado e emitido um relatório para tomada de medidas;</li>
                    <li className="text-justify my-4">É proibido tirar fotografias, excepto com autorização do departamento de Segurança;</li>
                    <li className="text-justify my-4">É obrigatório ligar as luzes do veículo e usar EPI / PPE em áreas operacionais</li>
                    <li className="text-justify my-4">O uso de telefones móveis durante a condução e/ou enquannto caminha é proibido;</li>
                    <li className="text-justify my-4">A MPDC através do departamento de Segurança reserva-se ao direito de revistar qualquer pessoa ou veículo que entrar ou sair do Porto. </li>
                    <li className="text-justify my-4">Os sinais e marcações de tráfego rodoviário, incluindo o limite de velocidade de 30 km/h na estrada principal e de 10 km/h nas bancas e Cais, são de cumprimento obrigatório;</li>
                    <li className="text-justify my-4">A circulação de veículos no Porto é feita através das vias devidamente indicadas e sinalizadas;</li>
                    <li className="text-justify my-4">Exceder o limite de velocidade ou qualquer violação das normas do Porto está sujeito a penalizações;</li>
                    <li className="text-justify my-4">Os veículos devem estar equipados com pirilampo;</li>
                    <li className="text-justify my-4">Fumar é somente permitido em áreas designadas;</li>
                    <li className="text-justify my-4">Somente veículos em boas condições de circulação e ou com certificado de inspecção de veículos válido serão autorizados a aceder ao Porto;</li>
                    <li className="text-justify my-4">Os agentes que visitarem navios deverão estacionar os seus veículos em áreas de estacionamento designadas;</li>
                    <li className="text-justify my-4">O estacionamento durante a noite, sem ser autorizado, ou após concluir o carregamento ou o descarregamento de carga, bem como reparações nas instalações do Porto ou estacionamento por tempo superior ao autorizado, não é permitido. Nos casos de não cumprimento, serão aplicadas taxas em conformidade com a tarifa do Porto e os veículos / camiões serão libertados somente após o pagamento ser confirmado com o escritório de facturação do Porto; e</li>
                    <li className="text-justify my-4">Quaisquer danos às infra-estruturas do Porto, causados por terceiros, estão sujeitos a reparação ou substituição obedecendo os padrões, termos e condições do Porto. As intervenções devem ser realizadas num prazo máximo de 8 horas após o incidente, salvo nos casos em que apresentar confirmação da seguradora. </li>
                </ul>
                <ul style={{listStyleType: "none"}}>
                  <li className="bold">Por este meio confirmo que tomei conhecimento das condições em que tenho autorização de acesso ao Porto de Maputo.</li>
                </ul>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="secondary" name="show_modal" onClick={(e) => changeTermsAndCondition(e, false)} > Reject </Button>
                <Button color="success" name="show_modal" onClick={(e) => changeTermsAndCondition(e, true)} > Accept </Button> 
            </ModalFooter>
          </Modal>
        </div>
      );
    
    // ::::::::::::::::::::::::::::::: CONDITIONAL FORM ELEMENTS
    const existingProvenanceSelectInput = 
        <div className="form-group">
            <select type="select" name="p_id_provenance" className="form-control dark-select"  value={p_id_provenance || ''} onChange={e => onChange(e)}>
                <CustomSelectOption options={AppFunctionalityUsable.provenances} hasId={true}/>
            </select>
        </div>

    const newProvenanceSelectInput = 
        <div className="form-group">
            <input type="name" name="provenance"  placeholder={t("new_provenience")} className="form-control dark-input" value={provenance || ''} onChange={e => onChange(e)}/>
        </div>

    const onlyForVisitorRegistrationInput = 
        <Fragment>
            <span className="separator d-block my-4"></span>
            {/* :: NEW PROVENANCE CHECKBOX */}
            <div className="form-group">
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" name="new_provenance" className="custom-control-input dark-check" id="nova-proveniencia" checked={new_provenance} onChange={e => onChange(e)}/>
                    <label className="custom-control-label dark-label" htmlFor="nova-proveniencia">{t("new_provenience")}</label>
                </div>
            </div>
            {/* :: CONDITIONAL PROVENANCE INPUT */}
            {new_provenance? newProvenanceSelectInput:existingProvenanceSelectInput}
            <span className="separator d-block mt-5"></span>
            {/* :: TERMS AND CONDITIONS INPUT */}
            <div className="form-group">
                <div className="custom-control custom-checkbox dark-check-container">
                    <input type="checkbox" className="custom-control-input dark-check" id="terms-and-conditions" checked={terms_condition} name="terms_condition" onChange={(e) => openTermsCond(e)}/>
                    <label className="custom-control-label dark-label" htmlFor="terms-and-conditions">{t("read_tc")}{" "}<span className="text-highlighted-dark-form">{t("terms_and_condition")}</span></label>
                </div>
            </div>
        </Fragment>
        
    
    return (
        <div className="form-page">
            <h2 className="form-header">{t('auth')}</h2>
            {/* :: EMAIL INPUT */}
            <div className="form-group iconed-input">
                <img src={EMAIL_INPUT_ICON} alt="user_icon" />
                <input type="email" name="p_email" placeholder={t("email")}  className="form-control dark-input" value={p_email || ''} onChange={e => onChange(e)}/>
            </div>
            {/* :: PASSWORD INPUT */}
            <div className="form-group iconed-input required">
                <img src={PASSWORD_INPUT_ICON} alt="user_icon" />
                <input type="password" name="p_psw" placeholder={t("password")} className="form-control dark-input" value={p_psw || ''} onChange={e => onChange(e)}/>
            </div>
            {/* :: PASSWORD CONFIRMATION INPUT */}
            <div className="form-group iconed-input required">
                <img src={PASSWORD_INPUT_ICON} alt="user_icon" />
                <input type="password" name="p_psw_2" placeholder={t("con_pas")} className="form-control dark-input" value={p_psw_2 || ''} onChange={e => onChange(e)}/>
            </div>
            
            {/* :: CONDITIONAL INPUT |> DISPLAYS ONLY FOR VISITORS */}
            {!forCompany && onlyForVisitorRegistrationInput}
            {/* :: TERMS AND CONDITION MODAL */}
            {termsAndConditions}
        </div>
    )
}

UserAccountDataForm.propTypes = {
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag
})

const mapDispatchToProps = {
    toastErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountDataForm)
