import { getToday, getInputDateFormatFromString, validateInputNames, validateInputDates, createStoreLoader, sendRequestDataWithDefaultConfig, checkApiResponseForErrors } from "@utils/utils";
import store from "@components/../store";
import { update } from "@actions/admin";
import { toastErrorMessage, toastSuccessMessage } from "@actions/toast";
import { ROOT_URL } from "@utils/constants";

class AlertMsg { 
    constructor(alertMsg) { 
        if(!alertMsg){
            this.p_msg = '';
            this.p_title = '';
            this.p_start = getInputDateFormatFromString(getToday());
            this.p_end = getInputDateFormatFromString(getToday().add(1, 'day'));;
            this.p_active = 1;
        } else { 
            this.createAlertMessageProperties(alertMsg);
        }
    }

    set(key, val) { 
        this[key] = val;
        store.dispatch(update());
    }

    get(key) {
        return this[key] || '';
    }

    /**
     * Sets a property and immedeately sends the update to the backend.
     * @param {string} key 
     * @param {*} val 
     */
    async setAndSubmit(key, val){ 
      this[key] = val;
      if(key==="active") this.p_active = val;
      await this.update(true);
      return true;
    }

    /**
     * Creates all alert message properties
     * @param {object} alertMsg 
     */
    createAlertMessageProperties(alertMsg) { 
        Object.keys(alertMsg).forEach(key => this[key] = alertMsg[key]);
        //::>> For Settings Table
        this.active = this.p_active;
        this.name = this.p_title
    }

    /**
     * Validates alert message Fields.
     */
    validate() { 
        let title, msg, start, end;
        title = validateInputNames(this.p_title);
        msg = validateInputNames(this.p_msg);
        start = validateInputDates(this.p_start);
        end = validateInputDates(this.p_end);

        if (this.to_edit) start = true;

        if(!title) return [title, 'Invalid Title'];
        if(!msg) return [msg, 'Invalid Message'];
        if(!start) return [start, 'Invalid Date'];
        if(!end) return [end, 'Invalid Date'];
        return [true, ''];
    }

    /**
     * Sends the Alert message data to the backend to be created.
     */
    async save() { 
        let savedSuccessfuly = true;
        const loader = createStoreLoader(store);
        try {
          const [isSuccessful, message] = this.validate();
          if(!isSuccessful)
            throw new Error(message);
          
          const body = JSON.stringify(this);
          let response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/addAlerMessage`, body);
          checkApiResponseForErrors(response);
          store.dispatch(toastSuccessMessage('Success'));
        } catch (error) {
          savedSuccessfuly = false;
          store.dispatch(toastErrorMessage(error.message));
        }

        loader.stopLoading();
        return savedSuccessfuly;
    }

    /**
     * Sends the Alert Message to the backend to be validated.
     * @param {boolean} skipValidation 
     */
    async update(skipValidation = false) { 
      let savedSuccessfuly = true;
      const loader = createStoreLoader(store);
      try {
        const [isSuccessful, message] = this.validate();
        if(!isSuccessful && !skipValidation) { 
          throw new Error(message);
        }

        const body = JSON.stringify(this);
        const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/editAlertMessage`, body);
        checkApiResponseForErrors(res);

        store.dispatch(update());
        store.dispatch(toastSuccessMessage('Success'));
      } catch (err) {
        savedSuccessfuly = false;
        store.dispatch(toastErrorMessage(err.message));
      }

      loader.stopLoading();
      return savedSuccessfuly;
    }

}

export default AlertMsg;