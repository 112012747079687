import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VisitorsVistsTable from './Shared/VisitorsVistsTable'
import VisitModal from '@visitorsPages/Visitor/VisitModal'
import { ADD_VISITOR_MODAL, CHOOSE_VISITOR_MODAL, CREATE_REQUISITION_SERVICE_PROVIDER_MODAL, CREATE_REQUISITION_X_MODAL, MIN_WINDOW_SIZE_FOR_TABLE_VIEW, SEE_ALERT_MSG_MODAL, VIEW_REQUISITION_MODAL } from '../../../utils/constants'
import ChooseVisitorModal from './ServiceProvider/ChooseVisitorModal'
import { isServiceProviderUserWorker } from '@utils/utils'
import ServiceProviderVisitModal from '@visitorsPages/ServiceProvider/ServiceProviderVisitModal'
import InternalNavBar from '@mainPage/Shared/InternalNavBar'
import { fetchAllWorkersOfServiceProvider, open_modal } from '@actions/admin'
import AlertMessageModal from './Shared/AlertMessageModal'
import AddWorkerModal from './ServiceProvider/AddWorkerModal'
import VisitViewModal from '@colaboratorsPages/Shared/UtilsModals/VisitViewModal/VisitViewModal'
import VisitorsVisitsCardView from './Shared/VisitorsVisitsCardView'
import { fetchAllVisitMaterials, fetchServiceProviderDataIfUserIs } from '@actions/visits'
import AlertMessagesView from './Visitor/AlertMessagesView'

const Main = ({visits, loggedUser, modalID,  open_modal, fetchAllWorkersOfServiceProvider, fetchAllVisitMaterials, fetchServiceProviderDataIfUserIs}) => {
    const [state, setState] = useState({
        windowSize: window.innerWidth
    });

    useEffect(() => {
        const handleResize = (e) => setState({...state, windowSize: window.innerWidth});
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize)};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    // ::: FUNCTIONS FOR CHILDREN ELEMENTS DISPLAY
    const updateVisitData = async (visit) => { 
        const isSuccessful = await fetchAllVisitMaterials(visit, loggedUser);
        if(isSuccessful) {
            if(isServiceProviderUserWorker(loggedUser) && visit.p_fast) 
                open_modal(VIEW_REQUISITION_MODAL, visit);
            else
                await fetchServiceProviderDataIfUserIs(visit, loggedUser);
                open_modal(CREATE_REQUISITION_X_MODAL, visit);
        }
    }

    const onSelectVisitVisitor = async (e, visit) => {
        await fetchServiceProviderDataIfUserIs(visit, loggedUser);
        await fetchAllWorkersOfServiceProvider(loggedUser);
        open_modal(CHOOSE_VISITOR_MODAL, visit);
    }

    const actionsForChildren = {onSelectVisitVisitor, updateVisitData}

    // ::: CONDITIONAL FLAGS TO DISPLAY VISITS
    const isToDisplayTable = state.windowSize >= MIN_WINDOW_SIZE_FOR_TABLE_VIEW;
    const isToDisplayCardView = !isToDisplayTable;
    

    return (
        <Fragment>
            <InternalNavBar/>
            <div className="main-display-container">
                {/* :: TABLE VIEW DISPLAY  */}
                {isToDisplayTable && <VisitorsVistsTable loggedUser={loggedUser} visits={visits} actionsForChildren={actionsForChildren} />}
                {/* :: CARD VIEW DISPLAY */}
                {isToDisplayCardView && <VisitorsVisitsCardView loggedUser={loggedUser} visits={visits} actionsForChildren={actionsForChildren}  />}
                {/* :: ALERT MESSAGES VIEW */}
                <AlertMessagesView />
            </div>
            {/* :: MODALS FOR ALL TYPES OF VISITOR PRIVILEGES */}
            {modalID === CREATE_REQUISITION_X_MODAL &&  <VisitModal/>}
            {modalID === CHOOSE_VISITOR_MODAL && <ChooseVisitorModal />}
            {modalID === CREATE_REQUISITION_SERVICE_PROVIDER_MODAL && <ServiceProviderVisitModal/> }
            {modalID === ADD_VISITOR_MODAL && <AddWorkerModal loggedUser={loggedUser} />}
            {modalID === SEE_ALERT_MSG_MODAL && <AlertMessageModal/>}
            {modalID === VIEW_REQUISITION_MODAL && <VisitViewModal/>}
        </Fragment>
    )
}

Main.propTypes = {
    visits: PropTypes.array.isRequired,
    loggedUser: PropTypes.object,
    modalID: PropTypes.string,
    open_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    visits: state.visits.visits,
    loggedUser: state.auth.user,
    modalID: state.admin.modal,
    
})

const mapDispatchToProps = {
    open_modal,
    fetchAllVisitMaterials,
    fetchServiceProviderDataIfUserIs,
    fetchAllWorkersOfServiceProvider,
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)