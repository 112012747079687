import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import InductionQuestion from '@model/Settings/InductionQuestion';
import { ROOT_URL } from '@utils/constants';
import { makeItOnlyNumber, makeItOnlyString } from '@utils/utils';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardImg, Col, CustomInput, Form, FormGroup, Input, Row, Button, Modal } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import ModalBody from 'reactstrap/lib/ModalBody';
import { v4 } from 'uuid';

const AddInductionModal = ({toShow, closeModal}) => {
    const { t } = useTranslation('settings');
    const [state, setState] = useState({
        question: {},
    })

    const {question} = state;

    useEffect(() => { 
        AppFunctionalityUsable.getNextQuestionId();
        const question = new InductionQuestion();
        question.createNewQuestion(AppFunctionalityUsable.nextQuestionId);
        setState({...state, question});
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [AppFunctionalityUsable.nextQuestionId]);

    // ::: EVENT HANDLERS
    const onSave = async () => { 
        const isSuccessful = await question.save();
        if (isSuccessful) 
            AppFunctionalityUsable.resetQuesionId();
    }

    // :::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const generateQuestionOptions = () => { 
        let listOfOptions = <div></div>
        if(question.options){
            listOfOptions = question.options.map((option, index) => { 
                return <Col md={4} key={v4()}>
                    <Card className={`${option.isCorrect? 'border-success': 'border-danger'}`}>
                        <CardImg top width="100%" style={{backgroundSize: "auto", maxHeight: "200px"}} src={`${ROOT_URL}/files/induction/${option.file_name}`}></CardImg>
                        <CardBody>
                            <FormGroup>
                                <CustomInput
                                    id={`fileInput_${index}`}
                                    type="file"
                                    label={option.img_name || ' '}
                                    name="q_img"
                                    placeholder={t("id")}
                                    onChange={e => option.setImage(e.target.files[0])}
                                    />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            })
        }
        return listOfOptions;
    }

    const inductionTypes = !AppFunctionalityUsable.inductionTypes? [] : AppFunctionalityUsable.inductionTypes.map((indType, index) => { 
        if (index === 0) return <span key={v4()}></span>;
        try {
            return <FormGroup key={v4()} check inline>
                    <Label check>
                    <Input type="checkbox" checked={question.get('p_cod_extra').includes(indType.code)} onChange = {e => question.set('p_cod_extra', indType.code)}/> {indType.name}
                    </Label>
                </FormGroup>
        } catch (error) {
            return <FormGroup key={v4()} check inline>
                        <Label check>
                        <Input type="checkbox" checked={false} onChange = {e => question.set('p_cod_extra', indType.code)}/> {indType.name}
                        </Label>
                    </FormGroup>
        }
        
    })

    return (
        
        <Modal isOpen={toShow} size="xl" className="no-border modal-xl" centered>
            {/* ::: MODAL TITLE */}
            <h3 className="title-of-modal">{ t('add_ind')}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <div>
                    <Form  className="my-4">
                        {/* ::: QUESTION TO ASK IN PT */}
                        <FormGroup row>
                            <Col md={12}>
                                <Input type="text" className="required" name="p_question" value={question.p_question || ''} placeholder={t('question')} onChange={e => question.set(e.target.name,  makeItOnlyString(e.target.value))}/>
                            </Col>
                        </FormGroup>
                        {/* ::: QUESTION TO ASK IN EN */}
                        <FormGroup row>
                            <Col md={12}>
                                <Input type="text" className="required" name="p_question_en" value={question.p_question_en || ''} placeholder={t('question_en')} onChange={e => question.set(e.target.name, makeItOnlyString(e.target.value))}/>
                            </Col>
                        </FormGroup>
                        {/* ::: QUESTION POINTS */}
                        <FormGroup row>
                            <Col md={4}>
                                <Label>{t('points') + ":"}</Label>
                                <Input type="text" className="required" name="p_points" value={question.p_points} placeholder={t('points')} onChange={e => question.set(e.target.name, makeItOnlyNumber(e.target.value))} />
                            </Col>
                        </FormGroup>
                        {/* ::: TO WHAT TYPE OF INDUCTION IT BELONGS */}
                        <FormGroup>
                            <p>{t('typ_ind')}</p>
                            {inductionTypes}
                        </FormGroup>
                        {/* ::: INDUCTION QUESTION IMAGE ANSWERS */}
                        <FormGroup>
                            <Row>
                                {generateQuestionOptions()}
                            </Row>
                        </FormGroup>
                        {/* ::: FORM BUTTONS */}
                        <div className="d-flex justify-content-between my-2">
                            <Button color="secondary" onClick={e => closeModal(e)}>{t('close')}</Button>
                            <Button color="success" onClick={e => onSave(e)}>{t('submit')}</Button>
                        </div>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddInductionModal;
