import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import InternalNavBar from '@mainPage/Shared/InternalNavBar'
import { useTranslation } from 'react-i18next'
import InductionSettingsTab from '@settingsPage/SettingsTabs/Induction/InductionSettingsTab'
import TerminalSettingsTab from '@settingsPage/SettingsTabs/Terminals/TerminalSettingsTab'
import AlertMsgSettingsTab from '@settingsPage/SettingsTabs/AlertMessages/AlertMsgSettingsTab'
import ExtraSettingsTab from '@settingsPage/SettingsTabs/Extras/ExtraSettingsTab'
import ServicesSettingsTab from '@settingsPage/SettingsTabs/Services/ServicesSettingsTab'
import UserSettingsTab from '@settingsPage/SettingsTabs/Users/UserSettingsTab'
import { MapPin, MessageSquare, Settings as SettingsIcon, Sliders, UserCheck, User as UserIcon, Truck} from 'react-feather'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { fetchAllHosts, fetchServiceProviderCompanies, getServerSavedConfigurations } from '@actions/admin'
import { Fragment } from 'react'
import ParameterSettingsTab from './SettingsTabs/Parameters/ParameterSettingsTab'

const REFRESH_ICON = require(`@resources/icons/cached.svg`);


const Settings = ({flag, user, fetchAllHosts, fetchServiceProviderCompanies, getServerSavedConfigurations}) => {
    const {t} = useTranslation('settings')
    const [state, setState] = useState({
        current_setting: "parameters",
    })
    const {current_setting} = state;

    const onChangeSettings = e => { 
        setState({...state, current_setting: e.target.getAttribute('name')})
    }

    const refresh = e => { 
        if(current_setting === t('induction')){
            AppFunctionalityUsable.getInductionQuestions(1);
        } else if(current_setting === t('terminal')){ 
            AppFunctionalityUsable.getTerminalsForEdit();
        } else if(current_setting === t('messages')) {
            AppFunctionalityUsable.getAlertMessages();
        } else if(current_setting === t('extras')){
            AppFunctionalityUsable.getMainFunctionalityUsabes(true);
        } else if(current_setting === t('users')){
            fetchAllHosts();
        } else if(current_setting === t('services')){
            fetchServiceProviderCompanies();
        } else if(current_setting === 'parameters') {
            getServerSavedConfigurations();
        }
    }

    return (
        <Fragment>
            <InternalNavBar/>
            <div className="main-display-container p-0 ">
                <div className="settings-container">
                    {/* :: SETTINGS NAVIGATION */}
                    <div className="settings-nav">
                        <div className={`settings-option ${current_setting === 'parameters'? "selected":""}`}>
                            <SettingsIcon size="18px" className="mr-3"/>
                            <span  name={'parameters'} onClick={e => onChangeSettings(e)}>{t('parameters')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('induction')? "selected":""}`}>
                            <UserCheck size="18px" className="mr-3"/>
                            <span  name={t('induction')} onClick={e => onChangeSettings(e)}>{t('induction')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('terminal')? "selected":""}`}>
                            <MapPin size="18px" className="mr-3"/>
                            <span  name={t('terminal')} onClick={e => onChangeSettings(e)}>{t('terminal')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('messages')? "selected":""}`}>
                            <MessageSquare size="18px" className="mr-3"/>
                            <span  name={t('messages')} onClick={e => onChangeSettings(e)}>{t('messages')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('extras')? "selected":""}`}>
                            <Sliders size="18px" className="mr-3"/>
                            <span  name={t('extras')} onClick={e => onChangeSettings(e)}>{t('extras')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('users')? "selected":""}`}>
                            <UserIcon size="18px" className="mr-3"/>
                            <span  name={t('users')} onClick={e => onChangeSettings(e)}>{t('users')}</span>
                        </div>
                        <div className={`settings-option ${current_setting === t('services')? "selected":""}`}>
                            <Truck size="18px" className="mr-3"/>
                            <span  name={t('services')} onClick={e => onChangeSettings(e)}>{t('services')}</span>
                        </div>
                    </div>

                    <div className="settings-content">
                        <span className="button default-button right-sided-button" onClick={e => refresh(e)}><img alt="refresh" className="mb-1" src={REFRESH_ICON} />Refresh</span>
                        <div className="separator transparent my-4"></div>
                        {current_setting === 'parameters' && <ParameterSettingsTab/>}
                        {current_setting === t('induction') && <InductionSettingsTab/>} 
                        {current_setting === t('terminal') && <TerminalSettingsTab/>} 
                        {current_setting === t('messages') && <AlertMsgSettingsTab/>}  
                        {current_setting === t('extras')&& <ExtraSettingsTab/>}  
                        {current_setting === t('users') && <UserSettingsTab/>} 
                        {current_setting === t('services') && <ServicesSettingsTab/>}
                    </div>
                   
                </div>
            </div>
        </Fragment>
    )
}

Settings.propTypes = {
    flag: PropTypes.bool,
    user: PropTypes.object,
    fetchAllHosts: PropTypes.func.isRequired,
    fetchServiceProviderCompanies: PropTypes.func.isRequired,
    getServerSavedConfigurations: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
})

const mapDispatchToProps = {
    fetchAllHosts,
    fetchServiceProviderCompanies,
    getServerSavedConfigurations,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
