import store from "@components/../store";
import { toastErrorMessage, toastSuccessMessage } from "@actions/toast";
import { ROOT_URL, TIN_TYPE } from "@utils/constants";
import { update } from "@actions/admin";
import AppFunctionalityUsable from "./AppFunctionalityUsable";

const {createStoreLoader, sendRequestDataWithDefaultConfig, checkApiResponseForErrors } = require("@utils/utils");

class NewExtra { 
    constructor(extraType) {
        this.p_id_etype = extraType;
        this.p_active = 1;
    }

    set(key, val) { 
        this[key] = val;
        store.dispatch(update());
    }

    get(key) { 
        return this[key] || [];
    }

    /**
     * Sends the new extra to the database.
     */
    async save() { 

      const loader = createStoreLoader(store);
        let isSuccessful = true;
        try {
            let body = JSON.stringify(this);
            const requestUrl = `${ROOT_URL}/extras/addExtra`;
            let res = await sendRequestDataWithDefaultConfig(requestUrl, body);
            checkApiResponseForErrors(res);

            if (this.p_id_etype === TIN_TYPE) { 
              this.p_name = `${this.p_name}_EN`;
              body = JSON.stringify(this);
              res = await sendRequestDataWithDefaultConfig(requestUrl, body);
              checkApiResponseForErrors(res);
            }

            AppFunctionalityUsable.getMainFunctionalityUsabes(true);
            store.dispatch(toastSuccessMessage('Success'));
            store.dispatch(update());
      } catch (err) {
          store.dispatch(toastErrorMessage(err.message));
          isSuccessful = false;
      }

      loader.stopLoading();
      return isSuccessful;
    }

}

export default NewExtra;