import React from 'react'
import { connect } from 'react-redux'
import { isCAMemberUser, isCoreSecurityUser, isHostUser, isThreadUser } from '@utils/utils';
import { User } from 'react-feather'
import LanguageSelector from '@components/Shared/LanguageSelector';
const InternalNavBar = ({loggedUser}) => {
  
    const onToggleNav = () => { 
        const hambButton = document.querySelector(".hamb-btn-container");
        const internalPageNav = document.querySelector(".internal-side-nav-container");
        hambButton.classList.toggle("open");
        internalPageNav.classList.toggle("open");
    }

    // :: NAVIGATION CONDITIONAL TEXT
    const isAHost = (isHostUser(loggedUser) || isThreadUser(loggedUser) || isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser));
    const userNameAndTerminal = `${loggedUser.p_fname + ' ' + loggedUser.p_lname} - ${loggedUser.company}`
    const userCompany = `${loggedUser.p_fname + ' ' + loggedUser.p_lname}  ${loggedUser.entity? "- " + loggedUser.entity : ""}`;
    const userInfo = isAHost? userNameAndTerminal : userCompany;

    return (
        <div className="internal-main-content-nav">
            <div className="user-info">
                <User/>
                <span>{userInfo}</span>
                {/* :: LANGUAGE SELECTOR */}
                <LanguageSelector/>
            </div>
            <div className="hamb-btn-container" onClick={e => onToggleNav()}>
                <div className="hamb-btn-lines"></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loggedUser: state.auth.user,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(InternalNavBar)
