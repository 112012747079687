import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'
import { getAppConfig } from '@utils/configurations'
import { ACCEPTED_TODAY, CANCELED_TODAY, PENDING_TODAY, ROOT_URL } from '@utils/constants'
import { getToday } from '@utils/utils'
import React from 'react'
import { RefreshCcw } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Badge, Form, Input, Label } from 'reactstrap'
import { v4 } from 'uuid';


const MainHostsInfoNdFilterSection = ({parentProperties, parentFunctions}) => {
    const { t } = useTranslation('main');
    const { REQUEST_FILTER } = getAppConfig(); 

    const {loggedUser, filter, filter_val, start_date, generalState} =  parentProperties;
    const {onChangeFilter, fetchLoggedUserVisits} = parentFunctions;

    const refreshPage = async e => {
        fetchLoggedUserVisits(loggedUser);
    }
       
    // ::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    let inputOfFilterType;
    if ((filter === 'department') || ((filter === 'terminal'))) { 
        const listToRender = filter === 'department'? AppFunctionalityUsable.departments : AppFunctionalityUsable.terminals;
        inputOfFilterType = (
            <Input type="select" name="filter_type" className="d-inline-block" value={filter_val} onChange={e => onChangeFilter(e)}>
                {listToRender.map(extra => { 
                    return <option key={v4()} value={extra.name}>{extra.name}</option>
                })}
            </Input>
        )
    } else  { 
        inputOfFilterType = <Input type="text" name="filter_type" placeholder={t('fil_val')} value={filter_val} className="d-inline-block" onChange={e => onChangeFilter(e)} />
    }


    return (
        <div className="stats-container">
            {/* ::: STATS AND FILTERS CONTAINER */}
            <div className="filters-container elevated-container">
                {/* ::: TITLE  */}
                <h3 className="text-medium">{t('my_req')} </h3>
                {/* ::: SUBSCRIPT */}
                <div className="stats-subscript">
                    <Badge className="mr-1 ml-0 text-thin" color="success">{t("approved")}</Badge>
                    <Badge className="mx-1 text-white text-thin bg-orange" color="warning">{t("pending")}</Badge>
                    <Badge className="mx-1 text-white text-thin" color="danger">{t("rej_can")}</Badge>
                </div>
                <div className="filters-form-container clear-float-both">
                    {/* ::: TYPE OF FILTER SELECTOR */}
                    <Form inline >
                        <Input type="select" name="filter" className="d-inline mr-3" value={filter} onChange={e => onChangeFilter(e)}>
                            {Object.keys(REQUEST_FILTER).map(key => { 
                                return <option key={v4()} value={REQUEST_FILTER[key]}>{t(key)}</option>
                            })}
                        </Input>
                        {/* ::: FILTER INPUT */}
                        {inputOfFilterType}
                    </Form>
                    {/* ::: DATE FILTER INPUT */}
                    <Form inline>
                        <Label for="date_start" className="mr-3 d-inline-block">{t('sta_dat')}</Label>
                        <Input type="date" id="date_start" className="small-form-ele" name="start_date" value={start_date} onChange={e => onChangeFilter(e)}/>
                        <div className="d-inline-block refresh-btn mx-2">
                            <RefreshCcw size="20px" onClick={e => refreshPage(e)}/>
                        </div>
                    </Form>
                    {/* ::: DOWNLOAD USER MANUAL BUTTON */}
                    <a href={`${ROOT_URL}/files/hosts/manual`} type="button" className="btn btn-secondary btn-sm float-right">{t('dow_use_gu')}</a>
                </div>
            </div>
            {/* ::: STATS OF DAY CONTAINER */}
            <div className="stats-day-container elevated-container">
                <span className="text-thin cdate">{getToday().format('ddd, MMMM Do')}</span>
                <div className="separator thin light mb-2"/>
                    <div>
                        <span className={`material-icons md-12 mr-1 mic text-green`}>stop_circle</span>
                        <span className={`st`}>{t('aproved')} : {generalState[ACCEPTED_TODAY] || '-'}</span>
                    </div>
                    <div>
                        <span className={`material-icons md-12 mr-1 mic text-red`}>stop_circle</span>
                        <span className={`st`}>{t('canceled')} : {generalState[CANCELED_TODAY] || '-'}</span>
                    </div>
                    <div>
                        <span className={`material-icons md-12 mr-1 mic text-yellow`}>stop_circle</span>
                        <span className={`st`}>{t('pending')} : {generalState[PENDING_TODAY] || '-'}</span>
                    </div>
                </div>
        </div>
    )
}

export default MainHostsInfoNdFilterSection
