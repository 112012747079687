
import React, { Fragment, useState } from 'react'
import { FormGroup, Input, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { addMaterialToVisit, addVehicleToVisit, getAllVisitMaterials, getAllVisitVehicles, removeVisitMaterialsFromVisit } from '@actions/visits';


const HostsVisitDataPage3 = ({visit}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
      flag: false,
    });

    // ::: EVENT HANDLERS
    const addObjectToVisit = (e, functionToExecute) => { 
      functionToExecute(visit);
      setState({...state, flag: !state.flag});
    }

    const removeObjectFromVisit = (e, materialName) => { 
      removeVisitMaterialsFromVisit(visit, materialName);
      setState({...state, flag: !state.flag});
    }

    // :::::::::::::::::::::: GENERATED HTML ELEMENTS
    // ::: GENERATOR FUNCTIONS
    const generateMaterialsList = () => {
      const materials = getAllVisitMaterials(visit);
        return materials.map((material, i) => {
          return (
            <FormGroup key={i} row>
              <Col md={5}>
                <Input type="text" name="material_name" value={material.p_oname} onChange={(e) => material.changeName(e.target.value)} placeholder={t("nam_mat")}/>
              </Col>
              <Col md={3}>
                <Input type="text" name="material_qtd" value={material.p_qnt} onChange={(e) => material.changeQuantity(e.target.value)} placeholder={t("quantity")} />
              </Col>
              <Col md={2}>
                <span name="accept" className="faded-button error" onClick={(e) => removeObjectFromVisit(e, material.p_oname)} >{t("remove")}</span>
              </Col>
            </FormGroup>
          );
        });    
    };
  
    const generateVehiclesList = () => {
      const vehicles =  getAllVisitVehicles(visit);
      return vehicles.map((vehicle, i) => {
      return (
          <FormGroup key={i} row>
              <Col md={5}>
                <Input type="text" name="vehicle" value={vehicle.p_oname} onChange={(e) => vehicle.changeName(e.target.value)} placeholder={t("license")} />
              </Col>
              <Col md={2}>
                <span name="remove" className="faded-button error" onClick={(e) => removeObjectFromVisit(e, vehicle.p_oname)}> {t("remove")} </span>
              </Col>
          </FormGroup>
          );
      });
    }
    
    return (
        <Fragment>
          {/* ::: INPUTS FOR MATERIALS AND VEHICLES */}
            <div className="divider transparent my-4"></div>
            {/* ::: MATERIALS INPUT */}
            <div>
                <p className="d-inline-block mx-2" col={1}> {t("material")} </p>
                <span name="accept" className="faded-button success" onClick={(e) => addObjectToVisit(e, addMaterialToVisit)} > {t("add_mat")} +{" "} </span>
            </div>
            {generateMaterialsList()}
            {/* ::: VEHICLES INPUT */}
            <div>
                <p className="d-inline-block mx-2" col={1}> {t("vehicle")} </p>
                <span name="accept" className="faded-button success" onClick={(e) => addObjectToVisit(e, addVehicleToVisit)} > {t("add_veh")} +{" "} </span>
            </div>
            {generateVehiclesList()}
        </Fragment>
    )
}
HostsVisitDataPage3.propTypes = {

}

const mapStateToProps = (state) => ({
  flag: state.admin.flag,
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(HostsVisitDataPage3);