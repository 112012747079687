import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, ModalFooter, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { setMessage, removeMessage } from '@actions/toast'
import { addServiceProviderWorkerToVisitToggle, close_modal, fetchAllWorkersOfServiceProvider, filterServiceProviderWorkers } from '@actions/admin'
import VisitDataPage1 from '../Shared/VisitDataPage1'
import VisitDataPage2 from '../Shared/VisitDataPage2'
import ChooseVisitorForm from './ChooseVisitorForm'
import { fetchLoggedUserVisits, performVisitorVisitSubmissionProcess, setNewServiceProviderVisitDefaultFields } from '@actions/visits'

 const ServiceProviderVisitModal = ({ loggedUser, fetchLoggedUserVisits, performVisitorVisitSubmissionProcess, addServiceProviderWorkerToVisitToggle, filterServiceProviderWorkers, setNewServiceProviderVisitDefaultFields, serviceProviderWorkers, fetchAllWorkersOfServiceProvider, close_modal}) => {
  const { t } = useTranslation('request');
  const [state, setState] = useState({
      page: 0,
      num_page: 2,
      flag: false,
      disSub: false,
      newVisit: setNewServiceProviderVisitDefaultFields({}, loggedUser)
  });
  const {page, num_page, disSub, newVisit} = state;

  // ::: NAVIGATION
  const next = e => { setState({...state, page: page >= num_page? num_page : page + 1});}
  const prev = e => { setState({...state, page: page <= 0? 0:page - 1});};

  useEffect(() => { 
    fetchAllWorkersOfServiceProvider(loggedUser);
    addServiceProviderWorkerToVisitToggle(newVisit, loggedUser);
    setState({...state, flag: !state.flag});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  // ::: EVENT HANDLERES
  const onSubmit = async e => { 
      setState({...state, disSub: true});
      const isSuccessful = await performVisitorVisitSubmissionProcess(newVisit);
      setState({...state, disSub: false});
      if(isSuccessful)
        onClose();
  }

  const onClose = e => {
      close_modal();
      fetchLoggedUserVisits(loggedUser);
  }

  return (
    <Modal isOpen={true} className="no-border" centered size="lg">
        {/* ::: TITLE OF MODAL */}
        <h3 className="title-of-modal"> {t("cre_req")} </h3>
        <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            <Form style={{ minHeight: "calc((100vh - 210px) - 180px)"}}>
                {/* ::: FORM PAGES */}
                {page === 0 && <VisitDataPage1 visit={newVisit} hideCol={true}/>} 
                {page === 1 && <ChooseVisitorForm loggedUser={loggedUser} addServiceProviderWorkerToVisitToggle={addServiceProviderWorkerToVisitToggle} visit={newVisit} serviceProviderWorkers={serviceProviderWorkers} filterServiceProviderWorkers={filterServiceProviderWorkers} />}
                {page === 2 && <VisitDataPage2 visit={newVisit}/>}
            </Form>
            {/* ::: PAGE INDICATORS */}
            <div className="page-indicators-container blue-version">
              <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
              <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
              <span className={`page-indicator ${page === 2 ? "active" : ""}`}></span>
            </div>
        </ModalBody>
        <ModalFooter>
            {/* ::: PAGE BUTTONS */}
            <div className="modal-footer-button-container">
                <Button className="left-sided-button" onClick={e => close_modal()}>{t('cancel')}</Button>
                <Button className="right-sided-button" disabled={disSub} color={`${page >= num_page? 'success':'primary'}`}  onClick={e => page >= num_page? onSubmit(e):next(e)}>{page >= num_page? t('submit'):t('next')}</Button>
                <Button className="right-sided-button"  color="primary" disabled={page <= 0} onClick={e => prev(e)}>{t('previous')}</Button>
            </div>
        </ModalFooter>
    </Modal>
  )
}

ServiceProviderVisitModal.propTypes = {
    modal: PropTypes.string,
    close_modal: PropTypes.func.isRequired,
    removeMessage: PropTypes.func.isRequired,
    loggedUser: PropTypes.object,
    serviceProviderWorkers: PropTypes.array.isRequired,
    flag: PropTypes.bool,
    setMessage: PropTypes.func.isRequired,
    setNewServiceProviderVisitDefaultFields: PropTypes.func.isRequired,
    fetchAllWorkersOfServiceProvider: PropTypes.func.isRequired,
    filterServiceProviderWorkers: PropTypes.func.isRequired,
    addServiceProviderWorkerToVisitToggle: PropTypes.func.isRequired,
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    performVisitorVisitSubmissionProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
     loggedUser: state.auth.user,
     modal: state.admin.modal,
     serviceProviderWorkers: state.admin.serviceProviderWorkers,
     flag: state.admin.flag,
})

const mapDispatchToProps = {
  close_modal,
  removeMessage,
  setMessage,
  setNewServiceProviderVisitDefaultFields,
  fetchAllWorkersOfServiceProvider,
  filterServiceProviderWorkers,
  addServiceProviderWorkerToVisitToggle,
  performVisitorVisitSubmissionProcess,
  fetchLoggedUserVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderVisitModal)