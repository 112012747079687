import { LOADING_TOAST, ERROR_TOAST, SUCCESS_TOAST, WARNING_TOAST, INFORMATION_TOAST, DEFAULT_TOAST_MESSAGE_TIMEOUT } from "../utils/constants";
import { SET_MESSAGE, REMOVE_MESSAGE, SET_LOADING_MESSAGE, REMOVE_LOADING_MESSAGE } from "./types";
import {v4} from 'uuid';
import store from "../store";

export const setMessage = (message, msgType, myId, timeout=8000) => dispach => {
    const id =  v4();
    dispach({
        type: SET_MESSAGE,
        payload: { message: [message], id: myId? myId:id , msgType }
    })
    if(msgType !== LOADING_TOAST){
        setTimeout(() => {
            dispach({
                type: REMOVE_MESSAGE,
                payload: id
            })
        },timeout);
    } 
    else { 
        setTimeout(() => { 
            dispach({
                type: REMOVE_MESSAGE,
                payload: myId
            })
        }, 25000)
    }
}


export const removeMessage = (id) => dispach => { 
    dispach({
        type: REMOVE_MESSAGE,
        payload: id
    })
}



//====================================== REFACTORING NEW METHODS ============================//
export const startLoading = (loadingCode) => dispatch => {
    dispatch({
        type: SET_LOADING_MESSAGE,
        payload: loadingCode
    });
}
  
export const stopLoading = (loadingCode) => dispatch => { 
    dispatch({
        type: REMOVE_LOADING_MESSAGE,
        payload: loadingCode
    })
}

const buildLoadingMessageController = () => { 
    return {
      loadingCode: v4(),
      start() {store.dispatch(startLoading(this.loadingCode))},
      stop() {store.dispatch(stopLoading(this.loadingCode))}
    }
}

export const buildAndStartLoader = () => dispatch => { 
    const loader = buildLoadingMessageController();
    loader.start();
    return loader;
}

export const toastErrorMessage = (message, timeout=DEFAULT_TOAST_MESSAGE_TIMEOUT) => dispatch => { 
    dispatch(setMessage(message, ERROR_TOAST, undefined, timeout));
}

export const toastWarningMessage = (message, timeout=DEFAULT_TOAST_MESSAGE_TIMEOUT) => dispatch => { 
    dispatch(setMessage(message, WARNING_TOAST, undefined, timeout));
}


export const toastSuccessMessage = (message, timeout=DEFAULT_TOAST_MESSAGE_TIMEOUT) => dispatch => { 
    dispatch(setMessage(message || 'Success', SUCCESS_TOAST, undefined, timeout));
}

export const toastInformationMessage = (message, timeout=DEFAULT_TOAST_MESSAGE_TIMEOUT) => dispatch => { 
    dispatch(setMessage(message, INFORMATION_TOAST, undefined, timeout));
}