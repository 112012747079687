import store from "../../store";
import { update } from "@actions/admin";

class VisitExtra  { 

    constructor() { 
        this.p_oname = '';
        this.p_qnt = 1;
        this.p_is_active = 1;
    }

    set(key, value) { 
        this[key] = value;
        this.formalize();
    }

    get(key) { 
        return this[key]? this[key]: '';
    }

    /**
     * Changes the material name.
     * @param {string} name 
     */
    changeName (name) { 
        this.p_oname = name;
        this.formalize()
        store.dispatch(update());
    }

    /**
     * Changes the material quantity
     * @param {number} qtd 
     */
    changeQuantity (qtd) { 
        this.p_qnt = qtd;
        this.formalize();
        store.dispatch(update());
    }

    /**
     * Creates the visit extra properties from a response Data
     * @param {*} responseData 
     */
    setVisitExtraProperties (responseData) { 
        Object.keys(responseData).forEach(key => this[key] = responseData[key]);
    }

    /**
     * Gets all the object properties, modifying the p_ismtrl bool
     */
    getProperties () { 
        return { p_oname: this.p_oname, p_qnt: this.p_qnt }
    }
       
}

export default VisitExtra;