import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, Form, FormGroup, Input, ModalFooter, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { blockVisitor, fetchAllVisitors } from '@actions/admin'

const VisitorBlockReasonsModal = ({flag, blockVisitor, fetchAllVisitors, visitor, user, toggleShowReasonsModal}) => {
    const {t} = useTranslation('main');
    const [state, setState] = useState({
        flag: false,
    })
    const {p_why} = visitor;

    const blockButtonColor = visitor.p_isblocked? 'success':'danger';
    const blockButtonText = t(`${visitor.p_isblocked? 'unblock':'block'}`);
   
    // ::: EVENT HANDLERS
    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        visitor[nodeName] = nodeValue;
        setState({flag: !state.flag});
    };

    const onSend = async e => { 
        const isSuccessful = await blockVisitor(visitor, user);
        if(isSuccessful){
            toggleShowReasonsModal();
            fetchAllVisitors();
        }
    }

    return (
        <Modal isOpen={true} size="m" className="no-border" centered>
            <h3 className="title-of-modal">{t("blo_use")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form>
                    {/* ::: INPUT FOR USER BLOCKING JUSTIFICATION */}
                    <FormGroup>
                        <Input type="textarea" name="p_why" disabled={visitor.p_isblocked} className="required" placeholder={t("reason")} value={p_why || ''} onChange={(e) => onChange(e)} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className=" w-100 d-flex align-items-center justify-content-between">
                    <div>
                        <Button color="secondary" onClick={e => toggleShowReasonsModal(e)}> {t("cancel")} </Button>
                    </div>
                    <div>
                        <Button name='submit' color={blockButtonColor} onClick={e => onSend(e)} className="mx-3"> {blockButtonText} </Button>
                    </div>
                </div>
            </ModalFooter>
      </Modal>
    )
}

VisitorBlockReasonsModal.propTypes = {
    user: PropTypes.object,
    flag: PropTypes.bool,
    blockVisitor: PropTypes.func.isRequired,
    fetchAllVisitors: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.state,
    user: state.auth.user,
})

const mapDispatchToProps = {
    blockVisitor,
    fetchAllVisitors,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorBlockReasonsModal)
