import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin';
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import CustomInput from 'reactstrap/lib/CustomInput';


const ServicesEditModal = ({modal_data, flag, close_modal}) => {
    const {t} = useTranslation('settings')
    const comp = modal_data;
    const { p_cname,p_cemail,p_cpnumber_1,p_cpsw2,p_cpnumber_2,p_cpsw} = comp;

    // ::: EVENT HANDLERS
    const onSave = async e => { 
        const toRet = await comp.update();
        if(toRet){
            AppFunctionalityUsable.getTerminalsForEdit();
            close_modal();
        }
    }

    const onClose = e => { 
        close_modal();
    }

    // ::: HTML CONDITIONALS
    const isLoadingSubCompanies = comp.sub_companies === null;
    const isNotLoadingSubCompanies = !isLoadingSubCompanies;
    const isAPortCompany = comp.p_cport;

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const _loader = <img alt="loading" className="d-block mx-auto my-2 loading-gif text-center" src={require(`@resources/icons/loading.gif`)}/>;
    
    const _listOfSubCompanies = comp.sub_companies.map( (subComp, index) => 
                                <FormGroup key={index}>
                                    <Fragment>
                                        <Col md={6}>
                                            <Input type="text" name="p_scname" value={subComp.p_scname} placeholder={t('sub_com_nam')} onChange={e => subComp.set(e.target.name, e.target.value)}/>
                                        </Col>
                                    </Fragment>
                                </FormGroup>);

    const _subCompanySettings =  <Fragment>
                                    {isLoadingSubCompanies && _loader}
                                    {isNotLoadingSubCompanies && _listOfSubCompanies}
                                    {/* ::: ADD SUBCOMPANY BUTTON */}
                                    <FormGroup row>
                                            <Col md={6}>
                                                <Button color="primary" disabled={comp.sub_companies === null} name='addSubCompany' className="w-100" onClick={e => comp.addSubCompany()}>{t('add_sub')}</Button>
                                            </Col>
                                    </FormGroup>
                                </Fragment>;

    


    return (
        
        <Modal isOpen={true} size="xl" className="no-border modal-xl" centered>
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal">{p_cname}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form className="my-4">
                    <FormGroup row>
                        {/* ::: COMPANY NAME */}
                        <Col md={6}>
                            <Input type="text" name="p_cname" value={p_cname} onChange={e => comp.set(e.target.name, e.target.value)} className="required" placeholder={t('com_nam')} />
                        </Col>
                        {/* ::: COMPANY EMAIL */}
                        <Col md={6}>
                            <Input type="email" name="p_cemail" value={p_cemail} onChange={e => comp.set(e.target.name, e.target.value)} placeholder={t('com_ema')} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        {/* ::: COMPANY NUMBER 01 */}
                        <Col md={6}>
                            <Input type="text" name="p_cpnumber_1" value={p_cpnumber_1} onChange={e => comp.set(e.target.name, e.target.value)} className="required" placeholder={t('com_num_1')} />
                        </Col>
                        {/* ::: COMPANY NUMBER 02 */}
                        <Col md={6}>
                            <Input type="text" name="p_cpnumber_2" value={p_cpnumber_2} onChange={e => comp.set(e.target.name, e.target.value)} placeholder={t('com_num_2')} />
                        </Col>
                    </FormGroup>
                    {/* ::: COMPANY PASSWORD */}
                    <FormGroup>
                        <Input type="password" name="p_cpsw" value={p_cpsw} onChange={e => comp.set(e.target.name, e.target.value)} placeholder={t('password')} className="required" />
                    </FormGroup>
                    {/* ::: COMPANY PASSWORD CONFIRMATION */}
                    <FormGroup>
                        <Input type="password" name="p_cpsw2" value={p_cpsw2} onChange={e => comp.set(e.target.name, e.target.value)} placeholder={t('password')}/>
                    </FormGroup>
                     {/* ::: COMPANY PUBLIC SERVICE CHECK */}
                     <FormGroup>
                        <CustomInput type="checkbox" id="isPublicService" name="p_is_ps" label={t('is_pub_ser')} checked={comp.p_is_ps}   onChange={e => comp.set(e.target.name, !comp.p_is_ps)}></CustomInput>
                    </FormGroup>
                    {/* ::: SUBCOMPANY SETTINGS */}
                    {!!isAPortCompany && _subCompanySettings}
                </Form>
            </ModalBody>
            {/* ::: FORM BUTTONS */}
            <ModalFooter>
            <div className="w-100 d-flex align-items-center justify-content-between">
                <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                <Button name='submit' color="success" onClick={e => onSave(e)} className="mx-3"> {t('submit')} </Button>
            </div>
            </ModalFooter>
  </Modal>
        
       
    )
}

ServicesEditModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesEditModal)
