import { capitalize, isEmptyValue, isNotEmptyValue, isOnlyNum } from "@utils/utils";
import VisitExtra from "./VisitExtra";

class Material extends VisitExtra { 

    constructor(cod_user) { 
        super();
        this.p_ismtrl = 1;
        this.p_cod_user = cod_user;
        this.p_qnt = '';
    }

    formalize() { 
        this.p_oname = capitalize(this.p_oname);
        this.p_qnt = this.fixInputMaterialsQtd(this.p_qnt);
    }

    changeName (name) { 
        super.changeName(name);
        if(isNotEmptyValue(this.p_oname) && isEmptyValue(this.p_qnt))
            this.p_qnt = 1;
    }

    fixInputMaterialsQtd (inputQtd)  { 
        let fixedQuantity = inputQtd;
        if(isEmptyValue(inputQtd))
            fixedQuantity = '';
        else if(!isOnlyNum(fixedQuantity))
            fixedQuantity = 1;
    
        return fixedQuantity;
    }

    /**
     * Gets all the object properties, modifying the p_ismtrl bool
     */
    getProperties () { 
        return  {...super.getProperties(), p_ismtrl: this.p_ismtrl}
    }



}


export default Material;