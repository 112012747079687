import React, { useState } from 'react'
import { Modal, ModalBody, Form, Button, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import VisitDataPage1 from '../Shared/VisitDataPage1';
import VisitDataPage2 from '../Shared/VisitDataPage2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { close_modal } from '@actions/admin';
import { fetchLoggedUserVisits, performVisitorVisitSubmissionProcess, performVisitUpdateProcess } from '@actions/visits';
import { isEmptyObject, isHostUser, isVisitToUpdate } from '@utils/utils';
import { CREATE_REQUISITION_HOST_MODAL, CREATE_REQUISITION_X_MODAL } from '@utils/constants';


const VisitModal = ({loggedUser,  visit, modal_data,performVisitUpdateProcess, fetchLoggedUserVisits, close_modal, performVisitorVisitSubmissionProcess}) => {
    const { t } = useTranslation('request');
    const [state, setState] = useState({
        page: 0,
        num_page: 1,
        disSub: false
    });

    const {page, num_page, disSub} = state;
    const visitToPropagate = isEmptyObject(visit)? modal_data : visit;

    // ::: NAVIGATION
    const next = e => { setState({...state, page: page >= num_page? num_page : page + 1});}
    const prev = e => { setState({...state, page: page <= 0? 0:page - 1});}

    // ::: EVENT HANDLERS
    const onSubmit = async e => { 
        setState({...state, disSub: true});
        let isSuccessful = false;
        if(isVisitToUpdate(visitToPropagate))
            isSuccessful = await performVisitUpdateProcess(visitToPropagate, loggedUser);
        else 
            isSuccessful = await performVisitorVisitSubmissionProcess(visitToPropagate);

        setState({...state, disSub: false});
        if (isSuccessful) 
            onClose();
    
    }

    const onClose = e => {
        const modal_id = isHostUser(loggedUser)? CREATE_REQUISITION_HOST_MODAL : CREATE_REQUISITION_X_MODAL;
        close_modal(modal_id);
    fetchLoggedUserVisits(loggedUser);
    }

    // ::: HTML CONDITIONALS
    const submitButtonColor = page >= num_page? 'success':'primary';
    const submitButtonString = page >= num_page? t('submit'):t('next');
    const isPrevButtonDisabled = page <= 0;
    return (
        <Modal isOpen={true} className="no-border" centered size="lg">
            {/* ::: MODAL TITLE */}
            <h3 className="title-of-modal"> {t("cre_req")} </h3>
            <ModalBody className="modal-body paged-form-container">
                {/* ::: FORM PAGES */}
                <Form className="modal-paged-form">
                    {page === 0 && <VisitDataPage1 visit={visitToPropagate}/>}
                    {page === 1 && <VisitDataPage2 visit={visitToPropagate}/>}
                </Form>
                {/* ::: PAGE INDICATORS */}
                <div className="page-indicators-container blue-version">
                    <span className={`page-indicator ${page === 0 ? "active" : ""}`}></span>
                    <span className={`page-indicator ${page === 1 ? "active" : ""}`}></span>
                </div>
            </ModalBody>
            <ModalFooter>
                {/* ::: PAGE BUTTONS */}
                <div className="modal-footer-button-container">
                    <Button className="left-sided-button" onClick={e => onClose()}>{t('cancel')}</Button>
                    <Button className="right-sided-button" disabled={disSub} color={submitButtonColor}  onClick={e => page >= num_page? onSubmit(e):next(e)}>{submitButtonString}</Button>
                    <Button className="right-sided-button"  color="primary" disabled={isPrevButtonDisabled} onClick={e => prev(e)}>{t('previous')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

VisitModal.propTypes = {
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    close_modal: PropTypes.func.isRequired,
    loggedUser: PropTypes.object,
    visit: PropTypes.object.isRequired,
    performVisitorVisitSubmissionProcess: PropTypes.func.isRequired,
    performVisitUpdateProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loggedUser: state.auth.user,
    visit: state.visits.visitOnModal,
    modal_data: state.admin.modal_data
})


export default connect(mapStateToProps, {fetchLoggedUserVisits, close_modal,performVisitUpdateProcess, performVisitorVisitSubmissionProcess})(VisitModal);
