import { CHANGE_FLAG, OPEN_MODAL, CLOSE_MODAL, CHANGE_PAGE, SET_REPORT_1, SET_REPORT_2, SET_REPORT_3, CLEAR_REPORT_DATA, SET_INDUCTION_ANSWER, CLEAR_INDUCTION_QUESTION, GET_INDCUTION_QUESTION,  CLEAR_REQ_INDUCTION_ANSWER, ADD_KNOWMOREMESSAGE, CLEAR_KNOWMOREMESSAGE, SET_MAIN_PANEL_STATS, SET_VISITORS, SET_HOSTS, SET_SERVICE_PROVIDERS, SET_SERVICE_PROVIDERS_WORKERS, SET_LANGUAGE, SET_VISIT_ON_MODAL } from "./types"
import axios from 'axios';
import { ROOT_URL, ERROR_TOAST, VISITORS_LOCALSTORAGE_KEY, DEFAULT_COMBOBOX_CODE, HOSTS_LOCALSTORAGE_KEY, SERVICE_PROVIDER_LOCALSTORAGE_KEY, SERVICE_PROVIDER_WORKER_LOCALTORAGE_KEY, CREATE_REQUISITION_HOST_MODAL, CREATE_SERV_REQUEST_MODAL, CREATE_FAST_REQUEST_MODAL, CREATE_REQUISITION_SERVICE_PROVIDER_MODAL, CREATE_REQUISITION_X_MODAL } from "../utils/constants";
import { getErrorMessage, createLoader, parseInductionAnswers, parseNewInductionQuestions, sendRequestDataWithDefaultConfig, checkApiResponseForErrors, isThreadUser, isCoreSecurityUser, saveObjectToLocalStorage, getToday, isEmptyValue, getObjectFromLocalStorage, validateArrayParameter, isCAMemberUser } from "../utils/utils";
import { buildAndStartLoader, setMessage, toastErrorMessage, toastSuccessMessage } from "./toast";
import Company from "@model/Settings/Company";
import { getServerConfig, updateAppConfigurations } from "@utils/configurations";

const randomstring = require('randomstring');

export const update = () => dispatch => { 
    dispatch({
      type: CHANGE_FLAG,
      payload: false
    })
  }

  export const uploadTemplateFile = (reqData, visitorOrEmployee, isCoreSec) => async dispatch => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      
      const body = reqData;
      let res = await axios.post(
        ROOT_URL + "/files/uploadFile",
        body,
        config
      );
        
      return res.data;
    } catch (err) {
      const errors = getErrorMessage(err);
      errors.forEach(err => {
        dispatch(setMessage(err, ERROR_TOAST));
      });
      return false;
    }
  };

  
export const open_modal = (modal_id, data) => dispatch => {
  dispatch({
    type: OPEN_MODAL,
    payload: {modal_id, data}
  })

}

export const close_modal = (modal_id) => dispatch => {
  const requestModals = [CREATE_REQUISITION_HOST_MODAL, CREATE_SERV_REQUEST_MODAL, CREATE_FAST_REQUEST_MODAL, CREATE_REQUISITION_SERVICE_PROVIDER_MODAL, CREATE_REQUISITION_X_MODAL]
  if(requestModals.includes(modal_id))
    dispatch(setStoreData(SET_VISIT_ON_MODAL, {}));
  dispatch({
    type: CLOSE_MODAL,
    payload: ''
  })
}


export const get_vis_by_entity = (idEntity) => async dispatch => { 
  try {
    const body = JSON.stringify({ p_cod_entity: idEntity });
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getVisByEntity`,body);
    checkApiResponseForErrors(res);
    return res;
  } catch (err) {
    const errors = getErrorMessage(err);
    errors.forEach(err => {
      dispatch(setMessage(err, ERROR_TOAST));
    });
  }
}

export const changeContentPage = (page) => dispatch => { 
  dispatch({
    type: CHANGE_PAGE,
    payload: page,
  })
}

export const setAppLanguage = (language) => dispatch => { 
  dispatch(setStoreData(SET_LANGUAGE, language));
}

export const writeServerConfig = (body) => async dispatch => {
  const loader = dispatch(buildAndStartLoader());
  let isSuccessful = true;
  try {
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/setConfig`, body)
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage('App Configurations Updated!'));   
    updateAppConfigurations(body);
  } catch (err) {
    isSuccessful = false;
    dispatch(toastErrorMessage(err.message));
  }
  loader.stop();
  return isSuccessful;
}

export const writeSupportEmail = (body) => async dispatch => {
  try {
    const res = sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/chSupEm`, body);
    checkApiResponseForErrors(res);
    return [true, 'success']

  } catch (err) {
    dispatch(toastErrorMessage(err.message));
  }
}

export const get_report = (rep_data) => async dispatch => {
  let isSuccessful = true;                             
  try {
    const body = JSON.stringify(rep_data);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getReport`, body);
    checkApiResponseForErrors(res);
    const {report_type} = rep_data;
    if(report_type === 1){
      dispatch({
        type: SET_REPORT_1,
        payload: res
      })
    } else if (report_type === 2){
      dispatch({
        type: SET_REPORT_2,
        payload: res
      })
    } else if (report_type === 3){
      dispatch({
        type: SET_REPORT_3,
        payload: res
      })
    }

  } catch (err) {
    dispatch(toastErrorMessage(err.message));
    isSuccessful = false;
  }
  return isSuccessful;
}

export const clear_report = () => dispatch => {
  dispatch({
    type: CLEAR_REPORT_DATA,
    payload: null
  })
}


export const send_induction_request= (inductionData) => async dispatch => {
  let isSuccessful = true;
  const loader = createLoader(dispatch);
  try {
    const body = JSON.stringify(inductionData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/reqInduction`, body);
    checkApiResponseForErrors(res);
    loader.stopLoading();
    dispatch(toastSuccessMessage("Induction Request Sent"));
  } catch (err) {
    dispatch(toastErrorMessage(err.message));
    isSuccessful = false;
  }

  return isSuccessful;
}

export const send_induction_request_all = (inductionData) => async dispatch => {
  let isSuccessful = true;
  const loader = createLoader(dispatch);
  try {
    const body = JSON.stringify(inductionData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/reqInductionAll`, body);
    checkApiResponseForErrors(res);
    loader.stopLoading();
    dispatch(toastSuccessMessage("Induction Request Sent"));
  } catch (err) {
    loader.stopLoading();
    const errors = getErrorMessage(err);
    errors.forEach(err => {
      dispatch(setMessage(err, ERROR_TOAST));
    });
    isSuccessful = false;
  }
  return isSuccessful;
}

export const set_induction_answer = (identfier, answer) => dispatch => {
  dispatch({
    type: SET_INDUCTION_ANSWER,
    payload: {[identfier]: answer}
  })
}


export const clear_induction_questions = () => dispatch => {
  dispatch({
    type: CLEAR_INDUCTION_QUESTION,
    payload: null,
  })
}

export const get_induction_questions = (inductionData) => async dispatch => {
  let isSuccessful = true;
  try {
    const body = JSON.stringify(inductionData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getInductionQuest`, body);
    checkApiResponseForErrors(res);

    const inductionAnswer = parseInductionAnswers([...res]);
    const formalizedInductionQuestions = parseNewInductionQuestions(res);

    dispatch({
      type: GET_INDCUTION_QUESTION,
      payload: formalizedInductionQuestions
    });

    dispatch({
      type: SET_INDUCTION_ANSWER,
      payload: inductionAnswer
    })

  } catch (err) {
    dispatch(toastErrorMessage(err.message));
    isSuccessful = false;
  }
  return isSuccessful;
}


export const submit_induction_result = (inductionData) => async dispatch => {
  let isSuccessful = true;
  const loader = createLoader(dispatch);
  try {
    const body = JSON.stringify(inductionData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/subInduction`, body);
    checkApiResponseForErrors(res);   
   } catch (err) {
      dispatch(toastErrorMessage(err.message));
      isSuccessful = false;
  }
  loader.stopLoading();
  return isSuccessful;
}

export const clear_user_induction_answer = () => dispatch => {
  dispatch({
    type: CLEAR_REQ_INDUCTION_ANSWER,
    payload: null
  })
}


export const send_support_email = (supportData) => async dispatch => {
  let isSuccessful = true;
  const loader = createLoader(dispatch);
  try {
    const body = JSON.stringify(supportData);
    const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/contactUs`, body);
    checkApiResponseForErrors(res);
    dispatch(toastSuccessMessage("Email Sent!"));
  } catch (err) {
    dispatch(toastErrorMessage(err.message));
    isSuccessful = false;
  }
  loader.stopLoading();
  return isSuccessful;
}


export const addKnowMoreMessage = (title, messagesArray) => async dispatch => { 
  dispatch({
    type: ADD_KNOWMOREMESSAGE,
    payload: {messages: messagesArray, title}
  });

  setTimeout(() => { 
    dispatch({
      type: CLEAR_KNOWMOREMESSAGE,
      payload: null
    })
  }, 5000)
}


//=============================== REFACTORING METHODS CLOSE =========================//

export const fetchWorkersOfServiceProvider = (serviceProviderId) => async dispatch => { 
  const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getVisByEntity`, { p_cod_entity: serviceProviderId });
  checkApiResponseForErrors(response);
  return response;
}

export const fetchMainPanelStatsOfAccount = (loggedUser) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    if(isThreadUser(loggedUser)) 
      await dispatch(fetchStatsOfTerminal(loggedUser));
    else if(isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser))
      await dispatch(fetchStatsOfAllTerminals());
    else
      throw Error("Could not identify user. Cannot get stats!")
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

export const fetchStatsOfAllTerminals = () => async dispatch => { 
  const requestBody = { prod: true}
  const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getStats`, requestBody);
  checkApiResponseForErrors(response);
  dispatch(setStoreData(SET_MAIN_PANEL_STATS, response));
}

export const fetchStatsOfTerminal = (terminalObject) => async dispatch => { 
  const requestBody = {p_id_thread: terminalObject.p_id_company, prod: true}
  const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getStatsTerm`, requestBody);
  checkApiResponseForErrors(response);
  dispatch(setStoreData(SET_MAIN_PANEL_STATS, response));
}

export const fetchStatsOfSelectedTerminal = (p_id_terminal) => async dispatch => {
  const loader = dispatch(buildAndStartLoader()); 
  try {
    if(p_id_terminal === DEFAULT_COMBOBOX_CODE)
      await dispatch(fetchStatsOfAllTerminals());
    else
      await dispatch(fetchStatsOfTerminal({p_id_company: p_id_terminal}))
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

export const setStoreData = (type, payload) => dispatch => { 
  dispatch({
    type,
    payload
  });
}

export const fetchAllHosts = () => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const hosts = await fetchAllUsersByType(0);
    addSettingsPropertiestoHosts(hosts);
    dispatch(setStoreData(SET_HOSTS, hosts));
    saveObjectToLocalStorage(HOSTS_LOCALSTORAGE_KEY, hosts);
    dispatch(toastSuccessMessage());
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

const addSettingsPropertiestoHosts = hosts => { 
  hosts.forEach(host => {
    host.name = `${host.p_fname} ${host.p_lname}`;
    host.active = host.p_active;
    host.p_psw_2 = host.p_psw;
  });
}

export const fetchAllVisitors = () => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const visitors = await fetchAllUsersByType(1);
    dispatch(setStoreData(SET_VISITORS, visitors));
    saveObjectToLocalStorage(VISITORS_LOCALSTORAGE_KEY, visitors);
    dispatch(toastSuccessMessage());
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

const fetchAllUsersByType = async (isVisitor) => { 
  const requestBody = {p_isvisitor: isVisitor, prod: true};
  const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getAllUsers`, requestBody);
  checkApiResponseForErrors(response);
  return response;
}

export const fetchAllWorkersOfServiceProvider = (loggedUser) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
    try {
      const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getVisByEntity`, { p_cod_entity: loggedUser.p_id_entity });
      checkApiResponseForErrors(response);
      dispatch(setStoreData(SET_SERVICE_PROVIDERS_WORKERS, response));
      saveObjectToLocalStorage(SERVICE_PROVIDER_WORKER_LOCALTORAGE_KEY, response);
      dispatch(toastSuccessMessage());
    } catch (error) {
      dispatch(toastErrorMessage(error.message));
    }
  loader.stop();
}

export const blockVisitor = (visitor, loggedUser) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const requestBody = buildBlockUserRequestBody(visitor, loggedUser);
    const link = getLinkToBlockVisitor(visitor);
    const response = await sendRequestDataWithDefaultConfig(link, requestBody);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }

  loader.stop();
  return false;
}

const getLinkToBlockVisitor = visitor => { 
  let link = `${ROOT_URL}/admin/editBlocked`;
  if(isEmptyValue(visitor.p_isblocked))
    link = `${ROOT_URL}/admin/addBlocked`;
  return link;
}

const buildBlockUserRequestBody = (visitor, loggedUser) => { 
  const requestBody = {};
  requestBody.p_id_user = visitor.p_id;
  requestBody.p_when = getToday().toISOString();
  requestBody.p_id_who = loggedUser.p_id;
  requestBody.p_active = 1;
  requestBody.p_why = visitor.p_why;

  if(isEmptyValue(visitor.p_why))
    throw new Error("Reason for blocking in required!");

  if(isEmptyValue(visitor.p_isblocked)) 
    requestBody.p_isblocked = 1;
  else 
    requestBody.p_isblocked = visitor.p_isblocked? 0:1;

  return requestBody;
}

export const filterVisitors = (filterKey, filterValue) => dispatch => { 
  dispatch(filterStoreArray({filterKey, filterValue}, VISITORS_LOCALSTORAGE_KEY, SET_VISITORS));
}

export const filterHosts = (filterKey, filterValue) => dispatch => { 
  dispatch(filterStoreArray({filterKey, filterValue}, HOSTS_LOCALSTORAGE_KEY, SET_HOSTS));
}

export const filterServiceProvider = (filterKey, filterValue) => dispatch => { 
  dispatch(filterStoreArray({filterKey, filterValue}, SERVICE_PROVIDER_LOCALSTORAGE_KEY, SET_SERVICE_PROVIDERS));
}

export const filterServiceProviderWorkers = (filterKey, filterValue) => dispatch => { 
  dispatch(filterStoreArray({filterKey, filterValue}, SERVICE_PROVIDER_WORKER_LOCALTORAGE_KEY, SET_SERVICE_PROVIDERS_WORKERS));

}

const filterStoreArray = (filterElements, localStorageKey, storeType) => dispatch => { 
  try {
    const {filterKey, filterValue} = filterElements;
    const storedElements = getObjectFromLocalStorage(localStorageKey);
    const filteredElements = storedElements.filter( visitor => { 
      if(isEmptyValue(filterValue) || isEmptyValue(visitor[filterKey]))
        return true;
      return visitor[filterKey].toLowerCase().startsWith(filterValue.toLowerCase());
    });
    dispatch(setStoreData(storeType, filteredElements));
  } catch (error) {
      dispatch(toastErrorMessage("Element not found!"));
      return [];
  }

}


export const fetchServiceProviderCompanies = () => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {

    const requestBody = {p_isport: 0, prod: true};
    let publicServices = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getAllCompanies`, requestBody);
    checkApiResponseForErrors(publicServices);
    publicServices = publicServices.map(publicService => new Company(publicService));
    dispatch(setStoreData(SET_SERVICE_PROVIDERS, publicServices));
    dispatch(toastSuccessMessage());
    
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}

export const getServerSavedConfigurations = () => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const response = await getServerConfig();
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage());;
    dispatch(setStoreData(CHANGE_FLAG));
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  loader.stop();
}


export const addServiceProviderWorkerToVisitToggle = (visit, worker) => dispatch =>  {
  try {
    visit.id_users = validateArrayParameter(visit.id_users);
    manageAddWorkerVisitToggle(visit, worker);
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }
  
}

const addWorkerToVisit = (visit, visitor) => { 
  visit.id_users.push(visitor.p_id);
  visit.p_users.push(visitor);
}

const removeWorkerFromVisit = (visit, visitor) => { 
  if(visit.id_users.length === 1)
    throw new Error('Less then one visitor!');
  visit.id_users = visit.id_users.filter(uid => uid !== visitor.p_id);
  visit.p_users = visit.p_users.filter(user => user.p_id !== visitor.p_id);
}

const manageAddWorkerVisitToggle = (visit, visitor) => { 
  if(visit.id_users.includes(visitor.p_id))
    removeWorkerFromVisit(visit, visitor);
  else 
    addWorkerToVisit(visit, visitor);
}

export const forceInductionResultToVisitor = (visit, verdict, inducitonType ) => async dispatch => { 
  const loader = dispatch(buildAndStartLoader());
  try {
    const requestBody = buildForceInductionResultRequestBody(visit, verdict, inducitonType);
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/forcIndRes`, requestBody);
    checkApiResponseForErrors(response);
    visit.is_induction = requestBody.p_deep_induction;
    visit.dtm_deep_induction = requestBody.p_dtm_deep_induction;
    loader.stop();
    return true;
  } catch (error) {
    dispatch(toastErrorMessage(error.message));
  }

  loader.stop();
  return false;
}

const buildForceInductionResultRequestBody = (visit, verdict, inductionType)  => {
  const newTokken = randomstring.generate();
  const requestBody = {
     p_id_visitor: visit.p_id_x,
     p_tokken: newTokken,
     p_deep_induction: verdict,
     p_dtm_deep_induction: getToday().toISOString(),
     p_id_tip_induc: inductionType,
     p_svisit: visit.p_id
    }
  return requestBody;
}

export const sendCompanyRegistrationLink = (loggedUser, companyEmail) => async dispatch => {
  let isSuccessful = true; 
  const loader = dispatch(buildAndStartLoader());
  try {
    const requestBody = {p_email: companyEmail, p_cod_core: loggedUser.p_id};
    const requestUrl = `${ROOT_URL}/admin/compRegLink`;
    const response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage('Registration Link Sent!'));
  } catch (err) {
    dispatch(toastErrorMessage(err.message));
    isSuccessful = false;
  }
  loader.stop();
  return isSuccessful;
}

export const send_recover_password = (p_contact) => async dispatch => {
  const loader = dispatch(buildAndStartLoader());
  let isSuccessful = false;

  try {
    const requestBody = {p_contact};
    const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/recPass`, requestBody);
    checkApiResponseForErrors(response);
    dispatch(toastSuccessMessage('An email or text message was sent to the contact.'));
    isSuccessful = true;

  } catch (err) {
    dispatch(toastErrorMessage(err.message));
  }

  loader.stop();
  return isSuccessful;
}

export const generateNewCompany = (userId) => { 
  const company = new Company();
  company.createNewCompany(userId);
  return company;
}
