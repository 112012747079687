import { update } from "@actions/admin";
import { setMessage, toastErrorMessage } from "@actions/toast";
import AlertMsg from "@model/Settings/AlertMsg";
import Company from "@model/Settings/Company";
import InductionQuestion from "@model/Settings/InductionQuestion";
import { DEFAULT_COMBOBOX_CODE, DEPARTMENT_TYPE, ERROR_TOAST, GATE_TYPE, ID_TYPE, MRE_TYPE, MRP_TYPE, MRS_TYPE, PROVENANCE_TYPE, ROOT_URL, RPP_TYPE, TIN_TYPE } from "@utils/constants";
import { checkApiResponseForErrors, checkResponseError, createStoreLoader, doesArrayExist, getErrorMessage, getRequestConfigurations, getToday, isNotEmptyValue, sendRequestDataWithDefaultConfig } from "@utils/utils";
import Axios from "axios";
import store from "src/store";
import Extra from "./Extra";


class AppFunctionalityUsable { 

    static provenances = [{code: DEFAULT_COMBOBOX_CODE, name:"Sem Proveniência"}];
    static idDocumentTypes = [{code: DEFAULT_COMBOBOX_CODE, name:"Tipo de Identificação"}];
    static departments = [{ code: DEFAULT_COMBOBOX_CODE, name: "Departamentos" }];
    static terminals = [{code: DEFAULT_COMBOBOX_CODE, name: "Terminais"}];
    static companies = [{code: DEFAULT_COMBOBOX_CODE, name: "Empresas"}]
    static reasonsOfVisit = [{code: DEFAULT_COMBOBOX_CODE, name: "Razões da Visita"}];
    static inductionTypes = [{code: DEFAULT_COMBOBOX_CODE, name: 'Tipo Indução'}];
    static gates = [{ code: DEFAULT_COMBOBOX_CODE, name: "Portão" }];
    static exitRejectionReasons = [{ code: DEFAULT_COMBOBOX_CODE, name: "Motivos de Recusar Saida" }];
    static entranceRejectionReasons = [{ code: DEFAULT_COMBOBOX_CODE, name: "Motivos de Recusar Entrada" }];
    static visitRejectionReasons = [{ code: DEFAULT_COMBOBOX_CODE, name: "Motivos de Recusar Visita" }];
    static reports = [];
    static subTerminalNameTerminalIDMap = {};

    static alertMessages = [];
    static editableTerminals = [];
    static nextQuestionId = -1;

    
    //::>> Refactor this later
    static ind_type_map = {};
    static ind_type_trans = {};
    static ind_quest = [];
    static ind_trans_map = {};
    static ind_type_trans_res = {};

    //::>> Flags
    static hasGottenAllExtras = false;
    static hasGottenAllTerminals = false;



    static update() { 
        store.dispatch(update());
    }


    static showInfoMessages() { 
        // setTimeout(() => {store.dispatch(addKnowMoreMessage('Simple title', ["MEssage 1", "MEssage 2"]));}, 1000)
    }

    /**
     * Gets the app usable (Extras) sending a request with the usabe type
     * @param {string} extra_type 
     * @param {number} active 
     */
    static async getUsable (extra_type, active) {
        let usable = [];
        try {
            let requestBody = { p_cod_etype: extra_type, prod: true }
            if (isNotEmptyValue(active))
                requestBody = {...requestBody, p_active: active, prod: true};
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/extras/getExt`, requestBody);
            checkApiResponseForErrors(response);

            usable = response.map(extra => new Extra(extra));
		} catch (err) {
            store.dispatch(toastErrorMessage('Failed to get configurations.'));
            store.dispatch(toastErrorMessage(err.message));
            usable = [{code: -1, name: '-'}];
		}

        return usable;
    }

    /**
     * Gets all or only active registered provenance of the users
     * @param {bool} isToGetAll 
     */
    static async getVisitorProvenance (isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.provenances.length > 1)) return;
        let provenances = await this.getUsable(PROVENANCE_TYPE, 1);
        if (isToGetAll) provenances = [...provenances, ...await this.getUsable(PROVENANCE_TYPE, 0)];
        AppFunctionalityUsable.provenances = [...AppFunctionalityUsable.provenances, ...provenances];
        AppFunctionalityUsable.update();
    }

    /**
     * Gets all or only active document Id Types
     * @param {bool} all 
     */
    static async getIdDocumentTypes(isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.idDocumentTypes.length > 1)) return;
        let idDocumentTypes = await this.getUsable(ID_TYPE, 1);
        if (isToGetAll) idDocumentTypes = [...idDocumentTypes, ...await this.getUsable(ID_TYPE, 0)];
        AppFunctionalityUsable.idDocumentTypes = idDocumentTypes;
        AppFunctionalityUsable.update();
    }

    /**
     * Gets all or only active departments 
     * @param {bool} all 
     */
    static async getDepartments(isToGetAll) {
        if (!isToGetAll && (AppFunctionalityUsable.departments.length > 1)) return;
        let departments = await this.getUsable(DEPARTMENT_TYPE, 1);
        departments.sort((a, b) => a.name > b.name ? 1 : -1)
        if (isToGetAll) departments = [...departments, ...await this.getUsable(DEPARTMENT_TYPE, 0)];
        AppFunctionalityUsable.departments = [AppFunctionalityUsable.departments[0], ...departments];
        AppFunctionalityUsable.update();
    }


    /**
     * Gets all of only active terminals.
     * @param {number} isActive 
     * @param {boolean} joinWithPrevious 
     */
	static async getTerminals(isActive, joinWithPrevious) {
        try {
            if(this.hasGottenAllTerminals) 
                return;

            const requestBody = JSON.stringify({ p_active: isActive, p_isport: 1, prod: true });
            let response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getCompanies`, requestBody);
            checkApiResponseForErrors(response);
            const subCompToCompIndx = response.length - 1;

            AppFunctionalityUsable.subTerminalNameTerminalIDMap = response[subCompToCompIndx];
            response = response.filter((_, index) => index !== (subCompToCompIndx))
            response.sort((a, b) => a.name > b.name ? 1 : -1)
            if (joinWithPrevious) { 
                AppFunctionalityUsable.terminals = [...AppFunctionalityUsable.terminals, ...response];
                
            } else { 
                AppFunctionalityUsable.terminals =  [AppFunctionalityUsable.terminals[0], ...response];    
            }
            
            AppFunctionalityUsable.update();
            this.hasGottenAllTerminals = true;
        } catch (err) {
            store.dispatch(toastErrorMessage(err.message));
        }
    }

    
    /**
     * Gets all or active reasons of visit
     * @param {*} isToGetAll 
     */
    static async getReasonOfVisit(isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.reasonsOfVisit.length > 1)) return;
        let reasonsOfVisit = await this.getUsable(RPP_TYPE, 1);
        if (isToGetAll) reasonsOfVisit = [...reasonsOfVisit, ...await this.getUsable(RPP_TYPE, 0)];
        AppFunctionalityUsable.reasonsOfVisit = [AppFunctionalityUsable.reasonsOfVisit[0], ...reasonsOfVisit];
        AppFunctionalityUsable.update();
    }

    /**
     * Gets All active External Companies
     */
    static async getCompanies() { 
        try {
            const requestBody = JSON.stringify({p_isport: 0, p_active: 1, prod: true});
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getCompanies`, requestBody);
            checkApiResponseForErrors(response);
            AppFunctionalityUsable.companies = [AppFunctionalityUsable.companies[0], ...response];
          } catch (err) {
            store.dispatch(toastErrorMessage(err.message));
          }
    }

    /**
     * Gets all or only active induction types
     * @param {bool} isToGetAll 
     */
    static async getInductionTypes(isToGetAll) {
        let inductionTypes = await this.getUsable(TIN_TYPE, 1);
        if (isToGetAll) inductionTypes = [...inductionTypes, ...await this.getUsable(TIN_TYPE, 0)];
        AppFunctionalityUsable.inductionTypes = [AppFunctionalityUsable.inductionTypes[0], ...inductionTypes]
        
        let newExtra = [];
        AppFunctionalityUsable.inductionTypes.forEach(inductionType => { 
            if(inductionType.name.includes('_EN')){
                AppFunctionalityUsable.ind_type_trans = {...AppFunctionalityUsable.ind_type_trans, [inductionType.name]: inductionType.code};
            } else { 
                newExtra = [...newExtra, inductionType];
                AppFunctionalityUsable.ind_type_trans = {...AppFunctionalityUsable.ind_type_trans, [inductionType.code]: inductionType.name};
            }

            AppFunctionalityUsable.ind_type_trans_res = {...AppFunctionalityUsable.ind_type_trans_res, [inductionType.code]: inductionType.name};
            AppFunctionalityUsable.ind_type_map = {...AppFunctionalityUsable.ind_type_map, [inductionType.code]: inductionType.name}
        })
        
        AppFunctionalityUsable.inductionTypes = newExtra
        AppFunctionalityUsable.update();
    }

    /**
     * Gets All or only active agates
     * @param {bool} isToGetAll 
     */
	static async getGates(isToGetAll) {
        if (!isToGetAll && (AppFunctionalityUsable.gates.length > 1)) return;
        let gates = await this.getUsable(GATE_TYPE, 1);
        if (isToGetAll) gates = [...gates, ...await this.getUsable(GATE_TYPE, 0)]
        AppFunctionalityUsable.gates = [AppFunctionalityUsable.gates[0], ...gates];
    }
    
    /**
     * Gets only active reasons to reject exit
     * @param {bool} isToGetAll 
     */
    static async getExitRejectionReasons(isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.exitRejectionReasons.length > 1)) return;
        let activeRejectionReasons = await AppFunctionalityUsable.getUsable(MRS_TYPE, 1);
        if (isToGetAll) activeRejectionReasons = [...activeRejectionReasons, ...await this.getUsable(MRS_TYPE, 1)]
        AppFunctionalityUsable.exitRejectionReasons = [AppFunctionalityUsable.exitRejectionReasons, ...activeRejectionReasons];
        store.dispatch(update());
    }

    /**
     * Gets only active entrance rejection reasons
     * @param {bool} isToGetAll 
     */
    static async getEntranceRejectionReasons(isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.entranceRejectionReasons.length > 1)) return;
        let activeRejectionReasons = await AppFunctionalityUsable.getUsable(MRE_TYPE, 1);
        if (isToGetAll) activeRejectionReasons = [...activeRejectionReasons, ...await this.getUsable(MRE_TYPE, 1)]
        AppFunctionalityUsable.entranceRejectionReasons = [...AppFunctionalityUsable.entranceRejectionReasons, ...activeRejectionReasons];
    }
    
    /**
     * Gets only active visit rejection reasons
     * @param {bool} isToGetAll 
     */
    static async getVisitRejectionReasons(isToGetAll) { 
        if (!isToGetAll && (AppFunctionalityUsable.visitRejectionReasons.length > 1)) return;
        let activeRejectionReasons = await AppFunctionalityUsable.getUsable(MRP_TYPE, 1);
        if (isToGetAll) activeRejectionReasons = [...activeRejectionReasons, ...await this.getUsable(MRP_TYPE, 0)]
        AppFunctionalityUsable.visitRejectionReasons = [...AppFunctionalityUsable.visitRejectionReasons, ...activeRejectionReasons];
    }

    /**
     * Gets all active alert messages.
     */
    static async getAlertMessages() { 
        const loader = createStoreLoader(store);
        try {
            const requestBody = JSON.stringify({p_cur_date: getToday().toISOString(), prod: true});
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getAlertMsg`, requestBody);
            checkApiResponseForErrors(response);

            AppFunctionalityUsable.alertMessages = response.map(alrt => new AlertMsg(alrt));
            store.dispatch(update());
          } catch (err) {
            store.dispatch(toastErrorMessage(err.message));
          }

        loader.stopLoading();
    }

    /**
     * Gets all Induction Questions
     * @param {bool} isToGetAll 
     * @param {number} type 
     */
    static async getInductionQuestions (isToGetAll, type) { 
        const loader = createStoreLoader(store);
        try {
            const requestBody = JSON.stringify({prod: true, p_id_type_induction: type});
            const requestUrl = isToGetAll? `${ROOT_URL}/admin/getInductionQuest` : `${ROOT_URL}/admin/getInduction`;
            const response = await sendRequestDataWithDefaultConfig(requestUrl, requestBody);
            checkApiResponseForErrors(response);

            let answerToQuestionCodeMap = {};
            let mappedAnswer;
            AppFunctionalityUsable.ind_quest = response.map(inductionQuestion => { 
                mappedAnswer = answerToQuestionCodeMap[inductionQuestion.p_answer];
                answerToQuestionCodeMap[inductionQuestion.p_answer] = mappedAnswer? [...mappedAnswer, inductionQuestion.p_cod] : [inductionQuestion.p_cod]
                return new InductionQuestion(inductionQuestion)
            });

            AppFunctionalityUsable.ind_trans_map = answerToQuestionCodeMap;
            store.dispatch(update());
        } catch (err) {
            store.dispatch(toastErrorMessage(err.message));
        }
        loader.stopLoading();
    }

    /**
     * Gets the induction questions of the type to answer
     * @param {int} ind_type 
     * @param {int} ind_type
     */
    static  getInductionQuestionsToAnswer (ind_type) { 
        if(doesArrayExist(AppFunctionalityUsable.ind_quest)) {
            const toAnswer = AppFunctionalityUsable.ind_quest.filter( qst => {
                if(qst.isOfInductionExtra(ind_type)){ 
                    return true;
                }
                return false;
            });
            return toAnswer;
        }
        return [];
    }

    
    /**
     * Gets all terminals as editable objects.
     */
    static async getTerminalsForEdit() { 
        const loader = createStoreLoader(store);
        try {
            let requestBody = JSON.stringify({p_isport: 1, prod: true});
            let response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getAllCompanies`, requestBody);
            checkApiResponseForErrors(response);

            AppFunctionalityUsable.editableTerminals = response.map(cop => new Company(cop));

            requestBody = JSON.stringify({p_isps: 1, prod: true});
            response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getAllCompanies`, requestBody);
            checkApiResponseForErrors(response);

            let publicServiceTerminals = response.map(cop => new Company(cop));
            //::> Remove duplicates
            publicServiceTerminals = publicServiceTerminals.filter(ps => !AppFunctionalityUsable.editableTerminals.find(psInside => psInside.p_id === ps.p_id));
            AppFunctionalityUsable.editableTerminals = [...AppFunctionalityUsable.editableTerminals, ...publicServiceTerminals]
         
            store.dispatch(update());
        } catch (err) {
            store.dispatch(err.message);
        }
        loader.stopLoading();
    }

    static async getAllExtras(forceGet=false) { 
        try {

            if(!forceGet){
                if(AppFunctionalityUsable.hasGottenAllExtras)
                    return;
            } else { 
                AppFunctionalityUsable.idDocumentTypes = [AppFunctionalityUsable.idDocumentTypes[0]]
                AppFunctionalityUsable.departments = [AppFunctionalityUsable.departments[0]]
                AppFunctionalityUsable.reasonsOfVisit = [AppFunctionalityUsable.reasonsOfVisit[0]]
                AppFunctionalityUsable.gates = [AppFunctionalityUsable.gates[0]]
                AppFunctionalityUsable.entranceRejectionReasons = [AppFunctionalityUsable.entranceRejectionReasons[0]]
                AppFunctionalityUsable.exitRejectionReasons = [AppFunctionalityUsable.exitRejectionReasons[0]]
                AppFunctionalityUsable.visitRejectionReasons = [AppFunctionalityUsable.visitRejectionReasons[0]]
            }

            const requestBody = {};
            const response = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/extras/getAllExtras`, requestBody);    
            checkApiResponseForErrors(response);
            
            AppFunctionalityUsable.idDocumentTypes = [...AppFunctionalityUsable.idDocumentTypes,
                ...response[ID_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.departments = [...AppFunctionalityUsable.departments,
                ...response[DEPARTMENT_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.reasonsOfVisit = [...AppFunctionalityUsable.reasonsOfVisit,
                ...response[RPP_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.gates = [...AppFunctionalityUsable.gates,
                ...response[GATE_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.entranceRejectionReasons = [...AppFunctionalityUsable.entranceRejectionReasons,
                ...response[MRE_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.exitRejectionReasons = [...AppFunctionalityUsable.exitRejectionReasons,
                ...response[MRS_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];

            AppFunctionalityUsable.visitRejectionReasons = [...AppFunctionalityUsable.visitRejectionReasons,
                ...response[MRP_TYPE]
                    .map(extra => new Extra(extra))
                    .sort((a, b) => a.name > b.name ? 1 : -1)];
            
		} catch (err) {
            store.dispatch(toastErrorMessage("Could not get configurations."));
            store.dispatch(toastErrorMessage(err.message));
		}
    }


    /**
     * Gets all main data that the apps needs to function.
     */
    static async getMainFunctionalityUsabes (forceGet=false) { 
        AppFunctionalityUsable.getAllExtras(forceGet);
        await AppFunctionalityUsable.getTerminals();
        await AppFunctionalityUsable.getAlertMessages();
        await AppFunctionalityUsable.getCompanies();
        await AppFunctionalityUsable.getInductionTypes();

        AppFunctionalityUsable.update();
        return true;
    }
    


    /**
     * Clears All Functionality Usables
     */
    static clearAllFuncUsables() { 
        AppFunctionalityUsable.idDocumentTypes = [AppFunctionalityUsable.idDocumentTypes[0]] 
        AppFunctionalityUsable.departments = [AppFunctionalityUsable.departments[0]] 
        AppFunctionalityUsable.reasonsOfVisit = [AppFunctionalityUsable.reasonsOfVisit[0]] 
        AppFunctionalityUsable.gates = [AppFunctionalityUsable.gates[0]] 
        AppFunctionalityUsable.entranceRejectionReasons = [AppFunctionalityUsable.entranceRejectionReasons[0]] 
        AppFunctionalityUsable.exitRejectionReasons = [AppFunctionalityUsable.exitRejectionReasons[0]] 
        AppFunctionalityUsable.visitRejectionReasons = [AppFunctionalityUsable.visitRejectionReasons[0]] 
    }


    /**
     * Gets the next Question ID for creating a new Induction Question
     */
    static async getNextQuestionId () { 
        try {
            const body = JSON.stringify({});
            const res = await sendRequestDataWithDefaultConfig(`${ROOT_URL}/admin/getIndQId`, body);
            checkApiResponseForErrors(res);
            AppFunctionalityUsable.nextQuestionId = res + 1;
            store.dispatch(update());
        } catch (err) {
            store.dispatch(err.message);
        }
    }

    /**
     * Resets the Question Id of a new Available id.
     */
    static async resetQuesionId() { 
        AppFunctionalityUsable.nextQuestionId = -1;
        AppFunctionalityUsable.getNextQuestionId();
        store.dispatch(update());
    }

    /**
     * Gets all available reports
     * @param {bool} isToGetAll 
     */
    static async getReports() { 
        try {
            const config = getRequestConfigurations();
            const res = await Axios.get(`${ROOT_URL}/admin/get_report_ssrs_view`,config);
            checkResponseError(res);
            AppFunctionalityUsable.reports = res.data;
            store.dispatch(update());
        } catch (err) {
            const errors = getErrorMessage(err);
            errors.forEach(err => {
                store.dispatch(setMessage(err, ERROR_TOAST));
            });
        }
    }
    

}

export default AppFunctionalityUsable;
