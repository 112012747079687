import TableNavigation from '@components/MainPage/Shared/TableNavigation';
import { DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE } from '@utils/constants';
import { calculateNumberOfPages, doesArrayExist, isEmptyValue } from '@utils/utils';
import React, { useState } from 'react'
import { Edit3, MinusCircle, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import CustomInput from 'reactstrap/lib/CustomInput';
import { v4 } from 'uuid';

const SettingsItemTable = ({items, openEditModal, openAddModal, options, updateFunction}) => {
    const { t } = useTranslation('settings');
    const [state, setState] = useState({
        page: 1,
        flag: false,
    });
    const {page} = state;
    
    // ::: TABLE NAVIGATION
    const { showAddBtn , title, canEdit } = options;
    const  totalPages =  calculateNumberOfPages( doesArrayExist(items)? items.length: 1, DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE);;
    const next = e => {setState({...state, page: page === totalPages? totalPages: page + 1})};
    const prev = e => {setState({...state, page: page === 1? page: page - 1})};
    const setPage = (e, page) => {setState({...state, page })}
    const indicators = Array.from(Array(totalPages)).map((e, i) => i + 1);

    // ::: EVENT HANDLERS
    const setAndSubmit = async (e, item) => { 
        let isSuccessful = false;
        if(isEmptyValue(item.setAndSubmit)) {
            item['p_active'] = item.active? 0:1;
            item['active'] = item['p_active'];
            isSuccessful = await updateFunction(item);
        } else {
            item.setAndSubmit(e.target.name, item.active? 0:1);
        }

        if(isSuccessful)
            setState({...state, flag: !state.flag});
    }


    // ::::::::::::::::::::::::::::::::::::::::::::::: GENERATED HTML ELEMENTS
    const itemstoShow = doesArrayExist(items)? items.filter((req, index) => (index >= ((page-1) * DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE)) && (index < (page * DEFAULT_NUMBER_ITEMS_TALBE_SETTINGS_PAGE))) : [];
    const _listOfItems = itemstoShow.map((item, idx) =>  { 
        return <tr key={v4()}>
                    <td ><span className="d-inline-block text-truncate" style={{maxWidth:"38rem"}}>{item.name}</span></td>
                    <td>
                        <CustomInput type="switch" name="active" checked={item.active ? "checked" : false} id={`customSwitch${idx}`} onChange={e => setAndSubmit(e, item)} inline label={item.active? t('active'):t("deactivated")} />
                    </td>
                    <td onClick={e => openEditModal(e, item)} className="text-center hoverable">
                        {canEdit && <Edit3 size="20px"/>}
                        {!canEdit && <MinusCircle size="20px" color="#eeeeee"/>}
                    </td>
                </tr>
    });

    const _addItemButton = <span className="faded-button success right-sided-button mr-3" onClick={e => openAddModal(e)}>
                                <Plus size="14px" className="mr-2"/>
                                {t('add')}
                            </span>;


    // ::: CHILDREN PROPERTIES
    const componentData = { 
        pageIndicators: indicators,
        totalPages,
        totalItems: items? items.length: 0,
        page,
        next,
        prev,
        setPage
    }

    return (
        <div className="elevated-container table-container mt-4">
            {/* ::: TITLE OF CONTAINER */}
            <span className="pl-2">{title}</span>
            {/* ::: ADD ITEM BUTTON */}
            {showAddBtn && _addItemButton}
            <div className="separator transparent my-2"/>
            {/* ::: TABLE FOR CONTENT */}
            <table className="striped-table default-line-height">
                <thead>
                    <tr>
                        <th>{t('name')}</th>
                        <th>{t('act_dea')}</th>
                        <th className="text-center">{t('action')}</th>
                    </tr>
                </thead>
                {/* ::: ALL ITEMS */}
                <tbody>
                    {_listOfItems}
                </tbody>
            </table>
            {/* ::: TABLE NAVIGATION */}
            <TableNavigation componentData={componentData}/>
        </div>
    )
}

export default SettingsItemTable
