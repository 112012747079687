import React, { Fragment, useState } from 'react'
import { Container, Badge, Button} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { calculateLastTime, isCoreSecurityUser, getInductionMessageNColor, doesObjectExist, doesArrayExist, simulateVisitApproval, isVisitorOfVisitInducting, isVisitorOfVisitNotInducting, isThreadUserFromMPDC, isCAMemberUser } from '@utils/utils';
import {forceInductionResultToVisitor, send_induction_request, send_induction_request_all } from '@actions/admin'
import PropTypes from 'prop-types'
import { setMessage, toastErrorMessage } from '@actions/toast';
import { ERROR_TOAST, HAS_INDUCTION, INDUCTION } from '@utils/constants';
import { connect } from 'react-redux';
import { canUserTakeAction, getAllVisitMaterials, getAllVisitVehicles } from '@actions/visits';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';




const VisitViewPage2 = ({visit,user, setMessage, forceInductionResultToVisitor, send_induction_request, send_induction_request_all, toastErrorMessage}) => {
    const { t } = useTranslation('main');
    const [state, setState] = useState({
        flag: false,
    });
    const {grouped} = visit || {grouped: []};

    // ::::::::::::::: HTML VIEW GENERATION
    let inductionStateMessage;
    const disableForceInduction = visit.sv_is_ps;
    const visitors = grouped.filter( visit => user.p_id !== visit.p_id_x);
    const groups = visitors.map(visit => { 
        let inductionMessageNdColor = getInductionMessageNColor(visit.is_induction);
        inductionStateMessage = isVisitorOfVisitInducting(visit)? t('inducting'):t('induct');
        const hasInd = visit.states[HAS_INDUCTION];
        if(!hasInd) inductionMessageNdColor = ['Never Inducted', 'warning'];

        return (
            <tr key={v4()}>
                <td className="hoverable text-truncate td" >{`${visit.v_name}`}</td>
                <td className="hoverable text-truncate td" >
                    <span className="tr-span mx-2">{`${calculateLastTime(visit.dtm_deep_induction)}`}</span>
                    <Badge className="ml-1" color={`${inductionMessageNdColor[1]}`}>{inductionMessageNdColor[0]}</Badge>
                </td>
                <td className="hoverable text-truncate td" >
                    
                    {isCoreSecurityUser(user) || isCAMemberUser(user) || isThreadUserFromMPDC(user)? 
                    <Fragment>
                        <Button disabled={disableForceInduction} color="primary" size="sm" className="mr-1" onClick={e => sendInductionReqSingle(e, visit)} >{inductionStateMessage}</Button>
                        <Button color="success" disabled={isVisitorOfVisitNotInducting(visit)} size="sm" className="mr-1" onClick={e => sendForcedInduction(e, visit, 1)}>{t('approve')}</Button>
                        <Button color="danger" disabled={isVisitorOfVisitNotInducting(visit)} size="sm" className="mr-1" onClick={e => sendForcedInduction(e, visit, 0)} >{t('reject')}</Button>
                    </Fragment> : "-"
                    }
                </td>
            </tr>
        )
    })

    // ::: ENVENT HANDLERS
    const sendInductionReqSingle = async (e, visitorVisit, inducting) => {
        if (inducting) return setMessage(t('cant_sub_to_ind'), ERROR_TOAST);
        let isSuccessful = false;
        if(canUserTakeAction(visit, user))
            isSuccessful = await send_induction_request({ p_id_x: visitorVisit.p_id_x, p_id_svisit: visit.p_id });
        else 
            toastErrorMessage(t('hos_not_tak_act'))

        if(isSuccessful)
            visitorVisit.states[INDUCTION] = true;
        setState({...state, flag: !state.flag});
    };

    const sendForcedInduction = async (e, visitorVisit, verdict) => { 
        const indCode = AppFunctionalityUsable.inductionTypes[1].code; 
        const isSuccessful = await forceInductionResultToVisitor(visitorVisit, verdict, indCode);
        if(isSuccessful)
            simulateVisitApproval(visitorVisit, verdict);
        setState({...state, flag: !state.flag});
    }
      
    const sendInductionReq = async (e) => {
        let users = [];
        visit.grouped.forEach(visit => users = [...users, visit.p_id_x]);
        const p_id_svisit = visit.p_id;
        const isSuccessful = await send_induction_request_all({ p_id_svisit, users }); //::>> This sends the code of the visit and not the code of the host
        if(isSuccessful)
            visitors.forEach(visit => visit.states[INDUCTION] = true);
        setState({...state, flag: !state.flag});
    };

    // ::::::::::::::::::::::::::::::::::::::::::::: HTML GENERATION 
    const renderMaterials = () => { 
        let vehicles;
        let materials;
        const canGenerateMaterialsView = doesObjectExist(visit) && doesArrayExist(visit.extraArray);
        if (canGenerateMaterialsView){
          materials = getAllVisitMaterials(visit);
          vehicles = getAllVisitVehicles(visit);
  
          materials = materials.map(material => { 
              return (
                  <tr key={v4()}>
                        <td className="hoverable text-truncate td">{material.p_oname}</td>
                        <td className="hoverable text-truncate text-center td">{material.p_qnt}</td>
                  </tr>
              )
          })
  
          vehicles = vehicles.map(vehicle => { 
              return (
                <tr key={v4()}>
                    <td className="hoverable text-truncate td">{vehicle.p_oname}</td>
                    <td className="hoverable text-truncate text-center td"> - </td>
                </tr>
              )
          })
        }
  
        return [vehicles, materials];
    }

    const materials = renderMaterials();

    return (
        <Container fluid={true}>
            {grouped && (grouped.length > 0) && 
            <Fragment>
                <h6 className="text-bold">{t('participants')}</h6>
                <div className="block-user-container">
                    <table className="striped-table ">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th>{t('induction')}</th>
                                <th>{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups}
                        </tbody>
                    </table>
                </div>
            </Fragment>
            }
            
            {(isCoreSecurityUser(user) || isCAMemberUser(user)) && (grouped.length > 1) && <Button color="primary" size="sm" className={`hoverable d-inline-block text-center btn-yellow rounded-sm px-1 py-1 my-2`} onClick={(e) => sendInductionReq(e)} >{t('ind_all')}</Button>}

            {doesArrayExist(materials[1]) && 
                <Fragment>
                    <h6 className="text-bold mt-4">{t('material')}</h6>
                    <div className="block-user-container">
                        <table className="striped-table ">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th className="text-center">{t('quantity')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materials[1]}
                            </tbody>
                        </table>
                    </div>
                </Fragment>}

                
            { doesArrayExist(materials[0]) && 
                <Fragment>
                    <h6 className="text-bold mt-4">{t('vehicle')}</h6>
                    <div className="block-user-container">
                        <table className="striped-table ">
                            <thead>
                                <tr>
                                    <th>{t('license_plate')}</th>
                                    <th className="text-center">{t('quantity')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materials[0]}
                            </tbody>
                        </table>
                    </div>
                </Fragment>}
        </Container>
    )
}


VisitViewPage2.propTypes = {
    user: PropTypes.object,
    flag: PropTypes.bool,
    setMessage: PropTypes.func.isRequired,
    send_induction_request_all: PropTypes.func.isRequired,
    send_induction_request: PropTypes.func.isRequired,
    forceInductionResultToVisitor: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    user: state.auth.user,
})

const mapDispatchToProps = {
    setMessage,
    send_induction_request, 
    send_induction_request_all,
    forceInductionResultToVisitor,
    toastErrorMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitViewPage2);

