import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import SettingsItemTable from '../Shared/SettingsItemTable';
import AlertMsgEditModal from './AlertMsgEditModal';

const AlertMessageSettingsTable = ({modal_data, open_modal, openAddModal}) => {
    const {t} = useTranslation('settings');
    const onEditMessage = (e, alertMessage) => {
        open_modal('_', alertMessage )
    }

    // ::: CHILDREN PROPERTIES
    const tableOptions =  { 
        title: t('messages'),
        canEdit: true,
        showAddBtn: true,
    }


    return (
        <Fragment>
            <SettingsItemTable items={AppFunctionalityUsable.alertMessages} openEditModal={onEditMessage} openAddModal={openAddModal} options={tableOptions} />
            {modal_data && <AlertMsgEditModal/>}
        </Fragment>
    )
}

export default AlertMessageSettingsTable
