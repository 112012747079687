import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { close_modal } from '@actions/admin'
import { useTranslation } from 'react-i18next'
import SunEditor from 'suneditor-react'

const AlertMessageModal = ({modal, modal_data, close_modal}) => {
    const { t } = useTranslation('main');
    const onClose = e => {
        close_modal();
    }

    return (
        <Modal isOpen={true} size="lg" className="no-border" centered>
              <h2 className="tag t5x px-5 text-center w-75 m-auto position-relative ">
            {modal_data? modal_data.p_title : "Title" }
            </h2>
        <ModalBody  className="px-4" style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
            {/* {modal_data? modal_data.p_msg: t('no_mes')} */}
            <SunEditor showToolbar={false} enableToolbar={true} name="p_msg" defaultValue={modal_data.p_msg} setOptions={{
                            height: 400,
                        }} />
        </ModalBody>
        <ModalFooter>
          <div className=" w-100 d-flex align-items-center justify-content-between">
            <div>
              <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
}

AlertMessageModal.propTypes = {
    modal_data: PropTypes.object,
}

const mapStateToProps = (state) => ({
    modal_data: state.admin.modal_data,
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessageModal)
