import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SideNavigation from '@mainPage/Shared/SideNavigation'
import Main from './Visitors/Main'
import { Redirect } from 'react-router-dom'
import { isVisitorUser, isHostUser, isThreadUser, isServiceProviderUser, isEmptyObject, isCoreSecurityUser, getObjectFromLocalStorage, isCAMemberUser } from '@utils/utils';
import { logout, recoverUserDataToReduxStore } from '@actions/auth'
import { MY_ACCOUNT_MODAL, REQUEST_PANEL, MAIN_PANEL, SETTINGS_PANEL, REPORTS_PANEL, USER_LOCALSTORAGE_KEY } from '@utils/constants';
import MyAccountModal from '@components/MainPage/Colaborators/Shared/UtilsModals/MyAccountModal';
import MainHosts from './Colaborators/MainHosts';
import MainPanel from '@components/MainPage/Colaborators/Shared/MainPanel/MainPanel';
import Settings from '@colaboratorsPages/CoreSecurity/SettingsPage/Settings';
import Reports from '@colaboratorsPages/CoreSecurity/Reports';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { Fragment } from 'react';
import { fetchLoggedUserVisits } from '@actions/visits';
import ErrorBoundary from '@components/Shared/ErrorBoundary'
import { getServerConfig } from '@utils/configurations'

const MainPage = ({loggedUser, page,toLogOut, logout, recoverUserDataToReduxStore, modalID, fetchLoggedUserVisits}) => {
    const [state, setState] = useState({
        hasMadeUserCheck: false,
        hasGottenConfig: false,
    });
    const {hasMadeUserCheck} = state;
    let _MainContent = <div className="my-4"><img src={require(`@resources/icons/loading.gif`)} className="d-block m-auto" style={{width: "45px", height: "45px"}} alt="laoding_gif"/></div>;

    useEffect(() => { 
        const retrieveConf = async () => { 
            await getServerConfig();
            const savedUser = getObjectFromLocalStorage(USER_LOCALSTORAGE_KEY);
            fetchLoggedUserVisits(savedUser); 
            setState({...state, hasMadeUserCheck: true, hasGottenConfig: true});
        }
        
        recoverUserDataToReduxStore();
        retrieveConf();
        AppFunctionalityUsable.getMainFunctionalityUsabes();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    if (hasMadeUserCheck && isEmptyObject(loggedUser)) return <Redirect to="/login"/>;
    if (toLogOut) return <Redirect to="/login"/>; 

    if (isServiceProviderUser(loggedUser)){
        _MainContent = <Main/>
    } else if(isVisitorUser(loggedUser)){
        _MainContent = <Main/>
    } else if (isHostUser(loggedUser)){
        _MainContent = <MainHosts/>
    } else if (isThreadUser(loggedUser)){ 
        _MainContent = page === REQUEST_PANEL? <MainHosts/> : <MainPanel/>;
    } else if (isCoreSecurityUser(loggedUser) || isCAMemberUser(loggedUser)) {
        switch(page) { 
            case REQUEST_PANEL: _MainContent = <MainHosts/>;break;
            case MAIN_PANEL: _MainContent = <MainPanel/>;break;
            case SETTINGS_PANEL: _MainContent = <Settings/>;break;
            case REPORTS_PANEL: _MainContent = <Reports/>;break;
            default: _MainContent = <MainHosts/>
        }
    }

    return (
        <ErrorBoundary>
            <div className="container-internal-page">
                {loggedUser && 
                <Fragment>
                    {/* :: SIDE NAV */}
                    <SideNavigation/>
                    <main className="internal-main-content">
                        {/* :: INTERNAL TOP NAV */}
                        {/* :: INTERNAL MAIN CONTENT */}
                        {_MainContent}
                    </main>
                    {/* :: GENERAL PRIVILEGE MODALS */}
                    {(modalID === MY_ACCOUNT_MODAL) && <MyAccountModal/>}
                </Fragment>
                }
            </div>
        </ErrorBoundary>
       
    )
}

MainPage.propTypes = {
    logout: PropTypes.func.isRequired,
    recoverUserDataToReduxStore: PropTypes.func.isRequired,
    page: PropTypes.string,
    modalID: PropTypes.string,
    toLogOut: PropTypes.bool.isRequired,
    loggedUser: PropTypes.object,
    fetchLoggedUserVisits: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    modalID: state.admin.modal,
    page: state.admin.page,
    loggedUser: state.auth.user,
    toLogOut: state.auth.toLogOut
})

const mapDispatchToProps = {
    logout,
    recoverUserDataToReduxStore,
    fetchLoggedUserVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
