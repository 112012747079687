import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setMessage } from '@actions/toast'
import { open_modal } from '@actions/admin'
import AddTerminalModal from './AddTerminalModal'
import TerminalSettingsTable from './TerminalSettingsTable'
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable'


const TerminalSettingsTab = ({flag, loggedUser, setMessage, open_modal, modal_data}) => {
    const [state, setState] = useState({
        toShowAddModal: false,
    })

    // ::: EVENT HANDLERS
    const openAddModal = e => { 
        setState({...state, toShowAddModal: true});
    }

    const closeAddModal = e => { 
        setState({...state, toShowAddModal: false});
    }

    useEffect(() => { 
        AppFunctionalityUsable.getTerminalsForEdit();
    }, [])

    return (
        <Fragment>
            <AddTerminalModal setMessage={setMessage} userId={loggedUser.p_id} toShow={state.toShowAddModal} closeModal={closeAddModal} />
            <TerminalSettingsTable open_modal={open_modal} modal_data={modal_data} openAddModal={openAddModal}  />
        </Fragment>
    )
}

TerminalSettingsTab.propTypes = {
    flag: PropTypes.bool,
    setMessage: PropTypes.func.isRequired,
    open_modal: PropTypes.func.isRequired,
    modal_data: PropTypes.object,
    loggedUser: PropTypes.object,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data,
    loggedUser: state.auth.user
})

const mapDispatchToProps = {
    setMessage,
    open_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminalSettingsTab)
