import React, { Fragment, useState } from 'react'
import { Form, Input } from 'reactstrap'
import ExtraEditModal from './ExtraEditModal';
import { DEPARTMENT_TYPE, GATE_TYPE, ID_TYPE, MRE_TYPE, MRP_TYPE, MRS_TYPE, PROVENANCE_TYPE, RPP_TYPE, TIN_TYPE } from '@utils/constants'
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';
import { getAppConfig } from '@utils/configurations';
import SettingsItemTable from '../Shared/SettingsItemTable';
import { Filter } from 'react-feather';
import FormGroup from 'reactstrap/lib/FormGroup';


const ExtraSettingsTable = ({modal_data, open_modal, openAddModal}) => {
    const { t } = useTranslation('settings');
    const { ETYPE_BY_SETTINGS } = getAppConfig();
    const [state, setState] = useState({
        filter: DEPARTMENT_TYPE,
    })
    const {filter} = state;
    
    // ::: FILTERS
    let extras;
    switch (filter) { 
        case DEPARTMENT_TYPE: extras = AppFunctionalityUsable.departments;break;
        case PROVENANCE_TYPE: extras = AppFunctionalityUsable.provenances;break;
        case GATE_TYPE: extras = AppFunctionalityUsable.gates;break;
        case MRE_TYPE: extras = AppFunctionalityUsable.entranceRejectionReasons;break;
        case MRS_TYPE: extras = AppFunctionalityUsable.exitRejectionReasons;break;
        case MRP_TYPE: extras = AppFunctionalityUsable.visitRejectionReasons;break;
        case ID_TYPE: extras = AppFunctionalityUsable.idDocumentTypes;break;
        case RPP_TYPE: extras = AppFunctionalityUsable.reasonsOfVisit; break;
        case TIN_TYPE: extras = AppFunctionalityUsable.inductionTypes;break;
        default: extras = AppFunctionalityUsable.departments;
    }
    extras = extras.filter((extra, index) => index !== 0);

    // ::: EVENT HANDELRS
    const onEditExtra = (e, extra) => { 
        open_modal('_', extra )
    }

    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if(nodeName === 'filter'){
            setState({...state, filter: nodeValue, filterTypeValue: ''})
        } else { 
            setState({...state, filterTypeValue: e.target.value});
        }
    }

    // ::: CHILDREN PROPERTIES
    const tableOptions =  { 
        title: t('extras'),
        canEdit: true,
        showAddBtn: true,
    }


    return (
        <Fragment>
            {/* ::: FILTERS */}
             <div className="my-2 d-flex justify-content-end">
                <Form inline>
                    <FormGroup>
                        <Filter size="24px" className="mx-3"/>
                        <Input type="select" name="filter" className="d-inline-block" value={filter} onChange={e => onChange(e)}>
                            {Object.keys(ETYPE_BY_SETTINGS).map(key => { 
                                return <option key={v4()} value={ETYPE_BY_SETTINGS[key]}>{key}</option>
                            })}
                        </Input>
                    </FormGroup>
                </Form>
            </div>
            {/* ::: TABLE OF EXTRAS */}
            <SettingsItemTable items={extras} openEditModal={onEditExtra} openAddModal={openAddModal} options={tableOptions} />
            {modal_data && <ExtraEditModal/>}
        </Fragment>
    )
}

export default ExtraSettingsTable
