import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {setMessage, removeMessage} from '@actions/toast';
import {send_support_email} from '@actions/admin'
import {ERROR_TOAST, SIMPLE_SUPPORT_EMAIL} from '@utils/constants';
import { extractFieldsFromObj } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import NavigationBar from '@sharedComponents/Navbar';
import { logout } from '@actions/auth';

const EMAIL_INPUT_ICON = require("@resources/icons/email.svg");


const ContactUs = ({setMessage, logout ,removeMessage, send_support_email}) => {
    const { t } = useTranslation(['contactUs']);
    const [formData, setFormData] = useState({
        p_email: '',
        p_detail: '',
        validSubmission: true,
        sent: false
    })

    const {p_email, p_detail, sent} = formData;

    if (sent) return  <Redirect to="/"/>;

    const onChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const validate = () => {
        if(p_email.replace(" ", "").length === 0) return false;
        if(p_detail.replace(" ", "").length === 0) return false; 
        return true
    }

    const onCancel = (e) => { 
        logout();
    }

    const onSubmit = async e => {
        e.preventDefault();

        if(!validate()){
            setMessage(t('val_fie'), ERROR_TOAST);
            return setFormData({...formData, validSubmission: false})
        }

        setFormData({...formData, validSubmission: true});
        const requestData = extractFieldsFromObj(SIMPLE_SUPPORT_EMAIL, formData);
        const isSuccessful = await send_support_email(requestData);
        
        if (isSuccessful) 
            setFormData({...formData, sent: true})
        
    }


    return (
        <div className="container-front-page">
            <NavigationBar/>
            <main className="main-content auth-container registration-container">
                <div className="form-container">
                    <form className="auth-form dark-form">
                        {/* :: TITLE */}
                        <h2 className="form-header">{t('con_us')}</h2>
                        {/* :: EMAIL INPUT */}
                        <div className="form-group iconed-input">
                            <img src={EMAIL_INPUT_ICON} alt="user_icon" />
                            <input type="text" name="p_email" className="form-control dark-input" value={p_email} placeholder={t('email')} onChange={e => onChange(e)} />
                        </div>
                        {/* :: REASON TEXT AREA */}
                        <div className="form-group">
                            <textarea rows="4" cols="50" className="form-control dark-textarea required" value={p_detail} name={"p_detail"}  placeholder={t('reason')} onChange={onChange} />
                        </div>
                        {/* :: BUTTONS */}
                        <div className="form-buttons clearfix">
                            <Link type="button" to="/login" className="button link-button left-sided-button" onClick={e => onCancel(e)}>{t('cancel')}</Link>
                            <button type="submit" className="button dark-button submit-button right-sided-button"  onClick={e => onSubmit(e)}>{t('send')}</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>     
    )
}

ContactUs.propTypes = {
    setMessage: PropTypes.func.isRequired,
    removeMessage: PropTypes.func.isRequired,
    send_support_email: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
}

export default connect(null,{setMessage, logout, removeMessage, send_support_email})(ContactUs);
