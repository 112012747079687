import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {setMessage, removeMessage, toastErrorMessage} from '@actions/toast';
import {send_recover_password} from '@actions/admin'
import { useTranslation } from 'react-i18next';
import NavigationBar from '@sharedComponents/Navbar';
import { validateEmpty } from '@utils/utils';

const CONTACT_INPUT_ICON = require("@resources/icons/user-input.svg");

const ForgotPassword = ({send_recover_password}) => {
    const { t } = useTranslation(['auth']);
    const [formData, setFormData] = useState({
        p_contact: '',
        isSuccessful: false
    })

    const {p_contact, isSuccessful} = formData;
    if (isSuccessful) return  <Redirect to="/login"/>;

    const onChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        setFormData({...formData, [targetName]: targetValue});
    }

    const onSubmit = async e => {
        e.preventDefault();
        try {
            // validateEmail(p_contact);
            validateEmpty(p_contact);
            const result = await send_recover_password(p_contact);
            setFormData({...formData, isSuccessful: result})
        } catch (error) {
            toastErrorMessage(error.message);
        }       
    }

    
    return (
        <div className="container-front-page">
            <NavigationBar />
            <main className="main-content auth-container registration-container">
                    <div className="form-container">
                    {/* :: FORM */}
                    <form className="auth-form dark-form">
                        {/* :: TITLE */}
                        <h2 className="form-header">{t('rec_pass')}</h2>
                        {/* :: EMAIL INPUT */}
                        <div className="form-group iconed-input">
                            <img src={CONTACT_INPUT_ICON} alt="user_icon" />
                            <input type="text" className="form-control dark-input" name={"p_contact"} value={p_contact} placeholder={t('email_or_phone')} onChange={e => onChange(e)} />
                        </div>
                        {/* :: INFORMATION TEXT */}
                        <small className="text-left d-block m-0 text-white">{t('rec_mes')}</small>
                        {/* :: BUTTONS */}
                        <div className="form-buttons clearfix">
                            <Link type="button" to="/login" className="button link-button left-sided-button">{t('cancel')}</Link>
                            <button type="submit" className="button dark-button submit-button right-sided-button"  onClick={e => onSubmit(e)}>{t('send')}</button>
                        </div>
                    </form>
                    </div>
                </main>
            </div>

    )
}

ForgotPassword.propTypes = {
    send_recover_password: PropTypes.func.isRequired,
}

export default connect(null,{setMessage, removeMessage, send_recover_password})(ForgotPassword);
