import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { close_modal } from '@actions/admin';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import AppFunctionalityUsable from '@model/Extras/AppFunctionalityUsable';


const ExtraEditModal = ({modal_data, flag, close_modal}) => {
    const { t } = useTranslation('settings')
    const extra = modal_data;

    // ::: EVENT HANDLERS
    const onSave = async e => { 
        const isSuccessful = await extra.save();
        if (isSuccessful) {
            AppFunctionalityUsable.getMainFunctionalityUsabes(true);
            close_modal();
        }
    }

    const onClose = e => { 
        close_modal();
    }

    return (
        <Modal isOpen={true} size="md" centered>
            {/* ::: TITLE OF MODAL */}
            <h3 className="title-of-modal">{t("edi_ext")}</h3>
            <ModalBody style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                <Form>
                    <Row>
                        {/* ::: NAME OF EXTRA */}
                        <Col md={12}>
                            <Input type="text" name="p_name" placeholder={t('name')} value={extra.p_name} onChange={e => extra.set(e.target.name, e.target.value)}/>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                {/* ::: FORM BUTTONS */}
                <div className=" w-100 d-flex align-items-center justify-content-between">
                    <Button color="secondary" onClick={e => onClose(e)}> {t("cancel")} </Button>
                    <Button name='submit' color="success" onClick={e => onSave(e)} className="mx-3"> {t('submit')} </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

ExtraEditModal.propTypes = {
    flag: PropTypes.bool,
    close_modal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    modal_data: state.admin.modal_data
})

const mapDispatchToProps = {
    close_modal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraEditModal)
