import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getInputDateFormatFromString, getToday } from '@utils/utils'
import HostsVisitsTable from '@colaboratorsPages/Shared/HostsVisitsTable';
import { CREATE_REQUISITION_HOST_MODAL, CREATE_SERV_REQUEST_MODAL, CREATE_FAST_REQUEST_MODAL, REJECT_MODAL, VIEW_REQUISITION_MODAL, GRANT_ACCESS_MODAL, BLOCK_USER_MODAL, SEE_ALERT_MSG_MODAL, MIN_WINDOW_SIZE_FOR_TABLE_VIEW, CONFIRMATION_MODAL} from '@utils/constants';
import HostsSimpleVisitModal from '@colaboratorsPages/Shared/Visits/SimpleVisit/HostsSimpleVisitModal';
import ServicesVisitModal from '@colaboratorsPages/Shared/Visits/ServicesVisit/ServicesVisitModal'
import FastVisitModal from '@colaboratorsPages/Shared/Visits/FastVisit/FastVisitModal'
import RejectModal from '@mainPage/Shared/RejectModal'
import VisitViewModal from '@colaboratorsPages/Shared/UtilsModals/VisitViewModal/VisitViewModal'
import GrantAccessModal from '@components/MainPage/Colaborators/Shared/UtilsModals/GrantAccessModal/GrantAccessModal'
import BlockVisitorModal from '@colaboratorsPages/Shared/UtilsModals/BlockVisitorModal'
import AlertMessageModal from '@mainPage/Visitors/Shared/AlertMessageModal'
import { open_modal } from '@actions/admin'
import InternalNavBar from '@mainPage/Shared/InternalNavBar'
import { removeMessage, setMessage, toastErrorMessage } from '@actions/toast'
import MainHostsInfoNdFilterSection from './MainHostsInfoNdFilterSection'
import { buildVisitStateObj, canUserTakeAction, fetchAllVisitMaterials, fetchLoggedUserVisits, filterMainPageVisits, performAcceptVisitProcess } from '@actions/visits'
import { Fragment } from 'react'
import VisitorsVisitsCardView from '../Visitors/Shared/VisitorsVisitsCardView'
import ConfirmationModal from '../Shared/ConfirmationModal';

const MainHosts = ({loggedUser, modal, generalState, open_modal, setMessage,fetchLoggedUserVisits, filterMainPageVisits, removeMessage, visits, visitOnModal, toastErrorMessage, fetchAllVisitMaterials, performAcceptVisitProcess}) => {
    // const {t} = useTranslation('main');
    const [state, setState] = useState({
        filter: 'x_fname',
        filter_val: '',
        start_date: getInputDateFormatFromString(getToday().toISOString()),
        windowSize: window.innerWidth,
    });

    useEffect(() => {
        const handleResize = (e) => setState({...state, windowSize: window.innerWidth});
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize)};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const {filter, filter_val, start_date} = state;
    

    // ::: EVENT HANDLERS
    const onChange = e => { 
        const nodeName = e.target.name;
        const nodeValue = e.target.value;
        if(nodeName === 'filter_type') { 
            setState({...state, filter_val: nodeValue});
            filterMainPageVisits(filter, nodeValue);
        } else if(nodeName === 'filter') { 
            setState({...state, [nodeName]: nodeValue, filter_val: ''});
            filterMainPageVisits("all", nodeValue);
        } else if (nodeName === 'start_date'){
            setState({...state, start_date: nodeValue});
            filterMainPageVisits('p_date_time', nodeValue, loggedUser);
        } else if (nodeName === 'page_num'){
            setState({...state, page: +nodeValue? +nodeValue : 1})
        }
    }

    // ::: ENVENT HANDLERS FOR CHILDREN COMPONENTS
    const onRowClicked = async visit => { 
        try {
            await fetchAllVisitMaterials(visit, loggedUser);
            open_modal(VIEW_REQUISITION_MODAL, visit);
        } catch (error) {
            toastErrorMessage(error.message);
        }
    }

    const onClickToUpdateVisit = async (visit, canEdit) => { 
        await fetchAllVisitMaterials(visit, loggedUser);
        open_modal(CREATE_REQUISITION_HOST_MODAL, visit);
    }

    const onClickAcceptReject = async (e, visit) => { 
        const verdict = e.target.getAttribute('name') === "accept"? 1: 0;
        const visitStateObj = buildVisitStateObj(verdict);
        if(verdict){

            if(canUserTakeAction(visit, loggedUser)){
                open_modal(CONFIRMATION_MODAL, { 
                    funcToExecute: () => performAcceptVisitProcess(visitStateObj, visit, loggedUser), 
                    onSuccessFunc: () => fetchLoggedUserVisits(loggedUser),
                    actionName: "acc_vis"}
                );
            } else {
                toastErrorMessage('Cannot Accept Visist. Host has not taken action');
            }
            
        } else { 
            open_modal(REJECT_MODAL, visit);
        }
    }


    const parentProperties = {loggedUser: loggedUser, filter, filter_val, start_date, generalState};
    const parentFunctions = {setMessage, removeMessage, open_modal, onChangeFilter: onChange, fetchLoggedUserVisits};

    const isToDisplayTable = state.windowSize >= MIN_WINDOW_SIZE_FOR_TABLE_VIEW;
    const isToDisplayCardView = !isToDisplayTable;

    return (
        <Fragment>
            <InternalNavBar/>
            <div className="main-display-container">
                {/* :: FILTERS SECTION */}
                <MainHostsInfoNdFilterSection parentProperties={parentProperties} parentFunctions={parentFunctions}/>
                {/* :: TABLE VIEW DISPLAY */}
                {isToDisplayTable && <HostsVisitsTable visits={visits} actionsForChildren={{onRowClicked, onClickToUpdateVisit, onClickAcceptReject}} />}
                {isToDisplayCardView && <VisitorsVisitsCardView loggedUser={loggedUser} visits={visits} actionsForChildren={{onClickToUpdateVisit, onClickAcceptReject}}/>}
            </div>
            {(modal === CREATE_REQUISITION_HOST_MODAL) && <HostsSimpleVisitModal request={visitOnModal} /*reqUser={{}}*/ reqUser={visitOnModal.p_users? visitOnModal.p_users[0] : {}} />}
            {(modal === CREATE_SERV_REQUEST_MODAL) && <ServicesVisitModal visit={visitOnModal} />}
            {(modal === CREATE_FAST_REQUEST_MODAL) && <FastVisitModal visit={visitOnModal}/>}
            {(modal === REJECT_MODAL) && <RejectModal/>}
            {(modal === CONFIRMATION_MODAL) && <ConfirmationModal/> }
            {(modal === VIEW_REQUISITION_MODAL) && <VisitViewModal/>}
            {(modal === GRANT_ACCESS_MODAL) && <GrantAccessModal/>}
            {(modal === BLOCK_USER_MODAL) && <BlockVisitorModal/>}
            {(modal === SEE_ALERT_MSG_MODAL) && <AlertMessageModal/>}
            {(modal === SEE_ALERT_MSG_MODAL) && <AlertMessageModal/>}
        </Fragment>
    )
}

MainHosts.propTypes = {
    flag: PropTypes.bool,
    modal: PropTypes.string,
    loggedUser: PropTypes.object,
    setMessage: PropTypes.func.isRequired,
    removeMessage: PropTypes.func.isRequired,
    open_modal: PropTypes.func.isRequired,
    visits: PropTypes.array.isRequired,
    visitOnModal: PropTypes.object,
    filterMainPageVisits: PropTypes.func.isRequired,
    fetchLoggedUserVisits: PropTypes.func.isRequired,
    generalState: PropTypes.object.isRequired,
    fetchAllVisitMaterials: PropTypes.func.isRequired,
    toastErrorMessage: PropTypes.func.isRequired,
    performAcceptVisitProcess: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    flag: state.admin.flag,
    loggedUser: state.auth.user,
    modal: state.admin.modal,
    visits: state.visits.visits,
    visitOnModal: state.visits.visitOnModal,
    generalState: state.visits.generalState,
    
})

const mapDispatchToProps = {
    open_modal,
    setMessage,
    removeMessage,
    filterMainPageVisits,
    fetchLoggedUserVisits,
    fetchAllVisitMaterials,
    toastErrorMessage,
    performAcceptVisitProcess
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHosts)
