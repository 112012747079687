import React, { Fragment } from 'react';
import {doesArrayExist, removeDuplicatesOfArr} from '@utils/utils'
import { v4 } from 'uuid';

export default function CustomSelectOption ({options, hasId, isToFilter=true}) {
    let optionsList = <option value="0"> Loading... </option>;
    
    if (doesArrayExist(options)){
        const filteredOptions = removeDuplicatesOfArr(options);
        let optionsToShow = filteredOptions;
        if(isToFilter) {
            optionsToShow = filteredOptions.filter(option => {
                if((option.p_active === 0) || (option.p_active === false) || (option.active === false) || (option.active === 0))
                    return false;
                return true;
            })
        }
       
        optionsList = optionsToShow.map(option => { 
            return <option key={v4()} value={hasId? option.code:option.name}>{option.name}</option>
        })
    }
    return (
        <Fragment>
            {optionsList}
        </Fragment>
    )
}
